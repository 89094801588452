import Actions from '../../action'
import VestStyle from '../../../config/vests.json'

const initialState = {
    isLoading: false,
    error: false,
    selectedCombination: {
        style: VestStyle['style'].child[0],
        pocket: VestStyle['pocket'].child[0],
        breast_pocket: VestStyle['breast_pocket'].child[0],
        lapels: VestStyle['lapels'].child[0],
        edge: VestStyle['edge'].child[0],
        lapels_width: VestStyle['lapels_width'].child[0]
    },
    isVests: false
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.UPDATE_VEST_STYLE: {
            const { selectedCombination } = state;
            return {
                ...state,
                selectedCombination: {
                    ...selectedCombination,
                    [action.key]: action.data,
                }
            };
        }
        case Actions.UPDATE_SUIT_TYPE: {
            return {
                ...state,
                isVests: action.data
            };
        } case Actions.RESET_CANVAS: {
            return {
                ...state,
                ...initialState
            }
        } case Actions.RESET_STYLE: {
            return {
                ...state,
                ...initialState
            }
        }
        default:
            return state;
    }

};