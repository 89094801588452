import React from 'react'
import { withRouter } from 'react-router-dom'
import { detectBrowser } from '../../util/detect-browser';

class ShirtZoom extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {

        const { selectedCombination, isShowFolded, isShowFront, isShowBack, isShowSide, openModal, closeModal } = this.props;
        const { frontPlacket, frontCollar, frontSleeve,
            shirtPocket, modelImage, shirtBackPlacket,
            frontViewPant, frontCollarCenterButton, shirtCuffAll,
            defaultFoldedShirt, foldedPocket, frontPlacketButton, foldedShirtShadow,
            stripeFoldedButton, frontShirtBody, cuffButton,
            collarDownButton, foldedCollarCenterButton, foldedCollarDownButton, backCollar,
            backPantModal, shirtBackModal, backShirtSleeve,
            frontPocketButton, foldedPocketButton, shirtBackPleats, backShirtCuffButton,
            backShirtFit, sideModal, sidePant, sideCollar, sideCollarButton, sideCollarDownButton, sidePlacket,
            sideShirtSleeve, sideShirtFit, sidePocket, sidePlacketButton, foldedCuffHoleUrl, foldedCuffThreadUrl, foldedStripHole,
            foldedStripThread, sidePocketButton, foldedCollar, sidePlacketSingleButton, folded_collar_fabric,
            foldedCollarCenterButtonThread, front_collar_fabric, foldedCollarDownButtonThread, foldedCollarDownButtonHole,
            foldedCuffFabric, frontCollarCenterButtonThread,
            frontCollarDownButtonThread, frontCollarDownButtonHole, frontStripHole, frontStripThread, foldedPocketHole, foldedPocketThread,
            frontPocketThread, frontPocketHole, frontCuffFabric, backCuffFabric, sideCuffFabric, backElbowPatches, sidePocketThread,
            sidePocketHole, backCuffThreadUrl, backCuffHoleUrl, side_collar_fabric, sideCollarCenterButtonThread, sideCollarCenterButtonHole, sideCollarDownButtonHole,
            sideCollarDownButtonThread, sideStripThread, sideStripeHole, back_collar_fabric, sideCollarSingleButton } = selectedCombination || {}
        return (
            <React.Fragment>
                {
                    openModal && (
                        <div className={`modal-backdrop fade show`}></div>
                    )
                }
                <div className={`modal fade bg-white ${openModal ? 'show' : ''}`} tabIndex="-1" style={{ display: openModal ? `block` : 'none', paddingRight: openModal ? `0px` : '' }} role="dialog" aria-modal="true">
                    <div className="modal-dialog modal-lg zoom_full_width" role="document">
                        <div className="modal-content canva_zoom_hgt rounded-0">
                            <div className="modal-header border-0 px-4 py-4">
                                <button type="button" className="btn-close"
                                    onClick={() => closeModal()} data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"></span>
                                </button>
                            </div>
                            <div className="modal-body zoom-canvas">
                                <div className="">
                                    <div className='carousel-inner' data-interval="false">
                                        <div className={`carousel-item ${isShowFolded ? 'active' : ''}`}>
                                            <div className=" pi-shirt-containers">

                                                <div className={`pi_container w-75 m-auto pi_shirt_cuff`}>

                                                    <img className="custom_shirt shirt_shadow"
                                                        src={foldedShirtShadow}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back"
                                                        src={defaultFoldedShirt.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_collar"
                                                        src={foldedCollar.replace('.png', detectBrowser())}
                                                        alt='shirt'
                                                        id="folded_screenshot" />
                                                    <img className="custom_shirt shirt_collar_only"
                                                        src={folded_collar_fabric.replace('.png', detectBrowser())}
                                                        alt='shirt' />

                                                    <img className="custom_shirt shirt_collar_button"
                                                        src={foldedCollarCenterButton}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_collar_down_button"
                                                        src={foldedCollarDownButton}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_collar_down_button_hole"
                                                        src={foldedCollarDownButtonHole}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_collar_button_thread"
                                                        src={foldedCollarCenterButtonThread}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_collar_down_button_down_thread"
                                                        src={foldedCollarDownButtonThread}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_placket"
                                                        src={shirtBackPlacket.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_placket_button"
                                                        src={stripeFoldedButton}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_placket_hole"
                                                        src={foldedStripHole}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_placket_thread"
                                                        src={foldedStripThread}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_cuff_all"
                                                        src={shirtCuffAll.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_cuff_inner"
                                                        src={foldedCuffFabric.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_cuff_button"
                                                        src={cuffButton}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_cuff_button_hole"
                                                        src={foldedCuffHoleUrl}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_cuff_button_thread"
                                                        src={foldedCuffThreadUrl}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_pocket"
                                                        src={foldedPocket.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_pocket_button"
                                                        src={foldedPocketButton}
                                                        alt='shirt' />

                                                    <img className="custom_shirt shirt_back_pocket_hole"
                                                        src={foldedPocketHole}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_pocket_thread"
                                                        src={foldedPocketThread}
                                                        alt='shirt' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`carousel-item ${isShowFront ? 'active' : ''}`}>
                                            <div className=" pi-shirt-containers ">
                                                <div className={`pi_container w-75 m-auto pi_shirt_front`}>
                                                    <img className="custom_shirt shirt_man"
                                                        src={modelImage}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_sleeve"
                                                        src={frontSleeve.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_pant"
                                                        src={frontViewPant}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_fit"
                                                        src={frontShirtBody.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_cuff_fabric"
                                                        src={frontCuffFabric.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_collar"
                                                        src={frontCollar.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_collar_fabric"
                                                        src={front_collar_fabric.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_button"
                                                        src={frontCollarCenterButton}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_collar_down_button"
                                                        src={collarDownButton}
                                                        alt='shirt' />

                                                    <img className="custom_shirt shirt_collar_fabric_down"
                                                        src={frontCollarDownButtonHole}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_button_hole"
                                                        src={frontCollarCenterButtonThread}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_collar_center_button_fabric"
                                                        src={frontCollarDownButtonThread}
                                                        alt='shirt' />

                                                    <img className="custom_shirt shirt_placket"
                                                        src={frontPlacket.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_placket_button"
                                                        src={frontPlacketButton}
                                                        alt='shirt' />

                                                    <img className="custom_shirt shirt_placket_hole"
                                                        src={frontStripHole}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_placket_button_thread"
                                                        src={frontStripThread}
                                                        alt='shirt' />

                                                    <img className="custom_shirt shirt_pocket"
                                                        src={shirtPocket.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_pocket_button"
                                                        src={frontPocketButton}
                                                        alt='shirt' />

                                                    <img className="custom_shirt shirt_pocket_hole"
                                                        src={frontPocketHole}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_pocket_button_thread"
                                                        src={frontPocketThread}
                                                        alt='shirt' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`carousel-item ${isShowBack ? 'active' : ''}`}>
                                            <div className=" pi-shirt-containers">
                                                <div className={`pi_container w-75 m-auto pi_shirt_back`}>
                                                    <img className="custom_shirt shirt_back"
                                                        src={shirtBackModal}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_shirt_sleeve"
                                                        src={backShirtSleeve.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_pant"
                                                        src={backPantModal}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_shirt_fit"
                                                        src={backShirtFit.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_collar"
                                                        src={backCollar?.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_collar_fabric"
                                                        src={back_collar_fabric?.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_shirt_pleats"
                                                        src={shirtBackPleats?.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_shirt_pleats_elbow"
                                                        src={backElbowPatches?.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_shirt_cuff_fabric"
                                                        src={backCuffFabric?.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_shirt_cuff_hole"
                                                        src={backCuffHoleUrl}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_shirt_cuff_button"
                                                        src={backShirtCuffButton}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_shirt_cuff_thread"
                                                        src={backCuffThreadUrl}
                                                        alt='shirt' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`carousel-item ${isShowSide ? 'active' : ''}`}>
                                            <div className="pi-shirt-containers">
                                                <div className={`pi_container w-75 m-auto pi_shirt_back`}>
                                                    <img className="custom_shirt shirt_side"
                                                        src={sideModal}
                                                        alt='shirt' />
                                                    <img className="custom_shirt side_shirt_sleeve"
                                                        src={sideShirtSleeve.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt side_shirt_cuff"
                                                        src={sideCuffFabric.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_pant"
                                                        src={sidePant}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_side_fit"
                                                        src={sideShirtFit.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_side_collar"
                                                        src={sideCollar.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_side_collar_fabric"
                                                        src={side_collar_fabric.replace('.png', detectBrowser())}
                                                        alt='shirt' />

                                                    <img className="custom_shirt shirt_side_collar_center_button_hole"
                                                        src={sideCollarCenterButtonHole}
                                                        alt='shirt' />

                                                    <img className="custom_shirt shirt_side_collar_center_button"
                                                        src={sideCollarButton}
                                                        alt='shirt' />

                                                    <img className="custom_shirt shirt_side_collar_center_button_thread"
                                                        src={sideCollarCenterButtonThread}
                                                        alt='shirt' />

                                                    <img className="custom_shirt shirt_side_collar_down_button_hole"
                                                        src={sideCollarDownButtonHole}
                                                        alt='shirt' />

                                                    <img className="custom_shirt shirt_side_collar_single_button"
                                                        src={sideCollarSingleButton}
                                                        alt='shirt' />

                                                    <img className="custom_shirt shirt_side_collar_down_button"
                                                        src={sideCollarDownButton}
                                                        alt='shirt' />

                                                    <img className="custom_shirt shirt_side_collar_down_button_thread"
                                                        src={sideCollarDownButtonThread}
                                                        alt='shirt' />


                                                    <img className="custom_shirt shirt_side_pocket"
                                                        src={sidePocket?.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_side_pocket_hole"
                                                        src={sidePocketHole}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_side_pocket_button"
                                                        src={sidePocketButton}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_side_pocket_thread"
                                                        src={sidePocketThread}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_side_placket_single_button"
                                                        src={sidePlacketSingleButton}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_side_placket"
                                                        src={sidePlacket.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_side_placket_button_hole"
                                                        src={sideStripeHole}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_side_placket_button"
                                                        src={sidePlacketButton}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_side_placket_button_thread"
                                                        src={sideStripThread}
                                                        alt='shirt' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(ShirtZoom)