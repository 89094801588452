import React from 'react'
import { Constants } from '../../util/Constant'
export class FabricsDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }


    _renderCommonFabrics() {
        const { selectedFabric } = this.props
        const { name, tags, images, description, id, selectedColorCode } = selectedFabric || {}
        let selectedImages = images && images?.edges?.filter((o) => o?.node?.altText?.toLowerCase().includes('fabric')) || images && images?.filter((o) => o?.alt?.toLowerCase().includes('fabric'))
        let selected = selectedImages?.find((i) => i?.node?.altText?.includes(selectedColorCode)) || selectedImages?.find((i) => i?.alt?.includes(selectedColorCode)) || images && images.edges && images.edges[0]
        let selectedImage = selected?.node?.src || selected?.src
        return (
            <React.Fragment>
                <div className="modal fade" id="FabricDetail" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content">
                            <div className="modal-body p-0">
                                <div className='row h-100 m-0'>
                                    <div className='col-lg-6 px-0 fabric_detail_popup_col'>
                                        <img src={selectedImage} className='canvas_fabric_img' alt='fabric-detail' />
                                    </div>
                                    <div className='col-lg-6 px-4 py-lg-5 d-flex flex-column gap-24 fabric_detail_popup_col'>
                                        <div className='d-flex justify-content-end'>
                                            <button type="button" className="btn-close canvas_close_btn"
                                                data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <p className='H-cb fs-24'>{name ? name : Constants.DEFAULT_SHIRT_FABRICS.title}</p>
                                        <p className='H-l fs-14'>
                                            {
                                                tags ? <p>{tags?.join(', ')}</p> : <p>{Constants.DEFAULT_SHIRT_FABRICS.tags}</p>
                                            }</p>
                                        <p className='H-cb fs-24'>Description</p>
                                        <p className='fs-14 H-l'>{description ? description : Constants.DEFAULT_SHIRT_FABRICS.description}</p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    _renderShirtFabric() {
        const { selectedFabric } = this.props
        const { name, tags, images, description, selectedColorCode } = selectedFabric || {}
        let selectedImages = images && images?.edges?.filter((o) => o?.node?.altText?.toLowerCase().includes('fabric')) || images && images?.filter((o) => o?.alt?.toLowerCase().includes('fabric'))
        let selected = selectedImages?.find((i) => i?.node?.altText?.includes(selectedColorCode)) || selectedImages?.find((i) => i?.alt?.includes(selectedColorCode)) || images && images.edges && images.edges[0]
        let selectedImage = selected?.node?.src || selected?.src
        return (
            <div className="modal fade" id="FabricDetail" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            <div className='row h-100 m-0'>
                                <div className='col-lg-6 px-0 fabric_detail_popup_col'>
                                    <img src={selectedImage} className='canvas_fabric_img' alt='fabric-detail' />
                                </div>
                                <div className='col-lg-6 px-4 py-lg-5 d-flex flex-column gap-24 fabric_detail_popup_col'>
                                    <div className='d-flex justify-content-end'>
                                        <button type="button" className="btn-close canvas_close_btn"
                                            data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <p className='H-cb fs-24'>{name ? name : Constants.DEFAULT_SHIRT_FABRICS.title}</p>
                                    <p className='H-l fs-14'>
                                        {
                                            tags ? <p>{tags?.join(', ')}</p> : <p>{Constants.DEFAULT_SHIRT_FABRICS.tags}</p>
                                        }</p>
                                    <p className='H-cb fs-24'>Description</p>
                                    <p className='fs-14 H-l'>{description ? description : Constants.DEFAULT_SHIRT_FABRICS.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

    render() {
        return (
            <React.Fragment>
                {this.props.isShirt ? this._renderShirtFabric() : this._renderCommonFabrics()}
            </React.Fragment>
        )
    }
}
