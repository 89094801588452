
export const Constants = {
    PRODUCTS: {
        SHIRT: 'shirt',
        PANT: 'pant',
        SUIT: 'suit',
        BLAZER: 'blazer',
        VEST: 'vests'
    },
    FONT_FAMILY: {
        fontFamily: "Roboto Condensed, sansserif"
    },

    MEASUREMENT_PRODUCT_PATH: {
        SHIRT: '/canvas/shirt',
        PANT: '/canvas/pant',
        SUIT: '/canvas/suit',
        BLAZER: '/canvas/blazer',
        VESTS: '/canvas/vests'
    },

    MEASUREMENT_TYPES: {
        STANDARD_MEASUREMENT: 'StandardMeasurement',
        CUSTOM_MEASUREMENT: 'CustomMeasurement',
        COPY_MEASUREMENT: 'CopyMeasurement'
    },

    COUNTRIES: [{
        country: 'Thailand',
        countryCode: '+66',
        addressCode: 'Thailand'
    }, {
        country: 'Singapore',
        countryCode: '+65',
        addressCode: 'Singapore'
    }, {
        country: 'Malaysia',
        countryCode: '+60',
        addressCode: 'Malaysia'
    }, {
        country: 'USA',
        countryCode: '+1',
        addressCode: 'USA'
    }, {
        country: 'India',
        countryCode: '+91',
        addressCode: 'India'
    }],
    MEASUREMENT_OPTIONS: {
        INCH: 'in',
        CENTIMETER: 'cm'
    },

    SHOPIFY_TYPE_URL: {
        productID: 'gid://shopify/Product/',
        adminOrderId: 'gid://shopify/Order/'
    },

    HUBSPOT_API: {
        PRIMARY_FORM: 'https://api.hsforms.com/submissions/v3/integration/submit/8155811/82bbe0ae34794a57b257a3f430e5a928',
        MEASUREMEMT_FORM: 'https://api.hsforms.com/submissions/v3/integration/submit/8155811/875b26e001404b70a6642d0be13e269d'
    },

    DEFAULT_COMMON_FABRICS: {
        title: 'Medus Checkered CashmereWool in light blue',
        tags: 'Blue, Cashmere Wool, Checkered, Light Blue, Pure Wool',
        description: 'The Medus explores the brighter themes available in our collection, a checkered pattern available in beige and bluemix. This patterncolor combination lends itself as the perfect brunch outfit, best worn when paired with solid colors. The fabric is composed of cashmere wool which ensures wrinkle resistance and longlasting durability.'
    },
    DEFAULT_SHIRT_FABRICS: {
        title: 'Oliver CottonStretch in pink',
        tags: 'Egyptian Cotton Stretch, Pink, Solid',
        description: 'This shirt is machine washable at 30C°, make sure to wash with similar colors.'
    }

}


export const revealSameProductIDS = (productId) => {
    let pantId = undefined
    let vestId = undefined
    switch (productId) {
        case '7079274250404/':
            pantId = 6800239886500
            vestId = 7081696952484
            break

        case '7079992066212/':
            pantId = 6695004536996
            vestId = 7081698853028
            break
        case '7080036892836/':
            pantId = 6695034388644
            vestId = 7081699770532
            break
        case '7080064647332/':
            pantId = 6699035754660
            vestId = 7081705472164
            break
        case '7080082309284/':
            pantId = 6699037327524
            vestId = 7081707176100
            break
        case '7080276983972/':
            pantId = 6700834717860
            vestId = 7081708519588
            break
        case '7080315125924/':
            pantId = 6700873547940
            vestId = 7081709994148
            break
        case '7080343011492/':
            pantId = 6710400516260
            vestId = 7081710747812
            break
        case '7080347828388/':
            pantId = 6721889829028
            vestId = 7081751937188
            break
        case '7080363065508/':
            pantId = 6721877442724
            vestId = 7081754198180
            break
        case '7080367292580/':
            pantId = 6721888059556
            vestId = 7081754820772
            break
        case '7080388886692/':
            pantId = 6721884979364
            vestId = 7081755476132
            break
        case '7080392229028/':
            pantId = 6710419947684
            vestId = 7081756557476
            break
        case '7080399241380/':
            pantId = 6710367584420
            vestId = 7081757245604
            break
        case '7080474378404/':
            pantId = 6710511730852
            vestId = 7081757900964
            break
        case '7080494923940/':
            pantId = 6735327789220
            vestId = 7081758523556
            break
        case '7080503640228/':
            pantId = 6700922863780
            vestId = 7081758982308
            break
        case '7080513110180/':
            pantId = 6700878069924
            vestId = 7081929212068
            break
        case '7080516649124/':
            pantId = 6702844575908
            vestId = 7081930358948
            break
        case '7080521105572/':
            pantId = 6702851326116
            vestId = 7081931473060
            break
        case '7080524841124/':
            pantId = 6700881969316
            vestId = 7081932488868
            break
        case '7080528019620/':
            pantId = 6702856175780
            vestId = 7081933078692
            break
        case '7080532836516/':
            pantId = 6700885999780
            vestId = 7081934028964
            break
        case '7080541585572/':
            pantId = 6700887900324
            vestId = 7081935274148
            break
        case '7080544338084/':
            pantId = 6735330672804
            vestId = 7081936617636
            break
        case '7080547025060/':
            pantId = 6735332049060
            vestId = 7081937895588
            break
        case '7080549712036/':
            pantId = 6702857617572
            vestId = 7081938747556
            break
        case '7080551088292/':
            pantId = 6700898779300
            vestId = 7081940025508
            break
        case '7080485847204/':
            pantId = 7513636405412
            vestId = 7081940975780
            break
        case '7080536473764/':
            pantId = 6710453633188
            vestId = 7081941532836
            break
        case '7080545747108/':
            pantId = 6700912935076
            vestId = 7081949462692
            break
        case '7080550531236/':
            pantId = 6700916244644
            vestId = 7081959719076
            break
        case '7080558887076/':
            pantId = 6710495871140
            vestId = 7081960636580
            break
        case '7080559509668/':
            pantId = 6702859845796
            vestId = 7081962799268
            break
        case '7080561049764/':
            pantId = 6700899303588
            vestId = 7081978232996
            break
        case '7080565801124/':
            pantId = 6700918440100
            vestId = 7081978953892
            break
        case '7080573304996/':
            pantId = 6700919554212
            vestId = 7081979740324
            break
        case '7080580743332/':
            pantId = 6695012761764
            vestId = 7081980297380
            break
        case '7080583987364/':
            pantId = 6695018954916
            vestId = 7081980985508
            break
        case '7080588607652/':
            pantId = 6695021215908
            vestId = 7081982001316
            break
        case '7080593719460/':
            pantId = 6695028588708
            vestId = 7081982623908
            break
        case '7080601813156/':
            pantId = 6695035568292
            vestId = 7081985278116
            break
        case '7080605450404/':
            pantId = 6699029889188
            vestId = 7081990848676
            break
        case '7080608006308/':
            pantId = 6699033362596
            vestId = 7081992650916
            break
        case '7080612659364/':
            pantId = 6694107447460
            vestId = 7081993535652
            break
        case '7080619016356/':
            pantId = 6699031199908
            vestId = 7082001301668
            break
        case '7080620130468/':
            pantId = 6700911755428
            vestId = 7082002251940
            break
        case '7514648576164/':
            pantId = 6700825804964
            vestId = 7082003431588
            break
        case '7080621605028/':
            pantId = 6800255746212
            vestId = 7082009100452
            break
        case '7080622915748/':
            pantId = 6800249979044
            vestId = 7082010116260
            break
        default:
            pantId = 6800239886500
            vestId = 7081696952484
            break
    }
    return {
        pantId,
        vestId
    }
}


export const revealVestId = (productId) => {

    let suitId = undefined
    let vestId = undefined
    switch (productId) {
        case Constants.SHOPIFY_TYPE_URL.productID + '7081696952484':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7079274250404`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081710747812':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080343011492`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081699770532':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080036892836`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081705472164':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080064647332`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081707176100':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080082309284`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081708519588':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080276983972`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081709994148':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080315125924`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081751937188':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080347828388`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081754198180':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080363065508`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081754820772':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080367292580`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081755476132':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080388886692`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081756557476':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080392229028`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081757245604':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080399241380`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081757900964':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080474378404`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081758523556':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080494923940`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081758982308':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080503640228`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081929212068':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080513110180`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081930358948':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080516649124`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081931473060':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080521105572`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081932488868':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080524841124`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081933078692':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080528019620`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081934028964':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080532836516`
            break;

        case Constants.SHOPIFY_TYPE_URL.productID + '7081935274148':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080541585572`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081936617636':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080544338084`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081937895588':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080547025060`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081938747556':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080549712036`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081940025508':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080551088292`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081940975780':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080485847204`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081941532836':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080536473764`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081949462692':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080545747108`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081959719076':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080550531236`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081960636580':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080558887076`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081962799268':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080559509668`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081978232996':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080561049764`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081978953892':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080565801124`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081979740324':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080573304996`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081980297380':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080580743332`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081980985508':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080583987364`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081982001316':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080588607652`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081982623908':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080593719460`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081985278116':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080601813156`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081990848676':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080605450404`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081992650916':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080608006308`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7081993535652':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080612659364`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7082001301668':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080619016356`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7082002251940':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080620130468`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7082003431588':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7514648576164`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7082009100452':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080621605028`
            break;
        case Constants.SHOPIFY_TYPE_URL.productID + '7082010116260':
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080622915748`
            break;
        default:
            suitId = `${Constants.SHOPIFY_TYPE_URL.productID}7080082309284`
            break
    }
    return {
        suitId,
        vestId
    }
}