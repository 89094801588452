import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { convertInchMeters } from '../../../util/Canvas_Measurement/measurement'

class CustomizeSize extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { selectedData, reduceCount, increaseCount, selectedUnits } = this.props
        return (
            <React.Fragment>
                {selectedData?.map((obj, subIndex) => {
                    return (
                        <div className='d-flex flex-column custom_measurement mt-lg-4 mt-2 measure_scroll_size'>
                            <div className='row align-items-center'>
                                <p className='col-4 text_grey m-0 cursor-pointer fs-14 H-l'>
                                    {obj?.label}
                                </p>
                                <div className='col-6'>
                                    <div className='custom_measure'>
                                        <p className='fs-16 H-l m-0 cursor-pointer' onClick={() => reduceCount(subIndex)}><i className="bi bi-dash-lg"></i></p>
                                        <p className='fs-16 H-cb m-0'>{selectedUnits === 'in' ? convertInchMeters(obj?.size) : obj?.size}</p>
                                        <p className='fs-16 H-l m-0 cursor-pointer' onClick={() => increaseCount(subIndex)}><i className="bi bi-plus-lg"></i></p>
                                    </div>
                                </div>
                                <div className='col-2'>
                                    <p className='fs-14 H-l m-0'>{selectedUnits === 'in' ? 'inch' : 'cm'}</p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </React.Fragment>
        )
    }
}


const mapStateToProp = (state) => {
    return {}
}

export default withRouter(connect(mapStateToProp, {

})(CustomizeSize));