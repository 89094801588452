const NAME = 'SELECTED_FABRICS'
export const UPDATE_PANT_FABRICS = `${NAME}/UPDATE_PANT_FABRICS`;
export const SET_DEFAULT_PANT_FABRICS = `${NAME}/SET_DEFAULT_PANT_FABRICS`

export const getSelectedPantFabric = store => store[NAME].pant
export const updateSelectedPantFabric = (value) => {
    return {
        type: UPDATE_PANT_FABRICS,
        value
    }
}

export const setDefaultPantFabrics = (data, key) => {
    return {
        type: SET_DEFAULT_PANT_FABRICS,
        data,
        key
    }
}