import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootWatchers from './redux/saga';
import { reducers } from './redux/reducer'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['LOGIN', 'SELETED_STYLE', 'SELECTED_FABRIC', 'MANAGE_MEASUREMENT',
    'CHECKOUT', 'SELECTED_SUIT_ACCENT', 'CUSTOMER', 'ADMIN_ORDER_ID',
    'SELECTED_MEASUREMENT', 'MEASUREMENT', 'SELECTED_CHECK_OUT',
    'SELECTED_ACCENT', 'SELECTED_PANT_FABRIC', 'SELECTED_SUIT_FABRIC',
    'SELETED_SUIT_STYLE', 'SELECTED_ACCENT', 'PLP', 'SELECTED_FABRICS', 'SELECTED_STYLE', 'SELECTED_ACCENTS']
};
const sagaMiddleware = createSagaMiddleware();
let middleware;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  middleware = applyMiddleware(sagaMiddleware, logger)
} else {
  middleware = applyMiddleware(sagaMiddleware)
}
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(persistedReducer, {}, compose(middleware),);
export const persistor = persistStore(store);

sagaMiddleware.run(rootWatchers);

