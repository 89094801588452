import React from 'react'
import { withRouter } from 'react-router-dom'
import { detectBrowser } from '../../util/detect-browser';

class SuitZoom extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    // renderPant() {
    //     const { isLoading } = this.state
    //     const { foldedPantFit, foldedCuff, foldedPleats, foldedPocket,
    //         foldedSidePocket } = this.props.selectedPantCombination || {}
    //     return (
    //         <div className="pi-shirt-containers suit_p">
    //             <div
    //                 className={`pi_container pi-pant folded-pant w-75 m-auto pi_pant_cuff ${isLoading && 'v-hide'} `}>
    //                 <img className="custom_shirt folded_fit"
    //                     src={foldedPantFit}
    //                     alt='shirt' />
    //                 <img className="custom_shirt folded_cuff"
    //                     src={foldedCuff}
    //                     alt='shirt' />
    //                 <img className="custom_shirt folded_pleats"
    //                     src={foldedPleats}
    //                     alt='shirt'
    //                 />
    //                 <img className="custom_shirt folded_pocket"
    //                     src={foldedPocket}
    //                     alt='shirt' />

    //                 <img className="custom_shirt folded_side_pocket"
    //                     src={foldedSidePocket}
    //                     alt='shirt' />
    //             </div>
    //         </div>
    //     )
    // }
    render() {
        const { selectedCombination, isShowFolded, isShowFront, isShowBack, openModal, closeModal } = this.props;
        const { backModal, backShirt, backSleeve, backSuitFit, backPant, backStyle, frontNormalFit, frontModal, frontSuitFit,
            frontSleeve, frontShirt, frontTie, frontPant, frontBreastPocket, frontPocket, foldedSuitFit, foldedPocket,
            foldedBreastPocket, foldedNormalFit, foldedSleeve, foldedSuitButtons,
            foldedCollar, foldedInnerBottom, foldedInnerSuit, suitPocketsSquare, suitInner,
            suitBottomInner, backSuitButtons, frontSuitButtons, backButtonThread, backButtonHole, foldedPant,
            foldedButtonHole, foldedButtonThread, frontButtonHole, frontButtonThread, foldedLapelsHole, frontLapelsHole, suitVests, suitVestsButtons } = selectedCombination || {}
        return (
            <React.Fragment>
                {
                    openModal && (
                        <div className={`modal-backdrop fade show`}></div>
                    )
                }
                <div className={`modal fade ${openModal ? 'show' : ''}`} tabIndex="-1" style={{ display: openModal ? `block` : 'none', paddingRight: openModal ? `0px` : '' }} role="dialog" aria-modal="true">
                    <div className="modal-dialog modal-lg zoom_full_width" role="document">
                        <div className="modal-content canva_zoom_hgt rounded-0">
                            <div className="modal-header border-0 px-4 py-4">
                                <button type="button" className="btn-close"
                                    onClick={() => closeModal()} data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"></span>
                                </button>
                            </div>
                            <div className="modal-body zoom-canvas">
                                <div className="">
                                    <div className='carousel-inner' data-interval="false">
                                        <div className={`carousel-item ${isShowFolded ? 'active' : ''}`}>
                                            <div className="pi-shirt-containers">
                                                <div className={`pi_container pi-suit w-75 m-auto pi_suit_cuff`}>
                                                    <img className="custom_shirt folded_fit"
                                                        src={foldedNormalFit.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_collar"
                                                        src={foldedCollar.replace('.png', detectBrowser())}
                                                        alt='shirt' />

                                                    <img className="custom_shirt folded_inner_suit"
                                                        src={foldedInnerSuit.replace('.png', detectBrowser())}
                                                        alt='shirt' />

                                                    <img className="custom_shirt folded_collar"
                                                        src={suitInner}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_collar"
                                                        src={foldedInnerBottom.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_collar"
                                                        src={suitBottomInner}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={suitVests.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_vests_button"
                                                        src={suitVestsButtons}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={foldedSuitFit.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    {/* <img className="custom_shirt folded_lapels"
                                                        src={foldedLapelsHole}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={suitPocketsSquare}
                                                        alt='shirt' /> */}

                                                    <img className="custom_shirt folded_pleats"
                                                        src={foldedSleeve.replace('.png', detectBrowser())}
                                                        alt='shirt'
                                                    />
                                                    <img className="custom_shirt folded_pocket"
                                                        src={foldedPocket.replace('.png', detectBrowser())}
                                                        alt='shirt' />

                                                    <img className="custom_shirt folded_side_pocket"
                                                        src={foldedBreastPocket.replace('.png', detectBrowser())}
                                                        alt='shirt' />

                                                    {/* <img className="custom_shirt folded_hole"
                                                        src={foldedButtonHole}
                                                        alt='shirt' /> */}

                                                    <img className="custom_shirt folded_button"
                                                        src={foldedSuitButtons}
                                                        alt='shirt' />

                                                    {/* <img className="custom_shirt folded_thread"
                                                        src={foldedButtonThread}
                                                        alt='shirt' /> */}
                                                    {/* {this.renderPant()} */}
                                                    <img className="custom_shirt folded_pant"
                                                        src={foldedPant}
                                                        alt='shirt' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`carousel-item ${isShowFront ? 'active' : ''}`}>
                                            <div className="pi-shirt-containers">
                                                <div className={`pi_container pi-pant w-75 m-auto pi_shirt_front`}>
                                                    <img className="custom_shirt shirt_man"
                                                        src={frontModal}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_sleeve"
                                                        src={frontShirt}
                                                        alt='shirt' />
                                                    {/* <img className="custom_shirt shirt_tie"
                                                        src={frontTie}
                                                        alt='shirt' /> */}
                                                    <img className="custom_shirt shirt_pant"
                                                        src={frontPant.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_fit"
                                                        src={frontNormalFit.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={suitVests.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={suitVestsButtons}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_fit"
                                                        src={frontSuitFit.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    {/* <img className="custom_shirt front_lapels"
                                                        src={frontLapelsHole}
                                                        alt='shirt' /> */}
                                                    <img className="custom_shirt shirt_sleeve"
                                                        src={frontSleeve.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_button"
                                                        src={frontPocket.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    {/* <img className="custom_shirt folded_fit"
                                                        src={suitPocketsSquare}
                                                        alt='shirt' /> */}
                                                    <img className="custom_shirt shirt_cuff_fabric"
                                                        src={frontBreastPocket.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    {/* <img className="custom_shirt folded_hole"
                                                        src={frontButtonHole}
                                                        alt='shirt' /> */}
                                                    <img className="custom_shirt folded_button"
                                                        src={frontSuitButtons}
                                                        alt='shirt' />
                                                    {/* <img className="custom_shirt folded_thread"
                                                        src={frontButtonThread}
                                                        alt='shirt' /> */}

                                                </div>
                                            </div>
                                        </div>
                                        <div className={`carousel-item ${isShowBack ? 'active' : ''}`}>
                                            <div className=" pi-shirt-containers">
                                                <div className={`pi_container pi-pant w-75 m-auto pi_suit_back`}>
                                                    <img className="custom_shirt suit_back"
                                                        src={backModal}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_shirt_sleeve"
                                                        src={backShirt}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_shirt_pocket"
                                                        src={backPant.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_pant"
                                                        src={backSleeve.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_shirt_fit"
                                                        src={backSuitFit.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_collar_fabric"
                                                        src={backStyle.replace('.png', detectBrowser())}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_hole"
                                                        src={backButtonHole}
                                                        alt='shirt' />

                                                    <img className="custom_shirt shirt_back_collar_fabric"
                                                        src={backSuitButtons}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_thread"
                                                        src={backButtonThread}
                                                        alt='shirt' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(SuitZoom)