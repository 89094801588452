export const LINE_ITEMS_ADD = `mutation checkoutLineItemsAdd($lineItems: [CheckoutLineItemInput!]!, $checkoutId: ID!) {
  checkoutLineItemsAdd(lineItems: $lineItems, checkoutId: $checkoutId ) {
    checkout {
      id 
     lineItems(first: 10) {
         edges {
           node {
             title
             id
             quantity
         variant {
              id
              image {
                altText
                id
                src
              }
            }
           }
         }
       }
    }
    checkoutUserErrors {
      code
      field
      message
    }
  }
}`