import { put, takeLatest, all } from 'redux-saga/effects';
import Actions from '../action';
import { getMeasurementQuery, saveMeasurement } from '../../service';
export function* measurementAdd({ query, input, callback }) {
    try {
        const response = yield saveMeasurement(query, input);
        yield put(Actions.measurementAddSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.measurementAddFailure({error}));
        if (callback) {
            callback({ error })
        }
    }
}

export function* getMeasurementList({ query }) {
    try {
        const response = yield getMeasurementQuery(query);
        yield put(Actions.getMeasurementListSuccess(response));
    } catch (error) {
        yield put(Actions.getMeasurementListFailure({ error }));
    }
}

export function* measurementDelete({ query, input, callback }) {
    try {
        const response = yield saveMeasurement(query, input);
        yield put(Actions.measurementDeleteSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.measurementDeleteFailure(error));
        if (callback) {
            callback({ error })
        }
    }
}

export function* measurementUpdate({ query, input, callback }) {
    try {
        const response = yield saveMeasurement(query, input);
        yield put(Actions.measurementUpdateSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.measurementUpdateFailure({error}));
        if (callback) {
            callback({ error })
        }
    }
}

export function* measurementDetails({ query, callback }) {
    try {
        const response = yield getMeasurementQuery(query);
        yield put(Actions.measurementDetailsSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.measurementDetailsFailure({ error }));
        if (callback) {
            callback({ error })
        }
    }
}

export default function* () {
    yield all([
        takeLatest(Actions.MEASUREMENT_ADD, measurementAdd),
        takeLatest(Actions.MEASUREMENT_LIST, getMeasurementList),
        takeLatest(Actions.MEASUREMENT_DELETE, measurementDelete),
        takeLatest(Actions.MEASUREMENT_UPDATE, measurementUpdate),
        takeLatest(Actions.MEASUREMENT_DETAILS, measurementDetails)
    ]);
}