const NAME = "LOGIN";
export const CURRENT_LOCATION = `${NAME}/CURRENT_LOCATION`;

export const USER_LOGIN = `${NAME}/USER_LOGIN`;
export const USER_LOGIN_SUCCESS = `${NAME}/USER_LOGIN_SUCCESS`;
export const USER_LOGIN_FAILURE = `${NAME}/USER_LOGIN_FAILURE`;

export const GET_CUSTOMER_LIST = `${NAME}/GET_CUSTOMER_LIST`;
export const GET_CUSTOMER_LIST_SUCCESS = `${NAME}/GET_CUSTOMER_LIST_SUCCESS`;
export const GET_CUSTOMER_LIST_FAILURE = `${NAME}/GET_CUSTOMER_LIST_FAILURE`;

export const CUSTOMER_PROFILE_UPDATE = `${NAME}/CUSTOMER_PROFILE_UPDATE`;
export const CUSTOMER_PROFILE_UPDATE_SUCCESS = `${NAME}/CUSTOMER_PROFILE_UPDATE_SUCCESS`;
export const CUSTOMER_PROFILE_UPDATE_FAILURE = `${NAME}/CUSTOMER_PROFILE_UPDATE_FAILURE`;

export const CUSTOMER_RESET_PASSWORD = `${NAME}/CUSTOMER_RESET_PASSWORD`;
export const CUSTOMER_RESET_PASSWORD_SUCCESS = `${NAME}/CUSTOMER_RESET_PASSWORD_SUCCESS`;
export const CUSTOMER_RESET_PASSWORD_FAILURE = `${NAME}/CUSTOMER_RESET_PASSWORD_FAILURE`;

export const RESET_PASSWORD_BY_URL = `/RESET_PASSWORD_BY_URL`;
export const RESET_PASSWORD_BY_URL_SUCCESS = `/RESET_PASSWORD_BY_URL_SUCCESS`;
export const RESET_PASSWORD_BY_URL_FAILURE = `/RESET_PASSWORD_BY_URL_FAILURE`;

export const LOGOUT = '/LOGOUT'
export const getCurrentLocation = store => store[NAME]

export const getCustomer = store => store[NAME]
export const getCustomerList = (query) => {
    return {
        type: GET_CUSTOMER_LIST,
        query
    }
}

export const getCustomerListSuccess = (data) => {
    return {
        type: GET_CUSTOMER_LIST_SUCCESS,
        data
    }
}

export const getCustomerListFailure = (error) => {
    return {
        type: GET_CUSTOMER_LIST_FAILURE,
        error
    }
}

export const customerProfileUpdate = (query, input, callback) => {
    return {
        type: CUSTOMER_PROFILE_UPDATE,
        query,
        input,
        callback
    }
}

export const customerProfileUpdateSuccess = (data) => {
    return {
        type: CUSTOMER_PROFILE_UPDATE_SUCCESS,
        data
    }
}

export const customerProfileUpdateFailure = (error) => {
    return {
        type: CUSTOMER_PROFILE_UPDATE_FAILURE,
        error
    }
}


export const customerResetPassword = (query, input, callback) => {
    return {
        type: CUSTOMER_RESET_PASSWORD,
        query,
        input,
        callback
    }
}

export const customerResetPasswordSuccess = (data) => {
    return {
        type: CUSTOMER_RESET_PASSWORD_SUCCESS,
        data
    }
}

export const customerResetPasswordFailure = (error) => {
    return {
        type: CUSTOMER_RESET_PASSWORD_FAILURE,
        error
    }
}

export const logout = (data) => {
    return {
        type: LOGOUT,
    }
}

export const getCustomerIdSelector = (state) => {
    return state.LOGIN;
};

export const login = (query, input, callback) => {
    return {
        type: USER_LOGIN,
        query,
        input,
        callback
    }
}

export const loginSuccess = (data) => {
    return {
        type: USER_LOGIN_SUCCESS,
        data
    }
}

export const loginFailure = (error) => {
    return {
        type: USER_LOGIN_FAILURE,
        error
    }
}
export const currentLocation = (data) => {
    return {
        type: CURRENT_LOCATION,
        data
    }
}
export const resetPasswordByUrl = (query, input, callback) => {
    return {
        type: RESET_PASSWORD_BY_URL,
        query,
        input,
        callback
    }
}

export const resetPasswordByUrlSuccess = (data) => {
    return {
        type: RESET_PASSWORD_BY_URL_SUCCESS,
        data
    }
}

export const resetPasswordByUrlFailure = (error) => {
    return {
        type: RESET_PASSWORD_BY_URL_FAILURE,
        error
    }
}

export const getTokenSelector = (state) => {
    return state.LOGIN;
};