import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { S3BASE_URL, S3BASE_URL_OLD } from '../../config/api_endpoint'
import Loader from '../Loader'
import CustomAccents from '../CustomAccents'
import { convertAdminProductId } from '../../util/event'
import { TRANSPARENT } from '../../config/constant'
import { detectBrowser, detectBrowserBaseUrl } from '../../util/detect-browser'
import { SHIRT_IMAGES } from '../../util/Canvas_Measurement'
import { PATH } from '../../globals/app-constraints'
class CanvasAdminShirtSection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedCombination: undefined,
            isShowFront: true,
            isShowFolded: false,
            isShowBack: false,
            isShowSide: false,
            isLoading: true,
            isLoadingStarted: false,
            front: 20,
            back: 12,
            folded: 22,
            side: 22,
            isShowCasual: false,
            selectedPosition: 'folded',
            selectedShirtCombination: {},
            selectedFabrics: {},
            selectedAccents: {}
        }
    }
    onloadImageSuccess() {
        this.onloadImages()
    }

    onloadImageError() {
        this.onloadImages()
    }
    onCanvasPositionChanged(selectedPosition) {
        this.setState({ selectedPosition: selectedPosition })
    }
    onloadImages() {
        const { isShowFront, isShowBack, isShowFolded, front, back, folded, side, isShowSide } = this.state;
        if (isShowFront) {
            if (front === 1) {
                this.setState({ isLoading: false, front: 20 })
                return;
            } else {
                this.setState({ front: front - 1 })
            }
        } else if (isShowBack) {
            if (back === 1) {
                this.setState({ isLoading: false, back: 12 })
                return;
            } else {
                this.setState({ back: back - 1 })
            }
        } else if (isShowFolded) {
            if (folded === 1) {
                this.setState({ isLoading: false, folded: 22 })
                return;
            } else {
                this.setState({ folded: folded - 1 })
            }
        } else if (isShowSide) {
            if (side === 1) {
                this.setState({ isLoading: false, side: 22 })
                return;
            } else {
                this.setState({ side: side - 1 })
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { selectedStyle: { selectedCombination },
            activeChildMenu, selectedFabric: { selectedShirtFabric },
            isShowCasual, selectedAccents: { shirtAccents } } = props;
        const { selectedPosition } = state
        let basepath = S3BASE_URL + '6689789280420/' + detectBrowserBaseUrl() + '/'
        let baseButtonsPath = S3BASE_URL + 'button/'
        let baseButtonsPathV2 = S3BASE_URL_OLD + 'Buttons/'
        let baseCollarPath = S3BASE_URL + '6689789280420/' + detectBrowserBaseUrl() + '/'
        let baseCuffPath = S3BASE_URL + '6689789280420/' + detectBrowserBaseUrl() + '/'
        let baseElbowPath = S3BASE_URL + '6689789280420/' + detectBrowserBaseUrl() + '/'
        let { collarFabricId, selectedCollarFabric, cuffFabricId,
            selectedCuffFabric, elbowPatches, elbowPatchesId,
            selectedButton, hole, thread } = shirtAccents || {}
        if (selectedShirtFabric?.productId !== 'undefined') {
            basepath = S3BASE_URL + convertAdminProductId(selectedShirtFabric?.productId) + detectBrowserBaseUrl() + '/'
            baseCollarPath = S3BASE_URL + convertAdminProductId(selectedShirtFabric?.productId) + detectBrowserBaseUrl() + '/'
            baseCuffPath = S3BASE_URL + convertAdminProductId(selectedShirtFabric?.productId) + detectBrowserBaseUrl() + '/'
            baseElbowPath = S3BASE_URL + convertAdminProductId(selectedShirtFabric?.productId) + detectBrowserBaseUrl() + '/'
        }

        if (collarFabricId) {
            let parts = collarFabricId?.split('/');
            let shopifyId = parts[parts.length - 1];
            baseCollarPath = S3BASE_URL + `${shopifyId}/` + detectBrowserBaseUrl() + '/'
        }
        if (cuffFabricId) {
            let parts = cuffFabricId?.split('/');
            let shopifyId = parts[parts.length - 1];
            baseCuffPath = S3BASE_URL + `${shopifyId}/` + detectBrowserBaseUrl() + '/'
        }
        if (elbowPatchesId) {
            let parts = elbowPatchesId?.split('/');
            let shopifyId = parts[parts.length - 1];
            baseElbowPath = S3BASE_URL + `${shopifyId}/` + detectBrowserBaseUrl() + '/'
        }
        let isShowFolded = true
        let isShowBack = false
        let isShowFront = false
        let isShowSide = false
        let selectedCombinationObj = {
            frontSleeve: basepath + 'sleeve/front/full_sleeve.png',
            frontViewPant: S3BASE_URL + 'modal/front_pant.png',
            frontCollar: basepath + 'collar/button_collar.png',
            foldedCollar: basepath + 'collar/back_button_collar.png',
            backCollar: baseCollarPath + 'collar/back/normal_collar.png',
            frontCollarCenterButton: basepath + 'button/white/front/collar_center.png',
            collarDownButton: basepath + TRANSPARENT,
            collarOnly: basepath + 'collar/kent_collar_only.png',
            foldedCollarCenterButton: basepath + 'collar/folded_collar_button.png',
            foldedCollarDownButton: basepath + 'collar/no_collar_button_folded_shirt.png',

            frontPlacket: basepath + 'placket/front/standard_tucked.png',
            shirtBackPlacket: basepath + 'placket/folded_standard.png',
            modelImage: S3BASE_URL + 'modal/front_modal.png',
            frontPlacketButton: baseButtonsPath + 'white/front/formal_placket.png',
            stripeFoldedButton: basepath + 'white/folded/placket.png',
            shirtPocket: basepath + 'pocket/front/transparent.png',
            foldedPocket: basepath + 'pocket/back_shirt_pocket.png',
            shirtCuffAll: basepath + 'cuff/folded/single_cuff_one_button.png',
            shirtBackModal: S3BASE_URL + 'modal/back_modal.png',
            backPantModal: S3BASE_URL + 'modal/back_pant.png',
            backShirtImage: basepath + 'sleeve/back_side_shirt.png',
            shirtBackPleats: basepath + 'pleats/no_pleats.png',
            frontShirtFit: basepath + 'fit/full_sleeve_normal_fit.png',
            frontShirtBody: basepath + 'fit/front/normal_fit.png',
            shirtBackTail: basepath + 'sleeve/back_shirt_full_sleeve.png',
            sideModal: S3BASE_URL + 'modal/side_modal.png',
            sidePant: S3BASE_URL + 'modal/side_pant.png',
            sideShirtFit: basepath + 'fit/side/normal_fit.png',
            sideShirtSleeve: basepath + 'sleeve/side_full_sleeve.png',
            sideCollarDownButton: basepath + TRANSPARENT,
            sideCollarButton: basepath + 'button/white/side/collar_center.png',
            sideCollar: basepath + 'collar/kent_collar.png',
            sidePlacket: basepath + 'placket/side_tucked_standard_strip.png',
            sidePlacketButton: basepath + 'placket/white/side/tucked_placket.png',
            sidePocketButton: basepath + TRANSPARENT,
            sidePlacketSingleButton: basepath + TRANSPARENT,
            foldedCuffHoleUrl: baseButtonsPath + 'cuff/hole/one/white.png',
            foldedCuffThreadUrl: baseButtonsPath + 'cuff/thread/one/white.png',
            folded_collar_fabric: baseCollarPath + 'collar/kent_collar/all_fabric.png',
            backElbowPatches: basepath + TRANSPARENT,
            back_collar_fabric: basepath + TRANSPARENT,
            sideCollarSingleButton: basepath + TRANSPARENT
        }
        switch (selectedPosition) {
            case 'front':
                isShowFolded = false
                isShowBack = false
                isShowFront = true
                isShowSide = false
                break;
            case 'folded':
                isShowFolded = true
                isShowBack = false
                isShowFront = false
                isShowSide = false
                break;
            case 'back':
                isShowFolded = false
                isShowBack = true
                isShowFront = false
                isShowSide = false
                break;
            case 'side':
                isShowFolded = false
                isShowBack = false
                isShowFront = false
                isShowSide = true
                break;
            default:
                break;
        }
        let selectedElbow = ''
        if (elbowPatches) {
            switch (elbowPatches) {
                case 'default':
                    selectedElbow = basepath + TRANSPARENT
                    break;
                case 'color':
                    selectedElbow = baseElbowPath + 'elbow/elbow_pleats.png'
                    break;
                default:
                    return selectedElbow = basepath + TRANSPARENT
            }
        }
        let foldedCollarFabric = ''
        let frontCollarFabric = ''
        let frontCasualFabric = ''
        let sideCollarFabric = ''
        let sideCasualCollarFabric = ''
        let backCollarFabric
        switch (selectedCollarFabric) {
            case 'all':
                foldedCollarFabric = baseCollarPath + selectedCombination['collar'].folded_all_fabric
                frontCollarFabric = baseCollarPath + selectedCombination['collar'].front_all_fabric
                frontCasualFabric = baseCollarPath + selectedCombination['collar'].front_casual_all_fabric
                sideCollarFabric = baseCollarPath + selectedCombination['collar'].side_all_fabric
                sideCasualCollarFabric = baseCollarPath + selectedCombination['collar'].side_casual_all_fabric
                backCollarFabric = baseCollarPath + selectedCombination['collar'].back_all_fabric
                break;
            case 'inner':
                foldedCollarFabric = baseCollarPath + selectedCombination['collar'].folded_inner_fabric
                frontCollarFabric = basepath + TRANSPARENT
                frontCasualFabric = baseCollarPath + selectedCombination['collar'].front_casual_inner_fabric
                sideCollarFabric = baseCollarPath + selectedCombination['collar'].side_inner_fabric
                sideCasualCollarFabric = baseCollarPath + selectedCombination['collar'].side_casual_inner_fabric
                backCollarFabric = basepath + TRANSPARENT
                break;
            case 'outer':
                foldedCollarFabric = baseCollarPath + selectedCombination['collar'].folded_outer_fabric
                frontCollarFabric = baseCollarPath + selectedCombination['collar'].front_outer_fabric
                frontCasualFabric = baseCollarPath + selectedCombination['collar'].front_casual_outer_fabric
                sideCollarFabric = baseCollarPath + selectedCombination['collar'].side_outer_fabric
                sideCasualCollarFabric = baseCollarPath + selectedCombination['collar'].side_casual_outer_fabric
                backCollarFabric = baseCollarPath + selectedCombination['collar'].back_outer_fabric
                break;
            case 'default':
                foldedCollarFabric = basepath + TRANSPARENT
                frontCollarFabric = basepath + TRANSPARENT
                frontCasualFabric = basepath + TRANSPARENT
                sideCollarFabric = basepath + TRANSPARENT
                sideCasualCollarFabric = basepath + TRANSPARENT
                backCollarFabric = basepath + TRANSPARENT
                break;
            default:
                break;
        }
        let folded_cuff_fabric = ''
        let front_cuff_fabric = ''
        let side_cuff_fabric = ''
        let back_cuff_fabric = ''
        let half_folded_cuff_fabric = ''
        let half_front_cuff_fabric = ''
        let half_side_cuff_fabric = ''
        let half_back_cuff_fabric = ''
        switch (selectedCuffFabric) {
            case 'all':
                folded_cuff_fabric = cuffFabricId === selectedShirtFabric?.productId ? basepath + TRANSPARENT : baseCuffPath + selectedCombination['cuff'].folded_cuff_all_fabric
                front_cuff_fabric = baseCuffPath + 'cuff/front/normal_cuff.png'
                side_cuff_fabric = baseCuffPath + selectedCombination['cuff'].side_cuff_only
                back_cuff_fabric = baseCuffPath + selectedCombination['cuff'].back_cuff_fabric_image_url
                half_folded_cuff_fabric = baseCuffPath + 'cuff/folded/folded_half_cuff.png'
                half_front_cuff_fabric = baseCuffPath + 'cuff/front/short_cuff.png'
                half_side_cuff_fabric = baseCuffPath + 'cuff/side/short_cuff.png'
                half_back_cuff_fabric = baseCuffPath + 'cuff/back/short_cuff.png'
                break;
            case 'inner':
                folded_cuff_fabric = basepath + TRANSPARENT
                front_cuff_fabric = baseCuffPath + 'cuff/front/inner_cuff.png'
                side_cuff_fabric = baseCuffPath + 'cuff/side/inner_cuff.png'
                back_cuff_fabric = baseCuffPath + 'cuff/back/inner_cuff.png'
                half_folded_cuff_fabric = baseCuffPath + 'cuff/folded/folded_half_cuff.png'
                half_front_cuff_fabric = basepath + TRANSPARENT
                half_side_cuff_fabric = basepath + TRANSPARENT
                half_back_cuff_fabric = basepath + TRANSPARENT
                break;
            case 'outer':
                folded_cuff_fabric = cuffFabricId === selectedShirtFabric?.productId ? basepath + TRANSPARENT : baseCuffPath + selectedCombination['cuff'].folded_cuff_outer_fabric
                front_cuff_fabric = baseCuffPath + 'cuff/front/normal_cuff.png'
                side_cuff_fabric = baseCuffPath + selectedCombination['cuff'].side_cuff_only
                back_cuff_fabric = baseCuffPath + selectedCombination['cuff'].back_cuff_fabric_image_url
                half_folded_cuff_fabric = baseCuffPath + 'cuff/folded/folded_half_cuff.png'
                half_front_cuff_fabric = baseCuffPath + 'cuff/front/short_cuff.png'
                half_side_cuff_fabric = baseCuffPath + 'cuff/side/short_cuff.png'
                half_back_cuff_fabric = baseCuffPath + 'cuff/back/short_cuff.png'
                break;
            case 'default':
                folded_cuff_fabric = basepath + TRANSPARENT
                front_cuff_fabric = basepath + TRANSPARENT
                side_cuff_fabric = basepath + TRANSPARENT
                back_cuff_fabric = basepath + TRANSPARENT
                half_folded_cuff_fabric = basepath + TRANSPARENT
                half_front_cuff_fabric = basepath + TRANSPARENT
                half_side_cuff_fabric = basepath + TRANSPARENT
                half_back_cuff_fabric = basepath + TRANSPARENT
                break;
            default:
                break;
        }
        Object.keys(selectedCombination).forEach((key) => {
            switch (key) {
                case 'sleeve':
                    if (selectedCombination[key].image_url) {
                        selectedCombinationObj = {
                            ...selectedCombinationObj,
                            frontSleeve: selectedCuffFabric === "inner" ? basepath + 'sleeve/front/full_inner_sleeve.png' : basepath + selectedCombination[key].image_url,
                            frontViewPant: S3BASE_URL + 'modal/front_pant.png',
                            sideShirtSleeve: selectedCuffFabric === "inner" ? basepath + 'sleeve/side/full_inner_sleeve.png' : basepath + selectedCombination[key].side_shirt_sleeve_url,
                            backElbowPatches: selectedElbow
                        }
                    }
                    break;
                case 'collar':
                    if (selectedCombination[key].image_url) {
                        if (isShowCasual) {
                            selectedCombinationObj = {
                                ...selectedCombinationObj,
                                frontCollar: basepath + selectedCombination[key].casual_collar,
                                foldedCollar: basepath + selectedCombination[key].folded_image_url,
                                frontCollarCenterButton: basepath + TRANSPARENT,
                                collarDownButton: basepath + TRANSPARENT,
                                foldedCollarCenterButton: baseButtonsPath + selectedButton + '/folded/' + selectedCombination[key].folded_color_center_button,
                                foldedCollarDownButton: selectedCombination[key].key === 'button_down' ? baseButtonsPath + selectedButton + '/folded/' + selectedCombination[key].folded_collar_down_button : basepath + TRANSPARENT,
                                foldedCollarCenterButtonThread: baseButtonsPathV2 + selectedCombination[key].folded_collar_center_thread + thread + '.png',
                                foldedCollarDownButtonThread: selectedCombination[key].key === 'button_down' ? baseButtonsPathV2 + selectedCombination[key].folded_collar_down_thread + thread + '.png' : basepath + TRANSPARENT,
                                foldedCollarDownButtonHole: selectedCombination[key].key === 'button_down' ? baseButtonsPathV2 + selectedCombination[key].folded_collar_center_hole + hole + '.png' : basepath + TRANSPARENT,
                                collarOnly: basepath + TRANSPARENT,
                                backCollar: basepath + selectedCombination['collar'].back_side_collar,
                                sideCollar: basepath + selectedCombination[key].casual_side_collar_image_url,
                                sideCollarButton: basepath + TRANSPARENT,
                                sideCollarSingleButton: baseButtonsPath + selectedButton + '/side/' + selectedCombination[key].side_casual_collar_single_button,
                                sideCollarDownButton: selectedCombination[key].key === 'button_down' ? baseButtonsPath + selectedButton + '/side/' + selectedCombination[key].side_casual_collar_down_button : basepath + TRANSPARENT,
                                folded_collar_fabric: foldedCollarFabric,
                                front_collar_fabric: frontCasualFabric,
                                back_collar_fabric: backCollarFabric,
                                frontCollarCenterButtonThread: basepath + TRANSPARENT,
                                frontCollarDownButtonThread: basepath + TRANSPARENT,
                                frontCollarDownButtonHole: basepath + TRANSPARENT,
                                side_collar_fabric: sideCasualCollarFabric,
                                sideCollarCenterButtonThread: basepath + TRANSPARENT,
                                sideCollarCenterButtonHole: basepath + TRANSPARENT,
                                sideCollarDownButtonHole: basepath + TRANSPARENT,
                                sideCollarDownButtonThread: baseButtonsPathV2 + selectedCombination[key].side_casual_collar_down_thread + thread + '.png',
                            }
                        } else {
                            if (selectedCombination[key].key === 'button_down') {
                                selectedCombinationObj = {
                                    ...selectedCombinationObj,
                                    frontCollar: basepath + selectedCombination[key].image_url,
                                    foldedCollar: basepath + selectedCombination[key].folded_image_url,
                                    frontCollarCenterButton: baseButtonsPath + selectedButton + '/front/' + selectedCombination[key].collar_center_button,
                                    collarDownButton: baseButtonsPath + selectedButton + '/front/' + selectedCombination[key].color_down_button,
                                    foldedCollarCenterButton: baseButtonsPath + selectedButton + '/folded/' + selectedCombination[key].folded_color_center_button,
                                    foldedCollarDownButton: baseButtonsPath + selectedButton + '/folded/' + selectedCombination[key].folded_collar_down_button,
                                    collarOnly: basepath + selectedCombination[key].collar_only,
                                    backCollar: basepath + selectedCombination['collar'].back_side_collar,
                                    sideCollar: basepath + selectedCombination[key].side_collar_image_url,
                                    sideCollarButton: baseButtonsPath + selectedButton + '/side/' + selectedCombination[key].side_collar_center_button,
                                    sideCollarDownButton: baseButtonsPath + selectedButton + '/side/' + selectedCombination[key].side_collar_down_button,
                                    foldedCollarCenterButtonThread: baseButtonsPathV2 + selectedCombination[key].folded_collar_center_thread + thread + '.png',
                                    foldedCollarDownButtonThread: baseButtonsPathV2 + selectedCombination[key].folded_collar_down_thread + thread + '.png',
                                    foldedCollarDownButtonHole: baseButtonsPathV2 + selectedCombination[key].folded_collar_center_hole + hole + '.png',
                                    folded_collar_fabric: foldedCollarFabric,
                                    front_collar_fabric: frontCollarFabric,
                                    frontCollarCenterButtonThread: baseButtonsPathV2 + selectedCombination[key].front_collar_center_thread + thread + '.png',
                                    frontCollarDownButtonThread: baseButtonsPathV2 + selectedCombination[key].front_collar_down_thread + thread + '.png',
                                    frontCollarDownButtonHole: baseButtonsPathV2 + selectedCombination[key].front_collar_center_hole + hole + '.png',
                                    side_collar_fabric: sideCollarFabric,
                                    sideCollarCenterButtonThread: baseButtonsPathV2 + selectedCombination[key].side_collar_center_thread + thread + '.png',
                                    sideCollarCenterButtonHole: baseButtonsPathV2 + selectedCombination[key].side_collar_center_hole + hole + '.png',
                                    sideCollarDownButtonHole: baseButtonsPathV2 + selectedCombination[key].side_collar_down_hole + hole + '.png',
                                    sideCollarDownButtonThread: baseButtonsPathV2 + selectedCombination[key].side_collar_down_thread + thread + '.png',
                                    back_collar_fabric: backCollarFabric,
                                    sideCollarSingleButton: basepath + TRANSPARENT,
                                }
                            } else {
                                selectedCombinationObj = {
                                    ...selectedCombinationObj,
                                    frontCollar: basepath + selectedCombination[key].image_url,
                                    foldedCollar: basepath + selectedCombination[key].folded_image_url,
                                    frontCollarCenterButton: baseButtonsPath + selectedButton + '/front/' + selectedCombination[key].collar_center_button,
                                    collarDownButton: basepath + TRANSPARENT,
                                    foldedCollarCenterButton: baseButtonsPath + selectedButton + '/folded/' + selectedCombination[key].folded_color_center_button,
                                    foldedCollarDownButton: basepath + TRANSPARENT,
                                    collarOnly: basepath + selectedCombination[key].collar_only,
                                    backCollar: basepath + selectedCombination['collar'].back_side_collar,
                                    sideCollar: basepath + selectedCombination[key].side_collar_image_url,
                                    sideCollarButton: baseButtonsPath + selectedButton + '/side/' + selectedCombination[key].side_collar_center_button,
                                    sideCollarDownButton: basepath + TRANSPARENT,
                                    foldedCollarCenterButtonThread: baseButtonsPathV2 + selectedCombination[key].folded_collar_center_thread + thread + '.png',
                                    foldedCollarDownButtonThread: basepath + TRANSPARENT,
                                    foldedCollarDownButtonHole: basepath + TRANSPARENT,
                                    folded_collar_fabric: foldedCollarFabric,
                                    front_collar_fabric: frontCollarFabric,

                                    frontCollarCenterButtonThread: baseButtonsPathV2 + selectedCombination[key].front_collar_center_thread + thread + '.png',
                                    frontCollarDownButtonThread: basepath + TRANSPARENT,
                                    frontCollarDownButtonHole: baseButtonsPathV2 + selectedCombination[key].front_collar_center_hole + hole + '.png',
                                    side_collar_fabric: sideCollarFabric,
                                    sideCollarCenterButtonThread: baseButtonsPathV2 + selectedCombination[key].side_collar_center_thread + thread + '.png',
                                    sideCollarCenterButtonHole: baseButtonsPathV2 + selectedCombination[key].side_collar_center_hole + hole + '.png',
                                    sideCollarDownButtonHole: basepath + TRANSPARENT,
                                    sideCollarDownButtonThread: basepath + TRANSPARENT,
                                    back_collar_fabric: backCollarFabric,
                                    sideCollarSingleButton: basepath + TRANSPARENT,
                                }
                            }

                        }

                    }
                    break;
                case 'placket':
                    if (selectedCombination[key].image_url) {
                        if (selectedCombination['bottom'].key === 'tucked') {
                            if ((selectedCombination['collar'].key === 'pinned_collar') && (isShowCasual)) {
                                selectedCombinationObj = {
                                    ...selectedCombinationObj,
                                    frontPlacket: basepath + selectedCombination[key].tucked_casual_strip,
                                    shirtBackPlacket: basepath + selectedCombination[key].folded_pinned_strip,
                                    foldedStripHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].folded_strip_button_hole + hole + '.png',
                                    foldedStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].folded_strip_button_thread + thread + '.png',
                                    frontPlacketButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/front/' + selectedCombination[key].front_casual_placket_button,
                                    stripeFoldedButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/folded/' + selectedCombination[key].folded_strip_button,
                                    sidePlacket: basepath + selectedCombination[key].side_tucked_casual_strip,
                                    sidePlacketButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/side/' + selectedCombination[key].side_strip_casual_button_tucked,
                                    sidePlacketSingleButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/side/' + selectedCombination[key].side_strip_casual_single_button,
                                    frontStripHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].front_strip_casual_button_hole + hole + '.png',
                                    frontStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].front_strip_casual_button_thread + thread + '.png',
                                    sideStripeHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].casual_side_strip_button_hole_tucked + hole + '.png',
                                    sideStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].casual_side_strip_button_thread_tucked + thread + '.png'
                                }
                            } else if (selectedCombination['collar'].key === 'pinned_collar') {
                                selectedCombinationObj = {
                                    ...selectedCombinationObj,
                                    frontPlacket: basepath + selectedCombination[key].image_url,
                                    shirtBackPlacket: basepath + selectedCombination[key].folded_pinned_strip,
                                    foldedStripHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].folded_strip_button_hole + hole + '.png',
                                    foldedStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].folded_strip_button_thread + thread + '.png',
                                    frontPlacketButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/front/' + selectedCombination[key].front_placket_button,
                                    stripeFoldedButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/folded/' + selectedCombination[key].folded_strip_button,
                                    sidePlacket: basepath + selectedCombination[key].side_tucked_strip,
                                    sidePlacketButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/side/' + selectedCombination[key].side_strip_button_tucked,
                                    sidePlacketSingleButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/side/' + selectedCombination[key].side_strip_casual_single_button,
                                    frontStripHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].front_strip_button_hole + hole + '.png',
                                    frontStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].front_strip_button_thread + thread + '.png',
                                    sideStripeHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].side_strip_button_hole_tucked + hole + '.png',
                                    sideStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].side_strip_button_thread_tucked + thread + '.png'
                                }
                            } else if (isShowCasual) {
                                selectedCombinationObj = {
                                    ...selectedCombinationObj,
                                    frontPlacket: basepath + selectedCombination[key].tucked_casual_strip,
                                    shirtBackPlacket: selectedCombination['collar'].key === 'long_collar' ? basepath + selectedCombination[key].folded_pinned_strip : basepath + selectedCombination[key].folded_image_url,
                                    foldedStripHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].folded_strip_button_hole + hole + '.png',
                                    foldedStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].folded_strip_button_thread + thread + '.png',
                                    frontPlacketButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/front/' + selectedCombination[key].front_casual_placket_button,
                                    stripeFoldedButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/folded/' + selectedCombination[key].folded_strip_button,
                                    sidePlacket: basepath + selectedCombination[key].side_tucked_casual_strip,
                                    sidePlacketButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/side/' + selectedCombination[key].side_strip_casual_button_tucked,
                                    sidePlacketSingleButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/side/' + selectedCombination[key].side_strip_casual_single_button,
                                    frontStripHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].front_strip_casual_button_hole + hole + '.png',
                                    frontStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].front_strip_casual_button_thread + thread + '.png',
                                    sideStripeHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].casual_side_strip_button_hole_tucked + hole + '.png',
                                    sideStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].casual_side_strip_button_thread_tucked + thread + '.png'
                                }
                            } else {
                                selectedCombinationObj = {
                                    ...selectedCombinationObj,
                                    frontPlacket: basepath + selectedCombination[key].image_url,
                                    shirtBackPlacket: selectedCombination['collar'].key === 'long_collar' ? basepath + selectedCombination[key].folded_pinned_strip : basepath + selectedCombination[key].folded_image_url,
                                    foldedStripHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].folded_strip_button_hole + hole + '.png',
                                    foldedStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].folded_strip_button_thread + thread + '.png',
                                    frontPlacketButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/front/' + selectedCombination[key].front_placket_button,
                                    stripeFoldedButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/folded/' + selectedCombination[key].folded_strip_button,
                                    sidePlacket: basepath + selectedCombination[key].side_tucked_strip,
                                    sidePlacketButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/side/' + selectedCombination[key].side_strip_button_tucked,
                                    sidePlacketSingleButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/side/' + selectedCombination[key].side_strip_casual_single_button,
                                    frontStripHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].front_strip_button_hole + hole + '.png',
                                    frontStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].front_strip_button_thread + thread + '.png',
                                    sideStripeHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].side_strip_button_hole_tucked + hole + '.png',
                                    sideStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].side_strip_button_thread_tucked + thread + '.png'
                                }
                            }
                        } else {
                            if ((selectedCombination['collar'].key === 'pinned_collar') && (isShowCasual)) {
                                selectedCombinationObj = {
                                    ...selectedCombinationObj,
                                    frontPlacket: basepath + selectedCombination[key].untucked_casual_strip,
                                    shirtBackPlacket: basepath + selectedCombination[key].folded_pinned_strip,
                                    foldedStripHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].folded_strip_button_hole + hole + '.png',
                                    foldedStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].folded_strip_button_thread + thread + '.png',
                                    frontPlacketButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/front/' + selectedCombination[key].front_casual_placket_button,
                                    stripeFoldedButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/folded/' + selectedCombination[key].folded_strip_button,
                                    sidePlacket: basepath + selectedCombination[key].side_untucked_casual_strip,
                                    sidePlacketButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/side/' + selectedCombination[key].side_strip_casual_button_untucked,
                                    sidePlacketSingleButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/side/' + selectedCombination[key].side_strip_casual_single_button,
                                    frontStripHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].front_strip_casual_button_hole + hole + '.png',
                                    frontStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].front_strip_casual_button_thread + thread + '.png',
                                    sideStripeHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].casual_side_strip_button_hole_untucked + hole + '.png',
                                    sideStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].casual_side_strip_button_thread_untucked + thread + '.png'
                                }
                            } else if (selectedCombination['collar'].key === 'pinned_collar') {
                                selectedCombinationObj = {
                                    ...selectedCombinationObj,
                                    frontPlacket: basepath + selectedCombination[key].untucked_strip,
                                    shirtBackPlacket: basepath + selectedCombination[key].folded_pinned_strip,
                                    foldedStripHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].folded_strip_button_hole + hole + '.png',
                                    foldedStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].folded_strip_button_thread + thread + '.png',
                                    frontPlacketButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/front/' + selectedCombination[key].front_placket_button,
                                    stripeFoldedButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/folded/' + selectedCombination[key].folded_strip_button,
                                    sidePlacket: basepath + selectedCombination[key].side_untucked_strip,
                                    sidePlacketButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/side/' + selectedCombination[key].side_strip_button_untucked,
                                    sidePlacketSingleButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/side/' + selectedCombination[key].side_strip_casual_single_button,
                                    frontStripHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].front_strip_button_hole + hole + '.png',
                                    frontStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].front_strip_button_thread + thread + '.png',
                                    sideStripeHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].side_strip_button_hole_untucked + hole + '.png',
                                    sideStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].side_strip_button_thread_untucked + thread + '.png'
                                }
                            } else if (isShowCasual) {
                                selectedCombinationObj = {
                                    ...selectedCombinationObj,
                                    frontPlacket: basepath + selectedCombination[key].untucked_casual_strip,
                                    shirtBackPlacket: selectedCombination['collar'].key === 'long_collar' ? basepath + selectedCombination[key].folded_pinned_strip : basepath + selectedCombination[key].folded_image_url,
                                    foldedStripHole: selectedCombination[key].key === 'hidden_placket' ?
                                        basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].folded_strip_button_hole + hole + '.png',
                                    foldedStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].folded_strip_button_thread + thread + '.png',
                                    frontPlacketButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/front/' + selectedCombination[key].front_casual_placket_button,
                                    stripeFoldedButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/folded/' + selectedCombination[key].folded_strip_button,
                                    sidePlacket: basepath + selectedCombination[key].side_untucked_casual_strip,
                                    sidePlacketButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/side/' + selectedCombination[key].side_strip_casual_button_untucked,
                                    sidePlacketSingleButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/side/' + selectedCombination[key].side_strip_casual_single_button,
                                    frontStripHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].front_strip_casual_button_hole + hole + '.png',
                                    frontStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].front_strip_casual_button_thread + thread + '.png',
                                    sideStripeHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].casual_side_strip_button_hole_untucked + hole + '.png',
                                    sideStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].casual_side_strip_button_thread_untucked + thread + '.png'
                                }
                            } else {
                                selectedCombinationObj = {
                                    ...selectedCombinationObj,
                                    frontPlacket: basepath + selectedCombination[key].untucked_strip,
                                    shirtBackPlacket: selectedCombination['collar'].key === 'long_collar' ? basepath + selectedCombination[key].folded_pinned_strip : basepath + selectedCombination[key].folded_image_url,
                                    foldedStripHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].folded_strip_button_hole + hole + '.png',
                                    foldedStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].folded_strip_button_thread + thread + '.png',
                                    frontPlacketButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/front/' + selectedCombination[key].front_placket_button,
                                    stripeFoldedButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/folded/' + selectedCombination[key].folded_strip_button,
                                    sidePlacket: basepath + selectedCombination[key].side_untucked_strip,
                                    sidePlacketButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/side/' + selectedCombination[key].side_strip_button_untucked,
                                    sidePlacketSingleButton: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPath + selectedButton + '/side/' + selectedCombination[key].side_strip_casual_single_button,
                                    frontStripHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].front_strip_button_hole + hole + '.png',
                                    frontStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].front_strip_button_thread + thread + '.png',
                                    sideStripeHole: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].side_strip_button_hole_untucked + hole + '.png',
                                    sideStripThread: selectedCombination[key].key === 'hidden_placket' ? basepath + TRANSPARENT : baseButtonsPathV2 + selectedCombination[key].side_strip_button_thread_untucked + thread + '.png'
                                }
                            }
                        }

                    }
                    break;
                case 'cuff':
                    if (selectedCombination[key].image_url) {
                        if (selectedCombination['sleeve'].key === 'long') {
                            if ((selectedCombination[key].key === 'french') || (selectedCombination[key].key === 'single_squared_french') ||
                                (selectedCombination[key].key === 'rounded_french') || (selectedCombination[key].key === 'double_french')) {
                                selectedCombinationObj = {
                                    ...selectedCombinationObj,
                                    defaultFoldedShirt: basepath + 'cuff/folded/folded_shirt.png',
                                    foldedShirtShadow: S3BASE_URL + 'modal/folded_shadow.png',
                                    shirtCuffAll: basepath + selectedCombination[key].image_url,
                                    foldedCuffFabric: folded_cuff_fabric,
                                    cuffButton: basepath + TRANSPARENT,
                                    backShirtSleeve: selectedCuffFabric === "inner" ? basepath + 'sleeve/back/full_inner_sleeve.png' : basepath + selectedCombination['cuff'].back_shirt_cuff,
                                    backShirtCuffButton: selectedCuffFabric === "inner" ? baseButtonsPathV2 + selectedButton + '/inner/back_' + selectedButton + '.png' : baseButtonsPathV2 + selectedButton + '/' + selectedCombination[key].back_shirt_cuff_button,
                                    sideShirtSleeve: selectedCuffFabric === "inner" ? basepath + 'sleeve/side/full_inner_sleeve.png' : basepath + selectedCombination['cuff'].side_cuff_image_url,
                                    foldedCuffHoleUrl: basepath + TRANSPARENT,
                                    foldedCuffThreadUrl: basepath + TRANSPARENT,
                                    frontCuffFabric: front_cuff_fabric,
                                    backCuffFabric: back_cuff_fabric,
                                    backElbowPatches: selectedElbow,
                                    backCuffHoleUrl: selectedCuffFabric === "inner" ? baseButtonsPathV2 + 'cuff/hole/back/inner/' + hole + '.png' : basepath + TRANSPARENT,
                                    backCuffThreadUrl: selectedCuffFabric === "inner" ? baseButtonsPathV2 + 'cuff/thread/back/inner/' + thread + '.png' : basepath + TRANSPARENT,
                                    sideCuffFabric: side_cuff_fabric
                                }
                            } else {
                                selectedCombinationObj = {
                                    ...selectedCombinationObj,
                                    defaultFoldedShirt: basepath + 'cuff/folded/folded_shirt.png',
                                    foldedShirtShadow: S3BASE_URL + 'modal/folded_shadow.png',
                                    shirtCuffAll: basepath + selectedCombination[key].image_url,
                                    foldedCuffFabric: folded_cuff_fabric,
                                    cuffButton: baseButtonsPath + selectedButton + '/folded/' + selectedCombination[key].cuff_Button,
                                    backShirtSleeve: selectedCuffFabric === "inner" ? basepath + 'sleeve/back/full_inner_sleeve.png' : basepath + selectedCombination['cuff'].back_shirt_cuff,
                                    backShirtCuffButton: selectedCuffFabric === "inner" ? baseButtonsPathV2 + selectedButton + '/inner/back_' + selectedButton + '.png' : baseButtonsPathV2 + selectedButton + '/' + selectedCombination[key].back_shirt_cuff_button,
                                    sideShirtSleeve: selectedCuffFabric === "inner" ? basepath + 'sleeve/side/full_inner_sleeve.png' : basepath + selectedCombination['cuff'].side_cuff_image_url,
                                    foldedCuffHoleUrl: baseButtonsPathV2 + selectedCombination['cuff'].folded_cuff_hole_url + '/' + hole + '.png',
                                    foldedCuffThreadUrl: baseButtonsPathV2 + selectedCombination['cuff'].folded_cuff_thread_url + '/' + thread + '.png',
                                    frontCuffFabric: front_cuff_fabric,
                                    backCuffFabric: back_cuff_fabric,
                                    backElbowPatches: selectedElbow,
                                    backCuffHoleUrl: selectedCuffFabric === "inner" ? baseButtonsPathV2 + 'cuff/hole/back/inner/' + hole + '.png' : baseButtonsPathV2 + selectedCombination['cuff'].back_cuff_hole_url + '/' + hole + '.png',
                                    backCuffThreadUrl: selectedCuffFabric === "inner" ? baseButtonsPathV2 + 'cuff/thread/back/inner/' + thread + '.png' : baseButtonsPathV2 + selectedCombination['cuff'].back_cuff_thread_url + '/' + thread + '.png',
                                    sideCuffFabric: side_cuff_fabric
                                }
                            }

                        } else {
                            selectedCombinationObj = {
                                ...selectedCombinationObj,
                                defaultFoldedShirt: basepath + 'cuff/folded/folded_shirt.png',
                                foldedShirtShadow: S3BASE_URL + 'modal/folded_shadow.png',
                                shirtCuffAll: basepath + 'cuff/folded/short_cuff.png',
                                foldedCuffFabric: half_folded_cuff_fabric,
                                cuffButton: basepath + TRANSPARENT,
                                frontSleeve: basepath + 'sleeve/front/half_sleeve.png',
                                backShirtSleeve: basepath + 'sleeve/back/half_sleeve.png',
                                backShirtCuffButton: basepath + TRANSPARENT,
                                sideShirtSleeve: basepath + 'sleeve/side/half_sleeve.png',
                                foldedCuffHoleUrl: basepath + TRANSPARENT,
                                foldedCuffThreadUrl: basepath + TRANSPARENT,
                                frontCuffFabric: half_front_cuff_fabric,
                                backCuffFabric: half_back_cuff_fabric,
                                backElbowPatches: basepath + TRANSPARENT,
                                backCuffHoleUrl: basepath + TRANSPARENT,
                                backCuffThreadUrl: basepath + TRANSPARENT,
                                sideCuffFabric: half_side_cuff_fabric
                            }
                        }
                    }
                    break;
                case 'pocket':
                    if (selectedCombination[key].image_url) {
                        if ((selectedCombination[key].key === 'flap_pocket') || (selectedCombination[key].key === 'two_flap_pocket')) {
                            selectedCombinationObj = {
                                ...selectedCombinationObj,
                                shirtPocket: basepath + selectedCombination[key].image_url,
                                foldedPocket: basepath + selectedCombination[key].folded_image_url,
                                frontPocketButton: baseButtonsPath + selectedButton + '/front/' + selectedCombination[key].front_pocket_button,
                                foldedPocketButton: baseButtonsPath + selectedButton + '/folded/' + selectedCombination[key].folded_pocket_button,
                                sidePocket: basepath + selectedCombination[key].side_pocket_image_url,
                                sidePocketButton: baseButtonsPath + selectedButton + '/side/' + selectedCombination[key].side_pocket_button,
                                foldedPocketHole: baseButtonsPathV2 + selectedCombination['pocket'].folded_pocket_hole + hole + '.png',
                                foldedPocketThread: baseButtonsPathV2 + selectedCombination['pocket'].folded_pocket_thread + thread + '.png',
                                frontPocketHole: baseButtonsPathV2 + selectedCombination[key].front_pocket_hole + hole + '.png',
                                frontPocketThread: baseButtonsPathV2 + selectedCombination[key].front_pocket_thread + thread + '.png',
                                sidePocketHole: baseButtonsPathV2 + selectedCombination[key].side_pocket_hole + hole + '.png',
                                sidePocketThread: baseButtonsPathV2 + selectedCombination[key].side_pocket_thread + thread + '.png'
                            }
                        } else {
                            selectedCombinationObj = {
                                ...selectedCombinationObj,
                                shirtPocket: basepath + selectedCombination[key].image_url,
                                foldedPocket: basepath + selectedCombination[key].folded_image_url,
                                frontPocketButton: basepath + TRANSPARENT,
                                foldedPocketButton: basepath + TRANSPARENT,
                                sidePocket: basepath + selectedCombination[key].side_pocket_image_url,
                                sidePocketButton: basepath + TRANSPARENT,
                                foldedPocketHole: basepath + TRANSPARENT,
                                foldedPocketThread: basepath + TRANSPARENT,
                                frontPocketHole: basepath + TRANSPARENT,
                                frontPocketThread: basepath + TRANSPARENT,
                                sidePocketHole: basepath + TRANSPARENT,
                                sidePocketThread: basepath + TRANSPARENT
                            }
                        }

                    }
                    break;
                case 'back':
                    if (selectedCombination[key].image_url) {
                        selectedCombinationObj = {
                            ...selectedCombinationObj,
                            shirtBackModal: S3BASE_URL + 'modal/back_modal.png',
                            shirtBackPleats: basepath + selectedCombination['back'].pleats_url
                        }
                    }
                    break;
                case 'fit':
                    if (selectedCombination['fit'].key === 'normal_fit') {
                        selectedCombinationObj = {
                            ...selectedCombinationObj,
                            frontShirtBody: basepath + selectedCombination[key].front_normal_fit_tucked,
                            backShirtFit: basepath + selectedCombination[key].back_normal_fit_tucked,
                            sideShirtFit: basepath + selectedCombination[key].side_normal_fit_image_url
                        }
                    } else {
                        selectedCombinationObj = {
                            ...selectedCombinationObj,
                            frontShirtBody: basepath + selectedCombination[key].front_slim_fit_tucked,
                            backShirtFit: basepath + selectedCombination[key].back_slim_fit_tucked,
                            sideShirtFit: basepath + selectedCombination[key].side_slim_fit_image_url
                        }
                    }
                    break;
                case 'bottom':
                    if (selectedCombination[key].key === 'tucked') {
                        if (selectedCombination['fit'].key === 'slim_fit') {
                            selectedCombinationObj = {
                                ...selectedCombinationObj,
                                frontShirtBody: basepath + selectedCombination[key].front_slim_fit_tucked,
                                backShirtFit: basepath + selectedCombination['fit'].back_slim_fit_tucked,
                                sideShirtFit: basepath + selectedCombination['fit'].side_slim_fit_image_url
                            }
                        } else {
                            selectedCombinationObj = {
                                ...selectedCombinationObj,
                                frontShirtBody: basepath + selectedCombination[key].front_normal_fit_tucked,
                                backShirtFit: basepath + selectedCombination['fit'].back_normal_fit_tucked,
                                sideShirtFit: basepath + selectedCombination['fit'].side_normal_fit_image_url
                            }
                        }
                    } else {
                        if (selectedCombination['fit'].key === 'slim_fit') {
                            if ((selectedCombination['bottom'].key === 'tail') || (selectedCombination['bottom'].key === 'un_tucked')) {
                                selectedCombinationObj = {
                                    ...selectedCombinationObj,
                                    frontShirtBody: basepath + selectedCombination[key].front_tail_slim_fit,
                                    backShirtFit: basepath + selectedCombination[key].back_tail_slim_fit,
                                    sideShirtFit: basepath + selectedCombination[key].side_tail_slim_fit
                                }
                            } else {
                                selectedCombinationObj = {
                                    ...selectedCombinationObj,
                                    frontShirtBody: basepath + selectedCombination[key].front_square_slim_fit,
                                    backShirtFit: basepath + selectedCombination[key].back_sqaure_slim_fit,
                                    sideShirtFit: basepath + selectedCombination[key].side_square_slim_fit
                                }
                            }
                        } else {
                            if ((selectedCombination['bottom'].key === 'tail') || (selectedCombination['bottom'].key === 'un_tucked')) {
                                selectedCombinationObj = {
                                    ...selectedCombinationObj,
                                    frontShirtBody: basepath + selectedCombination[key].front_tail_normal_fit,
                                    backShirtFit: basepath + selectedCombination[key].back_tail_normal_fit,
                                    sideShirtFit: basepath + selectedCombination[key].side_tail_normal_fit
                                }
                            } else {
                                selectedCombinationObj = {
                                    ...selectedCombinationObj,
                                    frontShirtBody: basepath + selectedCombination[key].front_square_normal_fit,
                                    backShirtFit: basepath + selectedCombination[key].back_sqaure_normal_fit,
                                    sideShirtFit: basepath + selectedCombination[key].side_square_normal_fit
                                }
                            }
                        }
                    }
                    break;
                default:
                    break;
            }
        })
        return {
            selectedCombination: selectedCombinationObj,
            activeChildMenu,
            isShowFolded: isShowFolded,
            isShowFront: isShowFront,
            isShowBack: isShowBack,
            isShowSide: isShowSide,
            selectedShirtCombination: selectedCombination,
            selectedFabrics: selectedShirtFabric,
            selectedAccents: shirtAccents
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if ((this.state.selectedPosition !== prevState.selectedPosition) || (this.props.selectedFabric !== prevProps.selectedFabric) || (this.props.selectedStyle !== prevProps.selectedStyle)) {
            this.setState({ isLoadingStarted: true, isLoading: true }, () => {
                this.setState({ isLoadingStarted: false })
            })
        }
    }
    showFront() {
        this.onCanvasPositionChanged('front')
        this.setState({ isLoadingStarted: true, isLoading: true }, () => {
            this.setState({ isLoadingStarted: false })
        })
    }
    showFolded() {
        this.onCanvasPositionChanged('folded')
        this.setState({ isLoadingStarted: true, isLoading: true }, () => {
            this.setState({ isLoadingStarted: false })
        })
    }
    showBack() {
        this.onCanvasPositionChanged('back')
        this.setState({ isLoadingStarted: true, isLoading: true }, () => {
            this.setState({ isLoadingStarted: false })
        })
    }
    showSide() {
        this.onCanvasPositionChanged('side')
        this.setState({ isLoadingStarted: true, isLoading: true }, () => {
            this.setState({ isLoadingStarted: false })
        })
    }
    _renderPrevCarousel(isShowFront, isShowFolded, isShowBack, isShowSide) {
        if (isShowFolded) {
            this.showSide()
        } else if (isShowBack) {
            this.showFront()
        } else if (isShowFront) {
            this.showFolded()
        } else if (isShowSide) {
            this.showBack()
        }
    }
    _renderNextCarousel(isShowFront, isShowFolded, isShowBack, isShowSide) {
        if (isShowFolded) {
            this.showFront()
        } else if (isShowBack) {
            this.showSide()
        } else if (isShowFront) {
            this.showBack()
        } else if (isShowSide) {
            this.showFolded()
        }
    }


    _renderMeasurements(measurements) {
        let selectedMeasurements = JSON.parse(measurements)
        return (
            <React.Fragment>
                {/* <span>Shirt Size: <b> {selectedMeasurements?.key}</b></span> */}
                <div className="">
                    <table className="shirt-mm">
                        {SHIRT_IMAGES()}
                        {/* <tbody>
                            <tr>
                                <td>{selectedMeasurements?.value?.collar}</td>
                                <td>{selectedMeasurements?.value?.chest}</td>
                                <td>{selectedMeasurements?.value?.back}</td>
                                <td>{selectedMeasurements?.value?.waist}</td>
                                <td>{selectedMeasurements?.value?.sleeve}</td>
                                <td>{selectedMeasurements?.value?.shirtLength}</td>
                            </tr>
                        </tbody> */}
                    </table>
                </div>
            </React.Fragment >
        )
    }

    render() {
        const { selectedCombination, isShowFolded, isShowFront, isShowBack,
            isLoadingStarted, isLoading, isShowSide, selectedShirtCombination, selectedFabrics, selectedAccents } = this.state;
        const { frontPlacket, frontCollar, frontSleeve,
            shirtPocket, modelImage, shirtBackPlacket,
            frontViewPant, frontCollarCenterButton, shirtCuffAll,
            defaultFoldedShirt, foldedPocket, frontPlacketButton, foldedShirtShadow,
            stripeFoldedButton, frontShirtBody, cuffButton,
            collarDownButton, foldedCollarCenterButton, foldedCollarDownButton, backCollar,
            backPantModal, shirtBackModal, backShirtSleeve,
            frontPocketButton, foldedPocketButton, shirtBackPleats, backShirtCuffButton,
            backShirtFit, sideModal, sidePant, sideCollar, sideCollarButton, sideCollarDownButton, sidePlacket,
            sideShirtSleeve, sideShirtFit, sidePocket, sidePlacketButton, foldedCuffHoleUrl, foldedCuffThreadUrl, foldedStripHole,
            foldedStripThread, sidePocketButton, foldedCollar, sidePlacketSingleButton, folded_collar_fabric,
            foldedCollarCenterButtonThread, front_collar_fabric, foldedCollarDownButtonThread, foldedCollarDownButtonHole,
            foldedCuffFabric, frontCollarCenterButtonThread,
            frontCollarDownButtonThread, frontCollarDownButtonHole, frontStripHole, frontStripThread, foldedPocketHole, foldedPocketThread,
            frontPocketThread, frontPocketHole, frontCuffFabric, backCuffFabric, sideCuffFabric, backElbowPatches, sidePocketThread,
            sidePocketHole, backCuffThreadUrl, backCuffHoleUrl, side_collar_fabric, sideCollarCenterButtonThread, sideCollarCenterButtonHole, sideCollarDownButtonHole,
            sideCollarDownButtonThread, sideStripThread, sideStripeHole, back_collar_fabric, sideCollarSingleButton } = selectedCombination || {}
        const { selectedMeasurement } = this.props.getSelectedMeasurement
        // const { selectedAccents: { shirtAccents } } = this.props;
        const { fill, font, hole, initials, selectedButton, thread, monogramPosition,
            collarFabricName, cuffFabricName, elbowFabricName } = selectedAccents || {}
        const { sleeve, back, bottom, collar, cuff, fit,
            placket, pocket } = selectedShirtCombination || {}
        return (
            <React.Fragment>
                <div className="row m-0">
                    <div className="col-lg-8 verify-casual h-auto p-0">
                        <div className='admin_canvas_detail_header d-flex gap-24 cusor-pointer'>
                            <div className='d-flex gap-8 mobile_admin_canvas_header'>
                                <img src={PATH.img.AdminCanvasHeader} alt="" />
                                <p className='fs-14 H-m mobile-hidden'>Back</p>
                            </div>
                            <img src={PATH.img.Logo_black} alt="" />
                        </div>

                        {isLoading && <Loader
                            isCanvas={true} />}
                        <div className='row mx-auto pt-1'>

                            <div className='col-lg-8 admin_canvas_img_height'>
                                <div id="carouselExampleIndicators" className="carousel slide text-center text-center" data-interval="false" data-ride="carousel">
                                    <ol className="carousel-indicators">
                                        <li data-target="#carouselExampleIndicators" title="FOLDED" onClick={() => this.showFolded()} className={`${isShowFolded ? 'active' : ''}`}></li>
                                        <li data-target="#carouselExampleIndicators" title="FRONT" onClick={() => this.showFront()} className={`${isShowFront ? 'active' : ''}`}></li>
                                        <li data-target="#carouselExampleIndicators" title="BACK" onClick={() => this.showBack()} className={`${isShowBack ? 'active' : ''}`}></li>
                                        <li data-target="#carouselExampleIndicators" title="SIDE" onClick={() => this.showSide()} className={`${isShowSide ? 'active' : ''}`}></li>
                                    </ol>
                                    {selectedCombination && (
                                        <div className='carousel-inner' data-interval="false">
                                            <div className={`carousel-item ${isShowFolded ? 'active' : ''}`}>
                                                <div className="pi-shirt-containers">
                                                    {(!isLoadingStarted && isShowFolded) &&
                                                        <div className={`pi_container w-75 m-auto pi_shirt_cuff ${isLoading && 'v-hide'}`}>
                                                            {
                                                                this.props.monogramPosition === 'high' && (
                                                                    <CustomAccents
                                                                        isFolded={true}
                                                                        monogramPosition={this.props.monogramPosition}
                                                                    />
                                                                )
                                                            }

                                                            <img className="custom_shirt shirt_shadow"
                                                                src={foldedShirtShadow}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_back"
                                                                src={defaultFoldedShirt.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_collar"
                                                                src={foldedCollar.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt'
                                                                id="folded_screenshot" />
                                                            <img className="custom_shirt shirt_collar_only"
                                                                src={folded_collar_fabric.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />

                                                            <img className="custom_shirt shirt_collar_button"
                                                                src={foldedCollarCenterButton}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_collar_down_button"
                                                                src={foldedCollarDownButton}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_collar_down_button_hole"
                                                                src={foldedCollarDownButtonHole}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_collar_button_thread"
                                                                src={foldedCollarCenterButtonThread}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_collar_down_button_down_thread"
                                                                src={foldedCollarDownButtonThread}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_placket"
                                                                src={shirtBackPlacket.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_placket_button"
                                                                src={stripeFoldedButton}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_placket_hole"
                                                                src={foldedStripHole}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_placket_thread"
                                                                src={foldedStripThread}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_cuff_all"
                                                                src={shirtCuffAll.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_cuff_inner"
                                                                src={foldedCuffFabric.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_cuff_button"
                                                                src={cuffButton}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_cuff_button_hole"
                                                                src={foldedCuffHoleUrl}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_cuff_button_thread"
                                                                src={foldedCuffThreadUrl}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_back_pocket"
                                                                src={foldedPocket.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_back_pocket_button"
                                                                src={foldedPocketButton}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />

                                                            <img className="custom_shirt shirt_back_pocket_hole"
                                                                src={foldedPocketHole}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_back_pocket_thread"
                                                                src={foldedPocketThread}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className={`carousel-item ${isShowFront ? 'active' : ''}`}>
                                                <div className="pi-shirt-containers ">
                                                    {(!isLoadingStarted && isShowFront) &&
                                                        <div className={`pi_container w-75 m-auto pi_shirt_front ${isLoading && 'v-hide'}`}>
                                                            <CustomAccents
                                                                monogramPosition={this.props.monogramPosition}
                                                            />
                                                            <img className="custom_shirt shirt_man"
                                                                src={modelImage}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_sleeve"
                                                                src={frontSleeve.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_pant"
                                                                src={frontViewPant}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_fit"
                                                                src={frontShirtBody.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_cuff_fabric"
                                                                src={frontCuffFabric.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_collar"
                                                                src={frontCollar.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_collar_fabric"
                                                                src={front_collar_fabric.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_button"
                                                                src={frontCollarCenterButton}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_collar_down_button"
                                                                src={collarDownButton}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />

                                                            <img className="custom_shirt shirt_collar_fabric_down"
                                                                src={frontCollarDownButtonHole}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_button_hole"
                                                                src={frontCollarCenterButtonThread}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_collar_center_button_fabric"
                                                                src={frontCollarDownButtonThread}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />

                                                            <img className="custom_shirt shirt_placket"
                                                                src={frontPlacket.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_placket_button"
                                                                src={frontPlacketButton}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />

                                                            <img className="custom_shirt shirt_placket_hole"
                                                                src={frontStripHole}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_placket_button_thread"
                                                                src={frontStripThread}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />

                                                            <img className="custom_shirt shirt_pocket"
                                                                src={shirtPocket.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_pocket_button"
                                                                src={frontPocketButton}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />

                                                            <img className="custom_shirt shirt_pocket_hole"
                                                                src={frontPocketHole}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_pocket_button_thread"
                                                                src={frontPocketThread}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                        </div>}
                                                </div>
                                            </div>
                                            <div className={`carousel-item ${isShowBack ? 'active' : ''}`}>
                                                <div className="pi-shirt-containers">
                                                    {(!isLoadingStarted && isShowBack) &&
                                                        <div className={`pi_container w-75 m-auto pi_shirt_back ${isLoading && 'v-hide'}`}>
                                                            <img className="custom_shirt shirt_back"
                                                                src={shirtBackModal}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_back_shirt_sleeve"
                                                                src={backShirtSleeve.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_back_pant"
                                                                src={backPantModal}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_back_shirt_fit"
                                                                src={backShirtFit.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_back_collar"
                                                                src={backCollar.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_back_collar_fabric"
                                                                src={back_collar_fabric.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_back_shirt_pleats"
                                                                src={shirtBackPleats.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_back_shirt_pleats_elbow"
                                                                src={backElbowPatches.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_back_shirt_cuff_fabric"
                                                                src={backCuffFabric.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_back_shirt_cuff_hole"
                                                                src={backCuffHoleUrl}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_back_shirt_cuff_button"
                                                                src={backShirtCuffButton}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_back_shirt_cuff_thread"
                                                                src={backCuffThreadUrl}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className={`carousel-item ${isShowSide ? 'active' : ''}`}>
                                                <div className="pi-shirt-containers">
                                                    {(!isLoadingStarted && isShowSide) &&
                                                        <div className={`m-auto pi_shirt_back ${isLoading && 'v-hide'}`}>
                                                            <img className="custom_shirt shirt_side"
                                                                src={sideModal}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt side_shirt_sleeve"
                                                                src={sideShirtSleeve.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt side_shirt_cuff"
                                                                src={sideCuffFabric.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_back_pant"
                                                                src={sidePant}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_side_fit"
                                                                src={sideShirtFit.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_side_collar"
                                                                src={sideCollar.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_side_collar_fabric"
                                                                src={side_collar_fabric.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />

                                                            <img className="custom_shirt shirt_side_collar_center_button_hole"
                                                                src={sideCollarCenterButtonHole}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />

                                                            <img className="custom_shirt shirt_side_collar_center_button"
                                                                src={sideCollarButton}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />

                                                            <img className="custom_shirt shirt_side_collar_center_button_thread"
                                                                src={sideCollarCenterButtonThread}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />

                                                            <img className="custom_shirt shirt_side_collar_down_button_hole"
                                                                src={sideCollarDownButtonHole}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />

                                                            <img className="custom_shirt shirt_side_collar_single_button"
                                                                src={sideCollarSingleButton}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />

                                                            <img className="custom_shirt shirt_side_collar_down_button"
                                                                src={sideCollarDownButton}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />

                                                            <img className="custom_shirt shirt_side_collar_down_button_thread"
                                                                src={sideCollarDownButtonThread}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />


                                                            <img className="custom_shirt shirt_side_pocket"
                                                                src={sidePocket.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_side_pocket_hole"
                                                                src={sidePocketHole}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_side_pocket_button"
                                                                src={sidePocketButton}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_side_pocket_thread"
                                                                src={sidePocketThread}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_side_placket_single_button"
                                                                src={sidePlacketSingleButton}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_side_placket"
                                                                src={sidePlacket.replace('.png', detectBrowser())}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_side_placket_button_hole"
                                                                src={sideStripeHole}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_side_placket_button"
                                                                src={sidePlacketButton}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                            <img className="custom_shirt shirt_side_placket_button_thread"
                                                                src={sideStripThread}
                                                                onLoad={() => this.onloadImageSuccess()}
                                                                onError={() => this.onloadImageError()}
                                                                alt='shirt' />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* <span className="carousel-control-prev prev_icon" role="button" data-slide="prev" onClick={() => this._renderPrevCarousel(isShowFront, isShowFolded, isShowBack, isShowSide)}>
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </span>
                            <span className="carousel-control-next prev_icon" role="button" data-slide="next" onClick={() => this._renderNextCarousel(isShowFront, isShowFolded, isShowBack, isShowSide)}>
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </span> */}
                                </div>
                            </div>
                            <div className='col-lg-4 pt-4 mobile_admin_canvas_detail_meas'>
                                <div>
                                    {/* <h3>Measurements</h3> */}
                                    {this._renderMeasurements(selectedMeasurement)}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4 admin_canvas_styles">
                        <div className="p-4">
                            <div className='d-flex gap-24 pb-4'>
                                <p className='H-m fs-16'>Styles</p>
                                {/* <p className='H-l fs-16'>Collar</p> */}
                            </div>
                            <div className='d-flex flex-column'>
                                <div className='d-flex gap-20 admin_canvas_styles_list'>
                                    <img src={PATH?.img?.AdminCanvasFabric} alt="" />
                                    <div className='d-flex flex-column gap-8'>
                                        <p className='fs-16 H-l'>Fabric</p>
                                        <p className='fs-14 H-l'>{selectedFabrics?.name}</p>
                                    </div>
                                </div>
                                <div className='d-flex gap-20 admin_canvas_styles_list'>
                                    <img src={PATH?.img?.AdminCanvasCollar} alt="" />
                                    <div className='d-flex flex-column gap-8'>
                                        <p className='fs-16 H-l'>Collar</p>
                                        <p className='fs-14 H-l'>{collar && collar.title}</p>
                                    </div>
                                </div>
                                <div className='d-flex gap-20 admin_canvas_styles_list'>
                                    <img src={PATH?.img?.AdminCanvasSleeve} alt="" />
                                    <div className='d-flex flex-column gap-8'>
                                        <p className='fs-16 H-l'>Sleeve</p>
                                        <p className='fs-14 H-l'>{sleeve && sleeve.title}</p>
                                    </div>
                                </div>
                                <div className='d-flex gap-20 admin_canvas_styles_list'>
                                    <img src={PATH?.img?.AdminCanvasPlacket} alt="" />
                                    <div className='d-flex flex-column gap-8'>
                                        <p className='fs-16 H-l'>Placket</p>
                                        <p className='fs-14 H-l'>{placket && placket.title}</p>
                                    </div>
                                </div>
                                <div className='d-flex gap-20 admin_canvas_styles_list'>
                                    <img src={PATH?.img?.AdminCanvasCuff} alt="" />
                                    <div className='d-flex flex-column gap-8'>
                                        <p className='fs-16 H-l'>Cuff</p>
                                        <p className='fs-14 H-l'>{cuff && cuff.title}</p>
                                    </div>
                                </div>
                                <div className='d-flex gap-20 admin_canvas_styles_list'>
                                    <img src={PATH?.img?.AdminCanvasThread} alt="" />
                                    <div className='d-flex flex-column gap-8'>
                                        <p className='fs-16 H-l'>Thread</p>
                                        <p className='fs-14 H-l'>{thread && thread}</p>
                                    </div>
                                </div>
                                <div className='d-flex gap-20 admin_canvas_styles_list'>
                                    <img src={PATH?.img?.AdminCanvasBack} alt="" />
                                    <div className='d-flex flex-column gap-8'>
                                        <p className='fs-16 H-l'>Back</p>
                                        <p className='fs-14 H-l'>{back && back.title}</p>
                                    </div>
                                </div>
                                <div className='d-flex gap-20 admin_canvas_styles_list p-4'>
                                    <img src={PATH?.img?.AdminCanvasPocket} alt="" />
                                    <div className='d-flex flex-column gap-8'>
                                        <p className='fs-16 H-l'>Pocket</p>
                                        <p className='fs-14 H-l'>{pocket && pocket.title}</p>
                                    </div>
                                </div>

                            </div>
                            {/* <h4>Styles</h4>
                            <p><b>Fabric : </b>{selectedFabrics?.name}</p>
                            <p>Sleeve : {sleeve && sleeve.title}</p>
                            <p>Collar : {collar && collar.title}</p>
                            <p>Cuff : {cuff && cuff.title}</p>
                            <p>Fit : {fit && fit.title}</p>
                            <p>Placket : {placket && placket.title}</p>
                            <p>Back : {back && back.title}</p>
                            <p>Bottom : {bottom && bottom.title}</p>
                            <p>Pocket : {pocket && pocket.title}</p> */}
                        </div>
                        {/* <div className="p-4">
                            <h4>Shirt Accents</h4>
                            {initials && (
                                <>
                                    <p>Intials: {initials}</p>
                                    <p>Font: {font}</p>
                                    <p>Monogram color : {fill && fill}</p>
                                    <p>Position: {monogramPosition && monogramPosition}</p>
                                </>
                            )}
                            <p>Hole : {hole && hole}</p>
                            <p>Selected Button : {selectedButton && selectedButton}</p>
                            <p>Thread : {thread && thread}</p>
                            {collarFabricName && <p><b> Collar Fabric:</b> {collarFabricName}</p>}
                            {cuffFabricName && <p><b> Cuff Fabric:</b> {cuffFabricName}</p>}
                            {elbowFabricName && <p><b> Elbow Fabric:</b> {elbowFabricName}</p>}
                        </div> */}
                    </div>
                </div>

            </React.Fragment>
        )
    }
}

const mapStateToProp = (state) => {
    return {
        selectedStyle: Actions.getShirtStyle(state),
        selectedFabric: Actions.getShirtFabric(state),
        selectedAccents: Actions.getShirtAccent(state),
        getSelectedMeasurement: Actions.getSelectedMeasurement(state)
    }
}

export default withRouter(connect(mapStateToProp, {
})(CanvasAdminShirtSection));
