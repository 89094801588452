import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { S3BASE_URL_VEST } from '../../config/api_endpoint'
import Loader from '../Loader'
import { convertAdminProductId } from '../../util/event'
import { detectBrowser, detectBrowserBaseUrl } from '../../util/detect-browser'
import { Constants } from '../../util/Constant'
import { PANT_IMAGES, SUIT_IMAGES } from '../../util/Canvas_Measurement'
class AdminVestSection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedCombination: {},
            isShowFront: false,
            isShowFolded: true,
            isShowBack: false,
            isShowSide: false,
            isLoading: true,
            isLoadingStarted: false,
            front: 10,
            back: 5,
            folded: 8,
            selectedVestCombination: {},
            selectedPosition: 'folded',
            selectedVestFabric: {},
            selectedAccents: {}
        }
    }
    onloadImageSuccess() {
        this.onloadImages()
    }

    onloadImageError() {
        this.onloadImages()
    }
    onCanvasPositionChanged(selectedPosition) {
        this.setState({ selectedPosition: selectedPosition })
    }
    onloadImages() {
        const { isShowFront, isShowBack, isShowFolded, front, back, folded } = this.state;
        if (isShowFront) {
            if (front === 1) {
                this.setState({ isLoading: false, front: 10 })
                return;
            } else {
                this.setState({ front: front - 1 })
            }
        } else if (isShowBack) {
            if (back === 1) {
                this.setState({ isLoading: false, back: 5 })
                return;
            } else {
                this.setState({ back: back - 1 })
            }
        } else if (isShowFolded) {
            if (folded === 1) {
                this.setState({ isLoading: false, folded: 8 })
                return;
            } else {
                this.setState({ folded: folded - 1 })
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { selectedPosition } = state
        const { selectedVestStyle: { selectedCombination }, activeChildMenu,
            selectedVestAccents: { selectedAccents } } = props;
        let { selectedVestFabric } = props.selectedVestFabric || {}
        let { productId } = selectedVestFabric || {}
        let { button, hole, thread, selectedVestInnerFabric, selectedVestLining } = selectedAccents || {}
        let basepath = S3BASE_URL_VEST + '7081696952484/' + detectBrowserBaseUrl() + '/'
        let vestBaseAccentPath = S3BASE_URL_VEST + 'accents/'
        let transparentPath = S3BASE_URL_VEST + '7081696952484/' + detectBrowserBaseUrl() + '/'
        let vestTransparentPath = S3BASE_URL_VEST + 'transparent/transparent.png'
        if (productId !== 'undefined') {
            basepath = S3BASE_URL_VEST + convertAdminProductId(productId) + detectBrowserBaseUrl() + '/'
            transparentPath = S3BASE_URL_VEST + convertAdminProductId(productId) + detectBrowserBaseUrl() + '/'
        }
        let baseVestColorPath = ''
        if (selectedVestInnerFabric) {
            let parts = selectedVestInnerFabric?.split('/');
            let shopifyId = parts[parts.length - 1];
            baseVestColorPath = vestBaseAccentPath + 'custom/' + shopifyId + '/'
        }

        let isShowFolded = true
        let isShowBack = false
        let isShowFront = false
        let selectedCombinationObj = {
            frontModal: S3BASE_URL_VEST + 'modal/front_modal.png',
            backModal: S3BASE_URL_VEST + 'modal/back_modal.png',
            frontPant: basepath + 'pant/front_pant.png',
            frontPocket: basepath + 'transparent/transparent.png',
            foldedPocket: basepath + 'transparent/transparent.png',
            frontBreastPocket: basepath + 'transparent/transparent.png',
            foldedBreastPocket: basepath + 'transparent/transparent.png',
            backPant: basepath + 'pant/back_pant.png',
            foldedPant: basepath + 'pant/folded_pant.png',
            backStyle: basepath + 'style/back/lining.png',
            frontStyle: basepath + 'style/notch/diagonal/slim/front/single_three_breasted.png',
            foldedStyle: basepath + 'style/notch/diagonal/slim/folded/single_three_breasted.png',
            frontShirt: S3BASE_URL_VEST + 'modal/front_shirt.png',
            backShirt: S3BASE_URL_VEST + 'modal/back_shirt.png',
            frontTie: S3BASE_URL_VEST + 'modal/tie.png',
            foldedVestButtons: vestBaseAccentPath + '/buttons/folded/single_1_button.png',
            frontButtonThread: vestBaseAccentPath + 'threads/front/single_3_button_thread/button_center/white.png',
            frontButtonHole: vestBaseAccentPath + 'threads/front/single_3_button_thread/button_hole/white.png',
            foldedButtonThread: vestBaseAccentPath + 'threads/folded/single_3_button_thread/button_center/white.png',
            foldedButtonHole: vestBaseAccentPath + 'threads/folded/single_3_button_thread/button_hole/white.png',
            foldedCustomColor: selectedVestLining === 'default' ? vestTransparentPath : baseVestColorPath + detectBrowserBaseUrl() + '/folded/custom_color.png',
            backCustomColor: selectedVestLining === 'default' ? vestTransparentPath : baseVestColorPath + detectBrowserBaseUrl() + '/back/custom_color.png'
        }
        switch (selectedPosition) {
            case 'front':
                isShowFolded = false
                isShowBack = false
                isShowFront = true
                break;
            case 'folded':
                isShowFolded = true
                isShowBack = false
                isShowFront = false
                break;
            case 'back':
                isShowFolded = false
                isShowBack = true
                isShowFront = false
                break;
            default:
                break;
        }

        Object.keys(selectedCombination).forEach((key) => {
            switch (key) {
                case 'pocket':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontPocket: selectedCombination[key].key === 'no_pocket' ? vestTransparentPath : basepath + selectedCombination[key].front_image_url,
                        foldedPocket: selectedCombination[key].key === 'no_pocket' ? vestTransparentPath : basepath + selectedCombination[key].folded_image_url,
                    }
                    break;
                case 'breast_pocket':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontBreastPocket: selectedCombination[key].key === 'no' ? vestTransparentPath : basepath + selectedCombination[key].front_image_url,
                        foldedBreastPocket: selectedCombination[key].key === 'no' ? vestTransparentPath : basepath + selectedCombination[key].folded_image_url,
                    }
                    break;
                case 'edge':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontStyle: basepath + 'style/' + selectedCombination['lapels'].front_image_url + '/' + selectedCombination[key].front_image_url + '/' + selectedCombination['lapels_width'].front_image_url + '/front/' + selectedCombination['style'].front_image_url,
                        foldedStyle: basepath + 'style/' + selectedCombination['lapels'].folded_image_url + '/' + selectedCombination[key].folded_image_url + '/' + selectedCombination['lapels_width'].front_image_url + '/folded/' + selectedCombination['style'].folded_image_url,
                    }
                    break;
                case 'style':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontStyle: basepath + 'style/' + selectedCombination['lapels'].front_image_url + '/' + selectedCombination['edge'].front_image_url + '/' + selectedCombination['lapels_width'].front_image_url + '/front/' + selectedCombination[key].front_image_url,
                        foldedStyle: basepath + 'style/' + selectedCombination['lapels'].folded_image_url + '/' + selectedCombination['edge'].folded_image_url + '/' + selectedCombination['lapels_width'].front_image_url + '/folded/' + selectedCombination[key].folded_image_url,
                        foldedVestButtons: vestBaseAccentPath + 'buttons/folded/' + button + '/' + selectedCombination['style'].button_image_url,
                        frontVestButtons: vestBaseAccentPath + 'buttons/front/' + button + '/' + selectedCombination['style'].button_image_url,
                        frontButtonThread: vestBaseAccentPath + selectedCombination['style'].front_button_thread_url + `${thread}.png`,
                        frontButtonHole: vestBaseAccentPath + selectedCombination['style'].front_button_hole_url + `${hole}.png`,
                        foldedButtonThread: vestBaseAccentPath + selectedCombination['style'].folded_button_thread_url + `${thread}.png`,
                        foldedButtonHole: vestBaseAccentPath + selectedCombination['style'].folded_button_hole_url + `${hole}.png`
                    }
                    break;
                case 'lapels':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontStyle: basepath + 'style/' + selectedCombination[key].front_image_url + '/' + selectedCombination['edge'].front_image_url + '/' + selectedCombination['lapels_width'].front_image_url + '/front/' + selectedCombination['style'].front_image_url,
                        foldedStyle: basepath + 'style/' + selectedCombination[key].folded_image_url + '/' + selectedCombination['edge'].folded_image_url + '/' + selectedCombination['lapels_width'].front_image_url + '/folded/' + selectedCombination['style'].folded_image_url,
                    }
                    break;
                case 'lapels_width':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        frontStyle: basepath + 'style/' + selectedCombination['lapels'].front_image_url + '/' + selectedCombination['edge'].front_image_url + '/' + selectedCombination['lapels_width'].front_image_url + '/front/' + selectedCombination['style'].front_image_url,
                        foldedStyle: basepath + 'style/' + selectedCombination['lapels'].front_image_url + '/' + selectedCombination['edge'].folded_image_url + '/' + selectedCombination['lapels_width'].front_image_url + '/folded/' + selectedCombination['style'].folded_image_url,
                    }
                    break;
                default:
                    break;
            }
        })
        return {
            selectedCombination: selectedCombinationObj,
            activeChildMenu,
            isShowFolded: isShowFolded,
            isShowFront: isShowFront,
            isShowBack: isShowBack,
            selectedVestCombination: selectedCombination,
            selectedVestFabric,
            selectedAccents
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if ((this.state.selectedPosition !== prevState.selectedPosition) || (this.props.selectedVestStyle !== prevProps.selectedVestStyle) || (this.props.selectedSuitFabric !== prevProps.selectedSuitFabric)) {
            this.setState({ isLoadingStarted: true, isLoading: true }, () => {
                this.setState({ isLoadingStarted: false })
            })
        }
    }
    showFront() {
        this.onCanvasPositionChanged('front')
        this.setState({ isLoadingStarted: true, isLoading: true }, () => {
            this.setState({ isLoadingStarted: false })
        })
    }
    showFolded() {
        this.onCanvasPositionChanged('folded')
        this.setState({ isLoadingStarted: true, isLoading: true }, () => {
            this.setState({ isLoadingStarted: false })
        })
    }
    showBack() {
        this.onCanvasPositionChanged('back')
        this.setState({ isLoadingStarted: true, isLoading: true }, () => {
            this.setState({ isLoadingStarted: false })
        })
    }
    _renderPrevCarousel(isShowFront, isShowFolded, isShowBack) {
        if (isShowFolded) {
            this.showBack()
        } else if (isShowBack) {
            this.showFront()
        } else if (isShowFront) {
            this.showFolded()
        }
    }
    _renderNextCarousel(isShowFront, isShowFolded, isShowBack) {
        if (isShowFolded) {
            this.showFront()
        } else if (isShowBack) {
            this.showFolded()
        } else if (isShowFront) {
            this.showBack()
        }
    }

    _renderSuitStandardMeasurements(measurementValue) {
        const { pantFields, suitFields } = measurementValue || {}
        return (
            <>
                <table className="table text-center shirt-mm">
                    {SUIT_IMAGES()}

                    <tbody>
                        <tr>
                            <td>{suitFields?.chest}</td>
                            <td>{suitFields?.stomach}</td>
                            <td>{suitFields?.shoulder}</td>
                            <td>{suitFields?.sleeve}</td>
                            <td>{suitFields?.shirtLength}</td>
                        </tr>
                    </tbody>

                </table>
                <table className="table text-center shirt-mm">
                    {PANT_IMAGES()}
                    <tbody>
                        <tr>
                            <td>{pantFields?.waist}</td>
                            <td>{pantFields?.hip}</td>
                            <td>{pantFields?.crotch}</td>
                            <td>{pantFields?.thigh}</td>
                            <td>{pantFields?.pantLength}</td>
                        </tr>
                    </tbody>

                </table>
            </>
        )
    }

    _renderSuitCustomMeasurement(measurementValue) {
        return (
            <>
                <table className="table text-center shirt-mm">
                    {SUIT_IMAGES()}
                    <tbody>
                        <tr>
                            <td>{measurementValue?.chest}</td>
                            <td>{measurementValue?.stomach}</td>
                            <td>{measurementValue?.shoulder}</td>
                            <td>{measurementValue?.sleeve}</td>
                            <td>{measurementValue?.shirtLength}</td>
                        </tr>
                    </tbody>

                </table>
                <table className="table text-center shirt-mm">
                    {PANT_IMAGES()}
                    <tbody>
                        <tr>
                            <td>{measurementValue?.waist}</td>
                            <td>{measurementValue?.hip}</td>
                            <td>{measurementValue?.crotch}</td>
                            <td>{measurementValue?.thigh}</td>
                            <td>{measurementValue?.pantLength}</td>
                        </tr>
                    </tbody>

                </table>
            </>
        )
    }


    _renderMeasurement(measurements) {
        let selectedMeasurement = JSON.parse(measurements)
        return (
            selectedMeasurement?.type === Constants.MEASUREMENT_TYPES.STANDARD_MEASUREMENT ? this._renderSuitStandardMeasurements(selectedMeasurement?.value) : this._renderSuitCustomMeasurement(selectedMeasurement?.value)
        )

    }

    render() {
        const { selectedCombination, isShowFolded, isShowFront, isShowBack, isLoadingStarted,
            isLoading, selectedVestCombination, selectedVestFabric, selectedAccents } = this.state;
        const { backModal, backPant, frontModal, frontPant, frontBreastPocket, frontPocket, foldedPocket,
            foldedBreastPocket, foldedPant, backStyle, foldedStyle, frontStyle, frontShirt, backShirt, frontTie, foldedVestButtons,
            frontVestButtons, frontButtonThread, frontButtonHole, foldedButtonHole, foldedButtonThread, foldedCustomColor, backCustomColor } = selectedCombination || {}

        const { style, pocket, edge, breast_pocket, lapels, lapels_width } = selectedVestCombination || {}

        const { selectedMeasurement } = this.props.measurements || {}

        const { button, hole, thread, liningFabricName } = selectedAccents || {}

        return (
            <React.Fragment>
                <div className="row m-0">
                    <div className="col-lg-6 order-1 order-sm-1 order-lg-2 verify-casual h-100">
                        {isLoading && <Loader
                            isCanvas={true} />}
                        <div id="carouselExampleIndicators" className="carousel slide text-center text-center" data-interval="false" data-ride="carousel">
                            <ol className="carousel-indicators">
                                <li data-target="#carouselExampleIndicators" title="FOLDED" onClick={() => this.showFolded()} className={`${isShowFolded ? 'active' : ''}`}></li>
                                <li data-target="#carouselExampleIndicators" title="FRONT" onClick={() => this.showFront()} className={`${isShowFront ? 'active' : ''}`}></li>
                                <li data-target="#carouselExampleIndicators" title="BACK" onClick={() => this.showBack()} className={`${isShowBack ? 'active' : ''}`}></li>
                                <a><i className="bi bi-chevron-right"></i></a>
                            </ol>
                            {selectedCombination && (
                                <div className='carousel-inner' data-interval="false">
                                    <div className={`carousel-item ${isShowFolded ? 'active' : ''}`}>
                                        <div className="pi-shirt-containers">
                                            {(!isLoadingStarted && isShowFolded) &&
                                                <div className={`pi_container pi-pant w-75 m-auto pi_vest_cuff  ${isLoading && 'v-hide'}`}>
                                                    <img className="custom_shirt folded_pocket"
                                                        src={foldedBreastPocket.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_style"
                                                        src={foldedCustomColor.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={foldedStyle.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={foldedButtonHole}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={foldedVestButtons}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={foldedButtonThread}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_breast_pocket"
                                                        src={foldedPocket.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_pant"
                                                        src={foldedPant.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className={`carousel-item ${isShowFront ? 'active' : ''}`}>
                                        <div className="pi-shirt-containers">
                                            {(!isLoadingStarted && isShowFront) &&
                                                <div className={`pi_container pi-pant w-75 m-auto pi_vest_front  ${isLoading && 'v-hide'}`}>
                                                    <img className="custom_shirt shirt_man"
                                                        src={frontModal}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_shirt"
                                                        src={frontShirt}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt front_tie"
                                                        src={frontTie}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_pant"
                                                        src={frontPant.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />

                                                    <img className="custom_shirt folded_fit"
                                                        src={frontStyle.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_button"
                                                        src={frontPocket.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_cuff_fabric"
                                                        src={frontBreastPocket.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={frontButtonHole}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={frontVestButtons}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={frontButtonThread}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                </div>}
                                        </div>
                                    </div>
                                    <div className={`carousel-item ${isShowBack ? 'active' : ''}`}>
                                        <div className="pi-shirt-containers">
                                            {(!isLoadingStarted && isShowBack) &&
                                                <div className={`pi_container pi-pant w-75 m-auto pi_vest_back  ${isLoading && 'v-hide'}`}>
                                                    <img className="custom_shirt suit_back"
                                                        src={backModal}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_shirt"
                                                        src={backShirt}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_shirt_pocket"
                                                        src={backPant.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_shirt_pocket"
                                                        src={backStyle.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_fit"
                                                        src={backCustomColor.replace('.png', detectBrowser())}
                                                        onLoad={() => this.onloadImageSuccess()}
                                                        onError={() => this.onloadImageError()}
                                                        alt='shirt' />

                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <ul className="nw_zoom">
                                        {
                                            <li className="cursor-pointer"
                                                onClick={() => this.props.showJacket()}>
                                                <img src={require('../../asset/images/suit/SVG/Show.svg')}
                                                    className="img-fluid" alt="icon" />
                                                <p>Show Jacket</p>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            )}

                            {/* <Link className="carousel-control-prev prev_icon" role="button" data-slide="prev" onClick={() => this._renderPrevCarousel(isShowFront, isShowFolded, isShowBack)}>
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                            </Link>
                            <Link className="carousel-control-next prev_icon" role="button" data-slide="next" onClick={() => this._renderNextCarousel(isShowFront, isShowFolded, isShowBack)}>
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                            </Link> */}
                        </div>
                    </div>
                    <div className="col-lg-6 mt-2" >
                        <div className="row">
                            <div className="col-sm-6">
                                <h4>Styles</h4>
                                <p><b>Fabric : </b>{selectedVestFabric?.name}</p>
                                <p>Vest Styles : {style && style.title}</p>
                                <p>Edge : {edge && edge.title}</p>
                                <p>Pocket : {pocket && pocket.title}</p>
                                <p>Breast Pocket : {breast_pocket && breast_pocket.title}</p>
                                <p>Lapels : {lapels && lapels.title}</p>
                                <p>Lapels Width : {lapels_width && pocket.title}</p>
                            </div>
                            <div className="col-sm-6">
                                <h4>Vest Accents</h4>
                                <p>Button : {button}</p>
                                <p>Hole : {hole}</p>
                                <p>Thread : {thread}</p>
                                {liningFabricName && <p><b>Inner Fabric: {liningFabricName}</b></p>}
                            </div>
                        </div>
                        {this._renderMeasurement(selectedMeasurement)}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProp = (state) => {
    return {
        selectedVestStyle: Actions.getSelectedVestStyle(state),
        selectedSuitFabric: Actions.getSelectedSuitFabric(state),
        selectedVestAccents: Actions.getSelectedVestAccent(state),
        measurements: Actions.getSelectedMeasurement(state),
        selectedVestFabric: Actions.getVestFabric(state),
    }
}

export default withRouter(connect(mapStateToProp, {
})(AdminVestSection));
