import { put, takeLatest, all } from 'redux-saga/effects';
import Actions from '../action';
import { getQuery, loginQuery, postQuery } from '../../service';

export function* checkoutCreate({ query }) {
  try {
    let input = {}
    const response = yield loginQuery(query, input);
    yield put(Actions.checkoutCreateSuccess(response));
    yield put(Actions.selectedCheckoutSuccess(response))
   
  } catch (error) {
   
    yield put(Actions.checkoutCreateFailure(error));
  }
}

export function* checkoutLineItemsAdd({ query, input, callback }) {
  try {
    const response = yield postQuery(query, input);
    yield put(Actions.checkoutLineItemsAddSuccess(response));
    if (callback) {
      callback(response)
    }
  } catch (error) {
    if (callback) {
      callback({ error })
    }
    yield put(Actions.checkoutLineItemsAddFailure(error));
  }
}

export function* checkoutLineItemsRemove({ query, input, callback }) {
  try {
    const response = yield postQuery(query, input);
    yield put(Actions.checkoutLineItemsRemoveSuccess(response));
    if (callback) {
      callback(response)
    }
  } catch (error) {
    if (callback) {
      callback({ error })
    }
    yield put(Actions.checkoutLineItemsRemoveFailure(error));
  }
}


export function* checkoutCustomerAssociate({ query, input, callback }) {
  try {
    const response = yield postQuery(query, input);
    yield put(Actions.checkoutCustomerAssociateSuccess(response));
    if (callback) {
      callback(response)
    }
  } catch (error) {
    if (callback) {
      callback({ error })
    }
    yield put(Actions.checkoutCustomerAssociateFailure(error));
  }
}

export function* checkoutGiftCardAdd({ query, input, callback }) {
  try {
    const response = yield postQuery(query, input);
    yield put(Actions.checkoutGiftCardAddSuccess(response));
    if (callback) {
      callback(response)
    }
  } catch (error) {
    if (callback) {
      callback({ error })
    }
    yield put(Actions.checkoutGiftCardAddFailure(error));
  }
}

export function* checkoutDiscountAdd({ query, input, callback }) {
  try {
    const response = yield postQuery(query, input);
    yield put(Actions.checkoutDiscountAddSuccess(response));
    if (callback) {
      callback(response)
    }
  } catch (error) {
    if (callback) {
      callback({ error })
    }
    yield put(Actions.checkoutDiscountAddFailure(error));
  }
}

export function* shippingAddressAdd({ query, input, callback }) {
  try {
    const response = yield postQuery(query, input);
    yield put(Actions.shippingAddressAddSuccess(response));
    if (callback) {
      callback(response)
    }
  } catch (error) {
    if (callback) {
      callback({ error })
    }
    yield put(Actions.shippingAddressAddFailure(error));
  }
}

export function* checkoutAttributesUpdate({ query, input, callback }) {
  try {
    const response = yield postQuery(query, input);
    yield put(Actions.checkoutAttributesUpdateSuccess(response));
    if (callback) {
      callback(response)
    }
  } catch (error) {
    if (callback) {
      callback({ error })
    }
    yield put(Actions.checkoutAttributesUpdateFailure(error));
  }
}

export function* checkoutLineItemsUpdate({ query, input, callback }) {
  try {
    const response = yield postQuery(query, input);
    yield put(Actions.checkoutLineItemsUpdateSuccess(response));
    if (callback) {
      callback(response)
    }
  } catch (error) {
    if (callback) {
      callback({ error })
    }
    yield put(Actions.checkoutLineItemsUpdateFailure(error));
  }
}

export function* checkoutShippingLineUpdate({ query, input, callback }) {
  try {
    const response = yield postQuery(query, input);
    yield put(Actions.checkoutShippingLineUpdateSuccess(response));
    if (callback) {
      callback(response)
    }
  } catch (error) {
    if (callback) {
      callback({ error })
    }
    yield put(Actions.checkoutShippingLineUpdateFailure(error));
  }
}

export function* getRecentCheckOut({ query }) {
  try {
    const response = yield getQuery(query);
    yield put(Actions.getRecentCheckOutDetailsSuccess(response));
  } catch (error) {
    yield put(Actions.getRecentCheckOutDetailsFailure(error));
  }
}


export function* getCheckoutDetails({ query }) {
  try {
    const response = yield getQuery(query);
    yield put(Actions.checkOutDetailsSuccess(response));
  } catch (error) {
    yield put(Actions.checkOutDetailsFailure(error));
  }
}

export default function* () {
  yield all([
    takeLatest(Actions.CHECKOUT_CREATE, checkoutCreate),
    takeLatest(Actions.CHECKOUT_LINE_ITEMS_ADD, checkoutLineItemsAdd),
    takeLatest(Actions.CHECKOUT_CUSTOMER_ASSOCIATE, checkoutCustomerAssociate),
    takeLatest(Actions.CHECKOUT_GIFT_CARD_ADD, checkoutGiftCardAdd),
    takeLatest(Actions.SHIPPING_ADDRESS_ADD, shippingAddressAdd),
    takeLatest(Actions.GET_RECENT_CHECKOUT, getRecentCheckOut),
    takeLatest(Actions.CHECKOUT_LINE_ITEMS_REMOVE, checkoutLineItemsRemove),
    takeLatest(Actions.CHECKOUT_LINE_ITEMS_UPDATE, checkoutLineItemsUpdate),
    takeLatest(Actions.CHECKOUT_DISCOUNT_ADD, checkoutDiscountAdd),
    takeLatest(Actions.SHIPPING_LINE_UPDATE, checkoutShippingLineUpdate),
    takeLatest(Actions.CHECKOUT_ATTRIBUTES_UPDATE, checkoutAttributesUpdate),
    takeLatest(Actions.GET_CHECKOUT_DETAILS, getCheckoutDetails)
  ]);
}
