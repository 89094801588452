import { put, takeLatest, all } from 'redux-saga/effects';
import Actions from '../action';
import { getQuery } from '../../service';
export function* getCustomerOrders({ query }) {
  try {
    const response = yield getQuery(query);
    yield put(Actions.customerOrdersSuccess(response));
  } catch (error) {
    yield put(Actions.customerOrdersFailure(error));
  }
}


export default function* () {
  yield all([
    takeLatest(Actions.GET_CUSTOMER_ORDERS, getCustomerOrders)
  ]);
}
