import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { S3BASE_URL_PANT } from '../../config/api_endpoint'
import Loader from '../Loader'
import { PANT_TRANSPARENT } from '../../config/constant'
import { convertAdminProductId } from '../../util/event'
import { PANT_IMAGES } from '../../util/Canvas_Measurement'
class CanvasAdminPantSection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedCombination: undefined,
            isShowFront: true,
            isShowFolded: false,
            isShowBack: false,
            isShowSide: false,
            isLoading: true,
            isLoadingStarted: false,
            front: 8,
            back: 6,
            folded: 5,
            isShowCasual: false,
            selectedPantCombination: {},
            selectedPosition: 'front',
            selectedFabrics: {}
        }
    }

    onloadImageSuccess() {
        this.onloadImages()
    }

    onloadImageError() {
        this.onloadImages()
    }

    onloadImages() {
        const { isShowFront, isShowBack, isShowFolded, front, back, folded } = this.state;
        if (isShowFront) {
            if (front === 1) {
                this.setState({ isLoading: false, front: 8 })
                return;
            } else {
                this.setState({ front: front - 1 })
            }
        } else if (isShowBack) {
            if (back === 1) {
                this.setState({ isLoading: false, back: 6 })
                return;
            } else {
                this.setState({ back: back - 1 })
            }
        } else if (isShowFolded) {
            if (folded === 1) {
                this.setState({ isLoading: false, folded: 5 })
                return;
            } else {
                this.setState({ folded: folded - 1 })
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { selectedPantStyle: { selectedCombination }, activeChildMenu,
            selectedPantFabric: { selectedPantFabric } } = props;
        let { productId } = selectedPantFabric || {}
        const { selectedPosition } = state
        let basepath = S3BASE_URL_PANT + '6695021215908/'
        if (productId !== 'undefined') {
            basepath = S3BASE_URL_PANT + convertAdminProductId(productId)
        }
        let isShowFolded = false
        let isShowBack = false
        let isShowFront = true
        let selectedCombinationObj = {
            frontModal: S3BASE_URL_PANT + 'modal/front/model.png',
            frontShirt: S3BASE_URL_PANT + 'modal/front/shirt.png',
            frontShoe: S3BASE_URL_PANT + 'modal/front/shoe.png',
            backModal: S3BASE_URL_PANT + 'modal/back/model.png',
            backShirt: S3BASE_URL_PANT + 'modal/back/shirt.png',
            backShoe: S3BASE_URL_PANT + 'modal/back/shoe.png',
            foldedPantFit: basepath + 'fit/folded/normal_fit.png',
            frontPantFit: basepath + 'fit/front/normal_fit.png',
            backPantFit: basepath + 'fit/back/normal_fit.png',
            backCuff: basepath + 'cuff/back/normal_fit_cuff.png',
            frontCuff: basepath + 'cuff/front/normal_fit_cuff.png',
            foldedCuff: basepath + 'cuff/folded/normal_cuff.png',
            backPocket: basepath + 'pocket/back/double_welted.png',
            frontFastening: basepath + 'fastening/front/centered.png',
            frontPleats: basepath + 'pleats/front/pleated.png',
            foldedPleats: basepath + 'pleats/folded/pleated.png',
            frontPocket: basepath + 'pocket/front/rounded.png',
            foldedSidePocket: basepath + 'pocket/folded/rounded.png',
            foldedPocket: basepath + 'pocket/folded/double_welted.png'
        }
        switch (selectedPosition) {
            case 'front':
                isShowFolded = false
                isShowBack = false
                isShowFront = true
                break;
            case 'folded':
                isShowFolded = true
                isShowBack = false
                isShowFront = false
                break;
            case 'back':
                isShowFolded = false
                isShowBack = true
                isShowFront = false
                break;
            default:
                break;
        }
        Object.keys(selectedCombination).forEach((key) => {
            switch (key) {
                case 'cuff':
                    if (selectedCombination['fit'].key === 'normal_fit') {
                        selectedCombinationObj = {
                            ...selectedCombinationObj,
                            foldedCuff: selectedCombination[key].key === 'no_cuffs' ? basepath + PANT_TRANSPARENT : basepath + selectedCombination[key].folded_image_url + 'normal_cuff.png',
                            frontCuff: selectedCombination[key].key === 'no_cuffs' ? basepath + PANT_TRANSPARENT : basepath + selectedCombination[key].front_image_url + 'normal_fit_cuff.png',
                            backCuff: selectedCombination[key].key === 'no_cuffs' ? basepath + PANT_TRANSPARENT : basepath + selectedCombination[key].back_image_url + 'normal_fit_cuff.png'
                        }
                    } else {
                        selectedCombinationObj = {
                            ...selectedCombinationObj,
                            foldedCuff: selectedCombination[key].key === 'no_cuffs' ? basepath + PANT_TRANSPARENT : basepath + selectedCombination[key].folded_image_url + 'slim_cuff.png',
                            frontCuff: selectedCombination[key].key === 'no_cuffs' ? basepath + PANT_TRANSPARENT : basepath + selectedCombination[key].front_image_url + 'slim_fit_cuff.png',
                            backCuff: selectedCombination[key].key === 'no_cuffs' ? basepath + PANT_TRANSPARENT : basepath + selectedCombination[key].back_image_url + 'slim_fit_cuff.png'
                        }
                    }

                    break;
                case 'pocket':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedPocket: selectedCombination[key].key === 'no_pocket' ? basepath + PANT_TRANSPARENT : basepath + selectedCombination[key].folded_image_url,
                        backPocket: selectedCombination[key].key === 'no_pocket' ? basepath + PANT_TRANSPARENT : basepath + selectedCombination[key].back_image_url,
                    }
                    break;
                case 'side_pocket':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedSidePocket: basepath + selectedCombination[key].folded_image_url,
                        frontPocket: basepath + selectedCombination[key].front_image_url,
                    }
                    break;
                case 'fit':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedPantFit: basepath + selectedCombination[key].folded_image_url,
                        frontPantFit: basepath + selectedCombination[key].front_image_url,
                        backPantFit: basepath + selectedCombination[key].back_image_url
                    }
                    break;
                case 'fastening':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedFastening: basepath + selectedCombination[key].front_normal_fit_tucked,
                        frontFastening: basepath + selectedCombination[key].front_image_url,
                    }
                    break;
                case 'pleats':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedPleats: selectedCombination[key].key === 'no_pleats' ? basepath + PANT_TRANSPARENT : basepath + selectedCombination[key].folded_image_url,
                        frontPleats: selectedCombination[key].key === 'no_pleats' ? basepath + PANT_TRANSPARENT : basepath + selectedCombination[key].front_image_url,
                    }
                    break;
                default:
                    break;
            }
        })
        return {
            selectedCombination: selectedCombinationObj,
            activeChildMenu,
            isShowFolded: isShowFolded,
            isShowFront: isShowFront,
            isShowBack: isShowBack,
            selectedPantCombination: selectedCombination,
            selectedFabrics: selectedPantFabric
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if ((this.state.selectedPosition !== prevState.selectedPosition) || (this.props.selectedPantStyle !== prevProps.selectedPantStyle) || (this.props.selectedPantFabric !== prevProps.selectedPantFabric)) {
            this.setState({ isLoadingStarted: true, isLoading: true }, () => {
                this.setState({ isLoadingStarted: false })
            })
        }
    }
    onCanvasPositionChanged(selectedPosition) {
        this.setState({ selectedPosition })
    }
    showFront() {
        this.onCanvasPositionChanged('front')
        this.setState({ isLoadingStarted: true, isLoading: true }, () => {
            this.setState({ isLoadingStarted: false })
        })
    }
    showFolded() {
        this.onCanvasPositionChanged('folded')
        this.setState({ isLoadingStarted: true, isLoading: true }, () => {
            this.setState({ isLoadingStarted: false })
        })
    }
    showBack() {
        this.onCanvasPositionChanged('back')
        this.setState({ isLoadingStarted: true, isLoading: true }, () => {
            this.setState({ isLoadingStarted: false })
        })
    }
    _renderPrevCarousel(isShowFront, isShowFolded, isShowBack) {
        if (isShowFolded) {
            this.showBack()
        } else if (isShowBack) {
            this.showFront()
        } else if (isShowFront) {
            this.showFolded()
        }
    }
    _renderNextCarousel(isShowFront, isShowFolded, isShowBack) {
        if (isShowFolded) {
            this.showFront()
        } else if (isShowBack) {
            this.showFolded()
        } else if (isShowFront) {
            this.showBack()
        }
    }
    _renderPantMeasurement(getMeasurements) {
        let measurements = JSON.parse(getMeasurements)
        let { value } = measurements || {}
        let { crotch, hip, pantLength, waist, thigh } = value || {}
        return (
            <div className="table-responsive">
                <table className="table text-center shirt-mm">
                    {PANT_IMAGES()}
                    <tbody>
                        <tr>
                            <td>{waist}</td>
                            <td>{hip}</td>
                            <td>{crotch}</td>
                            <td>{thigh}</td>
                            <td>{pantLength}</td>
                        </tr>
                    </tbody>

                </table>
            </div>

        )
    }

    render() {
        const { selectedCombination, isShowFolded, isShowFront, isShowBack,
            isLoadingStarted, isLoading, selectedPantCombination, selectedFabrics } = this.state;
        const { backModal, backShirt, backShoe, backPantFit, backCuff, backPocket, frontModal, frontShirt, frontShoe, frontPantFit, frontCuff,
            frontFastening, frontPleats, frontPocket, foldedPantFit, foldedCuff, foldedPleats, foldedPocket, foldedSidePocket } = selectedCombination || {}
        const { cuff, fastening, fit, pleats, pocket, side_pocket, rise, pant_bottom, suspender_button } = selectedPantCombination || {}
        const { selectedMeasurement } = this.props.measurements || {}
        return (
            <div className="row m-0">
                <div className="col-lg-6 order-1 order-sm-1 order-lg-2 verify-casual h-100">
                    <ul className="fabric_list">
                    </ul>
                    <div className="col-12 text-right mb-4 mbl-shar-ico reset-icon">
                    </div>
                    {isLoading && <Loader
                        isCanvas={true} />}
                    <div id="carouselExampleIndicators" className="carousel slide text-center text-center" data-interval="false" data-ride="carousel">
                        <ol className="carousel-indicators">
                            <li data-target="#carouselExampleIndicators" title="FRONT" onClick={() => this.showFront()} className={`${isShowFront ? 'active' : ''}`}></li>
                            <li data-target="#carouselExampleIndicators" title="BACK" onClick={() => this.showBack()} className={`${isShowBack ? 'active' : ''}`}></li>
                            <li data-target="#carouselExampleIndicators" title="FOLDED" onClick={() => this.showFolded()} className={`${isShowFolded ? 'active' : ''}`}></li>
                        </ol>

                        {selectedCombination && (
                            <div className='carousel-inner' data-interval="false">
                                <div className={`carousel-item ${isShowFront ? 'active' : ''}`}>
                                    <div className="pi-shirt-containers">
                                        {(!isLoadingStarted && isShowFront) &&
                                            <div className={`pi_container pi-pant w-75 m-auto pi_pant_front ${isLoading && 'v-hide'}`}>
                                                <img className="custom_shirt shirt_man"
                                                    src={frontModal}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_sleeve"
                                                    src={frontShirt}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_pant"
                                                    src={frontShoe}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_fit"
                                                    src={frontPantFit}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_cuff_fabric"
                                                    src={frontCuff}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_fastening"
                                                    src={frontFastening}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_collar_fabric"
                                                    src={frontPleats}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_button"
                                                    src={frontPocket}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                            </div>}
                                    </div>
                                </div>
                                <div className={`carousel-item ${isShowBack ? 'active' : ''}`}>
                                    <div className="pi-shirt-containers">
                                        {(!isLoadingStarted && isShowBack) &&
                                            <div className={`pi_container pi-pant w-75 m-auto pi_pant_back ${isLoading && 'v-hide'} `}>
                                                <img className="custom_shirt shirt_back"
                                                    src={backModal}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_back_shirt_sleeve"
                                                    src={backShirt}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_back_pant"
                                                    src={backShoe}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_back_shirt_fit"
                                                    src={backPantFit}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_back_collar_fabric"
                                                    src={backCuff}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_back_shirt_pocket"
                                                    src={backPocket}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className={`carousel-item ${isShowFolded ? 'active' : ''}`}>
                                    <div className="pi-shirt-containers">
                                        {(!isLoadingStarted && isShowFolded) &&
                                            <div className={`pi_container pi-pant folded-pant w-75 m-auto pi_pant_cuff ${isLoading && 'v-hide'} `}>
                                                <img className="custom_shirt folded_fit"
                                                    src={foldedPantFit}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_cuff"
                                                    src={foldedCuff}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_pleats"
                                                    src={foldedPleats}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt'
                                                />
                                                <img className="custom_shirt folded_pocket"
                                                    src={foldedPocket}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_side_pocket"
                                                    src={foldedSidePocket}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )}


                        {/* <Link className="carousel-control-prev prev_icon" role="button" data-slide="prev" onClick={() => this._renderPrevCarousel(isShowFront, isShowFolded, isShowBack)}>
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </Link>
                        <Link className="carousel-control-next prev_icon" role="button" data-slide="next" onClick={() => this._renderNextCarousel(isShowFront, isShowFolded, isShowBack)}>
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </Link> */}
                    </div>
                </div>
                <div className="col-lg-6 mt-2" >
                    <div className="row">
                        <div className="col-lg-12">
                            <div>
                                <h4>Styles</h4>
                                <p><b>Fabric : </b>{selectedFabrics?.name}</p>
                                <p>Cuff : {cuff && cuff.title}</p>
                                <p>Fastening : {fastening && fastening.title}</p>
                                <p>Fit : {fit && fit.title}</p>
                                <p>Pleats : {pleats && pleats.title}</p>
                                <p>Side Pockets : {side_pocket && side_pocket.title}</p>
                                <p>Pocket : {pocket && pocket.title}</p>
                                <p>Rise : {rise && rise.title}</p>
                                <p>Pant Bottom : {pant_bottom && pant_bottom.title}</p>
                                <p>Suspender Button : {suspender_button && suspender_button.title}</p>
                            </div>
                        </div>

                    </div>

                    {this._renderPantMeasurement(selectedMeasurement)}


                </div>

            </div>
        )
    }
}

const mapStateToProp = (state) => {
    return {
        selectedPantStyle: Actions.getSelectedPantStyle(state),
        selectedPantFabric: Actions.getSelectedPantFabric(state),
        measurements: Actions.getSelectedMeasurement(state)
    }
}

export default withRouter(connect(mapStateToProp, {
})(CanvasAdminPantSection));
