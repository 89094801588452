import React from 'react'
import Shirt from '../Shirt'
import Pant from '../Pant'
import Suit from '../Suit'
import Blazer from '../Blazer'
import Vest from '../Vest'
import { withRouter } from 'react-router-dom'
import { Collection } from '../../config/constant'
import { GET_COLLECTION_FILTER_LIST } from '../../Queries/collectionFilter'
import { connect } from 'react-redux'
import action from '../../redux/action'
import { CONTENTS, PATH, SERVERIMG } from '../../globals/app-constraints'
import ButtonDesign from '../../component/button'
import LazyLoad from 'react-lazyload';

class PLP extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            perPage: 100,
            sortBy: 'BEST_SELLING',
            reverse: true,
        }
    }


    _renderSubComponent() {
        const { isPant, isShirt, isBlazer, isSuit, isVest } = this.props
        if (isShirt) {
            return <Shirt />
        } else if (isPant) {
            return <Pant />
        } else if (isSuit) {
            return <Suit />
        } else if (isBlazer) {
            return <Blazer />
        } else if (isVest) {
            return <Vest />
        }
    }

    onNavComponentPage(page) {
        this.props.history.push(page)
    }

    _get_product_type() {
        let params;
        const { isPant, isShirt, isBlazer, isSuit, isVest } = this.props
        if (isPant) {
            params = Collection.plpPant
        } else if (isShirt) {
            params = Collection.plp
        } else if (isBlazer) {
            params = Collection.plpBlazer
        } else if (isSuit) {
            params = Collection.plpSuit
        } else if (isVest) {
            params = Collection.plpVest
        }
        return params
    }

    newest() {
        const { sortBy, perPage } = this.state
        let query = GET_COLLECTION_FILTER_LIST(this._get_product_type(), perPage, sortBy, false)
        this.props.getPLPList(query)
    }
    sortBy(params) {
        this.setState({ sortBy: params })
        const { perPage } = this.state
        let query = GET_COLLECTION_FILTER_LIST(this._get_product_type(), perPage, params, true)
        this.props.getPLPList(query)
    }

    render() {
        const { isPant, isShirt, isBlazer, isSuit, isVest } = this.props
        const { location } = this.props;
        const currentType = location?.pathname
        let dressType = ''
        const getCustomTypeData = () => {
            switch (currentType) {
                case '/suit':
                    dressType = 'SUIT'
                    break;
                case '/shirt':
                    dressType = 'SHIRT'
                    break;
                case '/pant':
                    dressType = 'PANT'
                    break;
                case '/vest':
                    dressType = 'VEST'
                    break;

                default:
                    dressType = 'BLAZER'
                    break;
            }
            return dressType
        }

        const getCustomTypeDataContent = () => {
            switch (currentType) {
                case '/suit':
                    dressType = CONTENTS.plp.customSuit
                    break;
                case '/shirt':
                    dressType = CONTENTS.plp.customShirt
                    break;
                case '/pant':
                    dressType = CONTENTS.plp.customPant
                    break;
                case '/vest':
                    dressType = CONTENTS.plp.customVest
                    break;

                default:
                    dressType = CONTENTS.plp.customBlazer
                    break;
            }
            return dressType
        }

        return (
            <React.Fragment>
                <div className='padding_gaps_top_bottom'>
                    <div className='plp_header'>
                        <div className='d-flex justify-content-center gap-80 plp_para'>
                            <div className='d-flex gap-80 plp_para_reverse'>
                                <div className=''>
                                    <p className='H-cb fs-64 para_width'>{CONTENTS.plp.customDress} {getCustomTypeData()}.</p>
                                </div>
                                <div className='para_width2'>
                                    <p className='H-l fs-14'><span className='mobile_hidden'>{getCustomTypeDataContent()}</span></p>
                                </div>
                            </div>
                            <ButtonDesign
                                btnWidth={'gap-16 px-0 d-flex'}
                                className={'para_width3 fs-16 H-cb py-12'}
                                ButtonValue={`Design your ${getCustomTypeData()}`}
                                
                                onClick={() => this.props.history.push(`/canvas${currentType === '/vest' ? '/vests' : currentType}?fabric`)}
                                arrowImg={PATH?.img?.PlpRightIcon}
                            />
                        </div>
                    </div>
                </div>
                <div className='padding_gaps mobile_padding_gaps_category_list'>
                    <div className='d-flex justify-content-center'>
                        <div className='category_list'>
                            <div className={`${isBlazer ? 'active_category' : ''} cursor-pointer`}
                                onClick={() => this.onNavComponentPage('blazer')}>
                                <p className={`${isBlazer ? '' : ''} H-l fs-14`}>{CONTENTS.plp.blazers}</p>
                            </div>
                            <div className={`${isSuit ? 'active_category' : ''} cursor-pointer`}
                                onClick={() => this.onNavComponentPage('suit')}
                            >
                                <p className={`${isSuit ? '' : ''} H-l fs-14`}>{CONTENTS.plp.suits}</p>
                            </div>
                            <div className={`${isVest ? 'active_category' : ''} cursor-pointer`}
                                onClick={() => this.onNavComponentPage('vest')}>
                                <p className={`${isVest ? '' : ''} H-l fs-14`}>{CONTENTS.plp.vests}</p>
                            </div>
                            <div className={`${isShirt ? 'active_category' : ''} cursor-pointer`}
                                onClick={() => this.onNavComponentPage('shirt')}>
                                <p className={`${isShirt ? '' : ''} H-l fs-14`}>{CONTENTS.plp.shirts}</p>
                            </div>
                            <div className={`${isPant ? 'active_category' : ''} cursor-pointer`}
                                onClick={() => this.onNavComponentPage('pant')}>
                                <p className={`${isPant ? '' : ''} H-l fs-14`}>{CONTENTS.plp.pants}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='padding_gaps'>
                    <div className='web_sort_section'>
                        <div className='container sort_section d-flex justify-content-end'>
                            {/* <div className='d-flex'>
                                <div className='sort_section_list'>
                                    <div className='d-flex gap-8 align-self-start'>
                                        <p className='H-m fs-16'>Colors</p>
                                        <img src={PATH?.img?.PlpDropdownIcon} className='plp_dropdown_icon' alt="dropdown" />
                                    </div>
                                    <div className='d-flex gap-8 align-self-start'>
                                        <p className='H-m fs-16'>Seasons</p>
                                        <img src={PATH?.img?.PlpDropdownIcon} className='plp_dropdown_icon' alt="dropdown" />
                                    </div>
                                    <div className='d-flex gap-8 align-self-start'>
                                        <p className='H-m fs-16'>Materials</p>
                                        <img src={PATH?.img?.PlpDropdownIcon} className='plp_dropdown_icon' alt="dropdown" />
                                    </div>
                                    <div className='d-flex gap-8 align-self-start'>
                                        <p className='H-m fs-16'>Collections</p>
                                        <img src={PATH?.img?.PlpDropdownIcon} className='plp_dropdown_icon' alt="dropdown" />
                                    </div>
                                    <div className='d-flex gap-8 align-self-start'>
                                        <p className='H-m fs-16'>Designs</p>
                                        <img src={PATH?.img?.PlpDropdownIcon} className='plp_dropdown_icon' alt="dropdown" />
                                    </div>
                                </div>
                            </div> */}
                            <div className='d-flex'>
                                <div className="dropdown">
                                    {/* <ButtonDesign
                                        btnWidth={'gap-16 sort_btn'}
                                        className={'fs-14 H-cb py-12 btn dropdown_toggle_remove dropdown-toggle'}
                                        ButtonValue={'sort by'}
                                        
                                        arrowImg={PATH?.img?.SortByIcon}
                                    /> */}
                                    <button className="btn dropdown_toggle_remove plp_sort_btn dropdown-toggle py-2"
                                        type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span className='H-cb fs-16 px-2 text-lowercase'>{CONTENTS.plp.sortBy} <img src={PATH?.img?.SortByIcon} alt="sortbyicon" className='sort_by_icon' />
                                        </span>
                                    </button>
                                    <ul className="dropdown-menu rounded-0 dropdown_border mt-2 py-2">
                                        <li><span className="fs-14 H-l dropdown-item cursor-pointer text-lowercase"
                                            onClick={() => this.sortBy('BEST_SELLING')}>{CONTENTS.plp.mostPopular}</span>
                                        </li>
                                        <li><span className="fs-14 H-l dropdown-item cursor-pointer text-lowercase"
                                            onClick={() => this.newest('false')}>{CONTENTS.plp.newest}</span>
                                        </li>
                                        <li><span className="fs-14 H-l dropdown-item cursor-pointer text-lowercase"
                                            onClick={() => this.sortBy('PRICE')}>{CONTENTS.plp.price}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* mobile version */}
                    {/* <div className='mobile_sort_section'>
                        <div className='mobile_sort_section_content d-flex justify-content-between'>
                            <div className='mobile_filter'>
                                <p className='H-cb fs-16 text-center'>filter by</p>
                            </div>
                            <div className='mobile_sort'>
                                <p className='H-cb fs-16 text-center'>sort by</p>
                            </div>
                        </div>
                    </div> */}
                </div>
                {/* <div className='padding_gaps_img_section'>
                    <div className='d-flex gap-6 img_section'>
                        <div className='d-flex flex-column gap-15 padding_gaps_img w-51'>
                            <img src={PATH?.img?.PlpImg1} alt="" />
                            <div className='d-flex flex-column img_section_content gap-15'>
                                <div className='d-flex justify-content-between align-items-start'>
                                    <div>
                                        <p className='H-l fs-14 max_width_content'>201-6 Silky+Easy care finish Cotton Rich in purple</p>
                                    </div>
                                    <div className='d-flex gap-8 align-items-baseline'>
                                        <img src={PATH?.img?.CurrencyIcon} className='currency_icon' alt="currency" />
                                        <p className='H-cb fs-16 text-color'>98</p>
                                    </div>
                                </div>
                                <div className='d-flex gap-8'>
                                    <div className='color_circle'></div>
                                    <div className='color_circle'></div>
                                    <div className='color_circle'></div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column gap-15 padding_gaps_img w-51'>
                            <img src={PATH?.img?.PlpImg2} alt="" />
                            <div className='d-flex flex-column img_section_content gap-15'>
                                <div className='d-flex justify-content-between align-items-start'>
                                    <div>
                                        <p className='H-l fs-14 max_width_content'>201-6 Silky+Easy care finish Cotton Rich in purple</p>
                                    </div>
                                    <div className='d-flex gap-8 align-items-baseline'>
                                        <img src={PATH?.img?.CurrencyIcon} className='currency_icon' alt="currency" />
                                        <p className='H-cb fs-16 text-color'>98</p>
                                    </div>
                                </div>
                                <div className='d-flex gap-8'>
                                    <div className='color_circle'></div>
                                    <div className='color_circle'></div>
                                    <div className='color_circle'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='padding_gaps_img_section mobile_padding_gaps_img_section'>
                    <div className='d-flex gap-6 img_section'>
                        <div className='d-flex flex-column gap-15 padding_gaps_img w-33'>
                            <img src={PATH?.img?.PlpImg3} alt="" />
                            <div className='d-flex flex-column img_section_content gap-15'>
                                <div className='d-flex justify-content-between align-items-start'>
                                    <div>
                                        <p className='H-l fs-14 max_width_content'>201-6 Silky+Easy care finish Cotton Rich in purple</p>
                                    </div>
                                    <div className='d-flex gap-8 align-items-baseline'>
                                        <img src={PATH?.img?.CurrencyIcon} className='currency_icon' alt="currency" />
                                        <p className='H-cb fs-16 text-color'>98</p>
                                    </div>
                                </div>
                                <div className='d-flex gap-8'>
                                    <div className='color_circle'></div>
                                    <div className='color_circle'></div>
                                    <div className='color_circle'></div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column gap-15 padding_gaps_img w-33'>
                            <img src={PATH?.img?.PlpImg4} alt="" />
                            <div className='d-flex flex-column img_section_content gap-15'>
                                <div className='d-flex justify-content-between align-items-start'>
                                    <div>
                                        <p className='H-l fs-14 max_width_content'>201-6 Silky+Easy care finish Cotton Rich in purple</p>
                                    </div>
                                    <div className='d-flex gap-8 align-items-baseline'>
                                        <img src={PATH?.img?.CurrencyIcon} className='currency_icon' alt="currency" />
                                        <p className='H-cb fs-16 text-color'>98</p>
                                    </div>
                                </div>
                                <div className='d-flex gap-8'>
                                    <div className='color_circle'></div>
                                    <div className='color_circle'></div>
                                    <div className='color_circle'></div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column gap-15 padding_gaps_img w-33'>
                            <img src={PATH?.img?.PlpImg5} alt="" />
                            <div className='d-flex flex-column img_section_content gap-15'>
                                <div className='d-flex justify-content-between align-items-start'>
                                    <div>
                                        <p className='H-l fs-14 max_width_content'>201-6 Silky+Easy care finish Cotton Rich in purple</p>
                                    </div>
                                    <div className='d-flex gap-8 align-items-baseline'>
                                        <img src={PATH?.img?.CurrencyIcon} className='currency_icon' alt="currency" />
                                        <p className='H-cb fs-16 text-color'>98</p>
                                    </div>
                                </div>
                                <div className='d-flex gap-8'>
                                    <div className='color_circle'></div>
                                    <div className='color_circle'></div>
                                    <div className='color_circle'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='padding_gaps_img_section mobile_padding_gaps_img_section'>
                    <div className='d-flex gap-6 img_section'>
                        <div className='d-flex flex-column gap-15 padding_gaps_img w-51'>
                            <img src={PATH?.img?.PlpImg1} alt="" />
                            <div className='d-flex flex-column img_section_content gap-15'>
                                <div className='d-flex justify-content-between align-items-start'>
                                    <div>
                                        <p className='H-l fs-14 max_width_content'>201-6 Silky+Easy care finish Cotton Rich in purple</p>
                                    </div>
                                    <div className='d-flex gap-8 align-items-baseline'>
                                        <img src={PATH?.img?.CurrencyIcon} className='currency_icon' alt="currency" />
                                        <p className='H-cb fs-16 text-color'>98</p>
                                    </div>
                                </div>
                                <div className='d-flex gap-8'>
                                    <div className='color_circle'></div>
                                    <div className='color_circle'></div>
                                    <div className='color_circle'></div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column gap-15 padding_gaps_img w-51'>
                            <img src={PATH?.img?.PlpImg2} alt="" />
                            <div className='d-flex flex-column img_section_content gap-15'>
                                <div className='d-flex justify-content-between align-items-start'>
                                    <div>
                                        <p className='H-l fs-14 max_width_content'>201-6 Silky+Easy care finish Cotton Rich in purple</p>
                                    </div>
                                    <div className='d-flex gap-8 align-items-baseline'>
                                        <img src={PATH?.img?.CurrencyIcon} className='currency_icon' alt="currency" />
                                        <p className='H-cb fs-16 text-color'>98</p>
                                    </div>
                                </div>
                                <div className='d-flex gap-8'>
                                    <div className='color_circle'></div>
                                    <div className='color_circle'></div>
                                    <div className='color_circle'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='padding_gaps_img_section mobile_padding_gaps_img_section'>
                    <div className='d-flex gap-6 img_section'>
                        <div className='d-flex flex-column gap-15 padding_gaps_img w-33'>
                            <img src={PATH?.img?.PlpImg3} alt="" />
                            <div className='d-flex flex-column img_section_content gap-15'>
                                <div className='d-flex justify-content-between align-items-start'>
                                    <div>
                                        <p className='H-l fs-14 max_width_content'>201-6 Silky+Easy care finish Cotton Rich in purple</p>
                                    </div>
                                    <div className='d-flex gap-8 align-items-baseline'>
                                        <img src={PATH?.img?.CurrencyIcon} className='currency_icon' alt="currency" />
                                        <p className='H-cb fs-16 text-color'>98</p>
                                    </div>
                                </div>
                                <div className='d-flex gap-8'>
                                    <div className='color_circle'></div>
                                    <div className='color_circle'></div>
                                    <div className='color_circle'></div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column gap-15 padding_gaps_img w-33'>
                            <img src={PATH?.img?.PlpImg4} alt="" />
                            <div className='d-flex flex-column img_section_content gap-15'>
                                <div className='d-flex justify-content-between align-items-start'>
                                    <div>
                                        <p className='H-l fs-14 max_width_content'>201-6 Silky+Easy care finish Cotton Rich in purple</p>
                                    </div>
                                    <div className='d-flex gap-8 align-items-baseline'>
                                        <img src={PATH?.img?.CurrencyIcon} className='currency_icon' alt="currency" />
                                        <p className='H-cb fs-16 text-color'>98</p>
                                    </div>
                                </div>
                                <div className='d-flex gap-8'>
                                    <div className='color_circle'></div>
                                    <div className='color_circle'></div>
                                    <div className='color_circle'></div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column gap-15 padding_gaps_img w-33'>
                            <img src={PATH?.img?.PlpImg5} alt="" />
                            <div className='d-flex flex-column img_section_content gap-15'>
                                <div className='d-flex justify-content-between align-items-start'>
                                    <div>
                                        <p className='H-l fs-14 max_width_content'>201-6 Silky+Easy care finish Cotton Rich in purple</p>
                                    </div>
                                    <div className='d-flex gap-8 align-items-baseline'>
                                        <img src={PATH?.img?.CurrencyIcon} className='currency_icon' alt="currency" />
                                        <p className='H-cb fs-16 text-color'>98</p>
                                    </div>
                                </div>
                                <div className='d-flex gap-8'>
                                    <div className='color_circle'></div>
                                    <div className='color_circle'></div>
                                    <div className='color_circle'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {this._renderSubComponent()}
                <div className='plp_looks_section'>
                    <div className='d-flex flex-column gap-44 text-center align-items-center'>
                        <p className='text-uppercase H-cb fs-44 mobile_plp_looks_section_header'>{CONTENTS.plp.section1.Head1}</p>
                        <div className='d-flex flex-column gap-16 align-items-center'>
                            <LazyLoad className="w-100 h-100 object-fit-cover d-flex justify-content-center align-items-center">
                                <img src={PATH?.img?.PlpFitIcon} className='plp_fit_icon' alt="fit-icon" />
                            </LazyLoad>
                            <p className='H-m fs-16 text-lowercase'>{CONTENTS.plp.section1.Head2}</p>
                            <p className='H-l fs-14 plp_looks_section_max_width'>{CONTENTS.plp.section1.Content}</p>
                        </div>
                    </div>
                </div>
                {/* <div className='d-flex plp_last_section_content'>
                    <div className='plp_last_section_left d-flex flex-column justify-content-between'>
                        <div className='d-flex flex-column align-items-end gap-10'>
                            <p className='fs-14 H-l text-lowercase'>{CONTENTS.plp.section2.content1}</p>
                            <p className='fs-14 H-l text-lowercase'>{CONTENTS.plp.section2.content2}</p>
                            <p className='fs-14 H-l text-lowercase'>{CONTENTS.plp.section2.content3}</p>
                            <p className='fs-14 H-l text-lowercase'>{CONTENTS.plp.section2.content4}</p>
                        </div>
                        <p className='text-capitalize H-cb fs-44 text-center PP_R'>{CONTENTS.plp.section2.Head}</p>
                        <div className='d-flex flex-column align-items-start gap-10'>
                            <p className='fs-14 H-l text-lowercase'>{CONTENTS.plp.section2.content5}</p>
                            <p className='fs-14 H-l text-lowercase'>{CONTENTS.plp.section2.content6}</p>
                            <p className='fs-14 H-l text-lowercase'>{CONTENTS.plp.section2.content7}</p>
                            <p className='fs-14 H-l text-lowercase'>{CONTENTS.plp.section2.content8}</p>
                        </div>
                    </div>
                    <div className='plp_last_section_right'>
                        <LazyLoad className="w-100 h-100 object-fit-cover">
                            <img src={SERVERIMG.image.plp.plpImg} className='plp_last_section_img' alt="plp-img" />
                        </LazyLoad>
                    </div>
                </div> */}
            </React.Fragment>
        )
    }
}
const mapStateToProp = (state) => {
    return {
        getPLP: action.getPLP(state)
    }
}

export default withRouter(connect(mapStateToProp, {
    getPLPList: action.getPLPList
})(PLP))