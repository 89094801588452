import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { suitMock } from '../../../util/measurement_mocks/suit_measurement_data'
import { CONTENTS } from '../../../globals/app-constraints'

class SuitMeasurement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { selectedData, selectedSuitMeasurementSize } = this.props
        return (
            <React.Fragment>
                <div className="measure_content_scroll">
                    <p className='fs-18 H-m text-center m-0 pb-2'>{CONTENTS.canvas.measureContent.measurePopup.selectYourSize}</p>
                    <p className='fs-13 text-center H-l'>{CONTENTS.canvas.measureContent.measurePopup.pickTheSize}</p>
                    {
                        suitMock?.overSize &&
                        <div className='buttons_align margin_align'>
                            <button className='btn common_btn measurement_btn border-0'><span>{CONTENTS.canvas.measureContent.measurePopup.short}</span><span className='text_grey'>&#60; 168 cm</span></button>
                            <button className='btn common_btn measurement_btn border-0'><span>{CONTENTS.canvas.measureContent.measurePopup.regular}</span><span className='text_grey'>168 - 186 cm</span></button>
                            <button className='btn common_btn measurement_btn border-0'><span>{CONTENTS.canvas.measureContent.measurePopup.long}</span><span className='text_grey'>&#62; 168 cm</span></button>
                        </div>
                    }
                    <div className='d-flex flex-column row-gap-3 px_measure_values'>
                        {
                            suitMock?.short?.map((pantSizes, index) => {
                                return (
                                    <div className='row m-0' key={index}>
                                        <div className='col-2 align-self-center p-0'>
                                            <p className='fs-15 text_grey m-0'>{pantSizes?.label}</p>
                                        </div>
                                        <div className='col-10'>
                                            <div className='row justify-content-between align-self-center p-0 measure_3_items_align'>

                                                <div className='col-4'>
                                                    <p className={`measure_data ${pantSizes?.category !== 'Small'
                                                        ? 'disable-measurement' : ''} ${(selectedData?.selectedIndex === index && selectedData?.category === 'Small') && 'active_measure'}`}
                                                        onClick={() => selectedSuitMeasurementSize(pantSizes, index, 'Small')}>
                                                        {pantSizes?.size} S
                                                    </p>
                                                </div>
                                                <div className='col-4'>
                                                    <p className={`measure_data  ${pantSizes?.category !== 'Medium'
                                                        ? 'disable-measurement' : ''} ${(selectedData?.selectedIndex === index && selectedData?.category === 'Medium') && 'active_measure'}`}
                                                        onClick={() => selectedSuitMeasurementSize(pantSizes, index, 'Medium')}>
                                                        {pantSizes?.size}
                                                    </p>
                                                </div>
                                                <div className='col-4'>
                                                    <p className={`measure_data  ${pantSizes?.category !== 'Long'
                                                        ? 'disable-measurement' : ''} ${(selectedData?.selectedIndex === index && selectedData?.category === 'Long') && 'active_measure'}`}
                                                        onClick={() => selectedSuitMeasurementSize(pantSizes, index, 'Long')}>
                                                        {pantSizes?.size} L
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProp = (state) => {
    return {}
}

export default withRouter(connect(mapStateToProp, {

})(SuitMeasurement));