import React from "react";
import { withRouter } from "react-router-dom";


class DeleteMeasurement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: "show",
      showModal: true,
      dialog: "dialog",
    };
  }
  renderUI() {
    let { show, showModal, dialog } = this.state;
    const { onCancel, onDelete, closeDeleteMeasurement, measurementId } = this.props;
    return (
      <React.Fragment>
        {showModal && <div className={`modal-backdrop fade show`}></div>}
        <div
          className={`modal fade ${show} address-modal`}
          tabIndex="-1"
          id="measureDelete"
          aria-labelledby="measure"
          aria-hidden="true"
          style={{
            display: showModal ? "block" : "none",
            padding_right: showModal ? " 17px" : "",
          }}
          aria-modal="true"
          role={dialog}
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header p-0 border-0">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => closeDeleteMeasurement()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="col-lg-12">
                  <p>
                    Are you sure you want to delete your measurement profile?
                  </p>
                </div>
              </div>
              <div className="col-12 text-center py-2">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => onDelete(measurementId)}
                >
                  Delete
                </button>
                &nbsp; &nbsp;
                <button
                  type="button"
                  className="btn btn-gren"
                  onClick={() => onCancel()}
                >
                  cancel
                </button>

              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  render() {
    const { isOpen } = this.props;
    return <React.Fragment>{isOpen && this.renderUI()}</React.Fragment>;
  }
}

export default withRouter(DeleteMeasurement);
