export const suitMock = {
    overSize: true,
    type: 'suit',
    short: [
        {
            label: 'S',
            size: 23,
            category:"Small",
            subSize: [
                {
                    label: "Chest width", size: 51
                },
                {
                    label: "Waist width", size: 45
                },
                {
                    label: "Shoulder width", size: 44
                },
                {
                    label: "Sleeve length", size: 57
                },
                {
                    label: "Jacket length", size: 69
                }
            ]
        }, {
            label: 'M',
            size: 24,
            category:"Medium",
            subSize: [
                {
                    label: "Chest width", size: 53
                },
                {
                    label: "Waist width", size: 47
                },
                {
                    label: "Shoulder width", size: 45
                },
                {
                    label: "Sleeve length", size: 58
                },
                {
                    label: "Jacket length", size: 70
                }
            ]
        }, {
            label: 'M/L',
            size: 25,
            category:"Medium",
            subSize: [
                {
                    label: "Chest width", size: 55
                },
                {
                    label: "Waist width", size: 49
                },
                {
                    label: "Shoulder width", size: 46
                },
                {
                    label: "Sleeve length", size: 59
                },
                {
                    label: "Jacket length", size: 71
                }
            ]
        }, {
            label: 'L',
            size: 26,
            category:"Long",
            subSize: [
                {
                    label: "Chest width", size: 57
                },
                {
                    label: "Waist width", size: 51
                },
                {
                    label: "Shoulder width", size: 47
                },
                {
                    label: "Sleeve length", size: 60
                },
                {
                    label: "Jacket length", size: 72
                }
            ]
        }, {
            label: 'XL',
            size: 27,
            category:"Long",
            subSize: [
                {
                    label: "Chest width", size: 59
                },
                {
                    label: "Waist width", size: 53
                },
                {
                    label: "Shoulder width", size: 48
                },
                {
                    label: "Sleeve length", size: 61
                },
                {
                    label: "Jacket length", size: 73
                }
            ]
        }, {
            label: 'XXL',
            size: 28,
            category:"Long",
            subSize: [
                {
                    label: "Chest width", size: 61
                },
                {
                    label: "Waist width", size: 55
                },
                {
                    label: "Shoulder width", size: 49
                },
                {
                    label: "Sleeve length", size: 61.5
                },
                {
                    label: "Jacket length", size: 74
                }
            ]
        }, {
            label: '3XL',
            size: 29,
            category:"Long",
            subSize: [
                {
                    label: "Chest width", size: 63
                },
                {
                    label: "Waist width", size: 57
                },
                {
                    label: "Shoulder width", size: 50
                },
                {
                    label: "Sleeve length", size: 62
                },
                {
                    label: "Jacket length", size: 75
                }
            ]
        }, {
            label: '4XL',
            size: 30,
            category:"Long",
            subSize: [
                {
                    label: "Chest width", size: 65
                },
                {
                    label: "Waist width", size: 59
                },
                {
                    label: "Shoulder width", size: 51
                },
                {
                    label: "Sleeve length", size: 62.5
                },
                {
                    label: "Jacket length", size: 76
                }
            ]
        }
    ],

    regular: [
        {
            label: 'S',
            size: 46,
            category:"Small",
            subSize: [
                {
                    label: "Chest width", size: 51
                },
                {
                    label: "Waist width", size: 45
                },
                {
                    label: "Shoulder width", size: 44
                },
                {
                    label: "Sleeve length", size: 59.5
                },
                {
                    label: "Jacket length", size: 72
                }
            ]
        }, {
            label: 'M',
            size: 48,
            category:"Medium",
            subSize: [
                {
                    label: "Chest width", size: 53
                },
                {
                    label: "Waist width", size: 47
                },
                {
                    label: "Shoulder width", size: 45
                },
                {
                    label: "Sleeve length", size: 60.5
                },
                {
                    label: "Jacket length", size: 73
                }
            ]
        }, {
            label: 'M/L',
            size: 50,
            category:"Medium",
            subSize: [
                {
                    label: "Chest width", size: 55
                },
                {
                    label: "Waist width", size: 49
                },
                {
                    label: "Shoulder width", size: 46
                },
                {
                    label: "Sleeve length", size: 61.5
                },
                {
                    label: "Jacket length", size: 74
                }
            ]
        }, {
            label: 'L',
            size: 52,
            category:"Long",
            subSize: [
                {
                    label: "Chest width", size: 57
                },
                {
                    label: "Waist width", size: 51
                },
                {
                    label: "Shoulder width", size: 47
                },
                {
                    label: "Sleeve length", size: 62.5
                },
                {
                    label: "Jacket length", size: 75
                }
            ]
        }, {
            label: 'XL',
            size: 54,
            category:"Long",
            subSize: [
                {
                    label: "Chest width", size: 59
                },
                {
                    label: "Waist width", size: 53
                },
                {
                    label: "Shoulder width", size: 48
                },
                {
                    label: "Sleeve length", size: 63.5
                },
                {
                    label: "Jacket length", size: 76
                }
            ]
        }, {
            label: 'XXL',
            size: 56,
            category:"Long",
            subSize: [
                {
                    label: "Chest width", size: 61
                },
                {
                    label: "Waist width", size: 55
                },
                {
                    label: "Shoulder width", size: 49
                },
                {
                    label: "Sleeve length", size: 64
                },
                {
                    label: "Jacket length", size: 77
                }
            ]
        }, {
            label: '3XL',
            size: 58,
            category:"Long",
            subSize: [
                {
                    label: "Chest width", size: 63
                },
                {
                    label: "Waist width", size: 57
                },
                {
                    label: "Shoulder width", size: 50
                },
                {
                    label: "Sleeve length", size: 64.5
                },
                {
                    label: "Jacket length", size: 78
                }
            ]
        }, {
            label: '4XL',
            size: 60,
            category:"Long",
            subSize: [
                {
                    label: "Chest width", size: 65
                },
                {
                    label: "Waist width", size: 59
                },
                {
                    label: "Shoulder width", size: 51
                },
                {
                    label: "Sleeve length", size: 65
                },
                {
                    label: "Jacket length", size: 79
                }
            ]
        }
    ],


    long: [
        {
            label: 'S',
            size: 90,
            category:"Small",
            subSize: [
                {
                    label: "Chest width", size: 50.5
                },
                {
                    label: "Waist width", size: 44
                },
                {
                    label: "Shoulder width", size: 44
                },
                {
                    label: "Sleeve length", size: 62
                },
                {
                    label: "Jacket length", size: 75
                }
            ]
        }, {
            label: 'M',
            size: 94,
            category:"Medium",
            subSize: [
                {
                    label: "Chest width", size: 52.5
                },
                {
                    label: "Waist width", size: 46
                },
                {
                    label: "Shoulder width", size: 45
                },
                {
                    label: "Sleeve length", size: 63
                },
                {
                    label: "Jacket length", size: 76
                }
            ]
        }, {
            label: 'M/L',
            size: 98,
            category:"Medium",
            subSize: [
                {
                    label: "Chest width", size: 54.5
                },
                {
                    label: "Waist width", size: 48
                },
                {
                    label: "Shoulder width", size: 46
                },
                {
                    label: "Sleeve length", size: 64
                },
                {
                    label: "Jacket length", size: 77
                }
            ]
        }, {
            label: 'L',
            size: 102,
            category:"Long",
            subSize: [
                {
                    label: "Chest width", size: 56.5
                },
                {
                    label: "Waist width", size: 50
                },
                {
                    label: "Shoulder width", size: 47
                },
                {
                    label: "Sleeve length", size: 65
                },
                {
                    label: "Jacket length", size: 78
                }
            ]
        }, {
            label: 'XL',
            size: 106,
            category:"Long",
            subSize: [
                {
                    label: "Chest width", size: 58.6
                },
                {
                    label: "Waist width", size: 52
                },
                {
                    label: "Shoulder width", size: 48
                },
                {
                    label: "Sleeve length", size: 66
                },
                {
                    label: "Jacket length", size: 79
                }
            ]
        }, {
            label: 'XXL',
            size: 110,
            category:"Long",
            subSize: [
                {
                    label: "Chest width", size: 60.5
                },
                {
                    label: "Waist width", size: 54
                },
                {
                    label: "Shoulder width", size: 49
                },
                {
                    label: "Sleeve length", size: 66.5
                },
                {
                    label: "Jacket length", size: 80
                }
            ]
        }, {
            label: '3XL',
            size: 114,
            category:"Long",
            subSize: [
                {
                    label: "Chest width", size: 62.5
                },
                {
                    label: "Waist width", size: 56
                },
                {
                    label: "Shoulder width", size: 50
                },
                {
                    label: "Sleeve length", size: 67.5
                },
                {
                    label: "Jacket length", size: 81
                }
            ]
        }, {
            label: '4XL',
            size: 118,
            category:"Long",
            subSize: [
                {
                    label: "Chest width", size: 64.5
                },
                {
                    label: "Waist width", size: 58
                },
                {
                    label: "Shoulder width", size: 51
                },
                {
                    label: "Sleeve length", size: 68.5
                },
                {
                    label: "Jacket length", size: 82
                }
            ]
        }
    ]
}