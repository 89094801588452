import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { isFieldEmpty } from '../../util/validation'
import Actions from '../../redux/action'
import { RESET_PASSWORD_BY_URL, CUSTOMER_LIST } from '../../Queries'
import { toast } from 'react-toastify'
import { store } from '../../createStore'
import Routes from '../../config/routes'
class ResetByUrl extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: {},
            errors: {}
        }
    }
    handleChange(e) {
        const { name, value } = e.target
        const { fields } = this.state
        fields[name] = value
        this.setState({ fields }, () => {
            this.handleValidation(name);
        });
    }
    handleValidation(field) {
        let { fields, errors } = this.state;
        switch (field) {
            case 'npassword':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the new password'
                    this.setState({ errors })
                    return false
                }
                else if (fields[field].length < 5) {
                    errors[field] = 'Your password length must be more than 5 characters.'
                    this.setState({ errors })
                    return false
                }
                break;
            case 'cpassword':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the confirm password'
                    this.setState({ errors })
                    return false
                }
                else if (fields[field].length < 5) {
                    errors[field] = 'Your password length must be more than 5 characters.'
                    this.setState({ errors })
                    return false
                }
                if (fields["cpassword"] && fields["cpassword"].trim() !== "" && fields["cpassword"] !== fields["npassword"]) {
                    errors["cpassword"] = "Password Does Not match!";
                    this.setState({ errors })
                    return false
                }
                break;
            default:
                return null
        }
        errors[field] = ''
        this.setState({ errors })
        return true;
    }
    handleSubmitValidation(fields) {
        let isFormValid = true;
        fields.forEach((field) => {
            if (!this.handleValidation(field)) {
                isFormValid = false
            }
        })
        return isFormValid;
    }
    resetPassword() {
        const { npassword } = this.state.fields
        if (this.handleSubmitValidation(["cpassword", "npassword"])) {
            const { id, token } = this.props.match.params
            const callback = (response) => {
                const { customer } = (response && response.customerReset) || {}
                if (customer !== null) {
                    toast.success(`Password change successfully`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    let { token = {} } = this.props.token
                    let { accessToken } = token || {}
                    let query = CUSTOMER_LIST(accessToken)
                    this.props.getCustomerList(query)
                    this.props.history.push(Routes.HOME)
                } else {
                    toast.error('Something Went wrong Please Try again later ', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            }
            let customerId = `gid://shopify/Customer/${id}`

            let input = {
                id: btoa(customerId),
                input: {
                    "resetToken": token,
                    "password": npassword
                }
            }
            this.props.resetPasswordByUrl(RESET_PASSWORD_BY_URL, input, callback)
        }
    }
    render() {
        const { fields, errors } = this.state
        return (
            <div className="container-fluid px-0 vh-100 main_class_pf">
                <div className="modal fade show" id="loginpop" tabIndex="-1" aria-labelledby="exampleModalLabel" style={{ display: 'block', padding_right: ' 17px' }} aria-modal="true" role='dialog'>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header p-0 border-0">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    {/* <span aria-hidden="true" >×</span> */}
                                </button>
                            </div>
                            <div className="modal-body">
                                <h2>ResetPassword</h2>
                                <form>
                                    <input type="password" className="form-control"
                                        placeholder="Enter your password"
                                        name="npassword"
                                        value={fields['npassword'] || ''}
                                        autoComplete="off"
                                        onChange={(e) => this.handleChange(e)}
                                        error={errors["npassword"]} />
                                    {errors && <small style={{ color: "red" }}>{errors['npassword']}</small>}
                                    <input type="password" className="form-control"
                                        placeholder="Enter your confirm password"
                                        name="cpassword"
                                        value={fields['cpassword'] || ''}
                                        autoComplete="off"
                                        onChange={(e) => this.handleChange(e)}
                                        error={errors["cpassword"]} />
                                    {errors && <small className="show-error">{errors['cpassword']}</small>}
                                </form>
                                <div className="col-12">
                                    <button type="button" className="btn btn-blue" onClick={() => this.resetPassword()} >Reset Password</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProp = (state) => {
    return {
        token: Actions.getTokenSelector(store.getState()) || {},
    }
}

export default withRouter(connect(mapStateToProp, {
    resetPasswordByUrl: Actions.resetPasswordByUrl,
    getCustomerList: Actions.getCustomerList,
})(ResetByUrl));