import { defaultPantFabricObject } from '../../../util/defaultFabricObject';
import Actions from '../../action'
const initialState = {
    isLoading: false,
    error: false,
    selectedPantFabric: defaultPantFabricObject()
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.UPDATE_PANT_FABRICS: {
            let { selectedPantFabric } = state
            return {
                ...state,
                selectedPantFabric: {
                    ...selectedPantFabric,
                    ...action.value
                }
            };
        }
        case Actions.UPDATE_BLAZER_AMOUNT: {
            return {
                ...state,
                selectedAmount: action.data.amount,
                selectedCurrencyCode: action.data.currencyCode
            };
        } case Actions.SET_DEFAULT_PANT_FABRICS: {
            return {
                ...state,
                selectedPantFabric: defaultPantFabricObject(action.data),
            };
        } case Actions.RESET_CANVAS: {
            return {
                ...state,
                ...initialState
            }
        }
        default:
            return state;
    }

};