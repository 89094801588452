export const ShirtChart = [
    {
        key: "S",
        value: {
            collar: 38,
            chest: 104,
            back: 87,
            waist: 92,
            sleeve: 72,
        }
    },
    {
        key: "M",
        value: {
            collar: 40,
            chest: 110,
            back: 88,
            waist: 98,
            sleeve: 72,
        }
    },
    {
        key: "L",
        value: {
            collar: 42,
            chest: 116,
            back: 89,
            waist: 104,
            sleeve: 73,
        }
    },
    {
        key: "XL",
        value: {
            collar: 44,
            chest: 124,
            back: 90,
            waist: 112,
            sleeve: 73,
        }
    },
    {
        key: "XXL",
        value: {
            collar: 46,
            chest: 136,
            back: 91,
            waist: 126,
            sleeve: 73,
        }
    }
]


export const USPANT_CHART = [
    {
        key: '34',
        value: [{
            waist: '29'
        }, {
            hip: '36'
        }, {
            crotch: '24'
        }, {
            thigh: '24.5'
        }, {
            pantLength: '40'
        }]
    },
    {
        key: "36",
        value: [{
            waist: '32'
        }, {
            hip: '38'
        }, {
            crotch: '26'
        }, {
            thigh: '25.5'
        }, {
            pantLength: '41'
        }]
    },
    {
        key: "38",
        value: [{
            waist: '34'
        }, {
            crotch: '27'
        }, {
            thigh: '26.5'
        }, {
            pantLength: '42'
        }]
    },
    {
        key: "40",
        value: [{
            waist: '35.5'
        }, {
            hip: '42'
        }, {
            crotch: '28'
        }, {
            thigh: '27.5'
        }, {
            pantLength: '42.5'
        }]
    },
    {
        key: "42",
        value: [{
            waist: '37'
        }, {
            hip: '45'
        }, {
            crotch: '29.5'
        }, {
            thigh: '28.5'
        }, {
            pantLength: '43'
        }]
    },
    {
        key: "44",
        value: [{
            waist: '39'
        }, {
            hip: '47'
        }, {
            crotch: '30.5'
        }, {
            thigh: '29.5'
        }, {
            pantLength: '43.5'
        }]
    },
    {
        key: "46",
        value: [{
            waist: '42'
        }, {
            hip: '48'
        }, {
            crotch: '31.5'
        }, {
            thigh: '30.5'
        }, {
            pantLength: '44'
        }]
    },
    {
        key: "48",
        value: [{
            waist: '44'
        }, {
            hip: '50'
        }, {
            crotch: '32'
        }, {
            thigh: '31.5'
        }, {
            pantLength: '44.5'
        }]
    },
    {
        key: "50",
        value: [{
            waist: '47'
        }, {
            hip: '51'
        }, {
            crotch: '32.5'
        }, {
            thigh: '32.5'
        }, {
            pantLength: '44.5'
        }]
    },
    {
        key: "52",
        value: [{
            waist: '49'
        }, {
            hip: '52'
        }, {
            crotch: '33'
        }, {
            thigh: '33'
        }, {
            pantLength: '44.5'
        }]
    },
    {
        key: "54",
        value: [{
            waist: '51'
        }, {
            hip: '53'
        }, {
            crotch: '33.5'
        }, {
            thigh: '33'
        }, {
            pantLength: '45'
        }]
    },
    {
        key: "56",
        value: [{
            waist: '53'
        }, {
            hip: '54'
        }, {
            crotch: '34'
        }, {
            thigh: '34'
        }, {
            pantLength: '45'
        }]
    }
]




export const UKPANT_CHART = [
    {
        key: '44',
        value: [{
            waist: '29'
        }, {
            hip: '36'
        }, {
            crotch: '24'
        }, {
            thigh: '24.5'
        }, {
            pantLength: '40'
        }]
    },
    {
        key: "46",
        value: [{
            waist: '32'
        }, {
            hip: '38'
        }, {
            crotch: '26'
        }, {
            thigh: '25.5'
        }, {
            pantLength: '41'
        }]
    },
    {
        key: "48",
        value: [{
            waist: '34'
        }, {
            hip: '40'
        }, {
            crotch: '27'
        }, {
            thigh: '26.5'
        }, {
            pantLength: '42'
        }]
    },
    {
        key: "50",
        value: [{
            waist: '35.5'
        }, {
            hip: '42'
        }, {
            crotch: '28'
        }, {
            thigh: '27.5'
        }, {
            pantLength: '42.5'
        }]
    },
    {
        key: "52",
        value: [{
            waist: '37'
        }, {
            hip: '45'
        }, {
            crotch: '29.5'
        }, {
            thigh: '28.5'
        }, {
            pantLength: '43'
        }]
    },
    {
        key: "54",
        value: [{
            waist: '39'
        }, {
            hip: '47'
        }, {
            crotch: '30.5'
        }, {
            thigh: '29.5'
        }, {
            pantLength: '43.5'
        }]
    },
    {
        key: "56",
        value: [{
            waist: '42'
        }, {
            hip: '48'
        }, {
            crotch: '31.5'
        }, {
            thigh: '30.5'
        }, {
            pantLength: '44'
        }]
    },
    {
        key: "58",
        value: [{
            waist: '44'
        }, {
            hip: '50'
        }, {
            crotch: '32'
        }, {
            thigh: '31.5'
        }, {
            pantLength: '44.5'
        }]
    },
    {
        key: "60",
        value: [{
            waist: '47'
        }, {
            hip: '51'
        }, {
            crotch: '32.5'
        }, {
            thigh: '32.5'
        }, {
            pantLength: '44.5'
        }]
    },
    {
        key: "62",
        value: [{
            waist: '49'
        }, {
            hip: '52'
        }, {
            crotch: '33'
        }, {
            thigh: '33'
        }, {
            pantLength: '44.5'
        }]
    },
    {
        key: "64",
        value: [{
            waist: '51'
        }, {
            hip: '53'
        }, {
            crotch: '33.5'
        }, {
            thigh: '33'
        }, {
            pantLength: '45'
        }]
    },
    {
        key: "66",
        value: [{
            waist: '53'
        }, {
            hip: '54'
        }, {
            crotch: '34'
        }, {
            thigh: '34'
        }, {
            pantLength: '45'
        }]
    }
]


export const convertCentiMeters = (value) => {
    let converted = value * 2.54
    if (isNaN(converted)) {
        return value
    } else {
        return Math.round(converted)
    }
}

export const convertInchMeters = (value) => {
    let converted = value / 2.54
    if (isNaN(converted)) {
        return value
    } else {
        return Math.round(converted)
    }
}



export const UKSUIT_CHART = [
    {
        key: '44',
        value: [{
            chest: '39'
        }, {
            stomach: '35'
        }, {
            shoulder: '16'
        }, {
            sleeve: '23.75'
        }, {
            shirtLength: '29.5'
        }]
    },
    {
        key: "46",
        value: [{
            chest: '41'
        }, {
            stomach: '37'
        }, {
            shoulder: '17'
        }, {
            sleeve: '24'
        }, {
            shirtLength: '30'
        }]
    },
    {
        key: "48",
        value: [{
            chest: '43'
        }, {
            stomach: '38.5'
        }, {
            shoulder: '18'
        }, {
            sleeve: '24.5'
        }, {
            shirtLength: '30.5'
        }]
    },
    {
        key: "50",
        value: [{
            chest: '45'
        }, {
            stomach: '40.5'
        }, {
            shoulder: '18.5'
        }, {
            sleeve: '24.75'
        }, {
            shirtLength: '31'
        }]
    },
    {
        key: "52",
        value: [{
            chest: '47'
        }, {
            stomach: '42'
        }, {
            shoulder: '19'
        }, {
            sleeve: '25.5'
        }, {
            shirtLength: '31.75'
        }]
    },
    {
        key: "54",
        value: [{
            chest: '49.5'
        }, {
            stomach: '44.5'
        }, {
            hip: '47'
        }, {
            shoulder: '20'
        }, {
            sleeve: '26'
        }, {
            shirtLength: '32'
        }]
    },
    {
        key: "56",
        value: [{
            chest: '52'
        }, {
            stomach: '46.5'
        }, {
            hip: '49'
        }, {
            shoulder: '20.5'
        }, {
            sleeve: '26.5'
        }, {
            shirtLength: '32.75'
        }]
    },
    {
        key: "58",
        value: [{
            chest: '54'
        }, {
            stomach: '49'
        }, {
            hip: '51.5'
        }, {
            shoulder: '21.5'
        }, {
            sleeve: '26.75'
        }, {
            shirtLength: '33'
        }]
    },
    {
        key: "60",
        value: [{
            chest: '56'
        }, {
            stomach: '51'
        }, {
            hip: '53'
        }, {
            shoulder: '21.75'
        }, {
            sleeve: '27'
        }, {
            shirtLength: '33.75'
        }]
    },
    {
        key: "62",
        value: [{
            chest: '58'
        }, {
            stomach: '53'
        }, {
            hip: '55'
        }, {
            shoulder: '22'
        }, {
            sleeve: '27'
        }, {
            shirtLength: '34'
        }]
    },
    {
        key: "64",
        value: [{
            chest: '60'
        }, {
            stomach: '55'
        }, {
            hip: '57'
        }, {
            shoulder: '22.25'
        }, {
            sleeve: '27'
        }, {
            shirtLength: '34.35'
        }]
    },
    {
        key: "66",
        value: [{
            chest: '61'
        }, {
            stomach: '57'
        }, {
            hip: '58'
        }, {
            shoulder: '22.5'
        }, {
            sleeve: '27'
        }, {
            shirtLength: '34.5'
        }]
    }
]



export const USSUIT_CHART = [
    {
        key: '34',
        value: [{
            chest: '39'
        }, {
            stomach: '35'
        }, {
            hip: '38.5'
        }, {
            shoulder: '16'
        }, {
            sleeve: '23.75'
        }, {
            shirtLength: '29.5'
        }]
    },
    {
        key: "36",
        value: [{
            chest: '41'
        }, {
            stomach: '37'
        }, {
            shoulder: '17'
        }, {
            sleeve: '24'
        }, {
            shirtLength: '30'
        }]
    },
    {
        key: "38",
        value: [{
            chest: '43'
        }, {
            stomach: '38.5'
        }, {
            hip: '41.5'
        }, {
            shoulder: '18'
        }, {
            sleeve: '24.5'
        }, {
            shirtLength: '30.5'
        }]
    },
    {
        key: "40",
        value: [{
            chest: '45'
        }, {
            stomach: '40.5'
        }, {
            shoulder: '18.5'
        }, {
            sleeve: '24.75'
        }, {
            shirtLength: '31'
        }]
    },
    {
        key: "42",
        value: [{
            chest: '47'
        }, {
            stomach: '42'
        }, {
            shoulder: '19'
        }, {
            sleeve: '25.5'
        }, {
            shirtLength: '31.75'
        }]
    },
    {
        key: "44",
        value: [{
            chest: '49.5'
        }, {
            stomach: '44.5'
        }, {
            shoulder: '20'
        }, {
            shirtLength: '32'
        }]
    },
    {
        key: "46",
        value: [{
            chest: '52'
        }, {
            stomach: '46.5'
        }, {
            shoulder: '20.5'
        }, {
            sleeve: '26.5'
        }, {
            shirtLength: '32.75'
        }]
    },
    {
        key: "48",
        value: [{
            chest: '54'
        }, {
            stomach: '49'
        }, {
            shoulder: '21.5'
        }, {
            sleeve: '26.75'
        }, {
            shirtLength: '33'
        }]
    },
    {
        key: "50",
        value: [{
            chest: '56'
        }, {
            stomach: '51'
        }, {
            shoulder: '21.75'
        }, {
            sleeve: '27'
        }, {
            shirtLength: '33.75'
        }]
    },
    {
        key: "52",
        value: [{
            chest: '58'
        }, {
            stomach: '53'
        }, {
            shoulder: '22'
        }, {
            sleeve: '27'
        }, {
            shirtLength: '34'
        }]
    },
    {
        key: "54",
        value: [{
            chest: '60'
        }, {
            stomach: '55'
        }, {
            shoulder: '22.25'
        }, {
            sleeve: '27'
        }, {
            shirtLength: '34.25'
        }]
    },
    {
        key: "56",
        value: [{
            chest: '61'
        }, {
            stomach: '57'
        }, {
            shoulder: '22.5'
        }, {
            sleeve: '27'
        }, {
            shirtLength: '34.5'
        }]
    }
]