import React from 'react'
import { withRouter } from 'react-router-dom'
import { images } from '../../util/image_render'

class SuitPocketsSquare extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { selectedPocketSquare, openPocketSquare } = this.props
        return (
            <React.Fragment>
                <div className="">
                    <p className="H-l fs-13 pb-2 text-lowercase">Pocket Square</p>
                    <div className='row row-gap-3 m-0'>
                        <div className='col-lg-3 col-6 px-1'>
                            <div className={`style_type ${selectedPocketSquare === 'without' ? 'active_type' : ''}`}
                                onClick={() => openPocketSquare('without')}>
                                <div className='style_img' >
                                    <img src={images('./images/suit/SVG/without.svg')} alt="suit-accents" />
                                </div>
                                <p className="pt-1 H-l fs-13 text-lowercase">Without</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-6 px-1'>
                            <div className={`style_type ${selectedPocketSquare === 'square' ? 'active_type' : ''}`}
                                onClick={() => openPocketSquare('square')}>
                                <div className='style_img' >
                                    <img src={images('./images/suit/SVG/custom_color_pocket.svg')} alt="suit-accents" />
                                </div>
                                <p className="pt-1 H-l fs-13 text-lowercase">Square</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <ul className="row mx-auto p-0 w-90 my-sm-4 my-1 custom-collar cuff_desg">
                    <h6 className="col-12 text-left px-0">POCKET SQUARE</h6>
                    <li className={selectedPocketSquare === 'without' ? 'active' : ''}>
                        <img
                            src={images('./images/suit/SVG/without.svg')}
                            className="img-fluid"
                            alt="icons"
                            onClick={() => openPocketSquare('without')} />
                        <p>WITH OUT</p>
                    </li>
                    <li className={selectedPocketSquare === 'square' ? 'active' : ''}>
                        <img
                            src={images('./images/suit/SVG/custom_color_pocket.svg')}
                            className="img-fluid"
                            alt="icons"
                            onClick={() => openPocketSquare('square')} />
                        <p>SQUARE</p>
                    </li>
                </ul> */}
            </React.Fragment>
        )
    }
}

export default withRouter(SuitPocketsSquare)