import Actions from '../../action'

const initialState = {
    isLoading: false,
    error: false,
    selectedMeasurement: {},
    pantMeasurement: undefined,
    suitMeasurement: undefined,
    blazerMeasurement: undefined,
    vestMeasurement: undefined
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.UPDATE_SELECTED_MEASUREMENT: {
            return {
                ...state,
                selectedMeasurement: action.data,
            };
        }
        case Actions.UPDATE_PANT_MEASUREMENT: {
            return {
                ...state,
                pantMeasurement: action.data,
            };
        }
        case Actions.UPDATE_SUIT_MEASUREMENT: {
            return {
                ...state,
                suitMeasurement: action.data,
            };
        }
        case Actions.UPDATE_BLAZER_MEASUREMENT: {
            return {
                ...state,
                blazerMeasurement: action.data,
            };
        }
        case Actions.UPDATE_VEST_MEASUREMENT: {
            return {
                ...state,
                vestMeasurement: action.data,
            };
        }
        default:
            return state;
    }

};