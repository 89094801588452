import { put, takeLatest, all } from 'redux-saga/effects';
import Actions from '../action';
import { doPost } from '../../service';
export function* hubSpot({ params, api, callback }) {
    try {
        const response = yield doPost(api, params);
        yield put(Actions.hubSpotSuccess(response));
        callback && callback(response)
    } catch (error) {
        callback && callback({ error })
        yield put(Actions.hubSpotFailure(error));
    }
}

export default function* () {
    yield all([takeLatest(Actions.HUB_SPOT_API, hubSpot)]);
}
