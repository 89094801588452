const NAME = 'MANAGE_MEASUREMENT'

export const UPDATE_MEASUREMENT_OPTIONS = `${NAME}/UPDATE_MEASUREMENT_OPTIONS`;

export const getMeasurementOptions = store => store[NAME]

export const updateMeasurementOptions = (measurementType,data) => {
    return {
        type: UPDATE_MEASUREMENT_OPTIONS,
        measurementType,
        data
    }
}

