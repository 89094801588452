import React from 'react'
import { withRouter } from 'react-router-dom'
import { PATH } from '../../globals/app-constraints'

class AccentsFabric extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { showCategory, selectedFabric, products, openCategories, closeSideBar, isSuit } = this.props
        return (
            <React.Fragment>
                {
                    showCategory && (
                        <div className={`offcanvas-backdrop fade show`}></div>
                    )
                }
                <div
                    className={`offcanvas offcanvas-end canvas_offcanvas_fabric ${showCategory ? 'show' : ''}`}
                    tabindex="-1"
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    data-bs-backdrop="static"
                >
                    <div className="offcanvas-header canvas_offcanvas_header" data-bs-backdrop="static">
                        <button
                            type="button"
                            className="canvas_close_btn bg-transparent border-0"
                            data-bs-dismiss="offcanvas" aria-label="Close"
                            onClick={() => openCategories(selectedFabric)}>
                            <img src={PATH?.img?.ExitPopupIcon} className='PDP_exit_popup_img cursor-pointer' alt="exit-popup" data-bs-dismiss="offcanvas" />
                        </button>
                    </div>
                    <div className="offcanvas-body p-0">
                        <div className='canvas_fabric_detail'>
                            <div className='row px-lg-2 px-3 m-0 row-gap-3'>
                                {
                                    products?.edges?.length && products?.edges?.map((o, index) => {
                                        let { title, images, id, availableForSale } = o.node || {}
                                        let { node: productImage } = images?.edges[0] || {}
                                        return (
                                            <div className='col-6 px-lg-2 px-1 cursor-pointer' onClick={() => closeSideBar(id, title)} key={index}>
                                                <div className='fabric_section' >
                                                    <img src={productImage?.src} title={title} className='fabric_material' alt='fabric1' />
                                                </div>
                                                <div className='d-flex justify-content-between mt-2'>
                                                    <p className='canvas_fabric_name text-nowrap'>
                                                        <span className='text_grey fs-12 H-l text-lowercase'>{title}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(AccentsFabric)