const NAME = 'SELECTED_FABRICS'
export const UPDATE_SHIRT_FABRIC = `${NAME}/UPDATE_SHIRT_FABRIC`;
export const RESET_CANVAS = `${NAME}/RESET_SHIRT_CANVAS`

export const RESET_STYLE = `${NAME}/RESET_STYLE`

export const getShirtFabric = store => store[NAME].shirt

export const updateShirtFabric = (data) => {
    return {
        type: UPDATE_SHIRT_FABRIC,
        data
    }
}

export const resetCanvas = () => {
    return {
        type: RESET_CANVAS
    }
}

export const resetStyle = () => {
    return {
        type: RESET_STYLE
    }
}
