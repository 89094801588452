const NAME = 'FABRIC'

export const GET_FABRIC_LIST = `${NAME}/GET_FABRIC_LIST`;
export const GET_FABRIC_LIST_SUCCESS = `${NAME}/GET_FABRIC_LIST_SUCCESS`;
export const GET_FABRIC_LIST_FAILURE = `${NAME}/GET_FABRIC_LIST_FAILURE`;

export const SET_DEFAULT_SHIRT_FABRIC = `${NAME}/SET_DEFAULT_SHIRT_FABRIC`;

export const getFabrics = store => store[NAME]
export const getFabricList = (query, key, isEdit) => {
    return {
        type: GET_FABRIC_LIST,
        query,
        key,
        isEdit
    }
}

export const getFabricListSuccess = (data, key) => {
    return {
        type: GET_FABRIC_LIST_SUCCESS,
        data,
        key
    }
}

export const getFabricListFailure = (error) => {
    return {
        type: GET_FABRIC_LIST_FAILURE,
        error
    }
}

export const setDefaultShirtFabrics = (data, key) => {
    return {
        type: SET_DEFAULT_SHIRT_FABRIC,
        data,
        key
    }
}