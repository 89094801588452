import Actions from '../action'

const initialState = {
    isLoading: false,
    adminOrderList: {},
    error: false,
    isUrlLoading: false
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_ADMIN_ORDER_ID: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_ADMIN_ORDER_ID_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            };
        }
        case Actions.GET_ADMIN_ORDER_ID_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.UPDATE_ORDER_URL_BY_ADMIN: {
            return {
                ...state,
                isUrlLoading: true,
            };
        }
        case Actions.UPDATE_ORDER_URL_BY_ADMIN_SUCCESS: {
            return {
                ...state,
                isUrlLoading: false,
                error: false
            };
        }
        case Actions.UPDATE_ORDER_URL_BY_ADMIN_FAILURE: {
            return {
                ...state,
                isUrlLoading: false,
                error: action.error
            };
        }
        case Actions.GET_ADMIN_ORDER_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_ADMIN_ORDER_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                adminOrderList: action.data,
                error: false
            };
        }
        case Actions.GET_ADMIN_ORDER_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        default:
            return state;
    }

};