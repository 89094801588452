import React from "react";

const ButtonDesign = ({ buttonImgPath, ButtonValue, className, btnWidth, onClick, arrowImg, ButtonarrowDesign, Noarrow, disabled, dismiss }) => {
    return (
        <React.Fragment>
            <button className={`btn fs-14 text-lowercase button-design-focus rounded-0 align-items-center justify-content-center ${btnWidth}`} type="button" onClick={onClick} disabled={disabled} data-bs-dismiss={dismiss}>
                <img className={` ${buttonImgPath ? 'd-block ps-2' : 'd-none'} ${ButtonarrowDesign}`} src={buttonImgPath} alt="button-img" />
                <p className={`text-nowrap ${className}`}>{ButtonValue}</p>
                <img className={`${arrowImg ? 'd-block ps-2' : 'd-none'}`} src={arrowImg} at="button-img" />
            </button>
        </React.Fragment>
    )
}

export default ButtonDesign;