import React from "react";
import { withRouter } from "react-router-dom";
import Routes from "../../config/routes";
import { connect } from "react-redux";
import Actions from "../../redux/action";
import { CUSTOMER_ADDRESS_DELETE, CUSTOMER_LIST, DEFAULT_ADDRESS, DELETE_MEASUREMENT, MEASUREMENT_LIST } from "../../Queries/index";
import { store } from "../../createStore";
import ProfileUpdate from "../../component/ProfileUpdate";
import ResetPassword from "../../container/ResetPassword";
import { isFieldEmpty, isValidName, isValidNumber } from "../../util/validation";
import { toast } from "react-toastify";
import moment from "moment";
import AddAddress from "../../component/AddAddress";
import DeleteMeasurement from "../../component/DeleteMeasurementModal";
import MeasurementViewModal from "../../component/MeasurementViewModal";
import { GET_CUSTOMER_ORDERS } from "../../Queries/customerOrders";
import { RECENT_ORDERS, TOTAL_ORDERS } from "../../config/constant";
import ProductModal from "./MeasurementTypeModal";
import { Constants } from "../../util/Constant";
import { titleCase } from "../../util/random_number";
// import shirt_img from '../../asset/new_images/canvas_img.svg'
import Address from "./address";
import OrderStatus from "../OrderStatus";
import ButtonDesign from '../../component/button'
import { CONTENTS, PATH } from '../../globals/app-constraints'
import ListOfAddress from "../../component/AddAddress/listOfAddress";
import OrderComponent from "./OrderComponent";
import LazyLoad from 'react-lazyload';

class Welcome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isResetPasswordModal: false,
            fields: {},
            errors: {},
            orderNumber: {},
            addressModal: false,
            addressListModal: false,
            isModelOpen: false,
            isViewOpen: false,
            measurementId: null,
            key: null,
            namespace: null,
            id: null,
            selectedMeasurement: {},
            isLoadMore: true,
            isOpenMeasurementTypeModal: false,
            activePage: "info",
        };
    }
    componentDidMount() {
        let { token = {} } = this.props.token;
        let { accessToken } = token;
        if (accessToken) {
            this._getCustomerDetails();
        }
        let { customerId } = this.props.customerId || {};
        if (customerId) {
            this._getMeasurementDetails(customerId);
        }

        const { location: { pathname } } = this.props
        if (pathname === '/order/status') {
            this.setState({ activePage: 'order_status' })
        }

    }
    closeModalViewMeasurement(e) {
        this.setState({ isViewOpen: false });
    }
    ViewMeasurementModel(obj) {
        this.setState({ isViewOpen: true, selectedMeasurement: obj });
    }
    deleteMeasurementModel(id) {
        this.setState({ isModelOpen: true, measurementId: id });
    }
    closeModalMeasurement() {
        this.setState({ isModelOpen: false });
    }
    _getMeasurementDetails(customerId) {
        let parts = btoa(customerId);
        let query = MEASUREMENT_LIST(parts);
        this.props.measurementList(query);
    }

    onAddressDelete(id) {
        let { token: { accessToken } } = this.props.token
        const callback = (response) => {
            let { customerUserErrors = [] } = response?.customerAddressDelete || {}
            if (customerUserErrors?.length === 0) {
                toast.success('Customer Address deleted Successfully ', {
                    position: toast.POSITION.TOP_RIGHT,
                });

                let query = CUSTOMER_LIST(accessToken)
                this.props.getCustomerList(query)
            } else {
                toast.error(`Something went Wrong,Please Try again later`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }

        let input = {
            id: id,
            customerAccessToken: accessToken
        }
        this.props.addressDelete(CUSTOMER_ADDRESS_DELETE, input, callback)
    }
    onDefaultAddress(id) {
        let { token: { accessToken } } = this.props.token || {}
        const callback = (response) => {
            let { customerUserErrors = [] } = response?.customerDefaultAddressUpdate || {}
            if (customerUserErrors?.length === 0) {
                toast.success('Default Address Created Successfully ', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                let query = CUSTOMER_LIST(accessToken)
                this.props.getCustomerList(query)
            } else {
                toast.error(`Something went Wrong,Please Try again later`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
        let input = {
            id: id,
            customerAccessToken: accessToken
        }
        this.props.setDefaultAddress(DEFAULT_ADDRESS, input, callback)
    }

    _getCustomerDetails() {
        let { token = {} } = this.props.token;
        let { accessToken } = token;
        let query = CUSTOMER_LIST(accessToken);
        let orderQuery = GET_CUSTOMER_ORDERS(RECENT_ORDERS, accessToken)
        this.props.getCustomerList(query);
        this.props.getCustomerOrders(orderQuery)
    }
    openResetPasswordModal(customer) {
        let { fields } = this.state;
        fields["password"] = customer?.password;
        this.setState({ isResetPasswordModal: true, fields });
    }
    closeResetPasswordModal(e) {
        this.setState({ isResetPasswordModal: false, fields: {}, errors: {} });
        if (e) {
            this._getCustomerDetails();
        }
    }
    logout() {
        this.props.logout();
        this.props.history.push(Routes.HOME);
    }

    orderStatus(id) {
        this.props.history.push(`/order/status?${id}`);
    }

    openProfileUpdateModal(customer) {
        let { fields } = this.state;
        fields["firstName"] = customer.firstName;
        fields["lastName"] = customer.lastName;
        fields["phone"] = customer.phone;
        this.setState({ fields, isProfileUpdateModal: true });
    }

    handleAddressChange(field, e) {
        let { fields } = this.state;
        fields[field] = e.target.value;
        this.setState({ fields }, () => {
            this.handleAddressValidation(field);
        });
    }


    handleAddressValidation(field) {
        let { fields, errors } = this.state;
        switch (field) {
            case "firstName":
                if (isFieldEmpty(fields[field])) {
                    errors[field] = "Please enter the first name";
                    this.setState({ errors });
                    return false;
                } else if (fields[field].length > 20) {
                    errors[field] = "First name allow only 20 Characters";
                    this.setState({ errors });
                    return false;
                } else if (!isValidName(fields[field])) {
                    errors[field] = "Alphabetic characters only allowed";
                    this.setState({ errors });
                    return false;
                }
                break;
            case "lastName":
                if (isFieldEmpty(fields[field])) {
                    errors[field] = "Please enter the last name";
                    this.setState({ errors });
                    return false;
                } else if (fields[field].length > 20) {
                    errors[field] = "Last name allow only 20 Characters";
                    this.setState({ errors });
                    return false;
                } else if (!isValidName(fields[field])) {
                    errors[field] = "Alphabetic characters only allowed";
                    this.setState({ errors });
                    return false;
                }
                break;
            case "address":
                if (isFieldEmpty(fields[field])) {
                    errors[field] = "Please enter the address";
                    this.setState({ errors });
                    return false;
                }
                break;

            case "zip":
                if (isFieldEmpty(fields[field])) {
                    errors[field] = "Please enter the zip code";
                    this.setState({ errors });
                    return false;
                }
                break;
            case "phone":
                if (isFieldEmpty(fields[field])) {
                    errors[field] = "Please enter the mobile number";
                    this.setState({ errors });
                    return false;
                }
                break;
            case "province":
                if (isFieldEmpty(fields[field])) {
                    errors[field] = "Please enter the state";
                    this.setState({ errors });
                    return false;
                } else if (isValidNumber(fields[field])) {
                    errors[field] = "Alphabetic characters only allowed";
                    this.setState({ errors });
                    return false;
                }
                break;
            case "country":
                if (isFieldEmpty(fields[field])) {
                    errors[field] = "Please choose the country";
                    this.setState({ errors });
                    return false;
                }
                break;
            case "city":
                if (isFieldEmpty(fields[field])) {
                    errors[field] = "Please enter the city";
                    this.setState({ errors });
                    return false;
                } else if (isValidNumber(fields[field])) {
                    errors[field] = "Alphabetic characters only allowed";
                    this.setState({ errors });
                    return false;
                }
                break;
            default:
                return null;
        }
        errors[field] = "";
        this.setState({ errors });
        return true;
    }


    handleAddressSubmitValidation(fields) {
        let isFormValid = true;
        fields.forEach((field) => {
            if (!this.handleAddressValidation(field)) {
                isFormValid = false;
            }
        });
        return isFormValid;
    }
    deleteMeasurement(id) {
        let { customerId } = this.props.customerId || {};
        const callback = (response) => {
            const { userErrors = {} } = (response && response.metafieldDelete) || {};
            if (userErrors !== null) {
                toast.success(`Measurement Successfully Deleted`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                this.closeModalMeasurement();
                this._getMeasurementDetails(customerId);
                return;
            } else {
                toast.error("Something Went wrong Please Try again later ", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        };
        let input = {
            id: id,
        };
        this.props.measurementDelete(DELETE_MEASUREMENT, input, callback);
    }
    openMeasureMent() {
        this.setState({ isOpenMeasurementTypeModal: true })
    }

    closeMeasurementTypeModal() {
        this.setState({ isOpenMeasurementTypeModal: false })
    }

    handleMeasurementType(e) {
        const { name, value } = e.target
        let { fields } = this.state
        fields[name] = value
        this.setState({ fields })
    }

    navCustomerMeasurement() {
        let { measurementType } = this.state.fields
        if (measurementType === Constants.PRODUCTS.SHIRT) {
            this.props.history.push(Routes.CUSTOMER_SHIRT_MEASUREMENT)
        } else if (measurementType === Constants.PRODUCTS.PANT) {
            this.props.history.push(Routes.CUSTOMER_PANT_MEASUREMENT)
        } else if (measurementType === Constants.PRODUCTS.SUIT) {
            this.props.history.push(Routes.CUSTOMER_SUIT_MEASUREMENT)
        } else if (measurementType === Constants.PRODUCTS.BLAZER) {
            this.props.history.push(Routes.CUSTOMER_BLAZER_MEASUREMENT)
        } else {
            this.props.history.push(Routes.CUSTOMER_SHIRT_MEASUREMENT)
        }
    }

    _renderOrderPlacedDate(date) {
        let updatedDate = moment(date);
        return moment(updatedDate).format("DD-MM-YYYY");
    }
    _renderOrderDate(date) {
        return moment(date).format("DD-MM-YYYY");
    }
    _renderLastUpdatedDate(date) {
        return moment(date).fromNow();
    }
    editMeasurement(key, namespace, id) {
        let measurementId = btoa(id);
        if (key.includes(titleCase(Constants.PRODUCTS.SHIRT))) {
            this.props.history.push(
                `/customer/tape/shirt/success/${key}/${namespace}/${measurementId}/:edit`
            );
        } else if (key.includes(titleCase(Constants.PRODUCTS.PANT))) {
            this.props.history.push(
                `/customer/tape/pant/success/${key}/${namespace}/${measurementId}/:edit`
            );
        } else if (key.includes(titleCase(Constants.PRODUCTS.SUIT))) {
            this.props.history.push(
                `/customer/tape/suit/success/${key}/${namespace}/${measurementId}/:edit`
            );
        } else if (key.includes(titleCase(Constants.PRODUCTS.BLAZER))) {
            this.props.history.push(
                `/customer/tape/blazer/success/${key}/${namespace}/${measurementId}/:edit`
            );
        } else {
            this.props.history.push(
                `/customer/tape/shirt/success/${key}/${namespace}/${measurementId}/:edit`
            );
        }

    }
    focusChild(content) {
        this.refs[`${content}`].scrollIntoView({
            behavior: "smooth",
            block: "end",
        });
    }
    _renderMeasurement() {
        const { measurementDetails: { customer } } = this.props.measurementDetails || {};
        return (
            <React.Fragment>
                <div className='pt-lg-4 pt-2 pb-lg-4 pb-2 m-0 row row-gap-4'>
                    <div className="d-flex align-items-center justify-content-end gap-24 px-0" data-bs-toggle="offcanvas" data-bs-target="#offcanvasmeasurement" >
                        <ButtonDesign
                            btnWidth={'d-flex'}
                            className={'H-l'}
                            buttonImgPath={PATH?.img?.AddIcon}
                            ButtonValue={CONTENTS?.profile?.measurementProfilesContent?.addMeasurement} />
                    </div>
                    <div className="col-lg-4 px-2">
                        {/* <div className="profile_measurement profile-data-border px-3 py-3">
                            <div className="d-flex justify-content-between">
                                <p className="fs-16 H-l m-0">{CONTENTS.profile.measurementProfilesContent.Head}</p>
                                <div className='d-flex gap-10'>
                                    <img src={PATH?.img?.EyeIcon} className="profile-icon cursor-pointer" alt="eye-icon" />
                                    <img src={PATH?.img?.EditIcon} className="profile-icon cursor-pointer" alt="edit-icon" />
                                    <img src={PATH?.img?.DeleteIcon} className="profile-icon cursor-pointer" alt="delete-icon" />
                                </div>
                            </div>
                            <p className="H-l fs-14 m-0 pt-2">{CONTENTS.profile.measurementProfilesContent.lastUpdated}</p>
                        </div> */}
                    </div>

                    {/* popup */}
                    <div className='offcanvas offcanvas-end measurement_offcanvas' id="offcanvasmeasurement">
                        <div className=''>
                            <a className='measurement_btn_close mx-4 mt-3 mb-lg-0 mb-2 d-inline-block' href='#' data-bs-dismiss="offcanvas">
                                <img src={PATH?.img?.ExitPopupIcon} className='PDP_exit_popup_img cursor-pointer' alt="exit-popup" data-bs-dismiss="offcanvas" />
                            </a>


                            {/* Instruction */}
                            <div className="measure_content_scroll">
                                <p className='fs-16 H-m text-center mb-1'>{CONTENTS.profile.measurementProfilesContent.popup.head}</p>
                                <p className='text_grey text-center H-l fs-14 mb-4 letter-spacing'>{CONTENTS.profile.measurementProfilesContent.popup.content}</p>
                                <div className="profile_measurement_popup">
                                    <div className="row mx-auto pb-3 pt-3">
                                        <div className="col-4">
                                            <div className="add_measurement_popup_circle">
                                                <img src={PATH?.img?.MeasurementCollar} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-3 d-flex align-items-center">
                                            <p className="fs-14 H-l">{CONTENTS.profile.measurementProfilesContent.popup.collar}</p>
                                        </div>
                                        <div className="col-5 d-flex align-items-center">
                                            <p className="fs-14 H-l">{CONTENTS.profile.measurementProfilesContent.popup.inches1}</p>
                                        </div>
                                    </div>
                                    <div className="row mx-auto pb-3">
                                        <div className="col-4">
                                            <div className="add_measurement_popup_circle">
                                                <img src={PATH?.img?.MeasurementBack} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-3 d-flex align-items-center">
                                            <p className="fs-14 H-l">{CONTENTS.profile.measurementProfilesContent.popup.back}</p>
                                        </div>
                                        <div className="col-5 d-flex align-items-center">
                                            <p className="fs-14 H-l">{CONTENTS.profile.measurementProfilesContent.popup.inches2}</p>
                                        </div>
                                    </div>
                                    <div className="row mx-auto pb-3">
                                        <div className="col-4">
                                            <div className="add_measurement_popup_circle">
                                                <img src={PATH?.img?.MeasurementWaist} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-3 d-flex align-items-center">
                                            <p className="fs-14 H-l">{CONTENTS.profile.measurementProfilesContent.popup.waist}</p>
                                        </div>
                                        <div className="col-5 d-flex align-items-center">
                                            <p className="fs-14 H-l">{CONTENTS.profile.measurementProfilesContent.popup.inches3}</p>
                                        </div>
                                    </div>
                                    <div className="row mx-auto pb-3">
                                        <div className="col-4">
                                            <div className="add_measurement_popup_circle">
                                                <img src={PATH?.img?.MeasurementSleeve} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-3 d-flex align-items-center">
                                            <p className="fs-14 H-l">{CONTENTS.profile.measurementProfilesContent.popup.sleeve}</p>
                                        </div>
                                        <div className="col-5 d-flex align-items-center">
                                            <p className="fs-14 H-l">{CONTENTS.profile.measurementProfilesContent.popup.inches4}</p>
                                        </div>
                                    </div>
                                    <div className="row mx-auto pb-3">
                                        <div className="col-4">
                                            <div className="add_measurement_popup_circle">
                                                <img src={PATH?.img?.MeasurementChest} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-3 d-flex align-items-center">
                                            <p className="fs-14 H-l">{CONTENTS.profile.measurementProfilesContent.popup.chest}</p>
                                        </div>
                                        <div className="col-5 d-flex align-items-center">
                                            <p className="fs-14 H-l">{CONTENTS.profile.measurementProfilesContent.popup.inches5}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        {/* <div className='d-flex gap-2 px-4 py-3 measure_offcanvas_bottom_btns'> */}
                        <div className='d-flex gap-15 px-4 py-2 address_popup_btn justify-content-start'>
                            <div data-bs-dismiss="offcanvas">
                                <ButtonDesign
                                    btnWidth={'profile_popup_close_btn'}
                                    className={'w-100 fs-16 H-cb py-12'}
                                    
                                    ButtonValue={CONTENTS?.profile?.measurementProfilesContent?.popup?.close}
                                />
                            </div>
                            <ButtonDesign
                                btnWidth={'profile_popup_proceed_btn button-primary'}
                                className={'w-100 fs-16 H-cb py-12'}
                                
                                ButtonValue={CONTENTS?.profile?.measurementProfilesContent?.popup?.alterMeasurement}
                            />
                        </div>
                    </div>

                </div>


            </React.Fragment>
        );
    }
    renderOrderNumber() {
        let { customerList = {} } = this.props.customerList;
        const { customer = {} } = (customerList && customerList.data) || {};
        let { orders } = (customer && customer) || {};
        let { node = {} } = (orders && orders.edges[0]) || {};
        return (
            <div className="col-lg-4">
                <h4>Profile</h4>
                {orders && orders.edges.length === 0 ? (
                    <p className="fs-14 H-l">You have no orders yet</p>
                ) : (
                    <p>
                        #{node && node.orderNumber}
                        <address>
                            <span>{customer && customer.displayName}</span> <br />
                            <span>{customer && customer.phone}</span>
                            <br />
                            <span> {customer && customer.email}</span>
                            <br />
                        </address>
                        <div className="col-12 p-0">
                            <ul className="my_detas">
                                <li>
                                    <i className="fas fa-pen"></i>{" "}
                                    <span
                                        className="account_edit"
                                        onClick={() => this.openProfileUpdateModal(customer)}
                                    >
                                        Edit
                                    </span>
                                </li>{" "}
                                &nbsp;
                                <li>
                                    <i className="fas fa-pen"></i>{" "}
                                    <span
                                        className="account_edit"
                                        onClick={() => this.openResetPasswordModal(customer)}
                                    >
                                        Change my password
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </p>
                )}
            </div>
        );
    }
    seeAllOrders(params) {
        let { isLoadMore } = this.state
        let { token = {} } = this.props.token;
        let { accessToken } = token;
        let orderQuery = GET_CUSTOMER_ORDERS(isLoadMore ? TOTAL_ORDERS : RECENT_ORDERS, accessToken)
        this.setState({ isLoadMore: params })
        this.props.getCustomerOrders(orderQuery)
    }
    closeAddressModal() {
        this.setState({ addressModal: false, fields: {}, errors: {} });
    }
    addAddressModal() {
        this.setState({ addressModal: true });
    }
    listAddressModel = () => {
        this.setState(prevState => ({
            addressListModal: !prevState.addressListModal
        }));
    };

    editAddress(obj) {
        let { fields } = this.state
        fields['firstName'] = obj?.firstName
        fields['lastName'] = obj?.lastName
        fields['address'] = obj?.address1
        fields['city'] = obj?.city
        fields['province'] = obj?.province
        fields['zip'] = obj?.zip
        fields['country'] = obj?.country
        fields['id'] = obj?.id
        fields['phone'] = obj?.phone
        this.setState({ addressModal: true, fields });
    }

    nav_profile_menu(params) {
        this.setState({ activePage: params })
    }
    toggleOffcanvas = () => {
        document.body.style.overflow = 'hidden';
    };

    render() {
        const { isModelOpen, isViewOpen, measurementId, selectedMeasurement, activePage,
            fields, errors, isResetPasswordModal, addressModal, addressListModal, isOpenMeasurementTypeModal } = this.state;

        let { data } = this.props.customerList?.customerList || {};
        let { defaultAddress, addresses } = data?.customer || {};
        const { orders, isLoading } = this.props.customerOrders?.orders || {}
        return (
            <React.Fragment>
                <div className='container profile-alignment-design'>
                    <div className="d-flex flex-md-row flex-column gap-24 py-32">
                        <div className='profile-letter rounded-circle d-flex align-items-center justify-content-center H-m fs-32'>{data?.customer?.displayName.charAt(0)}</div>
                        <div className="d-flex flex-column gap-6">
                            <p className="H-m fs-24 m-0">{data?.customer?.displayName}</p>
                            {defaultAddress?.address1 && <p className="H-l fs-14 m-0">{defaultAddress?.address1}, {defaultAddress?.country} </p>}
                            <div className="d-flex align-items-center gap-24">
                                <div onClick={() => this.toggleOffcanvas()}>
                                    <ButtonDesign
                                        btnWidth={'px-0 d-flex'}
                                        className={'H-l'}
                                        buttonImgPath={PATH?.img?.AddIcon}
                                        onClick={() => this.addAddressModal()}
                                        ButtonValue={CONTENTS?.profile?.addAddress}
                                    />
                                </div>
                                <div onClick={() => this.toggleOffcanvas()}>
                                    <ButtonDesign
                                        btnWidth={'px-0 d-flex'}
                                        className={'H-l'}
                                        ButtonarrowDesign={'w-17'}
                                        buttonImgPath={PATH?.img?.EyeIcon}
                                        onClick={() => this.listAddressModel()}
                                        ButtonValue={CONTENTS?.profile?.viewAddress}
                                    />
                                </div>
                            </div>
                            <ButtonDesign
                                btnWidth={'p-0 text-start'}
                                className={'H-l'}
                                ButtonValue={CONTENTS?.profile?.logout}
                                
                                onClick={() => this.logout()}
                                Noarrow
                            />
                        </div>
                    </div>
                    <div className="m-0">
                        <div className="d-flex profile-tab-align gap-44">
                            <div
                                className={`${activePage === "info" ? 'active_item' : ''} cursor-pointer H-m py-24 text-nowrap`}
                                onClick={() => this.nav_profile_menu("info")}>{CONTENTS.profile.information}
                            </div>
                            <div
                                className={`${activePage === "measure" ? 'active_item' : ''} cursor-pointer H-m py-24 text-nowrap`}
                                onClick={() => this.nav_profile_menu("measure")}>{CONTENTS.profile.measurementProfiles}
                            </div>
                            <div
                                className={`${activePage === "order" ? 'active_item' : ''} cursor-pointer H-m py-24 text-nowrap`}
                                onClick={() => this.nav_profile_menu("order")}>{CONTENTS.profile.orderHistory}
                            </div>
                            {/* <div
                                className={`${activePage === "address" ? 'active_item' : ''} cursor-pointer H-m py-24 text-nowrap`}
                                onClick={() => this.nav_profile_menu("address")}>{CONTENTS.profile.paymentMethod}
                            </div> */}
                        </div>
                        {/* <div className="col-lg-3">
                        <div className="row align-items-start mt-4 mb-2 profile_top_head">
                            <p className="fs-16 f-m col-8">Welcome <span>{data?.customer?.displayName}</span></p>
                            <div className="col-4 text-end">
                                <button className="btn profile_menu_btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#profileoffcanvas">View Menu</button>
                            </div>
                        </div>
                        <div className="offcanvas offcanvas-end border-0 profile_offcanvas" id="profileoffcanvas">
                            <div className="offcanvas-header justify-content-start p-lg-0 pb-0">
                                <a className='measurement_btn_close profile_canvas_close_icon' href='#' data-bs-dismiss="offcanvas">
                                    <i className="bi bi-x fs-22 text_black"></i>
                                    <i className="bi bi-chevron-left fs-18 text_black"></i>
                                </a>
                            </div>
                            <div className="offcanvas-body p-lg-0 px-4 pt-0">
                                <p className="text-center fs-16 f-sb offcanvas_profile_head">Account Details</p>
                                <p className="fs-24 f-m welcome_text">Welcome <span>{data?.customer?.displayName}</span></p>
                                <div>
                                    <div className="d-flex flex-column gap-3 profile_sidemenu py-4">
                                        <span
                                            className={`${activePage === "info" ? 'active_item' : ''} cursor-pointer`}
                                            onClick={() => this.nav_profile_menu("info")}>Information
                                        </span>
                                        <span
                                            className={`${activePage === "measure" ? 'active_item' : ''} cursor-pointer`}
                                            onClick={() => this.nav_profile_menu("measure")}>Measurement Profiles
                                        </span>
                                        <span
                                            className={`${activePage === "address" ? 'active_item' : ''} cursor-pointer`}
                                            onClick={() => this.nav_profile_menu("address")}>Addresses</span>
                                    </div>
                                    <div className="d-flex flex-column gap-3 profile_sidemenu py-4">
                                        <span
                                            className={`${activePage === "order" ? 'active_item' : ''} cursor-pointer`}
                                            onClick={() => this.nav_profile_menu("order")}>Order History</span>
                                    </div>
                                    <div className="d-flex flex-column gap-3 profile_sidemenu py-4 cursor-pointer"
                                        onClick={() => this.logout()}>
                                        <span>Logout</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                        <div className="">
                            <div className="">

                                {/* my information start */}
                                {activePage === "info" &&
                                    <React.Fragment>
                                        <ProfileUpdate
                                            payload={data?.customer}
                                            openModel={() => this.openResetPasswordModal()}
                                            _getCustomerDetails={() => this._getCustomerDetails()}
                                        />
                                    </React.Fragment>}

                                {/* my information end */}

                                {/* measurement profiles start */}
                                {activePage === "measure" && this._renderMeasurement()}
                                {/* measurement profiles end */}

                                {/* Add address start */}
                                {activePage === "address" &&
                                    <React.Fragment>
                                        {/* <div className='d-flex justify-content-end gap-2 px-md-4 px-2 py-3'>
                                            <button
                                                className='btn common_btn primary_btn profile_btn cursor-pointer'
                                                onClick={() => this.addAddressModal()}
                                                type="button" >Add Address</button>
                                        </div>
                                        <div className='pt-3 m-0 row row-gap-3'>
                                            <Address
                                                defaultAddress={defaultAddress}
                                                addressList={addresses}
                                                editAddress={(obj) => this.editAddress(obj)}
                                                onDefaultAddress={(id) => this.onDefaultAddress(id)}
                                                onAddressDelete={(id) => this.onAddressDelete(id)}
                                            />
                                        </div> */}
                                        <div className='pt-lg-4 pt-2 pb-lg-4 pb-2 m-0 row row-gap-4'>
                                            {/* <div className="d-flex align-items-center justify-content-end gap-24">
                                                <ButtonDesign
                                                    className={'H-l'}
                                                    buttonImgPath={PATH?.img?.AddIcon}
                                                    ButtonValue={'add measurement'}
                                                />
                                            </div> */}
                                            <div className="col-lg-4 px-2">
                                                {/* <div className="profile_measurement px-3 py-3">
                                                    <div className="d-flex justify-content-between">
                                                        <p className="fs-17 H-l m-0">{CONTENTS.profile.paymentMethodContent.head}</p>
                                                        <div className='d-flex gap-10'>
                                                            <img src={PATH?.img?.EyeIcon} className="profile-icon cursor-pointer" alt="eye-icon" />
                                                            <img src={PATH?.img?.EditIcon} className="profile-icon cursor-pointer" alt="edit-icon" />
                                                            <img src={PATH?.img?.DeleteIcon} className="profile-icon cursor-pointer" alt="delete-icon" />
                                                        </div>
                                                    </div>
                                                    <p className="H-l fs-14 m-0 pt-2">{CONTENTS.profile.paymentMethodContent.lastUpdated}</p>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* order tracking start */}
                                        {/* <ButtonDesign
                                            btnWidth={'gap-16'}
                                            className={'fs-16 H-cb py-12'}
                                            ButtonValue={'back'}
                                            buttonImgPath={PATH?.img?.GetMoreArrow}
                                            ButtonarrowDesign={'buttonDesign'}
                                            onClick={() => this.props.history.goBack()}
                                        />
                                        <div className='d-flex align-items-center gap-24'>
                                            <p className='H-l fs-16'>Order: <span>#4434</span></p>
                                            <p className='H-l fs-16'>Order Date: <span>04-07-2024</span></p>
                                        </div>
                                        <div className="row profile-order-status-align">
                                            <div className='col-lg-8 profile-order-status ps-0'>
                                                <ul className='px-0 py-3' id="progressbar">
                                                    <li
                                                        className="step1">
                                                        <div className='process_state'></div>
                                                        <p className='H-l fs-13 text-lowercase'>Processing</p>
                                                    </li>
                                                    <li
                                                        className={'step1'}>
                                                        <div className='process_state'></div>
                                                        <p className='H-l fs-13 text-lowercase'>Ready for Delivery</p>
                                                    </li>
                                                    <li
                                                        className={'step1'}>
                                                        <div className='process_state'></div>
                                                        <p className='H-l fs-13 text-lowercase'>Ready for Delivery</p>
                                                    </li><li
                                                        className={'step1'}>
                                                        <div className='process_state'></div>
                                                        <p className='H-l fs-13 text-lowercase'>Ready for Delivery</p>
                                                    </li>

                                                </ul>
                                                <div className="d-flex flex-column row-gap-3 w-100">
                                                    <div className='ordered_products'>
                                                        <div className='row m-0 w-100'>
                                                            <React.Fragment>
                                                                <div className='col-6'>
                                                                    <div className='d-flex gap-2'>
                                                                        <div className='ps-2'>
                                                                            <p className='H-l fs-16 text-lowercase'>Formal French Cuff Dress Shirt</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-3 pt-lg-0 pt-2'>
                                                                    <p className='H-l fs-14 text-lg-end'>Qty: <span className='fs-15 f-m'>1</span></p>
                                                                </div>
                                                                <div className='col-3 pt-lg-0 pt-2'>
                                                                    <p className='H-cb fs-18 text-lg-end'>SGD: 123</p>
                                                                </div>
                                                            </React.Fragment>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col-lg-4 px-0 data-divider'>
                                                <div className='d-flex flex-column gap-16'>
                                                    <div className='row'>
                                                        <p className='col-4 H-l fs-14 text-lowercase'>Name</p>
                                                        <p className='col-8 H-l fs-14'>Mercilin Divya</p>
                                                    </div>
                                                    <div className='row'>
                                                        <p className='col-4 H-l fs-14 text-lowercase'>Email</p>
                                                        <p className='col-8 H-l fs-14'>mercilindivya@gmail.com</p>
                                                    </div>
                                                    <div className='row'>
                                                        <p className='col-4 H-l fs-14 text-lowercase'>Phone Number</p>
                                                        <p className='col-8 H-l fs-14'>+91 9876543210</p>
                                                    </div>
                                                    <div className='row'>
                                                        <p className='col-4 H-l fs-14 text-lowercase'>Address</p>
                                                        <p className='col-8 H-l fs-14'>4 Tampines Central 5 Tampines Mall,
                                                            Singapore 529510 Singapore</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column row-gap-2 pt-4 pb-lg-0 pb-5'>
                                            <div className='d-flex justify-content-end align-items-center'>
                                                <p className='H-m fs-18'>Total:</p>
                                                <p className='H-cb fs-24 ps-2'>138 SGD</p>
                                            </div>
                                        </div> */}
                                        {/* order tracking end */}
                                    </React.Fragment>}

                                {/* Add address end */}

                                {activePage === "order" &&
                                    <React.Fragment>
                                        {
                                            orders?.edges?.length > 0 && <OrderComponent orders={orders} isLoading={isLoading} />
                                        }
                                        {!isLoading && orders?.edges?.length === 0 && <p className="text-center f-m py-3">{CONTENTS.profile.orderHistoryContent.noOrder}</p>}
                                    </React.Fragment>}

                                {activePage === 'order_status' &&
                                    <React.Fragment>
                                        <OrderStatus />
                                    </React.Fragment>}
                            </div>
                        </div>
                    </div>
                    <div className='container plp_looks_section d-flex flex-column gap-44'>
                        <p className='text-uppercase H-cb fs-24 text-center'>{CONTENTS.profile.Head}</p>
                        <div className="row">
                            <div className='col-lg-4 d-flex flex-column gap-16 text-center align-items-center px-4'>
                                <div className='d-flex flex-column gap-16 align-items-center'>
                                    <div className="icon-bg rounded-circle d-flex align-items-center justify-content-center">
                                        <LazyLoad className="w-100">
                                            <img src={PATH?.img?.FabricIcon} className='icon_alignment' alt="fit-icon" />
                                        </LazyLoad>
                                    </div>
                                    <p className='H-m fs-16'>{CONTENTS.profile.head1}</p>
                                    <p className='H-l fs-14 plp_looks_section_max_width'>{CONTENTS.profile.content1}</p>
                                </div>
                            </div>
                            <div className='col-lg-4 d-flex flex-column gap-16 text-center align-items-center px-4 pt-lg-0 pt-5'>
                                <div className='d-flex flex-column gap-16 align-items-center'>
                                    <div className="icon-bg rounded-circle d-flex align-items-center justify-content-center">
                                        <LazyLoad className="w-100">
                                            <img src={PATH?.img?.CustomSuit} className='icon_alignment' alt="fit-icon" />
                                        </LazyLoad>
                                    </div>
                                    <p className='H-m fs-16'>{CONTENTS.profile.head2}</p>
                                    <p className='H-l fs-14 plp_looks_section_max_width'>{CONTENTS.profile.content2}</p>
                                </div>
                            </div>
                            <div className='col-lg-4 d-flex flex-column gap-16 text-center align-items-center px-4 pt-lg-0 pt-5'>
                                <div className='d-flex flex-column gap-16 align-items-center'>
                                    <div className="icon-bg rounded-circle d-flex align-items-center justify-content-center">
                                        <LazyLoad className="w-100">
                                            <img src={PATH?.img?.MeasureIcon} className='icon_alignment' alt="fit-icon" />
                                        </LazyLoad>
                                    </div>
                                    <p className='H-m fs-16'>{CONTENTS.profile.head3}</p>
                                    <p className='H-l fs-14 plp_looks_section_max_width'>{CONTENTS.profile.content3}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <div className="container">
          <div className="col-12 s_heading">
            <h2>
              <b>My Information</b>
            </h2>
            <hr />
            <div className="row" ref={"my_details"}>
              {this.renderOrderNumber()}
              <div className="col-lg-4">
                <h4>Address Book</h4>
               
              </div>
              <div className="col-lg-4">
                <h4>Payment Method</h4>
              </div>
            </div>
          </div>
          <div className="col-12 s_heading" ref={"my_order"}>
            <h2 id="my_order">
              <b>Order history</b>
            </h2>
            {customerOrders && customerOrders.edges.length === 0 ? (
              <p className="text-center">You have no orders in the past.</p>
            ) : (
              <div className="nw_tbl_mbl">
                <table className="table order-table" id="profile_tbl">
                  <thead>
                    <tr>
                      <th scope="col">
                        <b>#</b>
                      </th>
                      <th scope="col">
                        <b>Date</b>
                      </th>
                      <th scope="col">
                        <b>Value</b>
                      </th>
                      <th scope="col">
                        <b>Status</b>
                      </th>
                      <th scope="col">
                        <b>Order Summary</b>
                      </th>
                    </tr>
                  </thead>
                  {customerOrders && customerOrders.edges.map((i, j) => {
                    const { totalPrice, financialStatus, processedAt, id } = i.node
                    return (
                      <React.Fragment>
                        <tbody key={j}>
                          <tr>
                            <td>{j + 1}</td>
                            <td>{this._renderOrderDate(processedAt)}</td>
                            <td>{totalPrice}</td>
                            <td>{financialStatus}</td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-gren"
                                onClick={() => this.orderStatus(id)}
                              >
                                View
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </React.Fragment>
                    );
                  })}
                </table>
                {
                  !isLoading && (
                    <p className="cursor-pointer" id="see_all">
                      {
                        isLoadMore ? <span onClick={() => this.seeAllOrders(false)}>
                          <i className="fas fa-long-arrow-alt-down"></i>
                          See All
                        </span> : <span onClick={() => this.seeAllOrders(true)}>
                          <i className="fas fa-long-arrow-alt-up"></i>
                          Hide
                        </span>
                      }
                    </p>
                  )
                }
              </div>
            )}
          </div>
          {this._renderMeasurement()}
        </div> */}
                {
                    isResetPasswordModal && <ResetPassword
                        closeResetPasswordModal={(e) => this.closeResetPasswordModal(e)}
                    />
                }
                {
                    addressModal && <AddAddress
                        closeAddModal={(e) => this.closeAddressModal(e)}
                        handleChange={this.handleAddressChange.bind(this)}
                        handleSubmitValidation={(e) => this.handleAddressSubmitValidation(e)}
                        errors={errors}
                        fields={fields}
                    />
                }
                {addressListModal && <ListOfAddress closeListAddressModal={this.listAddressModel} isCheckout={false} />}
                <DeleteMeasurement
                    isOpen={isModelOpen}
                    measurementId={measurementId}
                    closeDeleteMeasurement={() => this.closeModalMeasurement()}
                    onCancel={() => this.closeModalMeasurement()}
                    onDelete={(measurementId) => this.deleteMeasurement(measurementId)}
                />
                <MeasurementViewModal
                    isView={isViewOpen}
                    closeViewMeasurement={() => this.closeModalViewMeasurement()}
                    editMeasurementModel={(key, namespace, id) => this.editMeasurement(key, namespace, id)}
                    selectedMeasurement={selectedMeasurement}
                />
                <ProductModal
                    showModal={isOpenMeasurementTypeModal}
                    closeModal={() => this.closeMeasurementTypeModal()}
                    handleChange={(e) => this.handleMeasurementType(e)}
                    saveModal={() => this.navCustomerMeasurement()}
                />
            </React.Fragment >
        );
    }
}
const mapStateToProp = (state) => {
    return {
        customerList: Actions.getCustomer(state),
        token: Actions.getTokenSelector(store.getState()) || {},
        customerId: Actions.getCustomerIdSelector(state),
        measurementDetails: Actions.getMeasurement(state),
        customerOrders: Actions.getCustomerOrders(state)
    };
};

export default withRouter(
    connect(mapStateToProp, {
        getCustomerList: Actions.getCustomerList,
        logout: Actions.logout,
        measurementList: Actions.getMeasurementList,
        measurementDelete: Actions.measurementDelete,
        updateSelectedMeasurement: Actions.updateSelectedMeasurement,
        shippingAddressAdd: Actions.shippingAddressAdd,
        getCustomerOrders: Actions.customerOrders,
        addressDelete: Actions.addressDelete,
        setDefaultAddress: Actions.setDefaultAddress,
    })(Welcome)
);
