export const isValidEmail = (email) => {
  const validEmailRegex = RegExp(/^[a-zA-Z0-9+.]+@(?:[a-zA-Z0-9-]+\.)+[A-Za-z]+$/)
  return validEmailRegex.test(email);
}

export const isFieldEmpty = (field) => {
  return (!field || field.trim().length === 0)
}

export const checkFieldLength = (field = '', length) => {
  return field.length < length
}

export const isValidName = (field = '') => {
  // eslint-disable-next-line no-useless-escape
  var letters = /^[A-Za-z]+$/;
  return letters.test(field);
}

export const isValidNumber = (field = '') => {
  // eslint-disable-next-line no-useless-escape
  var letters = /^[0-9]+$/;
  return letters.test(field);
}
export const isValidText = (field = '') => {
  // eslint-disable-next-line no-useless-escape
  var letters = /^[A-Za-z ]+$/;
  return letters.test(field);
}

export const isValidComapnyName = (field = '') => {
  // eslint-disable-next-line no-useless-escape
  var letters = /^[A-Za-z ]+$/;
  return letters.test(field);
}

export const isValidMobile = (field) => {
  // eslint-disable-next-line no-useless-escape
  var phoneNo = /^\d{10}$/;
  return phoneNo.test(field)
}