import React from 'react'
import { withRouter } from 'react-router-dom'
import Actions from '../../redux/action'
import { connect } from 'react-redux'
import { StaticHeader } from '../../config/constant'
import { PATH } from '../../globals/app-constraints'
import { store } from '../../createStore'

class CustomHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            headerText: StaticHeader[0],
            isScrolled: false,
            showMenu: false
        };
    }
    _renderCartNotification() {
        let { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        if (lastIncompleteCheckout) {
            let { lineItems } = lastIncompleteCheckout || {}
            if (lineItems?.edges?.length > 0) {
                return <div className='cart_header_icon cart-count-default m-0 fs-13 H-cb'><span>{lineItems?.edges?.length}</span></div>
            }
        } else {
            let { data } = this.props.checkoutDetails || {}
            let { lineItems } = data?.node || {}

            if (lineItems?.edges?.length > 0) {
                return <div className='cart_header_icon cart-count-default m-0 fs-13 H-cb'><span>{lineItems?.edges?.length}</span></div>
            }
        }
    }
    navWelcomePage() {
        let { token: { accessToken } } = this.props.token || {}
        if (!accessToken) {
            this.props.history.push('/login')
        } else {
            this.props.history.push('/profile')
        }
    }
    navCartPage() {
        let { token: { accessToken } } = this.props.token || {}
        let { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        let { lineItems } = lastIncompleteCheckout || {}
        if (!accessToken) {
            this.props.history.push('/login')
        } else if (accessToken && !lineItems || lineItems?.edges?.length === 0) {
            this.props.history.push('/canvas/shirt?fabric')
        } else {
            this.props.history.push('/cart')
        }
    }

    toggleModal = () => {
        document.body.style.overflow = 'hidden';
    };
    toggleModalClose = () => {
        document.body.style.overflow = 'auto';
    };

    render() {
        const isHomePage = this.props.location.pathname === '/';
        const { history } = this.props;
        const { isScrolled, showMenu } = this.state;
        const { selectedCombination, isLoading, isFabricModal,
            isZoomModal, isLoadingStarted } = this.state;
        const { foldedSuitFit, foldedPocket, foldedBreastPocket, foldedNormalFit,
            foldedSleeve, foldedSuitButtons, foldedCollar, foldedInnerBottom, foldedInnerSuit,
            suitPocketsSquare, suitInner, suitBottomInner, foldedButtonHole,
            foldedButtonThread, foldedLapelsHole } = selectedCombination || {}
        const { selectedBlazerFabric } = this.props.blazerFabric
        return (
            <React.Fragment>
                <div className='canvas_padding navTemplateDesign w-100'>
                    <nav className={`container nav d-flex justify-content-between align-items-center w-100`}>
                        <div className='d-flex gap-4'>
                            <div className='d-flex align-items-center gap-30 h-100' onClick={() => this.toggleModal()}>
                                <button className="btn border-0 p-0"
                                    type="button"
                                    data-bs-toggle="modal" data-bs-target="#exampleModal"
                                    aria-controls="exampleModal"
                                    onClick={() => { this.setState({ showMenu: true }) }}>
                                    <img src={PATH?.img?.Menu} className='header_icons' alt='Menu' />
                                </button>
                            </div>
                            <div className='cursor-pointer' onClick={() => { history.push("/") }}>
                                <img className="canvas-logo"
                                    src={`${isHomePage && !isScrolled ? PATH?.img?.FooterAkalLogo : PATH?.img?.Logo_black}`}
                                    alt='logo'
                                />
                            </div>
                        </div>
                        <ul className='d-flex gap-22 list-unstyled m-0'>
                            <li className='d-flex align-item-center cursor-pointer H-m fs-14 mobile-hidden' onClick={() => this.navWelcomePage()}
                            ><img src={`${isHomePage && !isScrolled ? PATH?.img?.AccountWhite : PATH?.img?.Account}`} alt='search' className='mobile_hidden' /><span className={`ps-2 mobile_hidden ${isHomePage && !isScrolled ? 'text_color' : "header-text "}`}>Account</span>
                            </li>
                            <div className='cart_position'>
                                <li className='d-flex align-item-center cursor-pointer H-m fs-14' onClick={() => this.navCartPage()}
                                ><img src={`${isHomePage && !isScrolled ? PATH?.img?.CartWhite : PATH?.img?.Cart}`} alt='search' className='header_cart_img' /><span className={`ps-2 mobile_hidden ${isHomePage && !isScrolled ? 'text_color' : "header-text "}`}>Cart</span>
                                </li>
                                {this._renderCartNotification()}
                            </div>
                        </ul>
                    </nav >
                </div>
                <div className={`modal fade ${showMenu ? "show d-block" : "d-none"}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-modal="true">
                    <div className="modal-dialog modal-fullscreen modal_fullscreen">
                        <div className="modal-content">
                            <div className='web_popup flex-grow-1'>
                                <div className="d-flex justify-content-between w-100 web_popup_logo">
                                    <div className='cursor-pointer' data-bs-dismiss="modal" onClick={() => {
                                        this.setState({ showMenu: false });
                                        this.toggleModalClose()
                                    }}>
                                        <img src={PATH?.img?.ExitPopupIcon} className='header_exit_popup_img' alt="exit-popup" />
                                    </div>
                                    <div data-bs-dismiss="modal" onClick={() => {
                                        this.setState({ showMenu: false });
                                        this.toggleModalClose()
                                    }}>
                                        <img src={PATH?.img?.Logo_black} className='header_popup_logo cursor-pointer' alt="logo" />
                                    </div>
                                </div>
                                <div className='d-flex flex-column justify-content-between header-menu-align flex-grow-1'>
                                    <div className='mobile_popup_hidden'>
                                        <div className='d-flex flex-lg-row flex-column gap-49 header_popup_left w-100'>
                                            <div className='mobile-img-hide'>
                                                <div className='d-flex flex-column gap-24'>
                                                    <img src={PATH.img.HeaderImg} className='header_popup_img' alt="header-popup-img" />
                                                    <div className='d-flex flex-column align-items-start gap-6'>
                                                        <p className='H-l fs-12 letter-spacing'>Dictum sit risus velit gravida.</p>
                                                        <p className='PP_R fs-24'>Risus tincidunt purus posuero</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column gap-32 flex-grow-1'>
                                                <div>
                                                    <p className='H-l fs-34 letter-spacing header_popup_menu' onClick={() => {
                                                        this.setState({ showMenu: false });
                                                        this.toggleModalClose()
                                                        this.props.history.push('/blazer');
                                                    }}>BLAZERS</p>
                                                    <div className='header_popup_menu'>
                                                        <div className="accordion accordion-flush" id="accordionFlushExample">
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header">
                                                                    <button className="accordion-button collapsed text-uppercase H-l letter-spacing header_popup_dropdown_menu p-0" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                                        Suits
                                                                    </button>
                                                                </h2>
                                                                <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                                    <div className="card card-body collapse_ul border-0 p-0">
                                                                        <p className='H-l fs-24 header_popup_menu_dropdown text-uppercase' onClick={() => {
                                                                            this.setState({ showMenu: false });
                                                                            this.toggleModalClose()
                                                                            this.props.history.push('/suit');
                                                                        }}>2 piece suits</p>
                                                                        <p className='H-l fs-24  header_popup_menu_dropdown text-uppercase' onClick={() => {
                                                                            this.setState({ showMenu: false });
                                                                            this.toggleModalClose()
                                                                            this.props.history.push('/vest');
                                                                        }}>3 piece suits</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className='H-l fs-34 letter-spacing header_popup_menu' onClick={() => {
                                                        this.setState({ showMenu: false });
                                                        this.toggleModalClose()
                                                        this.props.history.push('/shirt');
                                                    }}>SHIRTS</p>
                                                    <p className='H-l fs-34 letter-spacing header_popup_menu' onClick={() => {
                                                        this.setState({ showMenu: false });
                                                        this.toggleModalClose()
                                                        this.props.history.push('/pant');
                                                    }}>PANTS</p>
                                                    <p className='H-l fs-34 letter-spacing header_popup_menu text-uppercase web_popup_hidden' onClick={() => {
                                                        this.setState({ showMenu: false });
                                                        this.toggleModalClose()
                                                        this.navWelcomePage();
                                                    }}>account</p>
                                                    <p className='H-l fs-34 letter-spacing header_popup_menu text-uppercase web_popup_hidden' onClick={() => {
                                                        this.setState({ showMenu: false });
                                                        this.toggleModalClose()
                                                        this.props.history.push('/blazer');
                                                    }}>new fabrics</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-lg-row flex-column justify-content-between header_popup_right'>
                                        <div className='d-flex flex-column align-items-start gap-16'>
                                            <div className='d-flex gap-24'>
                                                <div><img src={PATH?.img?.HeaderFacebookIcon} className='header_facebook_icon cursor-pointer' alt="facebook-icon" /></div>
                                                <div><a href='https://wa.me/66818091081' target="_blank"><img src={PATH?.img?.HeaderWhatsappIcon} className='header_whatsapp_icon cursor-pointer' alt="whatsapp-icon" /></a></div>
                                                <div><img src={PATH?.img?.HeaderTwitterIcon} className='header_twitter_icon cursor-pointer' alt="twitter-icon" /></div>
                                                <div><img src={PATH?.img?.HeaderInstagramIcon} className='header_instagram_icon cursor-pointer' alt="instagram-icon" /></div>
                                            </div>
                                            <p className='H-l fs-14'>We’re available by phone <a className='text-dark text-decoration-none' href="tel:+123-456-789" target="_blank">+123-456-789</a></p>
                                            <p className='H-l fs-14'><a className='text-dark text-decoration-none' href="mailto:info@example.com" target="_blank">info@example.com</a></p>
                                        </div>
                                        <div className='d-flex gap-6 copyrights_web_popup align-items-center popup_copyrights '>
                                            <img src={PATH?.img?.CopyrightsIcon} className='copyrights' alt="copyrights" />
                                            <p className='H-l fs-12 text-nowrap'>Copyrights AKAL. All Rights Reserved.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
const mapStateToProp = (state) => {
    return {
        selectedBlazerStyle: Actions.getSelectedBlazerStyle(state),
        blazerFabric: Actions.getSelectedBlazerFabric(state),
        blazerAccents: Actions.getSelectedBlazerAccent(state),
        getCheckoutDetails: Actions.getCheckoutId(store.getState()),
        checkoutDetails: Actions.getCheckoutDetails(state),
        token: Actions.getTokenSelector(store.getState()) || {},
    }
}
export default withRouter(connect(mapStateToProp, {
    resetStyle: Actions.resetStyle,
})(CustomHeader));