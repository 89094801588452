export const ORDER_STATUS = (Id) => {
    return `{
        node(id: "${Id}") {
          ... on Order {
            id
            email
            currencyCode
            financialStatus
            fulfillmentStatus
            name
            orderNumber
            phone
            processedAt
            totalShippingPrice {
              amount
              currencyCode
            }
            successfulFulfillments {
              trackingCompany
              trackingInfo {
                number
                url
              }
            }
            shippingAddress {
              address1
              address2
              city
              province
              country
              countryCode
              countryCodeV2
              firstName
              lastName
              zip
              phone
              id
            }
            lineItems(first: 200) {
              edges {
                node {
                  customAttributes {
                    key
                    value
                  }
                  title
                  quantity
                  variant {
                    availableForSale
                    id
                    price {
                      amount
                      currencyCode
                    }
                    image {
                      originalSrc
                      id
                      altText
                      src
                    }
                  }
                }
              }
            }
            subtotalPrice {
              amount
              currencyCode
            }
            totalTax {
              amount
              currencyCode
            }
            totalRefunded {
              amount
              currencyCode
            }
            totalPrice {
              amount
              currencyCode
            }
          }
        }
      }
      `
} 