import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import Actions from '../../redux/action'

class ContentHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        const { Cart, Address, Payment, OrderComplete } = this.props
        return (
            <React.Fragment>

                <div className='cart_product_scroll px-lg-5 px-3'>
                    <div className='cart_product_detail row m-0 py-4'>
                        <div className='text-end'>
                            <span><i className="bi bi-pencil-square text_grey"></i></span>
                            <span><i className="bi bi-x-lg text_grey ps-2"></i></span>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-3'>
                                <div className=''>
                                </div>
                            </div>
                            <div className='col-9'>
                                <p className='f-m mb-1'>Formal French Cuff Dress Shirt</p>
                                <p className='text_grey fs-12'>Lorem Ipsum is simply dummy text of the printing </p>
                                <p className='fs-15 f-m'>138 SGD</p>
                                <div className='cart_product_quantity'>
                                    <p className='fs-18 f-m m-0'>-</p>
                                    <p className='fs-15 f-m m-0'>1</p>
                                    <p className='fs-18 f-m m-0'>+</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='cart_product_detail row m-0 py-4'>
                        <div className='text-end'>
                            <span><i className="bi bi-pencil-square text_grey"></i></span>
                            <span><i className="bi bi-x-lg text_grey ps-2"></i></span>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-3'>
                                <div className=''>
                                    {/* <img src={shirt_img} className='cart_img' alt='shirt-img' /> */}
                                </div>
                            </div>
                            <div className='col-9'>
                                <p className='f-m mb-1'>Formal French Cuff Dress Shirt</p>
                                <p className='text_grey fs-12'>Lorem Ipsum is simply dummy text of the printing </p>
                                <p className='fs-15 f-m'>138 SGD</p>
                                <div className='cart_product_quantity'>
                                    <p className='fs-18 f-m m-0'>-</p>
                                    <p className='fs-15 f-m m-0'>1</p>
                                    <p className='fs-18 f-m m-0'>+</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='cart_product_detail row m-0 py-4'>
                        <div className='text-end'>
                            <span><i className="bi bi-pencil-square text_grey"></i></span>
                            <span><i className="bi bi-x-lg text_grey ps-2"></i></span>
                        </div>
                        <div className='row mt-2'>
                            <div className='col-3'>
                                <div className=''>
                                    {/* <img src={shirt_img} className='cart_img' alt='shirt-img' /> */}
                                </div>
                            </div>
                            <div className='col-9'>
                                <p className='f-m mb-1'>Formal French Cuff Dress Shirt</p>
                                <p className='text_grey fs-12'>Lorem Ipsum is simply dummy text of the printing </p>
                                <p className='fs-15 f-m'>138 SGD</p>
                                <div className='cart_product_quantity'>
                                    <p className='fs-18 f-m m-0'>-</p>
                                    <p className='fs-15 f-m m-0'>1</p>
                                    <p className='fs-18 f-m m-0'>+</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <ul>
                    <li className={Cart ? 'active' : ''}>
                        <Link to={`${(Cart || Payment || Address) ? '/cart' : ''}`}>
                            <i className="fas fa-shopping-cart"></i><span>  SHOPPING CART </span>
                        </Link>
                    </li>
                    <li className={Address ? 'active' : ''}>
                        {
                            (Address || Payment) ? (
                                <Link to={`${(Address || Payment) ? '/address' : ''}`}>
                                    <i className="fas fa-home"></i><span>ADDRESS</span>
                                </Link>
                            ) : (
                                <Link className='disable-content-link' to={'#'}>
                                    <i className="fas fa-home"></i><span>ADDRESS</span>
                                </Link>
                            )
                        }

                    </li>
                    <li className={Payment ? 'active' : ''}>
                        {
                            (Payment) ? (
                                <Link to={`${Payment ? '/payment' : ''}`}>
                                    <i className="fas fa-hand-holding-usd"></i><span> SUMMARY</span>
                                </Link>
                            ) : (
                                <Link className='disable-content-link' to={'#'}>
                                    <i className="fas fa-hand-holding-usd"></i><span> SUMMARY</span>
                                </Link>
                            )
                        }

                    </li>
                    <li className={OrderComplete ? 'active' : ''}><Link to='#' className="disable-content-link"><i className="fas fa-clipboard-list"></i><span> ORDER COMPLETED </span></Link></li>
                </ul> */}
            </React.Fragment>
        )
    }
}


const mapStateToProp = (state) => {
    return {
        customerList: Actions.getCustomer(state),
    }
}

export default withRouter(connect(mapStateToProp, {
})(ContentHeader));