const NAME = 'SELECTED_FABRICS'
export const UPDATE_SELECTED_SUIT_FABRIC = `${NAME}/UPDATE_SELECTED_SUIT_FABRIC`;
export const SET_DEFAULT_SUIT_FABRIC = `${NAME}/SET_DEFAULT_SUIT_FABRIC`

export const getSelectedSuitFabric = store => store[NAME].suit
export const updateSelectedSuitFabric = (data) => {
    return {
        type: UPDATE_SELECTED_SUIT_FABRIC,
        data
    }
}

export const setDefaultSuitFabrics = (data, key) => {
    return {
        type: SET_DEFAULT_SUIT_FABRIC,
        data,
        key
    }
}