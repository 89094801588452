import Actions from '../action'

const initialState = {
    isLoading: false,
    error: false,
    measurementDetails: {},
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.MEASUREMENT_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.MEASUREMENT_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                measurementDetails: action.data,
                error: false
            };
        }
        case Actions.MEASUREMENT_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.MEASUREMENT_ADD: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.MEASUREMENT_ADD_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                measurementDetails: action.data,
                error: false
            };
        }
        case Actions.MEASUREMENT_ADD_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.MEASUREMENT_DELETE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.MEASUREMENT_DELETE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            };
        }
        case Actions.MEASUREMENT_DELETE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.MEASUREMENT_UPDATE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.MEASUREMENT_UPDATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            };
        }
        case Actions.MEASUREMENT_UPDATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.MEASUREMENT_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.MEASUREMENT_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: false
            };
        }
        case Actions.MEASUREMENT_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        default:
            return state;
    }

};