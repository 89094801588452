const BASE_URL = 'https://ufu01h-my.myshopify.com/api/2024-10/graphql.json'
const s3_URL = "https://cvi-upload-test.s3.us-east-1.amazonaws.com/proglintfashion"
const ADMIN_BASE_URL = 'https://4b970555fd034ea80639c954738d4a27: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@paulsfashion.myshopify.com/admin/api/2021-01/graphql.json'
const S3BASE_URL = `${s3_URL}/canvas/shirt/`
const S3BASE_URL_PANT = `${s3_URL}/canvas/pant/`
const S3BASE_URL_OLD = `${s3_URL}/canvas/`
const S3BASE_URL_SUIT = `${s3_URL}/canvas/suit/`
const S3BASE_URL_VEST = `${s3_URL}/canvas/vest/`
const S3BASE_URL_BLAZER = `${s3_URL}/canvas/blazer/`
export {
    BASE_URL,
    ADMIN_BASE_URL,
    S3BASE_URL,
    S3BASE_URL_OLD,
    S3BASE_URL_PANT,
    S3BASE_URL_SUIT,
    S3BASE_URL_VEST,
    S3BASE_URL_BLAZER
}
