import React from 'react'
import { withRouter } from 'react-router-dom'
import SubFooter from '../../component/SubFooter'
import { CONTENTS, PATH, SERVERIMG } from '../../globals/app-constraints'
import LazyLoad from 'react-lazyload';
import Faqs from '../../component/FAQ';

class Exchange extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        return (
            <React.Fragment>
                <div className="p-0">
                    {/* <div className="main_banner w-100 vh-100 row m-0 position-relative">
                        <div className='overlay-bg'></div>
                        <div className='col-lg-6 p-0 h-100 d-flex align-items-center justify-content-center position-relative'>
                            <LazyLoad className="w-100 h-100 object-fit-cover">
                                <img src={SERVERIMG.image.footer.returnsExchange.bannerLeftImg} className='w-100 h-100 object-fit-cover' alt="blazer-right-img" />
                            </LazyLoad>
                            <div className="corporate-align container position-absolute z-2">
                                <h6 className='exchange_banner_head fs-24 H-cb text-uppercase text-white pt-lg-4 pt-0'>{CONTENTS.returnsExchange.bannerHead}</h6>
                                <p className='exchange_text fs-14 H-l text-white text-start pt-3 pb-5'>
                                    {CONTENTS.returnsExchange.bannerContent}
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-6 p-0 h-100 d-none d-lg-flex align-items-center justify-content-center'>
                            <LazyLoad className="w-100 h-100 object-fit-cover">
                                <img src={SERVERIMG.image.footer.returnsExchange.bannerRightImg} className='w-100 h-100 object-fit-cover' alt="blazer-right-img" />
                            </LazyLoad>
                        </div>
                    </div> */}

                    <div className='blazer_section mx-0 position-relative pb-lg-5 pt-lg-3'>
                        <div className='container pb-lg-5 pb-4'>
                            <div className='exchange_contents pt-lg-5 pt-4'>
                                <div className='d-flex flex-column gap-16'>
                                    <div className="icon-bg rounded-circle d-flex align-items-center">
                                        <LazyLoad className="w-100 d-flex align-items-center justify-content-center">
                                            <img src={PATH?.img?.Tailoring} className='icon_alignment our_story_icon' alt="fit-icon" />
                                        </LazyLoad>
                                    </div>
                                    <p className='H-m fs-16 pt-2 pb-3'>{CONTENTS.returnsExchange.head1}</p>
                                </div>
                                <p className='fs-14 py-1 H-l'>
                                    {CONTENTS.returnsExchange.head1Para1}
                                </p>
                                <p className='fs-14 py-1 H-l'>
                                    {CONTENTS.returnsExchange.head1Para2}
                                </p>
                                <p className='fs-14 py-1 H-l'>
                                    {CONTENTS.returnsExchange.head1Para3}
                                </p>
                                <p className='fs-14 py-1 H-l'><b>{CONTENTS.returnsExchange.head1Para4}</b></p>
                            </div>
                            <div className='exchange_contents pt-lg-4 pt-4'>
                                <div className='d-flex flex-column gap-16'>
                                    <div className="icon-bg rounded-circle d-flex align-items-center">
                                        <LazyLoad className="w-100 d-flex align-items-center justify-content-center">
                                            <img src={PATH?.img?.Remake} className='icon_alignment our_story_icon' alt="fit-icon" />
                                        </LazyLoad>
                                    </div>
                                    <p className='H-m fs-16 pt-2 pb-3'>{CONTENTS.returnsExchange.head2}</p>
                                </div>
                                <p className='fs-14 py-1 H-l'>
                                    {CONTENTS.returnsExchange.head2Para1}
                                </p>
                                <p className='fs-14 py-1 H-l'>
                                    {CONTENTS.returnsExchange.head2Para2}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>



                {/* <div className="col-12 p-0" >
                    <div className="copo container">
                        <h6 className='exchange_banner_head fs-24 H-cb text-uppercase pt-lg-4 pt-0'>Our Fit Guarantee</h6>
                        <p className='exchange_text fs-16 H-l text-dark text-start pt-3 pb-5'>
                            All garments produced under the Paul’s Fashion umbrella adhere to
                            a strict quality protocol. We strive to ensure a perfect fit every time.
                        </p>
                    </div>
                    <div className='blazer_section mx-0 position-relative corporate-bg'>
                        <div className='col-lg-6 p-0 h-100 d-flex align-items-center justify-content-center home_img_section_bg_color_change corporate-service-align'>
                            <LazyLoad className="w-100 h-100 object-fit-cover">
                                <img src={PATH?.img?.ReturnBgImg} className='w-100 h-100 object-fit-cover' alt="blazer-right-img" />
                            </LazyLoad>
                        </div>
                        <div className='container pb-lg-5 pb-4'>
                            <div className='row m-0'>
                                <div className='col-lg-6 ps-0 pe-4'>
                                    <div className='exchange_contents pt-lg-5 pt-4'>
                                        <div className='d-flex flex-column gap-16'>
                                            <div className="icon-bg rounded-circle d-flex align-items-center">
                                                <LazyLoad className="w-100 d-flex align-items-center justify-content-center">
                                                    <img src={PATH?.img?.Tailoring} className='icon_alignment our_story_icon' alt="fit-icon" />
                                                </LazyLoad>
                                            </div>
                                            <p className='H-m fs-20'>Alterations</p>
                                        </div>
                                        <p className='fs-16 py-1 H-l'>
                                            If the product is near-perfect but requires a few adjustments to become
                                            a home-run, we recommend taking it to your local tailor for an alteration.
                                        </p>
                                        <p className='fs-16 py-1 H-l'>
                                            Ask your tailor to provide you with an invoice/receipt for the cost of the alteration.
                                        </p>
                                        <p className='fs-16 py-1 H-l'>
                                            Please send us a clear picture of the invoice via email, our socials, or WhatsApp.
                                            Make sure to include the order number in your message.
                                        </p>
                                        <p className='fs-16 py-1 H-l'><b>We will reimburse you up to 25% of the order value.</b></p>
                                    </div>
                                    <div className='exchange_contents pt-lg-4 pt-4'>
                                        <div className='d-flex flex-column gap-16'>
                                            <div className="icon-bg rounded-circle d-flex align-items-center">
                                                <LazyLoad className="w-100 d-flex align-items-center justify-content-center">
                                                    <img src={PATH?.img?.Remake} className='icon_alignment our_story_icon' alt="fit-icon" />
                                                </LazyLoad>
                                            </div>
                                            <p className='H-m fs-20'>Remakes</p>
                                        </div>
                                        <p className='fs-16 py-1 H-l'>
                                            In the rare event that your garment cannot be altered, we will remake it for you. Now, we're talking pigs flying rare - it's unlikely but possible.
                                        </p>
                                        <p className='fs-16 py-1 H-l'>
                                            Tell us what went wrong and how we can fix it, and we'll get cracking on constructing your new garment from scratch.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <SubFooter />
            </React.Fragment>
        )
    }
}

export default withRouter(Exchange)