export const CHECKOUT_DETAILS = (checkoutId) => {
    return `{
      node(id: "${checkoutId}") {
        id
        ... on Checkout {
         id
            customAttributes {
              key
              value
            }
            appliedGiftCards {
              amountUsed
              balance
              id
              lastCharacters
              presentmentAmountUsed {
                amount
                currencyCode
              }
            }
            totalPrice {
              amount
              currencyCode
            }
            currencyCode
            shippingLine {
              price
              title
              handle
            }
            lineItemsSubtotalPrice {
              amount
              currencyCode
            }
            lineItems(first: 10) {
              edges {
                node {
                  customAttributes {
                    key
                    value
                  }
                  id
                  quantity
                  title
                  unitPrice {
                    amount
                  }
                  variant {
                    id
                    price
                    image {
                      id
                      src
                      altText
                    }
                  }
                }
              }
            }
        }
      }
    }`
}
