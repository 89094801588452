import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { S3BASE_URL_PANT } from '../../config/api_endpoint'
import Loader from '../Loader'
import { PANT_TRANSPARENT } from '../../config/constant'
import { convertAdminProductId } from '../../util/event'
import { FabricsDetails } from '../SelectedProducts/fabrics_details'
import PantZoom from '../Zoom/pant'
import { Link } from 'react-router-dom'
import { CONTENTS, PATH } from '../../globals/app-constraints'
import CustomHeader from '../Header/customHeader'
import { toast } from 'react-toastify'
import { CUSTOMER_ASSOCIATE, LINE_ITEMS_ADD, LINE_ITEMS_UPDATE } from '../../Queries'
import { PANT_CHECKOUT } from '../ProductCheckout'
import { Constants } from '../../util/Constant'
import { store } from '../../createStore'


class PantSection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedCombination: undefined,
            isShowFront: true,
            isShowFolded: false,
            isShowBack: false,
            isShowSide: false,
            isLoading: true,
            isLoadingStarted: false,
            front: 8,
            back: 6,
            folded: 5,
            isShowCasual: false,
            isFabricModal: false,
            isZoomModal: false
        }
    }

    openZoomModal() {
        this.setState({ isZoomModal: true })
    }
    closeZoomModal() {
        this.setState({ isZoomModal: false })
    }
    onloadImageSuccess() {
        this.onloadImages()
    }

    onloadImageError() {
        this.onloadImages()
    }

    onloadImages() {
        const { isShowFront, isShowBack, isShowFolded, front, back, folded } = this.state;
        if (isShowFront) {
            if (front === 1) {
                this.setState({ isLoading: false, front: 8 })
                return;
            } else {
                this.setState({ front: front - 1 })
            }
        } else if (isShowBack) {
            if (back === 1) {
                this.setState({ isLoading: false, back: 6 })
                return;
            } else {
                this.setState({ back: back - 1 })
            }
        } else if (isShowFolded) {
            if (folded === 1) {
                this.setState({ isLoading: false, folded: 5 })
                return;
            } else {
                this.setState({ folded: folded - 1 })
            }
        }
    }
    closeModal() {
        this.setState({ isFabricModal: false })
    }
    openModal() {
        this.setState({ isFabricModal: true })
    }
    static getDerivedStateFromProps(props, state) {
        const { selectedPantStyle: { selectedCombination }, activeChildMenu,
            selectedPosition, selectedPantFabric: { selectedPantFabric } } = props;
        let { productId } = selectedPantFabric || {}
        let basepath = S3BASE_URL_PANT + '6695021215908/'
        if (productId !== 'undefined') {
            basepath = S3BASE_URL_PANT + convertAdminProductId(productId)
        }
        let isShowFolded = false
        let isShowBack = false
        let isShowFront = true
        let selectedCombinationObj = {
            frontModal: S3BASE_URL_PANT + 'modal/front/model.png',
            frontShirt: S3BASE_URL_PANT + 'modal/front/shirt.png',
            frontShoe: S3BASE_URL_PANT + 'modal/front/shoe.png',
            backModal: S3BASE_URL_PANT + 'modal/back/model.png',
            backShirt: S3BASE_URL_PANT + 'modal/back/shirt.png',
            backShoe: S3BASE_URL_PANT + 'modal/back/shoe.png',
            foldedPantFit: basepath + 'fit/folded/normal_fit.png',
            frontPantFit: basepath + 'fit/front/normal_fit.png',
            backPantFit: basepath + 'fit/back/normal_fit.png',
            backCuff: basepath + 'cuff/back/normal_fit_cuff.png',
            frontCuff: basepath + 'cuff/front/normal_fit_cuff.png',
            foldedCuff: basepath + 'cuff/folded/normal_cuff.png',
            backPocket: basepath + 'pocket/back/double_welted.png',
            frontFastening: basepath + 'fastening/front/centered.png',
            frontPleats: basepath + 'pleats/front/pleated.png',
            foldedPleats: basepath + 'pleats/folded/pleated.png',
            frontPocket: basepath + 'pocket/front/rounded.png',
            foldedSidePocket: basepath + 'pocket/folded/rounded.png',
            foldedPocket: basepath + 'pocket/folded/double_welted.png'
        }
        switch (selectedPosition) {
            case 'front':
                isShowFolded = false
                isShowBack = false
                isShowFront = true
                break;
            case 'folded':
                isShowFolded = true
                isShowBack = false
                isShowFront = false
                break;
            case 'back':
                isShowFolded = false
                isShowBack = true
                isShowFront = false
                break;
            default:
                break;
        }
        Object.keys(selectedCombination).forEach((key) => {
            switch (key) {
                case 'cuff':
                    if (selectedCombination['fit'].key === 'normal_fit') {
                        selectedCombinationObj = {
                            ...selectedCombinationObj,
                            foldedCuff: selectedCombination[key].key === 'no_cuffs' ? basepath + PANT_TRANSPARENT : basepath + selectedCombination[key].folded_image_url + 'normal_cuff.png',
                            frontCuff: selectedCombination[key].key === 'no_cuffs' ? basepath + PANT_TRANSPARENT : basepath + selectedCombination[key].front_image_url + 'normal_fit_cuff.png',
                            backCuff: selectedCombination[key].key === 'no_cuffs' ? basepath + PANT_TRANSPARENT : basepath + selectedCombination[key].back_image_url + 'normal_fit_cuff.png'
                        }
                    } else {
                        selectedCombinationObj = {
                            ...selectedCombinationObj,
                            foldedCuff: selectedCombination[key].key === 'no_cuffs' ? basepath + PANT_TRANSPARENT : basepath + selectedCombination[key].folded_image_url + 'slim_cuff.png',
                            frontCuff: selectedCombination[key].key === 'no_cuffs' ? basepath + PANT_TRANSPARENT : basepath + selectedCombination[key].front_image_url + 'slim_fit_cuff.png',
                            backCuff: selectedCombination[key].key === 'no_cuffs' ? basepath + PANT_TRANSPARENT : basepath + selectedCombination[key].back_image_url + 'slim_fit_cuff.png'
                        }
                    }

                    break;
                case 'pocket':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedPocket: selectedCombination[key].key === 'no_pocket' ? basepath + PANT_TRANSPARENT : basepath + selectedCombination[key].folded_image_url,
                        backPocket: selectedCombination[key].key === 'no_pocket' ? basepath + PANT_TRANSPARENT : basepath + selectedCombination[key].back_image_url,
                    }
                    break;
                case 'side_pocket':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedSidePocket: basepath + selectedCombination[key].folded_image_url,
                        frontPocket: basepath + selectedCombination[key].front_image_url,
                    }
                    break;
                case 'fit':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedPantFit: basepath + selectedCombination[key].folded_image_url,
                        frontPantFit: basepath + selectedCombination[key].front_image_url,
                        backPantFit: basepath + selectedCombination[key].back_image_url
                    }
                    break;
                case 'fastening':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedFastening: basepath + selectedCombination[key].front_normal_fit_tucked,
                        frontFastening: basepath + selectedCombination[key].front_image_url,
                    }
                    break;
                case 'pleats':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedPleats: selectedCombination[key].key === 'no_pleats' ? basepath + PANT_TRANSPARENT : basepath + selectedCombination[key].folded_image_url,
                        frontPleats: selectedCombination[key].key === 'no_pleats' ? basepath + PANT_TRANSPARENT : basepath + selectedCombination[key].front_image_url,
                    }
                    break;
                default:
                    break;
            }
        })
        return {
            selectedCombination: selectedCombinationObj,
            activeChildMenu,
            isShowFolded: isShowFolded,
            isShowFront: isShowFront,
            isShowBack: isShowBack,
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if ((this.props.selectedPosition !== prevProps.selectedPosition) || (this.props.selectedPantStyle !== prevProps.selectedPantStyle) || (this.props.selectedPantFabric !== prevProps.selectedPantFabric)) {
            this.setState({ isLoadingStarted: true, isLoading: true }, () => {
                this.setState({ isLoadingStarted: false })
            })
        }
    }
    showFront() {
        this.props.onCanvasPositionChanged('front')
        this.setState({ isLoadingStarted: true, isLoading: true }, () => {
            this.setState({ isLoadingStarted: false })
        })
    }
    showFolded() {
        this.props.onCanvasPositionChanged('folded')
        this.setState({ isLoadingStarted: true, isLoading: true }, () => {
            this.setState({ isLoadingStarted: false })
        })
    }
    showBack() {
        this.props.onCanvasPositionChanged('back')
        this.setState({ isLoadingStarted: true, isLoading: true }, () => {
            this.setState({ isLoadingStarted: false })
        })
    }
    _renderPrevCarousel(isShowFront, isShowFolded, isShowBack) {
        if (isShowFolded) {
            this.showBack()
        } else if (isShowBack) {
            this.showFront()
        } else if (isShowFront) {
            this.showFolded()
        }
    }
    _renderNextCarousel(isShowFront, isShowFolded, isShowBack) {
        if (isShowFolded) {
            this.showFront()
        } else if (isShowBack) {
            this.showFolded()
        } else if (isShowFront) {
            this.showBack()
        }
    }

    navCartPage() {
        let { token: { accessToken } } = this.props.token || {}
        let { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        let { lineItems } = lastIncompleteCheckout || {}
        if (!accessToken) {
            this.props.history.push('/login')
        } else if (accessToken && !lineItems || lineItems?.edges?.length === 0) {
            this.props.history.push('/canvas/shirt?fabric')
        } else {
            this.props.history.push('/cart')
        }

    }

    resetCanvas() {
        this.props.resetStyle()
    }
    customerAssociate(checkoutId) {
        let { token: { accessToken } } = this.props.token
        let input = {
            checkoutId: checkoutId,
            customerAccessToken: accessToken
        }
        const callback = (response) => {
            let { customer } = response?.checkoutCustomerAssociateV2 || {}
            if (!customer) {
                toast.error(`Something went wrong ,try again later`, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            } else {
                this.props.history.push('/cart')
            }
        }
        this.props.checkoutCustomerAssociate(CUSTOMER_ASSOCIATE, input, callback)
    }
    checkoutPant() {
        const { edit } = this.props.match.params
        const { selectedMeasurement } = this.props.getMeasurement
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        let { selectedPantFabric } = this.props.selectedPantFabric
        const { selectedCombination } = this.props.selectedPantStyle
        let { token: { accessToken } } = this.props.token || {}
        const checkoutId = lastIncompleteCheckout ? lastIncompleteCheckout?.id : this.props.getNewCheckOutId

        let Measurements = {
            value: selectedMeasurement,
            type: Constants.MEASUREMENT_TYPES.CUSTOMER_MEASUREMENT_SIZE,
            productType: Constants.PRODUCTS.PANT
        }
        let customAttributes = PANT_CHECKOUT(selectedCombination, selectedPantFabric, Measurements)
        let lineItems;
        if (edit) {
            lineItems = [{
                quantity: 1,
                variantId: selectedPantFabric?.id,
                id: selectedPantFabric?.updated_line_item_id,
                customAttributes: customAttributes
            }]
        } else {
            lineItems = [{
                quantity: 1,
                variantId: selectedPantFabric?.id,
                customAttributes: customAttributes
            }]
        }
        let input = {
            lineItems,
            checkoutId: checkoutId,
        }
        const callback = (response) => {
            let { checkoutLineItemsAdd } = response || {}
            if (accessToken && checkoutLineItemsAdd) {
                if (!lastIncompleteCheckout) {
                    this.customerAssociate(checkoutId)
                } else {
                    this.props.history.push('/cart')
                }
            } else {
                this.props.history.push('/cart')
            }
        }
        if (edit) {
            this.props.checkoutLineItemsUpdate(LINE_ITEMS_UPDATE, input, callback)
        } else {
            this.props.checkoutLineItemsAdd(LINE_ITEMS_ADD, input, callback)

        }

    }
    onNextStep() {
        let { token = {} } = this.props.token
        let { accessToken } = token || {}
        const { history: { location: { search } }, isPant,
            isShirt, isSuit, isVest, isBlazer
        } = this.props || {}
        console.log("d", this.props);
        switch (search) {
            case '?fabric':
                this.props.history.push(`${this.props.location.pathname}?style`)
                break;
            case '?style':
                if (isPant)
                    this.props.history.push(`${this.props.location.pathname}?measure`)
                else
                    this.props.history.push(`${this.props.location.pathname}?accent`)
                break;
            case '?accent':
                this.props.history.push(`${this.props.location.pathname}?measure`)
                break;
            case '?measure':
                this.props.history.push(`${this.props.location.pathname}?summary`)
                break;
            case '?summary':
                if (!accessToken) {
                    this.props.history.push("/login")
                } else {
                    if (isPant) {
                        this.checkoutPant()
                    }
                }
        }
    }
    render() {
        const isHomePage = this.props.location.pathname === '/';
        const { isScrolled } = this.state;
        const { selectedCombination, isShowFolded, isShowFront, isShowBack, isLoadingStarted, isLoading, isFabricModal, isZoomModal } = this.state;
        const { backModal, backShirt, backShoe, backPantFit, backCuff, backPocket, frontModal, frontShirt, frontShoe, frontPantFit, frontCuff,
            frontFastening, frontPleats, frontPocket, foldedPantFit, foldedCuff, foldedPleats, foldedPocket, foldedSidePocket } = selectedCombination || {}
        const { selectedPantFabric } = this.props.selectedPantFabric
        let { location } = this.props
        let { search } = location
        return (
            <React.Fragment>
                <div className="order-1 order-sm-1 order-lg-2 verify-casual h-100">
                    {isLoading && <Loader
                        isCanvas={true} />}
                    <div id="carouselExampleIndicators" className="carousel slide text-center text-center"
                        data-interval="false" data-ride="carousel">
                        <ol className="carousel-indicators">
                            <li data-target="#carouselExampleIndicators" title="FRONT" onClick={() => this.showFront()} className={`${isShowFront ? 'active' : ''}`}></li>
                            <li data-target="#carouselExampleIndicators" title="BACK" onClick={() => this.showBack()} className={`${isShowBack ? 'active' : ''}`}></li>
                            <li data-target="#carouselExampleIndicators" title="FOLDED" onClick={() => this.showFolded()} className={`${isShowFolded ? 'active' : ''}`}></li>
                        </ol>
                        {selectedCombination && (
                            <div className='carousel-inner pant_canvas' data-interval="false">
                                <p className='canvas-finish H-cb fs-16' onClick={() => this.onNextStep()}>{search === '?summary' ? "Finish" : "Next"}</p>
                                <CustomHeader />
                                <div className={`carousel-item ${isShowFront ? 'active' : ''}`}>
                                    <div className="pi-shirt-containers">
                                        {(!isLoadingStarted && isShowFront) &&
                                            <div className={`pi_container pi-pant w-75 m-auto pi_pant_front ${isLoading && 'v-hide'}`}>
                                                <img className="custom_shirt shirt_man"
                                                    src={frontModal}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_sleeve"
                                                    src={frontShirt}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_pant"
                                                    src={frontShoe}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_fit"
                                                    src={frontPantFit}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_cuff_fabric"
                                                    src={frontCuff}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_fastening"
                                                    src={frontFastening}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_collar_fabric"
                                                    src={frontPleats}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_button"
                                                    src={frontPocket}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                            </div>}
                                    </div>
                                </div>
                                <div className={`carousel-item ${isShowBack ? 'active' : ''}`}>
                                    <div className="pi-shirt-containers">
                                        {(!isLoadingStarted && isShowBack) &&
                                            <div className={`pi_container pi-pant w-75 m-auto pi_pant_back ${isLoading && 'v-hide'} `}>
                                                <img className="custom_shirt shirt_back"
                                                    src={backModal}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_back_shirt_sleeve"
                                                    src={backShirt}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_back_pant"
                                                    src={backShoe}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_back_shirt_fit"
                                                    src={backPantFit}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_back_collar_fabric"
                                                    src={backCuff}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt shirt_back_shirt_pocket"
                                                    src={backPocket}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className={`carousel-item ${isShowFolded ? 'active' : ''}`}>
                                    <div className="pi-shirt-containers">
                                        {(!isLoadingStarted && isShowFolded) &&
                                            <div className={`pi_container pi-pant folded-pant w-75 m-auto pi_pant_cuff ${isLoading && 'v-hide'} `}>
                                                <img className="custom_shirt folded_fit"
                                                    src={foldedPantFit}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_cuff"
                                                    src={foldedCuff}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_pleats"
                                                    src={foldedPleats}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt'
                                                />
                                                <img className="custom_shirt folded_pocket"
                                                    src={foldedPocket}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_side_pocket"
                                                    src={foldedSidePocket}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='canvas_details_icons d-flex flex-column align-items-center gap-12'>
                                    <div onClick={() => this.openZoomModal()}>
                                        <div className='icons_name'>
                                            <img src={PATH?.img?.ZoomIn} alt='zoom-in' />
                                        </div>
                                        <p className='H-l fs-11 text_grey canvas-data-hide'>{CONTENTS.canvas.zoom}</p>
                                    </div>
                                    <div className='d-flex flex-column align-items-center' onClick={() => this.openModal()}>
                                        <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#FabricDetail">
                                            <div className='icons_name'>
                                                <img src={PATH?.img?.FabricCanvasIcon} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" alt='fabric-icon' />
                                            </div>
                                        </button>
                                        {/* <button className="btn" type="button"
                                            data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                            <div className='icons_name'>
                                                <img src={PATH?.img?.FabricCanvasIcon} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" alt='fabric-icon' />
                                            </div>
                                        </button> */}
                                        <p className='H-l fs-11 text_grey canvas-data-hide'>{CONTENTS.canvas.fabricDetails}</p>
                                    </div>
                                    <div className='d-flex flex-column align-items-center' onClick={() => this.resetCanvas()}>
                                        <div className='icons_name'>
                                            <img src={PATH?.img?.ResetIcon} alt='zoom-in' />
                                        </div>
                                        <p className='H-l fs-11 text_grey canvas-data-hide'>{CONTENTS.canvas.reset}</p>
                                        {/* <i className="bi bi-arrow-clockwise canvas_icon_color fs-16"></i> */}
                                    </div>
                                </div>
                                {/* <ul className="nw_zoom">
                                    <li className="cursor-pointer" onClick={() => this.openZoomModal()}> <img src={require('../../asset/images/zoom-in.svg')} className="img-fluid" alt="icon" /> <p>Zoom</p> </li>
                                    <li className="cursor-pointer" onClick={() => this.openModal()}> <img src={require('../../asset/images/fabric.svg')} className="img-fluid" alt="icon" /> <p>Fabric</p> </li>
                                    <li className="cursor-pointer" onClick={() => this.resetCanvas()}>
                                        <img src={require('../../asset/images/reset.png')} className="img-fluid" alt="icon" />
                                        <p>Reset</p>
                                    </li>
                                </ul> */}
                            </div >
                        )
                        }
                        <span className="carousel-control-prev" role="button" data-slide="prev"
                            onClick={() => this._renderPrevCarousel(isShowFront, isShowFolded, isShowBack)}>
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </span>
                        <span className="carousel-control-next" role="button" data-slide="next" onClick={() => this._renderNextCarousel(isShowFront, isShowFolded, isShowBack)}>
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </span>
                    </div>
                    <FabricsDetails
                        openFabricDesign={isFabricModal}
                        closeModal={() => this.closeModal()}
                        selectedFabric={selectedPantFabric}
                    />
                    <PantZoom
                        selectedCombination={selectedCombination}
                        openModal={isZoomModal}
                        closeModal={() => this.closeZoomModal()}
                        isShowBack={isShowBack}
                        isShowFolded={isShowFolded}
                        isShowFront={isShowFront}
                    />
                </div >
            </React.Fragment >
        )
    }
}

const mapStateToProp = (state) => {
    return {
        selectedPantStyle: Actions.getSelectedPantStyle(state),
        selectedPantFabric: Actions.getSelectedPantFabric(state),
        getMeasurement: Actions.getSelectedMeasurement(store.getState()) || {},
        getCheckoutDetails: Actions.getCheckoutId(store.getState()),
        token: Actions.getTokenSelector(store.getState()) || {},

    }
}

export default withRouter(connect(mapStateToProp, {
    resetStyle: Actions.resetStyle,
    checkoutLineItemsUpdate: Actions.checkoutLineItemsUpdate,
    checkoutLineItemsAdd: Actions.checkoutLineItemsAdd,
    checkoutCustomerAssociate: Actions.checkoutCustomerAssociate,

})(PantSection));
