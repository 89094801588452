import React from 'react'
import { withRouter } from 'react-router-dom';

class MonogramPosition extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        const { monogramPosition, moveMonogramPosition, sleeve } = this.props
        return (
            <React.Fragment>
                <div className="mt-2">
                    <p className="H-l fs-13 text-lowercase pb-2">Initials Position:</p>
                    <div className='d-flex gap-2'>
                        <div className={`style_type ${monogramPosition && monogramPosition === 'high' ? 'active_type' : ''}`}
                            onClick={(e) => moveMonogramPosition('high', e)}>
                            <p className='px-2 H-l fs-13 text-lowercase'>High</p>
                        </div>
                        {/* <li className="col-lg-6">
                        <div className="form-check">
                            <input className="form-check-input"
                                checked={monogramPosition && monogramPosition === 'high'} type="checkbox"
                                onChange={(e) => moveMonogramPosition('high', e)} />
                            <label className="form-check-label">
                                HIGH
                            </label>
                        </div>
                    </li> */}
                        {
                            sleeve?.key === 'long' && <div className={`style_type ${monogramPosition && monogramPosition === 'cuff' ? 'active_type' : ''}`}
                            onClick={(e) => moveMonogramPosition('cuff', e)}>
                                <p className='px-2 H-l fs-13 text-lowercase'>Cuff</p>
                            </div>
                            // <li className="col-lg-6">
                            //     <div className="form-check">
                            //         <input className="form-check-input"
                            //             checked={monogramPosition && monogramPosition === 'cuff'} type="checkbox"
                            //             onChange={(e) => moveMonogramPosition('cuff', e)} />
                            //         <label className="form-check-label">
                            //             CUFF
                            //         </label>
                            //     </div>
                            // </li>
                        }
                    </div>
                </div>

            </React.Fragment>
        )
    }

}

export default withRouter(MonogramPosition)