import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { store } from '../../createStore'
import YourAddress from '../../component/YourAddress'
import { LAST_INCOMPLETE_CHECK_OUT_ID } from '../../Queries'
import 'react-toastify/dist/ReactToastify.css';
import ContentHeader from '../../component/ContentHeader'
import CustomerAddress from '../../component/YourAddress/customerAddress'
class Cart extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: {},
            isLoginModal: false,
            errors: {},
            giftCode: ''
        }
    }
    componentDidMount() {
        let { token = {} } = this.props.token
        let { accessToken } = token || {}
        if (accessToken) {
            this.props.getRecentCheckOutDetails(LAST_INCOMPLETE_CHECK_OUT_ID(accessToken))
        } else {
            this.props.history.push('/')
        }
    }
    _renderRootAddress() {
        const { isCheckout } = this.props
        if (isCheckout) {
            return this._renderCustomerShippingAddress()
        } else {
            return this._renderCustomerAddress()
        }
    }
    _renderCustomerAddress() {
        const { customerList } = this.props.customerList || {}
        let { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        return (
            <div className="col-lg-12 shopping_crt">

                <ContentHeader Address={true} />
                {/* <CustomerAddress
                    customerList={customerList && customerList.data}
                    lastIncompleteCheckout={lastIncompleteCheckout}
                     /> */}
                <YourAddress
                    lastIncompleteCheckout={lastIncompleteCheckout} />
            </div>
        )
    }
    _renderCustomerShippingAddress() {
        let { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        let { lineItems } = (lastIncompleteCheckout && lastIncompleteCheckout) || {}
        return (
            <div className="col-lg-12 shopping_crt">
                <ContentHeader Address={true} />
                {
                    lineItems && lineItems.edges.length > 0 && (
                        <YourAddress
                            lastIncompleteCheckout={lastIncompleteCheckout} />
                    )
                }

            </div>
        )
    }
    render() {
        return (
            <>
                {this._renderRootAddress()}
            </>
        )
    }
}
const mapStateToProp = (state) => {
    return {
        token: Actions.getTokenSelector(store.getState()) || {},
        getCheckoutDetails: Actions.getCheckoutId(store.getState()),
        customerList: Actions.getCustomer(state),
    }
}

export default withRouter(connect(mapStateToProp, {
    getRecentCheckOutDetails: Actions.getRecentCheckOutDetails,
})(Cart));