import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { store } from '../../createStore'
import { LINE_ITEMS_REMOVE, LAST_INCOMPLETE_CHECK_OUT, LINE_ITEMS_UPDATE, CUSTOMER_LIST, CHECKOUT_DETAILS } from '../../Queries'
import { toast } from 'react-toastify'
import { selectedShirtJSON, selectedPantJSON, selectedSuitJSON, selectedVestJSON, selectedBlazerJSON } from '../../util/defaultFabricObject'
import { S3BASE_URL, S3BASE_URL_BLAZER, S3BASE_URL_PANT, S3BASE_URL_SUIT } from '../../config/api_endpoint'
import { detectBrowser } from '../../util/detect-browser'
import Loader from '../Loader'
import CartModal from '../CartModal'
import { convertAdminProductId } from '../../util/event'
import { CONTENTS, PATH } from '../../globals/app-constraints'
class CartProduct extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            cartEmptyMessage: '',
            fields: {},
            customAttributes: [],
            shirtLoading: 12,
            pantLoading: 5,
            suitLoading: 16,
            blazerLoading: 15,
            vestLoading: 18,
            vestLoader: false,
            shirtLoader: false,
            suitLoader: false,
            pantLoader: false,
            blazerLoader: false,
            isModalOpen: false,

        }
    }

    onloadImageSuccess(type) {
        if (type === 'shirt') {
            this.shirtOnloadImages()
        } else if (type === 'pant') {
            this.pantOnloadImages()
        } else if (type === 'suit') {
            this.suitOnloadImages()
        } else if (type === 'blazer') {
            this.blazerOnloadImages()
        } else if (type === 'vest') {
            this.suitVestOnloadImages()
        } else {
            this.shirtOnloadImages()
        }
    }

    onloadImageError(type) {
        if (type === 'shirt') {
            this.shirtOnloadImages()
        } else if (type === 'pant') {
            this.pantOnloadImages()
        } else if (type === 'suit') {
            this.suitOnloadImages()
        } else if (type === 'blazer') {
            this.blazerOnloadImages()
        } else if (type === 'vest') {
            this.suitVestOnloadImages()
        } else {
            this.shirtOnloadImages()
        }
    }

    shirtOnloadImages() {
        const { shirtLoading } = this.state
        if (shirtLoading === 1) {
            this.setState({ shirtLoader: false, shirtLoading: 13 })
            return;
        } else {
            this.setState({ shirtLoading: shirtLoading - 1 })
        }
    }

    pantOnloadImages() {
        const { pantLoading } = this.state
        if (pantLoading === 1) {
            this.setState({ pantLoader: false, pantLoading: 5 })
            return;
        } else {
            this.setState({ pantLoading: pantLoading - 1 })
        }
    }

    suitOnloadImages() {
        const { suitLoading } = this.state
        if (suitLoading === 1) {
            this.setState({ suitLoader: false, suitLoading: 16 })
            return;
        } else {
            this.setState({ suitLoading: suitLoading - 1 })
        }
    }

    blazerOnloadImages() {
        const { blazerLoading } = this.state
        if (blazerLoading === 1) {
            this.setState({ blazerLoader: false, blazerLoading: 15 })
            return;
        } else {
            this.setState({ blazerLoading: blazerLoading - 1 })
        }
    }

    suitVestOnloadImages() {
        const { vestLoading } = this.state
        if (vestLoading === 1) {
            this.setState({ vestLoader: false, vestLoading: 18 })
            return;
        } else {
            this.setState({ vestLoading: vestLoading - 1 })
        }
    }


    editCustomize(lineItem, customAttributes) {
        let { key } = customAttributes && customAttributes[0]
        const urlWithoutQueryString = convertAdminProductId(lineItem).split('?')[0];
        let lineItemId = urlWithoutQueryString ? urlWithoutQueryString : lineItem
        if (key === 'SelectedShirt') {
            let selectedShirt = selectedShirtJSON(customAttributes, S3BASE_URL)
            let { selectedAccents, selectedFabrics,
                selectedCombinationDetails, measurement } = selectedShirt || {}
            if (selectedCombinationDetails) {
                this.props.editShirtStyle(selectedCombinationDetails)
            }
            selectedFabrics['lineItemId'] = lineItemId
            selectedFabrics['updated_line_item_id'] = lineItem
            this.props.editShirtAccent(selectedAccents)
            this.props.updateShirtFabric(selectedFabrics)
            this.props.updateSelectedMeasurement(measurement)
            this.props.history.push(`/canvas/shirt/${lineItemId}?fabric`)
        } else if (key === 'SelectedPant') {
            let selectedPant = selectedPantJSON(customAttributes, S3BASE_URL_PANT)
            let { selectedFabrics, selectedCombinationDetails, measurement } = selectedPant || {}
            if (selectedCombinationDetails) {
                this.props.editSelectedPantCombination(selectedCombinationDetails)
            }
            selectedFabrics['lineItemId'] = lineItemId
            selectedFabrics['updated_line_item_id'] = lineItem
            this.props.updateSelectedPantFabric(selectedFabrics)
            this.props.updateSelectedMeasurement(measurement)
            this.props.history.push(`/canvas/pant/${lineItemId}?fabric`)
        } else if (key === 'SelectedSuit') {
            let selectedSuit = selectedSuitJSON(customAttributes, S3BASE_URL)
            let { selectedSuitAccents, selectedFabrics, selectedCombination, measurement } = selectedSuit || {}
            if (selectedCombination) {
                this.props.updateSelectedSuitStyle(selectedCombination)
            }
            selectedFabrics['lineItemId'] = lineItemId
            selectedFabrics['updated_line_item_id'] = lineItem
            this.props.updateSelectedSuitFabric(selectedFabrics)
            this.props.updateSelectedSuitAccent(selectedSuitAccents)
            this.props.updateSelectedMeasurement(measurement)
            this.props.history.push(`/canvas/suit/${lineItemId}?fabric`)
        } else if (key === 'SelectedBlazer') {
            let selectedBlazer = selectedBlazerJSON(customAttributes, S3BASE_URL)
            let { selectedSuitAccents,
                selectedFabrics, selectedCombination, measurement } = selectedBlazer || {}
            if (selectedCombination) {
                this.props.editBlazerStyle(selectedCombination)
            }
            selectedFabrics['lineItemId'] = lineItemId
            selectedFabrics['updated_line_item_id'] = lineItem
            this.props.updateSelectedBlazerFabric(selectedFabrics)
            this.props.editSelectedBlazerAccent(selectedSuitAccents)
            this.props.updateSelectedMeasurement(measurement)
            this.props.history.push(`/canvas/blazer/${lineItemId}?fabric`)
        } else if (key === 'selected3PieceSuit') {
            let selectedSuit = selectedVestJSON(customAttributes, S3BASE_URL)
            let { selectedFabrics,
                vestsFabrics, selectedSuitAccents,
                selectedCombination, selectedVestCombination,
                selectedVestAccents, measurement } = selectedSuit || {}
            if (selectedCombination) {
                this.props.updateSelectedSuitStyle(selectedCombination)
            }
            if (selectedVestCombination) {
                this.props.editSelectedVestCombination(selectedVestCombination)
            }
            vestsFabrics['lineItemId'] = lineItemId
            vestsFabrics['updated_line_item_id'] = lineItem
            this.props.updateSelectedSuitFabric(selectedFabrics)
            this.props.updateSelectedSuitAccent(selectedSuitAccents)
            this.props.updateVestFabric(vestsFabrics)
            this.props.editSelectedVestAccent(selectedVestAccents)
            this.props.updateSelectedMeasurement(measurement)
            this.props.history.push(`/canvas/vests/${lineItemId}?fabric`)
        }
    }


    loginLineItemsRemove(id) {
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        const callback = (response) => {
            let { checkout } = response && response.checkoutLineItemsRemove
            if (checkout === null) {
                toast.error(`Something Went Wrong ,Try Again Later`, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            } else {
                toast.success('Product Removed Successfully ', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                let { token = {} } = this.props.token
                let { accessToken } = token || {}
                let query = CUSTOMER_LIST(accessToken)
                this.props.getCustomerList(query)
                this.props.getRecentCheckOutDetails(LAST_INCOMPLETE_CHECK_OUT(accessToken))
            }
        }
        let input = {
            checkoutId: lastIncompleteCheckout && lastIncompleteCheckout.id,
            lineItemIds: [id]
        }
        this.props.checkoutLineItemsRemove(LINE_ITEMS_REMOVE, input, callback)
    }


    lineItemsRemove(id) {
        const callback = (response) => {
            let { checkout } = response && response.checkoutLineItemsRemove
            if (checkout === null) {
                toast.error(`Something Went Wrong ,Try Again Later`, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            } else {
                toast.success('Product Removed Successfully ', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                this.props.checkOutDetails(CHECKOUT_DETAILS(this.props.getNewCheckOutId))
            }
        }
        let input = {
            checkoutId: this.props.getNewCheckOutId,
            lineItemIds: [id]
        }
        this.props.checkoutLineItemsRemove(LINE_ITEMS_REMOVE, input, callback)
    }

    loginQuantityMinus = (variantId, quantity, id) => {
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        const callback = (response) => {
            let { checkout = {} } = response && response.checkoutLineItemsUpdate
            if (checkout === null) {
                toast.error(`Something Went Wrong ,Try Again Later`, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            } else {
                let { token = {} } = this.props.token
                let { accessToken } = token || {}
                let query = CUSTOMER_LIST(accessToken)
                this.props.getCustomerList(query)
                this.props.getRecentCheckOutDetails(LAST_INCOMPLETE_CHECK_OUT(accessToken))
            }
        }
        let lineItems = [{
            quantity: quantity - 1,
            id: id,
            variantId: variantId
        }]
        let input = {
            checkoutId: lastIncompleteCheckout && lastIncompleteCheckout.id,
            lineItems

        }
        this.props.checkoutLineItemsUpdate(LINE_ITEMS_UPDATE, input, callback)
    }


    quantityMinus = (variantId, quantity, id) => {
        const callback = (response) => {
            let { checkout = {} } = response && response.checkoutLineItemsUpdate
            if (checkout === null) {
                toast.error(`Something Went Wrong ,Try Again Later`, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            } else {
                this.props.checkOutDetails(CHECKOUT_DETAILS(this.props.getNewCheckOutId))
            }
        }
        let lineItems = [{
            quantity: quantity - 1,
            id: id,
            variantId: variantId
        }]
        let input = {
            checkoutId: this.props.getNewCheckOutId,
            lineItems

        }
        this.props.checkoutLineItemsUpdate(LINE_ITEMS_UPDATE, input, callback)
    }

    loginQuantityAdd = (variantId, quantity, id) => {
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        const callback = (response) => {
            let { checkout = {} } = response && response.checkoutLineItemsUpdate
            if (checkout === null) {
                toast.error(`Something Went Wrong ,Try Again Later`, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            } else {
                let { token = {} } = this.props.token
                let { accessToken } = token || {}
                let query = CUSTOMER_LIST(accessToken)
                this.props.getCustomerList(query)
                this.props.getRecentCheckOutDetails(LAST_INCOMPLETE_CHECK_OUT(accessToken))
            }
        }
        let lineItems = [{
            quantity: quantity + 1,
            id: id,
            variantId: variantId
        }]
        let input = {
            checkoutId: lastIncompleteCheckout && lastIncompleteCheckout.id,
            lineItems
        }
        this.props.checkoutLineItemsUpdate(LINE_ITEMS_UPDATE, input, callback)
    }



    quantityAdd = (variantId, quantity, id) => {
        const callback = (response) => {
            let { checkout = {} } = response && response.checkoutLineItemsUpdate
            if (checkout === null) {
                toast.error(`Something Went Wrong ,Try Again Later`, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            } else {
                this.props.checkOutDetails(CHECKOUT_DETAILS(this.props.getNewCheckOutId))
            }
        }
        let lineItems = [{
            quantity: quantity + 1,
            id: id,
            variantId: variantId
        }]
        let input = {
            checkoutId: this.props.getNewCheckOutId,
            lineItems
        }
        this.props.checkoutLineItemsUpdate(LINE_ITEMS_UPDATE, input, callback)
    }

    rootAddFunctions(variantId, quantity, id) {
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        if (lastIncompleteCheckout) {
            this.loginQuantityAdd(variantId, quantity, id)
        } else {
            this.quantityAdd(variantId, quantity, id)
        }
    }

    rootMinusFunctions(variantId, quantity, id) {
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        if (lastIncompleteCheckout) {
            this.loginQuantityMinus(variantId, quantity, id)
        } else {
            this.quantityMinus(variantId, quantity, id)
        }
    }


    rootRemoveFunctions(id) {
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        if (lastIncompleteCheckout) {
            this.loginLineItemsRemove(id)
        } else {
            this.lineItemsRemove(id)
        }
    }

    backtoShopping(customAttributes) {
        let { key } = customAttributes && customAttributes[0]
        if (key === 'SelectedShirt') {
            this.props.history.push('/canvas/shirt?fabric')
        } else if (key === 'SelectedPant') {
            this.props.history.push('/canvas/pant?fabric')
        } else if (key === 'SelectedSuit' || key === 'selected3PieceSuit') {
            this.props.history.push('/canvas/suit?fabric')
        } else if (key === 'SelectedBlazer') {
            this.props.history.push('/canvas/blazer?fabric')
        }
    }
    _renderFoldedShirtCartProducts(customAttributes, S3, variantId, quantity, productId, price) {
        const { shirtLoader } = this.state
        let selectedShirt = selectedShirtJSON(customAttributes, S3)
        let { defaultFoldedShirt, shirtBackCollar,
            foldedCollarCenterButton, foldedCollarDownButton,
            shirtBackPlacket, stripeFoldedButton, folded_collar_fabric,
            foldedCuffFabric, shirtCuffAll, cuffButton, shirtBackPocket,
            foldedPocketButton } = selectedShirt && selectedShirt.selectedCombinationObj
        return (
            <React.Fragment>

                <div className="col-xl-3 col-3 pi-shirt-containers p-0 h-100" onClick={() => this.openCartModal(customAttributes)}>
                    {shirtLoader && <Loader
                        isCanvas={true} />}
                    {<div className={`pi_container  pi_shirt_cuff`}>
                        <img className="custom_shirt shirt_back"
                            src={defaultFoldedShirt}
                            alt='shirt'
                            onLoad={() => this.onloadImageSuccess('shirt')}
                            onError={() => this.onloadImageError('shirt')}
                        />
                        <img className="custom_shirt shirt_collar"
                            src={shirtBackCollar.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('shirt')}
                            onError={() => this.onloadImageError('shirt')}
                            alt='shirt' />
                        <img className="custom_shirt shirt_collar_fabric"
                            src={folded_collar_fabric.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('shirt')}
                            onError={() => this.onloadImageError('shirt')}
                            alt='shirt' />
                        <img className="custom_shirt shirt_collar_button"
                            src={foldedCollarCenterButton}
                            onLoad={() => this.onloadImageSuccess('shirt')}
                            onError={() => this.onloadImageError('shirt')}
                            alt='shirt' />
                        <img className="custom_shirt shirt_collar_down_button"
                            src={foldedCollarDownButton}
                            onLoad={() => this.onloadImageSuccess('shirt')}
                            onError={() => this.onloadImageError('shirt')}
                            alt='shirt' />
                        <img className="custom_shirt shirt_placket"
                            src={shirtBackPlacket.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('shirt')}
                            onError={() => this.onloadImageError('shirt')}
                            alt='shirt' />
                        <img className="custom_shirt shirt_placket_button"
                            onLoad={() => this.onloadImageSuccess('shirt')}
                            onError={() => this.onloadImageError('shirt')}
                            src={stripeFoldedButton}
                            alt='shirt' />
                        <img className="custom_shirt shirt_cuff_all"
                            src={shirtCuffAll.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('shirt')}
                            onError={() => this.onloadImageError('shirt')}
                            alt='shirt' />
                        <img className="custom_shirt shirt_cuff_fabric"
                            src={foldedCuffFabric.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('shirt')}
                            onError={() => this.onloadImageError('shirt')}
                            alt='shirt' />
                        <img className="custom_shirt shirt_cuff_button"
                            src={cuffButton}
                            onLoad={() => this.onloadImageSuccess('shirt')}
                            onError={() => this.onloadImageError('shirt')}
                            alt='shirt' />
                        <img className="custom_shirt shirt_back_pocket"
                            src={shirtBackPocket.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('shirt')}
                            onError={() => this.onloadImageError('shirt')}
                            alt='shirt' />
                        <img className="custom_shirt shirt_back_pocket_button"
                            src={foldedPocketButton}
                            onLoad={() => this.onloadImageSuccess('shirt')}
                            onError={() => this.onloadImageError('shirt')}
                            alt='shirt' />

                    </div>
                    }
                </div>
                <div className='col-xl-9 col-9 d-flex flex-column justify-content-between flex-grow-1 cart-gap gap-3 px-lg-0 px-4'>
                    <div className='d-flex align-item-center justify-content-between '>
                        <p className='H-l fs-14 text-lowercase fabric-text'>{selectedShirt?.selectedFabrics?.name}</p>
                        <div className='d-flex gap-10 ps-4'>
                            <img src={PATH?.img?.EditIcon} className='cart-icon cursor-pointer' alt='edit-icon' onClick={() => this.editCustomize(productId, customAttributes)} />
                            <img src={PATH?.img?.DeleteIcon} className='cart-icon cursor-pointer' alt='delete-icon' onClick={() => this.rootRemoveFunctions(productId)} />
                        </div>
                    </div>
                    <div className='row cart-gap media-body'>
                        <div className='col-lg-6'>
                            <div className='d-flex align-items-center gap-18'>
                                <p className='H-l fs-14 text-lowercase'>{CONTENTS.cart.quantity}</p>
                                <div className='d-flex cart-quantity-border align-items-center'>
                                    <p className='fs-17 H-m m-0 cursor-pointer' onClick={() => quantity === 1 ? this.rootRemoveFunctions(productId) :
                                        this.rootMinusFunctions(variantId, quantity, productId)}><i className="bi bi-dash"></i></p>
                                    <p className='fs-14 H-cb m-0'>{quantity}</p>
                                    <p className='fs-17 H-m m-0 cursor-pointer' onClick={() => this.rootAddFunctions(variantId, quantity, productId)}><i className="bi bi-plus"></i></p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 d-flex align-items-center justify-content-between'>
                            <p className='H-l fs-14'>{quantity} x {(price?.amount)} {(price?.currencyCode)}</p>
                            <p className='H-l fs-14 subtotal-text'>{CONTENTS.cart.subtotal} <span>{(price?.amount * quantity)} {price?.currencyCode}</span></p>
                        </div>
                    </div>
                </div>
                {/* <div className="media-body">
                        <p className='f-m mb-2'>{selectedShirt?.selectedFabrics?.name}</p>
                        <p className='fs-15 f-m text_grey'>{(price?.amount * quantity)} {price?.currencyCode}</p>
                        <div className='cart_product_quantity'>
                            <p className='fs-20 f-m m-0 cursor-pointer' onClick={() => quantity === 1 ? this.rootRemoveFunctions(productId) :
                                this.rootMinusFunctions(variantId, quantity, productId)}><i className="bi bi-dash"></i></p>
                            <p className='fs-15 f-m m-0'>{quantity}</p>
                            <p className='fs-20 f-m m-0 cursor-pointer' onClick={() => this.rootAddFunctions(variantId, quantity, productId)}><i className="bi bi-plus"></i></p>
                        </div>
                    </div> */}
            </React.Fragment >
        )

    }

    _renderFoldedPantCartProducts(customAttributes, S3, variantId, quantity, productId, price) {
        const { pantLoader } = this.state
        let selectedPant = selectedPantJSON(customAttributes, S3)
        let { foldedPantFit, foldedCuff, foldedPleats, foldedPocket, foldedSidePocket } = selectedPant && selectedPant.selectedCombinationObj
        return (
            <>
                <div className="col-xl-3 col-3 pi-shirt-containers p-0 h-100" onClick={() => this.openCartModal(customAttributes)}>
                    {pantLoader && <Loader
                        isCanvas={true} />}
                    {<div className={`pi_container pi-pant folded-pant pi_shirt_cuff `}>
                        <img className="custom_shirt folded_fit"
                            src={foldedPantFit}
                            alt='shirt' />
                        <img className="custom_shirt folded_cuff"
                            src={foldedCuff}
                            alt='shirt' />
                        <img className="custom_shirt folded_pleats"
                            src={foldedPleats}
                            alt='shirt'
                        />
                        <img className="custom_shirt folded_pocket"
                            src={foldedPocket}
                            alt='shirt' />

                        <img className="custom_shirt folded_side_pocket"
                            src={foldedSidePocket}
                            alt='shirt' />
                    </div>
                    }
                </div>
                {/* <div className="col-6">
                    <div className="media-body">
                        <h3>{(price?.amount * quantity)} {price?.currencyCode} </h3>
                        <h5>{selectedPant?.selectedFabrics?.name}</h5>
                        <p>
                            <span
                                onClick={() => this.backtoShopping(customAttributes)}
                                className="text-blue cursor-pointer">Back to shopping</span>
                        </p>
                        <button
                            type="button"
                            className="btn btn-s-cus"
                            onClick={() => this.editCustomize(productId, customAttributes)}>
                            <span>Edit Cutomise</span>
                            <i onClick={() => this.editCustomize(productId, customAttributes)} className="fas fa-edit"></i>
                        </button>
                        <div className="number mt-3">
                            {quantity === 1 ? (
                                <span
                                    className="minus cursor-pointer"
                                    onClick={() => this.rootRemoveFunctions(productId)}>-</span>
                            ) : (
                                <span
                                    className="minus cursor-pointer"
                                    onClick={() => this.rootMinusFunctions(variantId, quantity, productId)}>-</span>
                            )}

                            <input
                                type="text"
                                value={quantity} readOnly />
                            <span
                                className="plus cursor-pointer"
                                onClick={() => this.rootAddFunctions(variantId, quantity, productId)}
                            >+</span>
                        </div>
                        <p>
                            <span
                                className="text-pink cursor-pointer"
                                onClick={() => this.rootRemoveFunctions(productId)}>
                                Remove
                            </span>
                        </p>
                    </div>
                </div> */}
                <div className='col-xl-9 col-9 d-flex flex-column justify-content-between flex-grow-1 cart-gap gap-3 px-lg-0 px-4'>
                    <div className='d-flex align-item-center justify-content-between '>
                        <p className='H-l fs-14 text-lowercase fabric-text'>{selectedPant?.selectedFabrics?.name}</p>
                        <div className='d-flex gap-10 ps-4'>
                            <img src={PATH?.img?.EditIcon} className='cart-icon cursor-pointer' alt='edit-icon' onClick={() => this.editCustomize(productId, customAttributes)} />
                            <img src={PATH?.img?.DeleteIcon} className='cart-icon cursor-pointer' alt='delete-icon' onClick={() => this.rootRemoveFunctions(productId)} />
                        </div>
                    </div>
                    <div className='row cart-gap media-body'>
                        <div className='col-lg-6'>
                            <div className='d-flex align-items-center gap-18'>
                                <p className='H-l fs-14 text-lowercase'>{CONTENTS.cart.quantity}</p>
                                <div className='d-flex cart-quantity-border align-items-center'>
                                    <p className='fs-17 H- m-0 cursor-pointer' onClick={() => quantity === 1 ? this.rootRemoveFunctions(productId) :
                                        this.rootMinusFunctions(variantId, quantity, productId)}><i className="bi bi-dash"></i></p>
                                    <p className='fs-14 H-cb m-0'>{quantity}</p>
                                    <p className='fs-17 f-m m-0 cursor-pointer' onClick={() => this.rootAddFunctions(variantId, quantity, productId)}><i className="bi bi-plus"></i></p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 d-flex align-items-center justify-content-between'>
                            <p className='H-l fs-14'>{quantity} x {(price?.amount)} {(price?.currencyCode)}</p>
                            <p className='H-l fs-14 subtotal-text'>{CONTENTS.cart.subtotal} <span>{(price?.amount * quantity)} {price?.currencyCode}</span></p>
                        </div>
                    </div>
                </div>


                {/* <div className="media-body">
                        <p className='f-m mb-2'>{selectedPant?.selectedFabrics?.name}</p>
                        <p className='fs-15 f-m text_grey'>{(price?.amount * quantity)} {price?.currencyCode}</p>
                        <div className='cart_product_quantity'>
                            <p className='fs-20 f-m m-0 cursor-pointer' onClick={() => quantity === 1 ? this.rootRemoveFunctions(productId) :
                                this.rootMinusFunctions(variantId, quantity, productId)}><i className="bi bi-dash"></i></p>
                            <p className='fs-15 f-m m-0'>{quantity}</p>
                            <p className='fs-20 f-m m-0 cursor-pointer' onClick={() => this.rootAddFunctions(variantId, quantity, productId)}><i className="bi bi-plus"></i></p>
                        </div>
                    </div> */}
            </>
        )
    }
    _renderFoldedSuitCartProducts(customAttributes, S3, variantId, quantity, productId, price) {
        const { suitLoader } = this.state
        let combineSuitFabrics = selectedSuitJSON(customAttributes, S3)
        const { foldedSuitFit, foldedPocket,
            foldedBreastPocket, foldedNormalFit, foldedPant, foldedSleeve, foldedSuitButtons,
            foldedCollar, foldedInnerBottom, foldedInnerSuit, suitPocketsSquare, suitInner,
            suitBottomInner, foldedButtonHole,
            foldedButtonThread, foldedLapelsHole } = combineSuitFabrics && combineSuitFabrics.selectedCombinationObj
        return (
            <React.Fragment>
                <div className="col-xl-3 col-3 pi-shirt-containers p-0 h-100 vest-cart" onClick={() => this.openCartModal(customAttributes)}>
                    {suitLoader && <Loader
                        isCanvas={true} />}
                    <div className={`pi_container pi-pant folded-pant pi_shirt_cuff`}>
                        <img className="custom_shirt folded_fit cart_suit"
                            src={foldedNormalFit.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('suit')}
                            onError={() => this.onloadImageError('suit')}
                            alt='shirt' />
                        <img className="custom_shirt folded_collar cart_suit"
                            src={foldedCollar.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('suit')}
                            onError={() => this.onloadImageError('suit')}
                            alt='shirt' />

                        <img className="custom_shirt folded_inner_suit cart_suit"
                            src={foldedInnerSuit.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('suit')}
                            onError={() => this.onloadImageError('suit')}
                            alt='shirt' />

                        <img className="custom_shirt folded_collar cart_suit"
                            src={suitInner}
                            alt='shirt' />
                        <img className="custom_shirt folded_collar cart_suit"
                            src={foldedInnerBottom.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('suit')}
                            onError={() => this.onloadImageError('suit')}
                            alt='shirt' />
                        <img className="custom_shirt folded_collar cart_suit"
                            src={suitBottomInner}
                            onLoad={() => this.onloadImageSuccess('suit')}
                            onError={() => this.onloadImageError('suit')}
                            alt='shirt' />
                        <img className="custom_shirt folded_fit cart_suit"
                            src={foldedSuitFit.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('suit')}
                            onError={() => this.onloadImageError('suit')}
                            alt='shirt' />
                        <img className="custom_shirt folded_lapels cart_suit"
                            src={foldedLapelsHole}
                            onLoad={() => this.onloadImageSuccess('suit')}
                            onError={() => this.onloadImageError('suit')}
                            alt='shirt' />
                        <img className="custom_shirt folded_fit cart_suit"
                            src={suitPocketsSquare}
                            onLoad={() => this.onloadImageSuccess('suit')}
                            onError={() => this.onloadImageError('suit')}
                            alt='shirt' />

                        <img className="custom_shirt folded_pleats cart_suit"
                            src={foldedSleeve.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('suit')}
                            onError={() => this.onloadImageError('suit')}
                            alt='shirt'
                        />
                        <img className="custom_shirt folded_pocket cart_suit"
                            src={foldedPocket.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('suit')}
                            onError={() => this.onloadImageError('suit')}
                            alt='shirt' />

                        <img className="custom_shirt folded_side_pocket cart_suit"
                            src={foldedBreastPocket.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('suit')}
                            onError={() => this.onloadImageError('suit')}
                            alt='shirt' />

                        <img className="custom_shirt folded_hole cart_suit"
                            src={foldedButtonHole}
                            onLoad={() => this.onloadImageSuccess('suit')}
                            onError={() => this.onloadImageError('suit')}
                            alt='shirt' />

                        <img className="custom_shirt folded_button cart_suit"
                            onLoad={() => this.onloadImageSuccess('suit')}
                            onError={() => this.onloadImageError('suit')}
                            src={foldedSuitButtons}
                            alt='shirt' />

                        <img className="custom_shirt folded_thread cart_suit"
                            src={foldedButtonThread}
                            onLoad={() => this.onloadImageSuccess('suit')}
                            onError={() => this.onloadImageError('suit')}
                            alt='shirt' />

                        <img className="custom_shirt folded_pant cart_suit"
                            src={foldedPant.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('suit')}
                            onError={() => this.onloadImageError('suit')}
                            alt='shirt' />
                    </div>
                </div>
                {/* <div className="col-6">
                    <div className="media-body">
                        <h3>{(price?.amount * quantity)} {price?.currencyCode} </h3>
                        <h5>{combineSuitFabrics?.selectedFabrics?.name}</h5>
                        <p>
                            <span
                                onClick={() => this.backtoShopping(customAttributes)}
                                className="text-blue cursor-pointer">Back to shopping</span>
                        </p>
                        <button
                            type="button"
                            className="btn btn-s-cus"
                            onClick={() => this.editCustomize(productId, customAttributes)}>
                            <span>Edit Cutomise</span>
                            <i onClick={() => this.editCustomize(productId, customAttributes)} className="fas fa-edit"></i>
                        </button>
                        <div className="number mt-3">
                            {quantity === 1 ? (
                                <span
                                    className="minus cursor-pointer"
                                    onClick={() => this.rootRemoveFunctions(productId)}>-</span>
                            ) : (
                                <span
                                    className="minus cursor-pointer"
                                    onClick={() => this.rootMinusFunctions(variantId, quantity, productId)}>-</span>
                            )}

                            <input
                                type="text"
                                value={quantity} readOnly />
                            <span
                                className="plus cursor-pointer"
                                onClick={() => this.rootAddFunctions(variantId, quantity, productId)}
                            >+</span>
                        </div>
                        <p>
                            <span
                                className="text-pink cursor-pointer"
                                onClick={() => this.rootRemoveFunctions(productId)}>
                                Remove
                            </span>
                        </p>
                    </div>
                </div> */}
                <div className='col-xl-9 col-9 d-flex flex-column justify-content-between flex-grow-1 cart-gap gap-3 px-lg-0 px-4'>
                    <div className='d-flex align-item-center justify-content-between '>
                        <p className='H-l fs-14 text-lowercase fabric-text'>{combineSuitFabrics?.selectedFabrics?.name}</p>
                        <div className='d-flex gap-10 ps-4'>
                            <img src={PATH?.img?.EditIcon} className='cart-icon cursor-pointer' alt='edit-icon' onClick={() => this.editCustomize(productId, customAttributes)} />
                            <img src={PATH?.img?.DeleteIcon} className='cart-icon cursor-pointer' alt='delete-icon' onClick={() => this.rootRemoveFunctions(productId)} />
                        </div>
                    </div>
                    <div className='row cart-gap media-body'>
                        <div className='col-lg-6'>
                            <div className='d-flex align-items-center gap-18'>
                                <p className='H-l fs-14 text-lowercase'>{CONTENTS.cart.quantity}</p>
                                <div className='d-flex cart-quantity-border align-items-center'>
                                    <p className='fs-17 H- m-0 cursor-pointer' onClick={() => quantity === 1 ? this.rootRemoveFunctions(productId) :
                                        this.rootMinusFunctions(variantId, quantity, productId)}><i className="bi bi-dash"></i></p>
                                    <p className='fs-14 H-cb m-0'>{quantity}</p>
                                    <p className='fs-17 f-m m-0 cursor-pointer' onClick={() => this.rootAddFunctions(variantId, quantity, productId)}><i className="bi bi-plus"></i></p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 d-flex align-items-center justify-content-between'>
                            <p className='H-l fs-14'>{quantity} x {(price?.amount)} {(price?.currencyCode)}</p>
                            <p className='H-l fs-14 subtotal-text'>{CONTENTS.cart.subtotal} <span>{(price?.amount * quantity)} {price?.currencyCode}</span></p>
                        </div>
                    </div>
                </div>

                {/* <div className="media-body">
                        <p className='f-m mb-2'>{combineSuitFabrics?.selectedFabrics?.name}</p>
                        <p className='fs-15 f-m text_grey'>{(price?.amount * quantity)} {price?.currencyCode}</p>
                        <div className='cart_product_quantity'>
                            <p className='fs-20 f-m m-0 cursor-pointer' onClick={() => quantity === 1 ? this.rootRemoveFunctions(productId) :
                                this.rootMinusFunctions(variantId, quantity, productId)}><i className="bi bi-dash"></i></p>
                            <p className='fs-15 f-m m-0'>{quantity}</p>
                            <p className='fs-20 f-m m-0 cursor-pointer' onClick={() => this.rootAddFunctions(variantId, quantity, productId)}><i className="bi bi-plus"></i></p>
                        </div>
                    </div> */}
            </React.Fragment>
        )
    }

    _renderFoldedVestCartProducts(customAttributes, S3, variantId, quantity, productId, price) {
        const { vestLoader } = this.state
        let combineSuitFabrics = selectedVestJSON(customAttributes, S3)
        const { foldedSuitFit, foldedPocket,
            foldedBreastPocket, foldedNormalFit, foldedPant, foldedSleeve, foldedSuitButtons,
            foldedCollar, foldedInnerBottom, foldedInnerSuit, suitPocketsSquare, suitInner,
            suitBottomInner, foldedButtonHole,
            foldedButtonThread, foldedLapelsHole, suitVests, suitVestsButtons } = combineSuitFabrics && combineSuitFabrics.selectedCombinationObj
        return (
            <React.Fragment>
                <div className="col-xl-3 col-3 pi-shirt-containers p-0 h-100 " onClick={() => this.openCartModal(customAttributes)}>
                    {vestLoader && <Loader
                        isCanvas={true} />}
                    <div className={`pi_container pi-pant folded-pant pi_shirt_cuff`}>
                        <img className="custom_shirt folded_fit cart_suit"
                            src={foldedNormalFit.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('vest')}
                            onError={() => this.onloadImageError('vest')}
                            alt='shirt' />
                        <img className="custom_shirt folded_collar cart_suit"
                            src={foldedCollar.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('vest')}
                            onError={() => this.onloadImageError('vest')}
                            alt='shirt' />

                        <img className="custom_shirt folded_inner_suit cart_suit"
                            src={foldedInnerSuit.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('vest')}
                            onError={() => this.onloadImageError('vest')}
                            alt='shirt' />

                        <img className="custom_shirt folded_collar cart_suit"
                            src={suitInner}
                            onLoad={() => this.onloadImageSuccess('vest')}
                            onError={() => this.onloadImageError('vest')}
                            alt='shirt' />
                        <img className="custom_shirt folded_collar cart_suit"
                            src={foldedInnerBottom.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('vest')}
                            onError={() => this.onloadImageError('vest')}
                            alt='shirt' />
                        <img className="custom_shirt folded_collar cart_suit"
                            src={suitBottomInner}
                            onLoad={() => this.onloadImageSuccess('vest')}
                            onError={() => this.onloadImageError('vest')}
                            alt='shirt' />
                        <img className="custom_shirt folded_fit cart_suit"
                            src={suitVests.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('vest')}
                            onError={() => this.onloadImageError('vest')}
                            alt='shirt' />
                        <img className="custom_shirt folded_vests_button cart_suit"
                            src={suitVestsButtons}
                            onLoad={() => this.onloadImageSuccess('vest')}
                            onError={() => this.onloadImageError('vest')}
                            alt='shirt' />
                        <img className="custom_shirt folded_fit cart_suit"
                            src={foldedSuitFit.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('vest')}
                            onError={() => this.onloadImageError('vest')}
                            alt='shirt' />
                        <img className="custom_shirt folded_lapels cart_suit"
                            src={foldedLapelsHole}
                            onLoad={() => this.onloadImageSuccess('vest')}
                            onError={() => this.onloadImageError('vest')}
                            alt='shirt' />
                        <img className="custom_shirt folded_fit cart_suit"
                            src={suitPocketsSquare}
                            onLoad={() => this.onloadImageSuccess('vest')}
                            onError={() => this.onloadImageError('vest')}
                            alt='shirt' />

                        <img className="custom_shirt folded_pleats cart_suit"
                            src={foldedSleeve.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('vest')}
                            onError={() => this.onloadImageError('vest')}
                            alt='shirt'
                        />
                        <img className="custom_shirt folded_pocket cart_suit"
                            src={foldedPocket.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('vest')}
                            onError={() => this.onloadImageError('vest')}
                            alt='shirt' />

                        <img className="custom_shirt folded_side_pocket cart_suit"
                            src={foldedBreastPocket.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('vest')}
                            onError={() => this.onloadImageError('vest')}
                            alt='shirt' />

                        <img className="custom_shirt folded_hole cart_suit"
                            src={foldedButtonHole}
                            onLoad={() => this.onloadImageSuccess('vest')}
                            onError={() => this.onloadImageError('vest')}
                            alt='shirt' />

                        <img className="custom_shirt folded_button cart_suit"
                            src={foldedSuitButtons}
                            onLoad={() => this.onloadImageSuccess('vest')}
                            onError={() => this.onloadImageError('vest')}
                            alt='shirt' />

                        <img className="custom_shirt folded_thread cart_suit"
                            src={foldedButtonThread}
                            onLoad={() => this.onloadImageSuccess('vest')}
                            onError={() => this.onloadImageError('vest')}
                            alt='shirt' />

                        <img className="custom_shirt folded_pant cart_suit"
                            src={foldedPant.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('vest')}
                            onError={() => this.onloadImageError('vest')}
                            alt='shirt' />
                    </div>
                </div>
                {/* <div className="col-6">
                    <div className="media-body">
                        <h3>{(price?.amount * quantity)} {price?.currencyCode} </h3>
                        <h5>{combineSuitFabrics?.selectedFabrics?.name}</h5>
                        <p>
                            <span
                                onClick={() => this.backtoShopping(customAttributes)}
                                className="text-blue cursor-pointer">Back to shopping</span>
                        </p>
                        <button
                            type="button"
                            className="btn btn-s-cus"
                            onClick={() => this.editCustomize(productId, customAttributes)}>
                            <span>Edit Cutomise</span>
                            <i onClick={() => this.editCustomize(productId, customAttributes)} className="fas fa-edit"></i>
                        </button>
                        <div className="number mt-3">
                            {quantity === 1 ? (
                                <span
                                    className="minus cursor-pointer"
                                    onClick={() => this.rootRemoveFunctions(productId)}>-</span>
                            ) : (
                                <span
                                    className="minus cursor-pointer"
                                    onClick={() => this.rootMinusFunctions(variantId, quantity, productId)}>-</span>
                            )}

                            <input
                                type="text"
                                value={quantity} readOnly />
                            <span
                                className="plus cursor-pointer"
                                onClick={() => this.rootAddFunctions(variantId, quantity, productId)}
                            >+</span>
                        </div>
                        <p>
                            <span
                                className="text-pink cursor-pointer"
                                onClick={() => this.rootRemoveFunctions(productId)}>
                                Remove
                            </span>
                        </p>
                    </div>
                </div> */}
                {/* <div className='col-xl-9 col-9 d-flex flex-column justify-content-between flex-grow-1 cart-gap gap-3 px-lg-0 px-4'>
                    <div className="media-body">
                        <p className='H-l text-lowercase mb-2'>{combineSuitFabrics?.selectedFabrics?.name}</p>
                        <p className='fs-14 H-l text_grey'>{(price?.amount * quantity)} {price?.currencyCode}</p>
                        <div className='cart_product_quantity align-items-center'>
                            <p className='fs-17 f-m m-0 cursor-pointer' onClick={() => quantity === 1 ? this.rootRemoveFunctions(productId) :
                                this.rootMinusFunctions(variantId, quantity, productId)}><i className="bi bi-dash"></i></p>
                            <p className='fs-14 H-l m-0'>{quantity}</p>
                            <p className='fs-17 f-m m-0 cursor-pointer' onClick={() => this.rootAddFunctions(variantId, quantity, productId)}><i className="bi bi-plus"></i></p>
                        </div>
                    </div>
                </div>
                 */}
                <div className='col-xl-9 col-9 d-flex flex-column justify-content-between flex-grow-1 cart-gap gap-3 px-lg-0 px-4'>
                    <div className='d-flex align-item-center justify-content-between '>
                        <p className='H-l fs-14 text-lowercase fabric-text'>{combineSuitFabrics?.selectedFabrics?.name}</p>
                        <div className='d-flex gap-10 ps-4'>
                            <img src={PATH?.img?.EditIcon} className='cart-icon cursor-pointer' alt='edit-icon' onClick={() => this.editCustomize(productId, customAttributes)} />
                            <img src={PATH?.img?.DeleteIcon} className='cart-icon cursor-pointer' alt='delete-icon' onClick={() => this.rootRemoveFunctions(productId)} />
                        </div>
                    </div>
                    <div className='row cart-gap media-body'>
                        <div className='col-lg-6'>
                            <div className='d-flex align-items-center gap-18'>
                                <p className='H-l fs-14 text-lowercase'>{CONTENTS.cart.quantity}</p>
                                <div className='d-flex cart-quantity-border align-items-center'>
                                    <p className='fs-17 H-m m-0 cursor-pointer' onClick={() => quantity === 1 ? this.rootRemoveFunctions(productId) :
                                        this.rootMinusFunctions(variantId, quantity, productId)}><i className="bi bi-dash"></i></p>
                                    <p className='fs-14 H-cb m-0'>{quantity}</p>
                                    <p className='fs-17 H-m m-0 cursor-pointer' onClick={() => this.rootAddFunctions(variantId, quantity, productId)}><i className="bi bi-plus"></i></p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 d-flex align-items-center justify-content-between'>
                            <p className='H-l fs-14'>{quantity} x {(price?.amount)} {(price?.currencyCode)}</p>
                            <p className='H-l fs-14 subtotal-text'>{CONTENTS.cart.subtotal} <span>{(price?.amount * quantity)} {price?.currencyCode}</span></p>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
    _renderFoldedBlazerCartProducts(customAttributes, S3, variantId, quantity, productId, price) {
        const { blazerLoader } = this.state
        let combineSuitFabrics = selectedBlazerJSON(customAttributes, S3)
        const { foldedSuitFit, foldedPocket,
            foldedBreastPocket, foldedNormalFit, foldedSleeve, foldedSuitButtons,
            foldedCollar, foldedInnerBottom, foldedInnerSuit, suitPocketsSquare, suitInner,
            suitBottomInner, foldedButtonHole,
            foldedButtonThread, foldedLapelsHole } = combineSuitFabrics && combineSuitFabrics.selectedCombinationObj
        return (
            <React.Fragment>
                <div className="col-xl-3 col-3 pi-shirt-containers p-0 h-100">
                    {blazerLoader && <Loader
                        isCanvas={true} />}
                    <div
                        className={`pi_container pi-pant folded-pant pi_shirt_cuff`}
                        onClick={() => this.openCartModal(customAttributes)}>
                        <img className="custom_shirt folded_fit mt-4"
                            src={foldedNormalFit.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('blazer')}
                            onError={() => this.onloadImageError('blazer')}
                            alt='shirt' />
                        <img className="custom_shirt folded_collar mt-4"
                            src={foldedCollar.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('blazer')}
                            onError={() => this.onloadImageError('blazer')}
                            alt='shirt' />

                        <img className="custom_shirt folded_inner_suit mt-4"
                            src={foldedInnerSuit.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('blazer')}
                            onError={() => this.onloadImageError('blazer')}
                            alt='shirt' />

                        <img className="custom_shirt folded_collar mt-4"
                            src={suitInner}
                            onLoad={() => this.onloadImageSuccess('blazer')}
                            onError={() => this.onloadImageError('blazer')}
                            alt='shirt' />
                        <img className="custom_shirt folded_collar mt-4"
                            src={foldedInnerBottom.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('blazer')}
                            onError={() => this.onloadImageError('blazer')}
                            alt='shirt' />
                        <img className="custom_shirt folded_collar mt-4"
                            src={suitBottomInner}
                            onLoad={() => this.onloadImageSuccess('blazer')}
                            onError={() => this.onloadImageError('blazer')}
                            alt='shirt' />
                        <img className="custom_shirt folded_fit mt-4"
                            src={foldedSuitFit.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('blazer')}
                            onError={() => this.onloadImageError('blazer')}
                            alt='shirt' />
                        <img className="custom_shirt folded_lapels mt-4"
                            src={foldedLapelsHole}
                            onLoad={() => this.onloadImageSuccess('blazer')}
                            onError={() => this.onloadImageError('blazer')}
                            alt='shirt' />
                        <img className="custom_shirt folded_fit mt-4"
                            src={suitPocketsSquare}
                            onLoad={() => this.onloadImageSuccess('blazer')}
                            onError={() => this.onloadImageError('blazer')}
                            alt='shirt' />

                        <img className="custom_shirt folded_pleats mt-4"
                            src={foldedSleeve.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('blazer')}
                            onError={() => this.onloadImageError('blazer')}
                            alt='shirt'
                        />
                        <img className="custom_shirt folded_pocket mt-4"
                            src={foldedPocket.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('blazer')}
                            onError={() => this.onloadImageError('blazer')}
                            alt='shirt' />

                        <img className="custom_shirt folded_side_pocket mt-4"
                            src={foldedBreastPocket.replace('.png', detectBrowser())}
                            onLoad={() => this.onloadImageSuccess('blazer')}
                            onError={() => this.onloadImageError('blazer')}
                            alt='shirt' />

                        <img className="custom_shirt folded_hole mt-4"
                            src={foldedButtonHole}
                            onLoad={() => this.onloadImageSuccess('blazer')}
                            onError={() => this.onloadImageError('blazer')}
                            alt='shirt' />

                        <img className="custom_shirt folded_button mt-4"
                            src={foldedSuitButtons}
                            onLoad={() => this.onloadImageSuccess('blazer')}
                            onError={() => this.onloadImageError('blazer')}
                            alt='shirt' />

                        <img className="custom_shirt folded_thread mt-4"
                            src={foldedButtonThread}
                            onLoad={() => this.onloadImageSuccess('blazer')}
                            onError={() => this.onloadImageError('blazer')}
                            alt='shirt' />
                    </div>
                </div>
                {/* <div className="col-6">
                    <div className="media-body">
                    <h3>{(price?.amount * quantity)} {price?.currencyCode} </h3>
                        <h5>{combineSuitFabrics?.selectedFabrics?.name}</h5>
                        <p>
                            <span
                                onClick={() => this.backtoShopping(customAttributes)}
                                className="text-blue cursor-pointer">Back to shopping</span>
                        </p>
                        <button
                            type="button"
                            className="btn btn-s-cus"
                            onClick={() => this.editCustomize(productId, customAttributes)}>
                            <span>Edit Cutomise</span>
                            <i onClick={() => this.editCustomize(productId, customAttributes)} className="fas fa-edit"></i>
                        </button>
                        <div className="number mt-3">
                            {quantity === 1 ? (
                                <span
                                    className="minus cursor-pointer"
                                    onClick={() => this.rootRemoveFunctions(productId)}>-</span>
                            ) : (
                                <span
                                    className="minus cursor-pointer"
                                    onClick={() => this.rootMinusFunctions(variantId, quantity, productId)}>-</span>
                            )}

                            <input
                                type="text"
                                value={quantity} readOnly />
                            <span
                                className="plus cursor-pointer"
                                onClick={() => this.rootAddFunctions(variantId, quantity, productId)}
                            >+</span>
                        </div>
                        <p>
                            <span
                                className="text-pink cursor-pointer"
                                onClick={() => this.rootRemoveFunctions(productId)}>
                                Remove
                            </span>
                        </p>
                    </div>
                </div> */}
                <div className='col-xl-9 col-9 d-flex flex-column justify-content-between flex-grow-1 cart-gap gap-3 px-lg-0 px-4'>
                    <div className='d-flex align-item-center justify-content-between '>
                        <p className='H-l fs-14 text-lowercase fabric-text'>{combineSuitFabrics?.selectedFabrics?.name}</p>
                        <div className='d-flex gap-10 ps-4'>
                            <img src={PATH?.img?.EditIcon} className='cart-icon cursor-pointer' alt='edit-icon' onClick={() => this.editCustomize(productId, customAttributes)} />
                            <img src={PATH?.img?.DeleteIcon} className='cart-icon cursor-pointer' alt='delete-icon' onClick={() => this.rootRemoveFunctions(productId)} />
                        </div>
                    </div>
                    <div className='row cart-gap media-body'>
                        <div className='col-lg-6'>
                            <div className='d-flex align-items-center gap-18'>
                                <p className='H-l fs-14 text-lowercase'>{CONTENTS.cart.quantity}</p>
                                <div className='d-flex cart-quantity-border align-items-center'>
                                    <p className='fs-17 H-m m-0 cursor-pointer' onClick={() => quantity === 1 ? this.rootRemoveFunctions(productId) :
                                        this.rootMinusFunctions(variantId, quantity, productId)}><i className="bi bi-dash"></i></p>
                                    <p className='fs-14 H-cb m-0'>{quantity}</p>
                                    <p className='fs-17 H-m m-0 cursor-pointer' onClick={() => this.rootAddFunctions(variantId, quantity, productId)}><i className="bi bi-plus"></i></p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 d-flex align-items-center justify-content-between'>
                            <p className='H-l fs-14'>{quantity} x {(price?.amount)} {(price?.currencyCode)}</p>
                            <p className='H-l fs-14 subtotal-text'>{CONTENTS.cart.subtotal} <span>{(price?.amount * quantity)} {price?.currencyCode}</span></p>
                        </div>
                    </div>
                </div>

                {/* <div className="media-body">
                        <p className='f-m mb-2'>{combineSuitFabrics?.selectedFabrics?.name}</p>
                        <p className='fs-15 f-m text_grey'>{(price?.amount * quantity)} {price?.currencyCode}</p>
                        <div className='cart_product_quantity'>
                            <p className='fs-20 f-m m-0 cursor-pointer' onClick={() => quantity === 1 ? this.rootRemoveFunctions(productId) :
                                this.rootMinusFunctions(variantId, quantity, productId)}><i className="bi bi-dash"></i></p>
                            <p className='fs-15 f-m m-0'>1</p>
                            <p className='fs-20 f-m m-0 cursor-pointer' onClick={() => this.rootAddFunctions(variantId, quantity, productId)}><i className="bi bi-plus"></i></p>
                        </div>
                    </div> */}

            </React.Fragment>
        )
    }
    _renderCustomerOrderedProducts(customAttributes, variantId, quantity, productId, price) {
        let { key } = customAttributes && customAttributes[0]
        if (key === 'SelectedShirt') {
            return this._renderFoldedShirtCartProducts(customAttributes, S3BASE_URL,
                variantId, quantity, productId, price)
        } else if (key === 'SelectedPant') {
            return this._renderFoldedPantCartProducts(customAttributes, S3BASE_URL_PANT,
                variantId, quantity, productId, price)
        } else if (key === 'SelectedSuit') {
            return this._renderFoldedSuitCartProducts(customAttributes, S3BASE_URL_SUIT,
                variantId, quantity, productId, price)
        } else if (key === 'SelectedBlazer') {
            return this._renderFoldedBlazerCartProducts(customAttributes, S3BASE_URL_BLAZER,
                variantId, quantity, productId, price)
        } else if (key === 'selected3PieceSuit') {
            return this._renderFoldedVestCartProducts(customAttributes, S3BASE_URL_SUIT,
                variantId, quantity, productId, price)
        } else {
            return this._renderFoldedShirtCartProducts(customAttributes, S3BASE_URL,
                variantId, quantity, productId, price)
        }
    }

    openCartModal(attributes) {
        this.setState({ isModalOpen: true, attributes })
    }

    closeModal() {
        this.setState({ isModalOpen: false })
    }

    render() {
        let { lineItems } = this.props
        return (
            <div className={`row ${lineItems?.edges?.length === 0 ? '' : 'border-btm-cus1'} mx-0`}>
                {
                    lineItems?.edges.map((o, i) => {
                        let { variant = {}, quantity, id: productId, customAttributes } = o?.node || {}
                        let { price, id: variantId, image } = variant || {}
                        return (
                            <div className='' key={i}>
                                {/* <div className='d-flex gap-10 ps-4'>
                                    <img src={PATH?.img?.EditIcon} className='cart-icon' alt='edit-icon' onClick={() => this.editCustomize(productId, customAttributes)} />
                                    <img src={PATH?.img?.DeleteIcon} className='cart-icon' alt='delete-icon' onClick={() => this.rootRemoveFunctions(productId)} />
                                </div> */}
                                {/* <span className='cursor-pointer'
                                        onClick={() => this.editCustomize(productId, customAttributes)}>
                                        <i className="bi bi-pencil-square text_grey"></i>
                                    </span>
                                    <span className='cursor-pointer'
                                        onClick={() => this.rootRemoveFunctions(productId)}>
                                        <i className="bi bi-x-lg text_grey ps-2"></i>
                                    </span> */}
                                <div className="media position-relative row cart-product-alignment">
                                    {customAttributes?.length > 0 && (
                                        this._renderCustomerOrderedProducts(customAttributes, variantId,
                                            quantity, productId, price))}
                                </div>
                                <div className='w-100 cart-divider-line '></div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}
const mapStateToProp = (state) => {
    return {
        fabricList: Actions.getFabrics(state),
        selectedFabric: Actions.getShirtFabric(state),
        getCheckoutDetails: Actions.getCheckoutId(store.getState()),
        token: Actions.getTokenSelector(store.getState()) || {},
        getNewCheckOutId: Actions.getNewCheckOutId(state),
    }
}

export default withRouter(connect(mapStateToProp, {
    checkoutLineItemsRemove: Actions.checkoutLineItemsRemove,
    getRecentCheckOutDetails: Actions.getRecentCheckOutDetails,
    checkoutLineItemsUpdate: Actions.checkoutLineItemsUpdate,
    updateShirtFabric: Actions.updateShirtFabric,
    getCustomerList: Actions.getCustomerList,
    editShirtAccent: Actions.editShirtAccent,
    editShirtStyle: Actions.editShirtStyle,
    editSelectedPantCombination: Actions.editSelectedPantCombination,
    updateSelectedPantFabric: Actions.updateSelectedPantFabric,
    updateSelectedSuitAccent: Actions.updateSelectedSuitAccent,
    updateSelectedSuitStyle: Actions.updateSelectedSuitStyle,
    updateSelectedSuitFabric: Actions.updateSelectedSuitFabric,
    editSelectedVestCombination: Actions.updateSelectedVestStyle,
    editSelectedVestAccent: Actions.editSelectedVestAccent,
    checkOutDetails: Actions.checkOutDetails,
    updateSelectedMeasurement: Actions.updateSelectedMeasurement,
    updateSelectedBlazerFabric: Actions.updateSelectedBlazerFabric,
    editBlazerStyle: Actions.editBlazerStyle,
    editSelectedBlazerAccent: Actions.editSelectedBlazerAccent,
    updateVestFabric: Actions.updateVestFabric
})(CartProduct));