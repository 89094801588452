import React from 'react'
import { withRouter } from 'react-router-dom'
import { images } from '../../util/image_render'

class AccentsCollar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        const { selectedCollarFabric, openCollarCategories } = this.props
        return (
            <React.Fragment>
                <div className='mt-2'>
                    <p className="H-l fs-13 text-lowercase pb-2">Contrasted Collar</p>
                    <div className='row row-gap-3 m-0'>
                        <div className='col-lg-3 col-6 px-1'>
                            <div className={`style_type ${selectedCollarFabric === 'default' ? 'active_type' : ''}`}
                                onClick={() => openCollarCategories('default')}>
                                <div className='style_img' >
                                    <img src={images('./images/shirt/sidebar_icons/by default.svg')} />
                                </div>
                                <p className="pt-1 fs-13 H-l text-lowercase">Default</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-6 px-1'>
                            <div className={`style_type ${selectedCollarFabric === 'all' ? 'active_type' : ''}`}
                                onClick={() => openCollarCategories('all')}>
                                <div className='style_img' >
                                    <img src={images('./images/shirt/sidebar_icons/all.svg')} />
                                </div>
                                <p className="pt-1 fs-13 H-l text-lowercase">All</p>
                            </div>
                        </div>

                        <div className='col-lg-3 col-6 px-1'>
                            <div className={`style_type ${selectedCollarFabric === 'inner' ? 'active_type' : ''}`}
                                onClick={() => openCollarCategories('inner')}>
                                <div className='style_img' >
                                    <img src={images('./images/shirt/sidebar_icons/inner_fabric.svg')} />
                                </div>
                                <p className="pt-1 fs-13 H-l text-lowercase">Inner</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-6 px-1'>
                            <div className={`style_type ${selectedCollarFabric === 'outer' ? 'active_type' : ''}`}
                                onClick={() => openCollarCategories('outer')}>
                                <div className='style_img' >
                                    <img src={images('./images/shirt/sidebar_icons/outer_fabric.svg')} />
                                </div>
                                <p className="pt-1 fs-13 H-l text-lowercase">Outer</p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withRouter(AccentsCollar)