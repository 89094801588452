import { put, takeLatest, all } from 'redux-saga/effects';
import Actions from '../action';
import {  loginQuery } from '../../service';
export function* signup({ query, input, callback }) {
  try {
    const response = yield loginQuery(query, input);
    yield put(Actions.signUpSuccess(response));
    if (callback) {
      callback(response)
    }
  } catch (error) {
    yield put(Actions.signUpFailure(error));
    if (callback) {
      callback({ error })
    }
  }
}

export default function* () {
  yield all([
    takeLatest(Actions.SIGNUP, signup),
  ]);
}
