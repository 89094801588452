import { put, takeLatest, all } from 'redux-saga/effects';
import Actions from '../action';
import { loginQuery , getQuery , postQuery } from '../../service';
export function* customerLogin({ query, input, callback }) {
  try {
    const response = yield loginQuery(query, input);
    yield put(Actions.loginSuccess(response));
    if (callback) {
      callback(response)
    }
  } catch (error) {
    yield put(Actions.loginFailure(error));
    if (callback) {
      callback({ error })
    }
  }
}

export function* getCustomerList({ query }) {
  try {
    const response = yield getQuery(query);
    yield put(Actions.getCustomerListSuccess(response));
  } catch (error) {
    yield put(Actions.getCustomerListFailure(error));
  }
}

export function* customerProfileUpdate({ query, input, callback }) {
  try {
    const response = yield postQuery(query, input);
    yield put(Actions.customerProfileUpdateSuccess(response));
    if (callback) {
      callback(response)
    }
  } catch (error) {
    if (callback) {
      callback({ error })
    }
    yield put(Actions.customerProfileUpdateFailure(error));
  }
}

export function* customerResetPassword({ query, input, callback }) {
  try {
    const response = yield postQuery(query, input);
    yield put(Actions.customerResetPasswordSuccess(response));
    if (callback) {
      callback(response)
    }
  } catch (error) {
    if (callback) {
      callback({ error })
    }
    yield put(Actions.customerResetPasswordFailure(error));
  }
}

export default function* loginWatcher() {
  yield all([
    takeLatest(Actions.USER_LOGIN, customerLogin),
    takeLatest(Actions.GET_CUSTOMER_LIST, getCustomerList),
    takeLatest(Actions.CUSTOMER_PROFILE_UPDATE, customerProfileUpdate),
    takeLatest(Actions.CUSTOMER_RESET_PASSWORD,customerResetPassword)
  ]);
}
