import React from 'react'
import { withRouter } from 'react-router-dom'
import { images } from '../../util/image_render'

class SuitLining extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { selectedSuitLining, openSuitInnerFabrics } = this.props
        return (
            <React.Fragment>
                <div className="">
                    <p className="H-l fs-13 pb-2 text-lowercase">Internal Lining</p>
                    <div className='row row-gap-3 m-0'>
                        <div className='col-lg-3 col-6 px-1'>
                            <div className={`style_type ${selectedSuitLining === 'default' ? 'active_type' : ''}`}
                                onClick={() => openSuitInnerFabrics('default')}>
                                <div className='style_img' >
                                    <img src={images('./images/suit/SVG/Default.svg')} alt="suit-accents" />
                                </div>
                                <p className="pt-1 H-l fs-13 text-lowercase">Default</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-6 px-1'>
                            <div className={`style_type ${selectedSuitLining === 'custom' ? 'active_type' : ''}`}
                                onClick={() => openSuitInnerFabrics('custom')}>
                                <div className='style_img' >
                                    <img src={images('./images/suit/SVG/CustomColor.svg')} alt="suit-accents" />
                                </div>
                                <p className="pt-1 H-l fs-13 text-lowercase">Custom Color</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-6 px-1'>
                            <div className={`style_type ${selectedSuitLining === 'unlined' ? 'active_type' : ''}`}
                                onClick={() => openSuitInnerFabrics('unlined')}>
                                <div className='style_img' >
                                    <img src={images('./images/suit/SVG/Unlined.svg')} alt="suit-accents" />
                                </div>
                                <p className="pt-1 H-l fs-13 text-lowercase">Unlined</p>
                            </div>
                        </div>
                    </div>
                    {/* <li className={selectedSuitLining === 'default' ? 'active' : ''}>
                        <img
                            src={images('./images/suit/SVG/Default.svg')}
                            className="img-fluid"
                            alt="icons"
                            onClick={() => openSuitInnerFabrics('default')} />
                        <p>BY DEFAULT</p>
                    </li> */}
                    {/* <li className={selectedSuitLining === 'custom' ? 'active' : ''}>
                        <img
                            src={images('./images/suit/SVG/CustomColor.svg')}
                            className="img-fluid"
                            alt="icons"
                            onClick={() => openSuitInnerFabrics('custom')} />
                        <p>CUSTOM COLOR</p>
                    </li>
                    <li className={selectedSuitLining === 'unlined' ? 'active' : ''}>
                        <img
                            src={images('./images/suit/SVG/Unlined.svg')}
                            className="img-fluid"
                            alt="icons"
                            onClick={() => openSuitInnerFabrics('unlined')} />
                        <p>UNLINED</p>
                    </li> */}
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(SuitLining)