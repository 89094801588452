import React from 'react'
import { withRouter } from 'react-router-dom'
import { CONTENTS } from '../../globals/app-constraints'


class TermsAndConditions extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        return (
            <React.Fragment>
                <div className="col-12 p-0" >
                    <div className="container mt-3 mb-5">
                        <div className='exchange_contents px-lg-0 px-2'>
                            <h1 className='exchange_head my-4 text-center H-m fs-24'>{CONTENTS.termsConditions.head}</h1>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.para1}</p>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.para2} <a href="https://worldofakal.com" className='text-decoration-none H-m text-dark'>{CONTENTS.termsConditions.akalLink}</a></p>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.para3}</p>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.para4}</p>
                            <h3 className='exchange_head mt-lg-4 mt-3 mb-3 fs-16 H-m'>{CONTENTS.termsConditions.head1}</h3>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head1Para1} </p>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head1Para2}</p>
                            <h3 className='exchange_head mt-lg-4 mt-3 mb-3 fs-16 H-m'>{CONTENTS.termsConditions.head2}</h3>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head2Para1}</p>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head2Para2}</p>
                            <ul>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head2Point1}</li>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head2Point2}</li>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head2Point3}</li>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head2Point4}</li>
                            </ul>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head2Para3} <a href="https://www.termsandconditionsgenerator.com" className='text-decoration-none H-m text-dark'>{CONTENTS.termsConditions.termsConditionLink}</a>.</p>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head2Para4}</p>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head2Para5}</p>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head2Para6}</p>
                            <ul>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head2Para6Point1}</li>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head2Para6Point2}</li>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head2Para6Point3}</li>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head2Para6Point4}</li>
                            </ul>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head2Para7}</p>
                            <h3 className='exchange_head mt-lg-4 mt-3 mb-3 H-m fs-16'>{CONTENTS.termsConditions.head3}</h3>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para1}</p>
                            <ul>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para1Point1}</li>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para1Point2}</li>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para1Point3}</li>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para1Point4}</li>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para1Point5}</li>
                            </ul>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para2}</p>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para3}</p>
                            <ul>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para3Point1}</li>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para3Point2}</li>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para3Point3}</li>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para3Point4}</li>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para3Point5}</li>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para3Point6}</li>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para3Point7}</li>
                            </ul>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para4}</p>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para5}</p>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para6}</p>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para7}</p>
                            <ul>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para7Point1}</li>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para7Point2}</li>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para7Point3}</li>
                            </ul>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head3Para8}</p>
                            <h3 className='exchange_head mt-lg-4 mt-3 mb-3 H-m fs-16'>{CONTENTS.termsConditions.head4}</h3>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head4Para}</p>
                            <h3 className='exchange_head f-sb mt-lg-4 mt-3 mb-3 H-m fs-16'>{CONTENTS.termsConditions.head5}</h3>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head5Para}</p>
                            <h3 className='exchange_head f-sb mt-lg-4 mt-3 mb-3 H-m fs-16'>{CONTENTS.termsConditions.head6}</h3>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head6Para}</p>
                            <h3 className='exchange_head f-sb mt-lg-4 mt-3 mb-3 H-m fs-16'>{CONTENTS.termsConditions.head7}</h3>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head7Para}</p>
                            <h3 className='exchange_head f-sb mt-lg-4 mt-3 mb-3 H-m fs-16'>{CONTENTS.termsConditions.head8}</h3>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head8Para1}</p>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head8Para2}</p>
                            <h3 className='exchange_head f-sb mt-lg-4 mt-3 mb-3 H-m fs-16'>{CONTENTS.termsConditions.head9}</h3>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head9Para1}</p>
                            <ul>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head9Para1Point1}</li>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head9Para1Point2}</li>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head9Para1Point3}</li>
                                <li className='fs-14 H-l'>{CONTENTS.termsConditions.head9Para1Point4}</li>
                            </ul>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head9Para2}</p>
                            <p className='fs-14 H-l'>{CONTENTS.termsConditions.head9Para3}</p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(TermsAndConditions)