import React from "react";
import { withRouter } from "react-router-dom";
import { titleCase } from "../../util/random_number";


class MeasurementViewModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: "show",
      showModal: true,
      dialog: "dialog",
    };
  }
  renderUI() {
    let { show, showModal, dialog } = this.state;
    const { closeViewMeasurement, editMeasurementModel, selectedMeasurement } = this.props;
    const { value, key, id, namespace } = selectedMeasurement;
    const selectedData = value ? JSON.parse(value) : []
    return (
      <React.Fragment>
        {showModal && <div className={`modal-backdrop fade show`}></div>}
        <div
          className={`modal fade ${show} address-modal`}
          tabIndex="-1"
          id="measure"
          aria-labelledby="measure"
          aria-hidden="true"
          style={{
            display: showModal ? "block" : "none",
            padding_right: showModal ? " 17px" : "",
          }}
          aria-modal="true"
          role={dialog}
        >
          <div className="modal-dialog modal-lg" role="document" >
            <div className="modal-content">
              <div className="modal-header p-0 border-0">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => closeViewMeasurement()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="col-lg-12 shopping_crt">
                <div className="col-12 s_heading">
                  <h2 className="text-center">Your Measurement is now complete!</h2>
                </div>
                <div className="container c-measurements c-measure-quick c-measure-quickp mm-man">
                  <ul>
                    <li>
                      {
                        Object.keys(selectedData && selectedData.value).map((key) => {
                          return <p>{titleCase(key)}</p>
                        })

                      }
                    </li>
                    <li>
                      {
                        Object.keys(selectedData && selectedData.value).map((key) => {
                          return <p>{selectedData && selectedData.value[key]} in</p>
                        })
                      }
                    </li>

                  </ul>
                  <div className="col-12 text-center">
                    <button
                      type="button"
                      className="btn btn-dark mb-3"
                      onClick={() => editMeasurementModel(key, namespace, id)}>
                      Alter Measurements</button> &nbsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>

    )
  }
  render() {
    const { isView } = this.props;
    return <React.Fragment>{isView && this.renderUI()}</React.Fragment>;
  }
}

export default withRouter(MeasurementViewModal);
