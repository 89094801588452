import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { store } from '../../createStore'
import { toast } from 'react-toastify'
// import StandardChart from './MeasurementChart'
import { LINE_ITEMS_ADD, LINE_ITEMS_UPDATE, CHECKOUT_CREATE, CUSTOMER_ASSOCIATE } from '../../Queries'
import { convertCentiMeters, convertInchMeters } from '../../util/Canvas_Measurement/measurement'
import { PANT_IMAGES, SHIRT_IMAGES, SUIT_IMAGES } from '../../util/Canvas_Measurement'
import { UKPANT_CHART, USPANT_CHART, ShirtChart, USSUIT_CHART, UKSUIT_CHART } from '../../util/Canvas_Measurement/measurement'
import { BLAZER_CHECKOUT, PANT_CHECKOUT, SHIRT_CHECKOUT, SUIT_CHECKOUT, VEST_CHECKOUT } from '../../component/ProductCheckout'
import { Constants } from '../../util/Constant'

class Size extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            defaultShirtType: 'S',
            openModal: false,
            fields: {
                sizeType: 'US',
                pantSize: '34',
                suitSize: '34',
                shirtSize: 'S'
            },
            pantFields: {
                waist: '',
                hip: '',
                crotch: '',
                thigh: '',
                pantLength: ''
            },
            suitFields: {
                chest: '',
                stomach: '',
                shirtLength: '',
                shoulder: '',
                sleeve: ''
            },
            shirtFields: {
                collar: '',
                chest: '',
                back: '',
                waist: '',
                sleeve: ''
            },
            UkPantChart: UKPANT_CHART,
            UsPantChart: USPANT_CHART,
            selectedPantMeters: 'in',
            selectedShirtMeters: 'cm',
            UsSuitChart: USSUIT_CHART,
            UkSuitChart: UKSUIT_CHART,
        }
    }
    handleShirtMeasurementPage(value) {
        this.setState({ defaultShirtType: value })
    }

    openShirtModal() {
        this.setState({ openModal: true })
    }

    componentDidMount() {
        let { fields, UkPantChart, UsPantChart, UsSuitChart, UkSuitChart } = this.state
        this.props.getRecentCheckOutDetails()
        this.createIntialCheckOutId()
        this._handlePantSize(fields["sizeType"] === 'EU' ? UkPantChart : UsPantChart)
        this._handleSuitSize(fields["sizeType"] === 'EU' ? UkSuitChart : UsSuitChart)

        let ShirtFilter = ShirtChart && ShirtChart.find((o) => o.key === fields['shirtSize'])
        this.handleShirtSize(ShirtFilter)
    }

    pantConvertMeters(e) {
        const { checked } = e.target
        if (checked) {
            this.setState({ selectedPantMeters: "cm" })
        } else {
            this.setState({ selectedPantMeters: "in" })
        }
    }

    shirtConvertMeters(e) {
        const { checked } = e.target
        if (checked) {
            this.setState({ selectedShirtMeters: "cm" })
        } else {
            this.setState({ selectedShirtMeters: "in" })
        }
    }

    _handlePantSize(arr) {
        let { fields, pantFields } = this.state
        let filterArrays = arr.find((o) => o.key === fields.pantSize)
        let { value } = filterArrays || {}
        value && value.map((o) => {
            return pantFields[Object.keys(o)] = Object.values(o)[0]
        })
        this.setState({ pantFields })
    }

    _handleSuitSize(arr) {
        let { fields, suitFields } = this.state
        let filterArrays = arr.find((o) => o.key === fields.suitSize)
        let { value } = filterArrays || {}
        value && value.map((o) => {
            return suitFields[Object.keys(o)] = Object.values(o)[0]
        })
        this.setState({ suitFields })
    }

    handleShirtSize(arr) {
        this.setState({ shirtFields: arr?.value })
    }

    closeModal() {
        this.setState({ openModal: false })
    }
    _renderRootMeasurement() {
        const { isShirt, isSuit, isPant, isBlazer, isVest } = this.props
        if (isShirt) {
            return this._renderShirtMeasurement()
        } else if (isSuit || isBlazer || isVest) {
            return this._renderSuitMeasurement(isBlazer)
        } else if (isPant) {
            return this._renderPantMeasurement()
        } else {
            return this._renderShirtMeasurement()
        }
    }
    _renderShirtMeasurement() {
        let { fields, selectedShirtMeters } = this.state
        return (
            <div className="custom_measurment">
                <div className="d-flex justify-content-between">
                    <div className="pr-md-5 p-2">
                        <p className="p-2">Shirt Size Chart</p>
                        <div className="row">
                            <div className="col-sm-6">
                                <select
                                    className="form-control"
                                    name="shirtSize"
                                    value={fields["shirtSize"] || ''}
                                    onChange={(e) => this.handleShirtChart(e)}
                                >
                                    <option value='' disabled>Choose any option</option>
                                    <option value='S'>S</option>
                                    <option value='M'>M</option>
                                    <option value='L'>L</option>
                                    <option value='XL'>XL</option>
                                    <option value='XXL'>XXL</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center pb-3 pb-md-4">
                        <label className="mx-2">in</label>
                        <label className="switch_m">
                            <input
                                type="checkbox"
                                defaultChecked={selectedShirtMeters === 'cm' ? true : false}
                                onClick={(e) => this.shirtConvertMeters(e)}
                            />
                            <span className="slider_m round_m"></span>
                        </label>
                        <label className="mx-2">cm</label>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table text-center shirt-mm">
                        {SHIRT_IMAGES()}
                        <tbody>
                            {this._renderShirtType(ShirtChart)}
                        </tbody>

                    </table>
                </div>
            </div>
        )
    }

    handleShirtChart(e) {
        let { name, value } = e.target
        let { fields } = this.state
        fields[name] = value
        this.setState({ fields })
        let ShirtFilter = ShirtChart && ShirtChart.find((o) => o.key === value)
        this.handleShirtSize(ShirtFilter)
    }

    handleSizeChart(e) {
        let { name, value } = e.target
        let { UkPantChart, UsPantChart, fields, UsSuitChart, UkSuitChart } = this.state
        fields[name] = value
        this.setState({ fields })
        this._handlePantSize(value === 'EU' ? UkPantChart : UsPantChart)
        this._handleSuitSize(value === 'EU' ? UkSuitChart : UsSuitChart)
    }

    handlePantChart(e) {
        let { name, value } = e.target
        let { fields, UkPantChart, UsPantChart } = this.state
        fields[name] = value
        if (name === fields['sizeType']) {
            if (value === 'EU') {
                fields['pantSize'] = '44'
                this.setState({ fields })
            } else {
                fields['pantSize'] = '34'
                this.setState({ fields })
            }
        }
        this._handlePantSize(value === 'EU' ? UkPantChart : UsPantChart)
        this.setState({ fields })
    }

    handleSuitChart(e) {
        let { name, value } = e.target
        let { fields, UsSuitChart, UkSuitChart, UsPantChart, UkPantChart } = this.state
        fields[name] = value
        fields['pantSize'] = value
        if (name === fields['sizeType']) {
            if (value === 'EU') {
                fields['suitSize'] = '44'
                this.setState({ fields })
            } else {
                fields['suitSize'] = '34'
                this.setState({ fields })
            }
        }
        this._handleSuitSize(value === 'EU' ? UkSuitChart : UsSuitChart)
        this._handlePantSize(value === 'EU' ? UkPantChart : UsPantChart)
        this.setState({ fields })
    }

    handlePantValueChange(e) {
        const { name, value } = e.target
        let { pantFields } = this.state
        pantFields[name] = value
        this.setState({ pantFields })
    }

    handleShirtValueChange(e) {
        const { name, value } = e.target
        let { shirtFields } = this.state
        shirtFields[name] = value
        this.setState({ shirtFields })
    }

    handleSuitValueChange(e) {
        const { name, value } = e.target
        let { suitFields } = this.state
        suitFields[name] = value
        this.setState({ suitFields })
    }
    _renderShirtType() {
        let { selectedShirtMeters, shirtFields } = this.state
        let { back, chest, collar, sleeve, waist, shirtLength } = shirtFields || {}
        return (
            <tr>
                <td>{selectedShirtMeters === 'in' ? convertInchMeters(collar) : collar}</td>
                <td>
                    <input
                        type="text"
                        name="chest"
                        style={{ textAlign: 'center', border: '1px solid black' }}
                        value={selectedShirtMeters === 'in' ? convertInchMeters(chest) : chest}
                        onChange={(e) => this.handleShirtValueChange(e)} />
                </td>
                <td>{selectedShirtMeters === 'in' ? convertInchMeters(back) : back}</td>
                <td>{selectedShirtMeters === 'in' ? convertInchMeters(waist) : waist}</td>
                <td>
                    <input
                        type="text"
                        name="sleeve"
                        style={{ textAlign: 'center' }}
                        value={selectedShirtMeters === 'in' ? convertInchMeters(sleeve) : sleeve}
                        onChange={(e) => this.handleShirtValueChange(e)} />
                </td>
                <td>
                    <input
                        type="text"
                        name="shirtLength"
                        style={{ textAlign: 'center' }}
                        value={selectedShirtMeters === 'in' ? convertInchMeters(shirtLength) : shirtLength}
                        onChange={(e) => this.handleShirtValueChange(e)} />
                </td>
            </tr>
        )
    }

    _renderPantType() {
        let { pantFields, selectedPantMeters } = this.state
        return (
            <tr>
                <td>
                    <input
                        type="text"
                        name="waist"
                        style={{ textAlign: 'center' }}
                        value={selectedPantMeters === 'cm' ? convertCentiMeters(pantFields.waist) : pantFields.waist}
                        onChange={(e) => this.handlePantValueChange(e)} />
                </td>
                <td>{selectedPantMeters === 'cm' ? convertCentiMeters(pantFields.hip) : pantFields.hip}</td>
                <td>{selectedPantMeters === 'cm' ? convertCentiMeters(pantFields.crotch) : pantFields.crotch}</td>
                <td>{selectedPantMeters === 'cm' ? convertCentiMeters(pantFields.thigh) : pantFields.thigh}</td>
                <td>
                    <input
                        type="text"
                        id="pantLength"
                        name="pantLength"
                        style={{ textAlign: 'center' }}
                        value={selectedPantMeters === 'cm' ? convertCentiMeters(pantFields.pantLength) : pantFields.pantLength}
                        onChange={(e) => this.handlePantValueChange(e)}
                    />
                </td>
            </tr>
        )

    }
    _renderSuitType() {
        let { suitFields, selectedPantMeters } = this.state
        return (
            <tr>
                <td>{selectedPantMeters === 'cm' ? convertCentiMeters(suitFields.chest) : suitFields.chest}</td>
                <td>{selectedPantMeters === 'cm' ? convertCentiMeters(suitFields.stomach) : suitFields.stomach}</td>
                <td>{selectedPantMeters === 'cm' ? convertCentiMeters(suitFields.shoulder) : suitFields.shoulder}</td>
                <td>
                    <input
                        type="text"
                        id="lname"
                        name="sleeve"
                        style={{ textAlign: 'center' }}
                        value={selectedPantMeters === 'cm' ? convertCentiMeters(suitFields.sleeve) : suitFields.sleeve}
                        onChange={(e) => this.handleSuitValueChange(e)}
                    />
                </td>
                <td>
                    <input
                        type="text"
                        id="length"
                        name="shirtLength"
                        style={{ textAlign: 'center' }}
                        value={selectedPantMeters === 'cm' ? convertCentiMeters(suitFields.shirtLength) : suitFields.shirtLength}
                        onChange={(e) => this.handleSuitValueChange(e)}
                    />
                </td>
            </tr>
        )
    }
    _renderChartOption() {
        const { fields } = this.state
        return (
            <div className="col-sm-6">
                <select
                    className="form-control"
                    name="sizeType"
                    value={fields["sizeType"] || ''}
                    onChange={(e) => this.handleSizeChart(e)}
                >
                    <option value=''>Choose your size</option>
                    <option value='EU'>European Size</option>
                    <option value='US'>UK/American Size</option>
                </select>
            </div>
        )
    }
    _renderPantMeasurement() {
        const { fields, UkPantChart, UsPantChart } = this.state
        return (
            <div className="custom_measurment">
                <div className="d-flex justify-content-between">
                    <div className="pr-md-5 p-2">
                        <p className="p-2">Pant Size Chart</p>
                        <div className="row">
                            {this._renderChartOption()}
                            <div className="col-sm-6">
                                <select
                                    className="form-control"
                                    name="pantSize"
                                    value={fields["pantSize"] || ''}
                                    onChange={(e) => this.handlePantChart(e)}
                                >
                                    <option value=''>Choose your size</option>
                                    {
                                        fields["pantSizeType"] === 'EU' ? UkPantChart.map((o) => <option value={o.key}>{o.key}</option>) : UsPantChart.map((o) => <option value={o.key}>{o.key}</option>)
                                    }
                                </select>
                            </div>

                        </div>

                    </div>

                    <div className="d-flex justify-content-center pb-3 pb-md-4">
                        <label className="mx-2">in</label>
                        <label className="switch_m">
                            <input type="checkbox" onClick={(e) => this.pantConvertMeters(e)} />
                            <span className="slider_m round_m"></span>
                        </label>
                        <label className="mx-2">cm</label>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table text-center shirt-mm">
                        {PANT_IMAGES()}
                        <tbody>
                            {this._renderPantType()}
                        </tbody>

                    </table>
                </div>
            </div>
        )
    }

    _renderSuitMeasurement(isBlazer) {
        const { fields, UsSuitChart, UkSuitChart } = this.state
        return (
            <div className="custom_measurment">
                <div className="d-flex justify-content-between">
                    <div className="pr-md-5 p-2">
                        <p className="p-2">{isBlazer ? 'Blazer Size Chart' : 'Suit Size Chart'}</p>
                        <div className="row">
                            {this._renderChartOption()}
                            <div className="col-sm-6">
                                <select
                                    className="form-control"
                                    name="suitSize"
                                    value={fields["suitSize"] || ''}
                                    onChange={(e) => this.handleSuitChart(e)}
                                >
                                    <option value=''>Choose your size</option>
                                    {
                                        fields["sizeType"] === 'EU' ? UkSuitChart.map((o) => <option value={o.key}>{o.key}</option>) : UsSuitChart.map((o) => <option value={o.key}>{o.key}</option>)
                                    }
                                </select>
                            </div>

                        </div>

                    </div>

                    <div className="d-flex justify-content-center pb-3 pb-md-4">
                        <label className="mx-2">in</label>
                        <label className="switch_m">
                            <input type="checkbox" onClick={(e) => this.pantConvertMeters(e)} />
                            <span className="slider_m round_m"></span>
                        </label>
                        <label className="mx-2">cm</label>
                    </div>
                </div>
                <h6>Blazer</h6>
                <div className="table-responsive">
                    <table className="table text-center shirt-mm">
                        {SUIT_IMAGES()}
                        <tbody>
                            {this._renderSuitType()}
                        </tbody>

                    </table>
                </div>
                {
                    !isBlazer && (
                        <>
                            <h6>Pant</h6>
                            <div className="table-responsive">
                                <table className="table text-center shirt-mm">
                                    {PANT_IMAGES()}
                                    <tbody>
                                        {this._renderPantType()}
                                    </tbody>

                                </table>
                            </div>
                        </>
                    )
                }
            </div>
        )
    }

    shirtCheckout(checkoutId) {
        let { selectedShirtFabric } = this.props.selectedFabric
        const { selectedCombination } = this.props.selectedStyle
        const { shirtAccents } = this.props.selectedAccents

        let { id, lineItemId } = selectedShirtFabric || {}
        let { edit } = this.props.match.params
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}

        let { token = {} } = this.props.token
        let { accessToken } = token || {}
        let { fields, shirtFields } = this.state

        let Measurements = {
            key: fields?.shirtSize,
            value: shirtFields,
            type: Constants.MEASUREMENT_TYPES.STANDARD_MEASUREMENT,
            productType: Constants.PRODUCTS.SHIRT
        }

        let customAttributes = SHIRT_CHECKOUT(shirtAccents, selectedCombination,
            selectedShirtFabric, Measurements)

        let lineItems = [{
            quantity: 1,
            variantId: id,
            customAttributes: customAttributes
        }]
        let input = {
            lineItems,
            checkoutId: checkoutId,
        }
        const callback = (response) => {
            let { checkoutLineItemsAdd = {} } = response && response
            if (checkoutLineItemsAdd === null) {
                this.createIntialCheckOutId()
            } else if (accessToken) {
                if (!lastIncompleteCheckout) {
                    this.customerAssociate(checkoutId)
                } else {
                    this.props.history.push('/cart')
                }
            } else {
                this.props.history.push('/cart')
            }
        }
        if (edit) {
            let lineItems = [{
                quantity: 1,
                variantId: id,
                id: lineItemId,
                customAttributes: customAttributes
            }]
            let input = {
                checkoutId: checkoutId,
                lineItems
            }
            this.props.checkoutLineItemsUpdate(LINE_ITEMS_UPDATE, input, callback)
        } else {
            this.props.checkoutLineItemsAdd(LINE_ITEMS_ADD, input, callback)
        }
    }
    createIntialCheckOutId() {
        let { token = {} } = this.props.token
        let { accessToken } = token || {}
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        if (accessToken) {
            if (!lastIncompleteCheckout && !this.props.getNewCheckOutId) {
                this.props.checkoutCreate(CHECKOUT_CREATE)
            }
        } else {
            if (!this.props.getNewCheckOutId) {
                this.props.checkoutCreate(CHECKOUT_CREATE)
            }
        }
    }
    blazerCheckout(checkoutId) {
        let { selectedBlazerFabric } = this.props.blazerFabric
        let { selectedAccents } = this.props.blazerAccents
        let { selectedCombination } = this.props.selectedBlazerStyle
        let { fields, suitFields } = this.state
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}

        let { token = {} } = this.props.token
        let { accessToken } = token || {}

        let Measurements = {
            key: fields['pantSize'],
            value: suitFields,
            type: Constants.MEASUREMENT_TYPES.STANDARD_MEASUREMENT,
            productType: Constants.PRODUCTS.SUIT,
            productSizeType: fields['sizeType'] === 'EU' ? 'UK_SUIT_SIZE' : 'US_SUIT_SIZE'
        }

        let customAttributes = BLAZER_CHECKOUT(selectedCombination, selectedBlazerFabric, selectedAccents, Measurements)

        let lineItems = [{
            quantity: 1,
            variantId: selectedBlazerFabric?.id,
            customAttributes: customAttributes
        }]
        let input = {
            lineItems,
            checkoutId: checkoutId,
        }
        const callback = (response) => {
            let { checkoutLineItemsAdd = {} } = response && response
            if (checkoutLineItemsAdd === null) {
                this.createIntialCheckOutId()
            } else if (accessToken) {
                if (!lastIncompleteCheckout) {
                    this.customerAssociate(checkoutId)
                } else {
                    this.props.history.push('/cart')
                }
            } else {
                this.props.history.push('/cart')
            }
        }
        this.props.checkoutLineItemsAdd(LINE_ITEMS_ADD, input, callback)
    }
    suitCheckout(checkoutId) {
        const { isBlazer } = this.props
        let { selectedSuitFabric } = this.props.selectedSuitFabric
        let { selectedAccents } = this.props.selectedSuitAccents
        let { selectedCombination } = this.props.selectedSuitStyle
        let { fields, suitFields, pantFields } = this.state

        let { id } = selectedSuitFabric || {}

        let checkBlazer = isBlazer ? suitFields : { suitFields, pantFields }
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}

        let { token = {} } = this.props.token
        let { accessToken } = token || {}

        let Measurements = {
            key: fields['pantSize'],
            value: checkBlazer,
            type: Constants.MEASUREMENT_TYPES.STANDARD_MEASUREMENT,
            productType: Constants.PRODUCTS.SUIT,
            productSizeType: fields['sizeType'] === 'EU' ? 'UK_SUIT_SIZE' : 'US_SUIT_SIZE'
        }

        let customAttributes = SUIT_CHECKOUT(isBlazer, selectedCombination, selectedSuitFabric,
            selectedAccents, Measurements)

        let lineItems = [{
            quantity: 1,
            variantId: id,
            customAttributes: customAttributes
        }]
        let input = {
            lineItems,
            checkoutId: checkoutId,
        }
        const callback = (response) => {
            let { checkoutLineItemsAdd = {} } = response && response
            if (checkoutLineItemsAdd === null) {
                this.createIntialCheckOutId()
            } else if (accessToken) {
                if (!lastIncompleteCheckout) {
                    this.customerAssociate(checkoutId)
                } else {
                    this.props.history.push('/cart')
                }
            } else {
                this.props.history.push('/cart')
            }
        }
        this.props.checkoutLineItemsAdd(LINE_ITEMS_ADD, input, callback)
    }


    vestCheckout(checkoutId) {
        let { selectedSuitFabric } = this.props.selectedSuitFabric
        let { selectedAccents } = this.props.selectedSuitAccents
        let { selectedCombination } = this.props.selectedSuitStyle
        let { fields, suitFields, pantFields } = this.state
        let { selectedVestStyle, selectedVestAccents, selectedVestFabric: { selectedVestFabric } } = this.props

        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}

        let { token = {} } = this.props.token
        let { accessToken } = token || {}

        let { id } = selectedVestFabric || {}
        let Measurements = {
            key: fields['pantSize'],
            value: { suitFields, pantFields },
            type: Constants.MEASUREMENT_TYPES.STANDARD_MEASUREMENT,
            productType: Constants.PRODUCTS.SUIT,
            productSizeType: fields['sizeType'] === 'EU' ? 'UK_SUIT_SIZE' : 'US_SUIT_SIZE'
        }

        let customAttributes = VEST_CHECKOUT(selectedSuitFabric, selectedCombination,
            selectedAccents, selectedVestAccents, selectedVestStyle, Measurements, selectedVestFabric)
        let lineItems = [{
            quantity: 1,
            variantId: id,
            customAttributes: customAttributes
        }]
        let input = {
            lineItems,
            checkoutId: checkoutId,
        }
        const callback = (response) => {
            let { checkoutLineItemsAdd = {} } = response && response
            if (checkoutLineItemsAdd === null) {
                this.createIntialCheckOutId()
            } else if (accessToken) {
                if (!lastIncompleteCheckout) {
                    this.customerAssociate(checkoutId)
                } else {
                    this.props.history.push('/cart')
                }
            } else {
                this.props.history.push('/cart')
            }
        }
        this.props.checkoutLineItemsAdd(LINE_ITEMS_ADD, input, callback)
    }

    pantCheckOut(checkoutId) {
        let { selectedPantFabric } = this.props.selectedPantFabric
        const { selectedCombination } = this.props.selectedPantStyle
        let { pantFields, fields } = this.state
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        let { token = {} } = this.props.token
        let { accessToken } = token || {}

        let Measurements = {
            key: fields['pantSize'],
            value: pantFields,
            type: Constants.MEASUREMENT_TYPES.STANDARD_MEASUREMENT,
            productType: Constants.PRODUCTS.PANT,
            productSizeType: fields['sizeType'] === 'EU' ? 'UK_PANT_SIZE' : 'US_PANT_SIZE'
        }
        let customAttributes = PANT_CHECKOUT(selectedCombination, selectedPantFabric, Measurements)
        let lineItems = [{
            quantity: 1,
            variantId: selectedPantFabric?.id,
            customAttributes: customAttributes
        }]
        let input = {
            lineItems,
            checkoutId: checkoutId,
        }
        const callback = (response) => {
            let { checkoutLineItemsAdd = {} } = response && response
            if (checkoutLineItemsAdd === null) {
                this.createIntialCheckOutId()
            } else if (accessToken) {
                if (!lastIncompleteCheckout) {
                    this.customerAssociate(checkoutId)
                } else {
                    this.props.history.push('/cart')
                }
            } else {
                this.props.history.push('/cart')
            }
        }
        this.props.checkoutLineItemsAdd(LINE_ITEMS_ADD, input, callback)
    }

    rootCheckout() {
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        const { isBlazer, isShirt, isPant, isSuit, isVest } = this.props
        let { token = {} } = this.props.token
        let { accessToken } = token || {}

        if (accessToken) {
            if (isSuit) {
                this.suitCheckout(lastIncompleteCheckout ? lastIncompleteCheckout.id : this.props.getNewCheckOutId)
            } else if (isShirt) {
                this.shirtCheckout(lastIncompleteCheckout ? lastIncompleteCheckout.id : this.props.getNewCheckOutId)
            } else if (isBlazer) {
                this.blazerCheckout(lastIncompleteCheckout ? lastIncompleteCheckout.id : this.props.getNewCheckOutId)
            } else if (isVest) {
                this.vestCheckout(lastIncompleteCheckout ? lastIncompleteCheckout.id : this.props.getNewCheckOutId)
            } else if (isPant) {
                this.pantCheckOut(lastIncompleteCheckout ? lastIncompleteCheckout.id : this.props.getNewCheckOutId)
            } else {
                this.shirtCheckout(lastIncompleteCheckout ? lastIncompleteCheckout.id : this.props.getNewCheckOutId)
            }
        } else {
            if (isSuit) {
                this.suitCheckout(this.props.getNewCheckOutId)
            } else if (isShirt) {
                this.shirtCheckout(this.props.getNewCheckOutId)
            } else if (isBlazer) {
                this.blazerCheckout(this.props.getNewCheckOutId)
            } else if (isVest) {
                this.vestCheckout(this.props.getNewCheckOutId)
            } else if (isPant) {
                this.pantCheckOut(this.props.getNewCheckOutId)
            } else {
                this.shirtCheckout(this.props.getNewCheckOutId)
            }
        }
    }

    customerAssociate(checkoutId) {
        let { token = {} } = this.props.token
        let { accessToken } = token || {}

        let input = {
            checkoutId: checkoutId,
            customerAccessToken: accessToken
        }
        const callback = (response) => {
            let { customer = {} } = response && response?.checkoutCustomerAssociate || {}
            if (customer === null) {
                toast.error(`Something went wrong ,try again later`, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            } else {
                this.props.history.push('/cart')
            }
        }
        this.props.checkoutCustomerAssociate(CUSTOMER_ASSOCIATE, input, callback)
    }
    render() {
        return (
            <div className="col-lg-12 quick_size">
                <div className="col-12 s_heading">
                    <h2 className="text-center">Select your size</h2>
                </div>
                <div className="container">
                    {this._renderRootMeasurement()}
                    <div className="col-12 d-flex justify-content-center mb-5">
                        <button type="button" className="btn btn-next btn-100 mx-4" onClick={() => this.rootCheckout()}>Add to Bag</button>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProp = (state) => {
    return {
        checkoutId: Actions.getCheckoutId(store.getState()) || {},
        customerId: Actions.getCustomerIdSelector(state),
        token: Actions.getTokenSelector(store.getState()) || {},
        getMeasurement: Actions.getSelectedMeasurement(store.getState()) || {},
        getCheckoutDetails: Actions.getCheckoutId(store.getState()),
        selectedStyle: Actions.getShirtStyle(state),
        selectedAccents: Actions.getShirtAccent(state),
        selectedSuitFabric: Actions.getSelectedSuitFabric(state),
        selectedSuitAccents: Actions.getSelectedSuitAccent(state),
        selectedSuitStyle: Actions.getSelectedSuitStyle(state),
        selectedFabric: Actions.getShirtFabric(state),
        selectedPantStyle: Actions.getSelectedPantStyle(state),
        selectedPantFabric: Actions.getSelectedPantFabric(state),
        getNewCheckOutId: Actions.getNewCheckOutId(state),
        blazerFabric: Actions.getSelectedBlazerFabric(state),
        selectedBlazerStyle: Actions.getSelectedBlazerStyle(state),
        blazerAccents: Actions.getSelectedBlazerAccent(state),

        selectedVestAccents: Actions.getSelectedVestAccent(state),
        selectedVestStyle: Actions.getSelectedVestStyle(state),
        selectedVestFabric: Actions.getVestFabric(state)
    }
}

export default withRouter(connect(mapStateToProp, {
    getRecentCheckOutDetails: Actions.getRecentCheckOutDetails,
    checkoutAttributesUpdate: Actions.checkoutAttributesUpdate,
    updateMeasurementOptions: Actions.updateMeasurementOptions,
    checkoutLineItemsAdd: Actions.checkoutLineItemsAdd,
    checkoutLineItemsUpdate: Actions.checkoutLineItemsUpdate,
    checkoutCreate: Actions.checkoutCreate,
    checkoutCustomerAssociate: Actions.checkoutCustomerAssociate
})(Size));