import React from 'react'
import { withRouter } from 'react-router-dom'
import { Constants } from '../../util/Constant'
import { titleCase } from '../../util/random_number'

class MeasurementTypeModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { showModal, handleChange, closeModal, saveModal } = this.props
        return (
            <>
                {showModal && <div className={`modal-backdrop fade show`}></div>}
                <div
                    className={`modal fade show`}
                    tabIndex="-1"
                    id="measureDelete"
                    aria-labelledby="measure"
                    aria-hidden="true"
                    style={{
                        display: showModal ? "block" : "none",
                        padding_right: showModal ? " 17px" : "",
                    }}
                    aria-modal="true"
                    role={'dialog'}
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => closeModal()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h5 className="text-center">Choose your measurement type</h5>
                                <select className="form-control"
                                    name="measurementType"
                                    onChange={(e) => handleChange(e)}
                                >
                                    <option value='' disabled>Choose Type</option>
                                    <option value={Constants.PRODUCTS.SHIRT}>{titleCase(Constants.PRODUCTS.SHIRT)}</option>
                                    <option value={Constants.PRODUCTS.PANT}>{titleCase(Constants.PRODUCTS.PANT)}</option>
                                    <option value={Constants.PRODUCTS.SUIT}>{titleCase(Constants.PRODUCTS.SUIT)}</option>
                                    <option value={Constants.PRODUCTS.BLAZER}>{titleCase(Constants.PRODUCTS.BLAZER)}</option>
                                </select>
                            </div>
                            <div className="modal-footer">
                                <div style={{ justifyContent: 'end' }}>
                                    <button className="btn btn-gren" onClick={() => saveModal()}>Select</button> &nbsp;
                                    <button className="btn btn-secondary" onClick={() => closeModal()}>Cancel</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(MeasurementTypeModal)