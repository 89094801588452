import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { LOGIN_QUERY, CUSTOMER_LIST, LAST_INCOMPLETE_CHECK_OUT_ID } from '../../Queries'
import { store } from '../../createStore'
import { isValidEmail } from '../../util/validation'
import ForgetPassword from '../ForgetPassword'
import Encrypt from '../../component/Encrypt'
import Decrypt from '../../component/Decrypt'
import Loader from '../../component/Loader'
import { CONTENTS, PATH, SERVERIMG } from '../../globals/app-constraints'
import ButtonDesign from '../../component/button'
import LazyLoad from 'react-lazyload';

const initialState = {
    email: '',
    password: '',
    isChecked: false,
    error: false,
    isForgetModal: false,
    showPassword: false,
    currentLocation: {}
}
class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = initialState
    }
    closeForgetModal() {
        this.setState({ isForgetModal: false })
    }
    openForgetModal() {
        this.setState({ isForgetModal: true })
    }
    handleChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value })
    }
    rememberMe(e) {
        let checked = e.target.checked
        if (checked) {
            this.setState({ isChecked: true })
        } else {
            this.setState({ isChecked: false })
        }
    }
    componentDidMount() {
        const CurrentLocation = window.location.pathname
        if (CurrentLocation !== "/signup") {
            this.props.currentLocation(CurrentLocation)
        }
        let { email, password, isChecked } = this.state
        email = localStorage.getItem("email")
        password = localStorage.getItem("password")
        let decryptPassword = ''
        if (email || password) {
            isChecked = true
            if (password) {
                decryptPassword = Decrypt(password)
                this.setState({ password: decryptPassword })
            }
        } else {
            isChecked = false
        }
        this.setState({ email, isChecked })
    }
    removeError = () => {
        this.setState(initialState)
    }
    handleSubmit(event) {
        event.preventDefault()
        const { email, password } = this.state;
        if (!isValidEmail(email) || (password === null || password.length <= 0)) {
            this.setState({ error: true })
            return;
        }
        this.setState({ error: false, isLoading: true })
        let input = {
            email: email,
            password: password
        }
        this.props.login(LOGIN_QUERY, input, this.callback)
    }
    callback = (response) => {
        this.setState({ isLoading: false })
        let { customerAccessToken } = response?.customerAccessTokenCreate || {}
        if (customerAccessToken === null) {
            this.setState({ error: true });
            return;
        } else {
            let { token = {} } = this.props.token
            let { accessToken } = token || {}
            let query = CUSTOMER_LIST(accessToken)
            this.props.getCustomerList(query)
            this.reg_remember_me()
            this.success_call()
        }
    }


    reg_remember_me() {
        let { isChecked, email, password } = this.state
        if (isChecked) {
            let encryptPassword = Encrypt(password)
            localStorage.setItem('email', email)
            localStorage.setItem('password', encryptPassword)
        } else {
            localStorage.removeItem("email")
            localStorage.removeItem("password")
        }
    }

    success_call() {
        this.setState({ email: '', password: '', error: '' })
        window.history.back()
    }

    onShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword })
    }
    handleNextKeyUp(e) {
        if (e.key === 'Enter') { this.handleSubmit(e) }
    }
    toggleModal = () => {
        document.body.style.overflow = 'hidden';
    };
    render() {
        let { isForgetModal, email, password, showPassword, isChecked, error, isLoading } = this.state
        return (
            <React.Fragment>
                {isLoading && <Loader />}

                <div className='d-flex mobile_login h-100'>
                    <div className='login_img_section'>
                        <LazyLoad className="w-100 h-100">
                            <img src={PATH.img.LoginImg} alt="" className='login_img' />
                        </LazyLoad>
                    </div>
                    <div className='d-flex flex-column login_content_section justify-content-center gap-42'>
                        <img src={PATH?.img?.Logo_black} className='login_logo cursor-pointer' alt="logo" onClick={() => this.props.history.push('/')} />
                        <div className='d-flex flex-column gap-12'>
                            <p className='H-m fs-24 text-center'>{CONTENTS.login.login}</p>
                            <p className='fs-14 H-l text-center'>{CONTENTS.login.content}</p>
                        </div>
                        {error && <div className="alert alert-danger" role="alert">{CONTENTS.login.invalidError}</div>}
                        <div className='d-flex flex-column gap-37'>
                            <div className="">
                                <label htmlFor="exampleFormControlInput1" className="fs-14 H-l form-label login_input_text_color">{CONTENTS.signup.email}</label>
                                <input
                                    type="text"
                                    className="form-control login_form_control"
                                    name="email"
                                    value={email || ''}
                                    id="exampleFormControlInput1"
                                    onChange={(e) => this.handleChange(e)} />
                            </div>
                            <div className="">
                                <label htmlFor="exampleFormControlInput1"
                                    className="fs-14 H-l form-label login_input_text_color">{CONTENTS.login.password}</label>
                                <div className='input-group login_form_control'>
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        className="form-control border-0"
                                        onChange={(e) => this.handleChange(e)}
                                        name="password"
                                        value={password || ''}
                                        onKeyUp={(event) => this.handleNextKeyUp(event)}
                                        id="exampleFormControlInput1" />
                                    <span
                                        onClick={() => this.onShowPassword()}
                                        className="input-group-text forgot_password_input_text cursor-pointer"
                                        id="basic-addon1">
                                        <img className='cursor-pointer' src={showPassword ? PATH.img.EyeIcon : PATH.img.EyeCloseIcon} alt='eye-icon' />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-column gap-24'>
                            <div className='d-flex justify-content-between'>
                                <div className="form-check">
                                    <input
                                        className="form-check-input remember-me"
                                        checked={isChecked}
                                        type="checkbox"
                                        value="" id="flexCheckDefault"
                                        onChange={(e) => this.rememberMe(e)} />
                                    <label className="form-check-label fs-14 H-l" htmlFor="flexCheckDefault">
                                        {CONTENTS.login.rememberMe}
                                    </label>
                                </div>
                                <div onClick={() => this.toggleModal()}>
                                    <p className='fs-14 H-l cursor-pointer' onClick={() => this.openForgetModal()}>{CONTENTS.login.forgotPassword}</p></div>
                            </div>
                            <div className='d-flex flex-column gap-28'>
                                <ButtonDesign
                                    btnWidth={'w-100 px-0 button-primary'}
                                    className={'btn-getmore fs-16 H-cb py-12 w-100'}
                                    ButtonValue={CONTENTS.login.login}
                                    
                                    onClick={(event) => this.handleSubmit(event)}
                                    Noarrow
                                />
                                {/* <button type="button" className="btn login_btn fs-14">login</button> */}
                                <p className='H-l fs-14 text-center login_input_text_color'>{CONTENTS.login.account} <span className='H-l fs-15 text-decoration-underline text-color-black cursor-pointer' onClick={() => this.props.history.push('/signup')}>{CONTENTS.login.signup}</span> </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className='login_form m-0'>
                    <div className='login_section_part1 p-0'>
                        <img src={loginimg} className='img-fluid login_page_img'
                            alt="login-img" />
                        <img src={loginimgmob} className='img-fluid login_page_mob_img'
                            alt="login-img" />
                    </div>
                    <div className='login_section_part2 px_login align-self-center pt-lg-0'>
                        <h4 className='f-sb text-center fs-20 mb-3'>Login</h4>
                        <p className='text_grey text-center fs-13 mb-3'>Enter your details to get log in to your account</p>
                        <div className='login_textbox_align'>
                            <div className='d-flex flex-column email_password_align'>
                                <div className='cart_form'>
                                    <label className='form-label mb-2'>Email</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Please enter your email"
                                        name="email"
                                        value={email || ''}
                                        autoComplete="off"
                                        onChange={(e) => this.handleChange(e)} />
                                </div>
                                <div className='cart_form'>
                                    <label className='form-label mb-2'>Password</label>
                                    <div className='input-group'>
                                        <input
                                            className="form-control forgot_password_input"
                                            placeholder="Please enter your password"
                                            name="password"
                                            value={password || ''}
                                            type={showPassword ? 'text' : 'password'}
                                            onKeyUp={(event) => this.handleNextKeyUp(event)}
                                            onChange={(e) => this.handleChange(e)} />
                                        <span
                                            onClick={() => this.onShowPassword()}
                                            className="input-group-text forgot_password_input_text cursor-pointer"
                                            id="basic-addon1"><i className="bi bi-eye-fill"></i></span>

                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between forgot_password'>
                                <div className='form-check d-flex align-items-center'>
                                    <input type='checkbox'
                                        onChange={(e) => this.rememberMe(e)}
                                        className='form-check-input mt-0 form_checked cursor-pointer'
                                        checked={isChecked} />
                                    <label className='form-check-label text_grey ms-2'>Remember me
                                    </label>
                                </div>
                                <p className='text_grey cursor-pointer m-0'
                                    onClick={() => this.openForgetModal()}>Forgot Password</p>
                            </div>
                            <button
                                className='btn common_btn primary_btn w-100 login_btn'
                                type="button"
                                onClick={(event) => this.handleSubmit(event)}>Login
                            </button>
                            <p className='text_grey mt-4 text-center'
                                onClick={() => this.props.history.push('/signup')}>
                                Don’t have an account?
                                <span className='signup_link fs-15 f-sb ps-2 cursor-pointer' >Sign Up</span></p>
                        </div>
                    </div>
                </div> */}


                {isForgetModal && <ForgetPassword
                    isForgetModal={isForgetModal}
                    closeForgetModal={() => this.closeForgetModal()}
                />}


            </React.Fragment>
        )
    }
}
const mapStateToProp = (state) => {
    return {
        token: Actions.getTokenSelector(store.getState()) || {},
        checkoutDetails: Actions.getCheckoutDetails(state),
        getcurrentLocation: Actions.getCurrentLocation(state),

    }
}

export default withRouter(connect(mapStateToProp, {
    login: Actions.login,
    getCustomerList: Actions.getCustomerList,
    getRecentCheckOutDetails: Actions.getRecentCheckOutDetails,
    currentLocation: Actions.currentLocation
})(Login));
