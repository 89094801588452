export const pantMock = {
    overSize: true,
    type: 'pant',
    short: [
        {
            label: 'S',
            size: 23,
            category:"Small",
            subSize: [
                {
                    label: "Waist width", size: 41
                },
                {
                    label: "Inside leg", size: 79.5
                },
                {
                    label: "Upper leg width", size: 27
                },
                {
                    label: "Foot opening width", size: 17.5
                }
            ]
        }, {
            label: 'M',
            size: 24,
            category:"Medium",
            subSize: [
                {
                    label: "Waist width", size: 43
                },
                {
                    label: "Inside leg", size: 80.5
                },
                {
                    label: "Upper leg width", size: 28
                },
                {
                    label: "Foot opening width", size: 18
                }
            ]
        }, {
            label: 'M/L',
            size: 25,
            category:"Medium",
            subSize: [
                {
                    label: "Waist width", size: 45
                },
                {
                    label: "Inside leg", size: 81.5
                },
                {
                    label: "Upper leg width", size: 29
                },
                {
                    label: "Foot opening width", size: 18.5
                }
            ]
        }, {
            label: 'L',
            size: 26,
            category:"Long",
            subSize: [
                {
                    label: "Waist width", size: 47
                },
                {
                    label: "Inside leg", size: 82.5
                },
                {
                    label: "Upper leg width", size: 30
                },
                {
                    label: "Foot opening width", size: 19
                }
            ]
        },{
            label: 'XL',
            size: 27,
            category:"Long",
            subSize: [
                {
                    label: "Waist width", size: 49
                },
                {
                    label: "Inside leg", size: 83.5
                },
                {
                    label: "Upper leg width", size: 30.5
                },
                {
                    label: "Foot opening width", size: 19.5
                }
            ]
        }, {
            label: 'XXL',
            size: 28,
            category:"Long",
            subSize: [
                {
                    label: "Waist width", size: 51
                },
                {
                    label: "Inside leg", size: 84.5
                },
                {
                    label: "Upper leg width", size: 31.5
                },
                {
                    label: "Foot opening width", size: 20
                }
            ]
        }, {
            label: '3XL',
            size: 29,
            category:"Long",
            subSize: [
                {
                    label: "Waist width", size: 53
                },
                {
                    label: "Inside leg", size: 85.5
                },
                {
                    label: "Upper leg width", size: 32.5
                },
                {
                    label: "Foot opening width", size: 20.5
                }
            ]
        }, {
            label: '4XL',
            size: 30,
            category:"Long",
            subSize: [
                {
                    label: "Waist width", size: 55
                },
                {
                    label: "Inside leg", size: 86.5
                },
                {
                    label: "Upper leg width", size: 33.5
                },
                {
                    label: "Foot opening width", size: 21
                }
            ]
        }
    ],

    regular: [
        {
            label: 'S',
            size: 46,
            category:"Small",
            subSize: [
                {
                    label: "Waist width", size: 41
                },
                {
                    label: "Inside leg", size: 84
                },
                {
                    label: "Upper leg width", size: 27
                },
                {
                    label: "Foot opening width", size: 17.5
                }
            ]
        }, {
            label: 'M',
            size: 48,
            category:"Medium",
            subSize: [
                {
                    label: "Waist width", size: 43
                },
                {
                    label: "Inside leg", size: 85
                },
                {
                    label: "Upper leg width", size: 28
                },
                {
                    label: "Foot opening width", size: 18
                }
            ]
        }, {
            label: 'M/L',
            size: 50,
            category:"Medium",
            subSize: [
                {
                    label: "Waist width", size: 45
                },
                {
                    label: "Inside leg", size: 86
                },
                {
                    label: "Upper leg width", size: 29
                },
                {
                    label: "Foot opening width", size: 18.5
                }
            ]
        }, {
            label: 'L',
            size: 52,
            category:"Long",
            subSize: [
                {
                    label: "Waist width", size: 47
                },
                {
                    label: "Inside leg", size: 87
                },
                {
                    label: "Upper leg width", size: 30
                },
                {
                    label: "Foot opening width", size: 19
                }
            ]
        },{
            label: 'XL',
            size: 54,
            category:"Long",
            subSize: [
                {
                    label: "Waist width", size: 49
                },
                {
                    label: "Inside leg", size: 88
                },
                {
                    label: "Upper leg width", size: 30.5
                },
                {
                    label: "Foot opening width", size: 19.5
                }
            ]
        }, {
            label: 'XXL',
            size: 56,
            category:"Long",
            subSize: [
                {
                    label: "Waist width", size: 51
                },
                {
                    label: "Inside leg", size: 89
                },
                {
                    label: "Upper leg width", size: 31.5
                },
                {
                    label: "Foot opening width", size: 20
                }
            ]
        }, {
            label: '3XL',
            size: 58,
            category:"Long",
            subSize: [
                {
                    label: "Waist width", size: 53
                },
                {
                    label: "Inside leg", size: 90
                },
                {
                    label: "Upper leg width", size: 32.5
                },
                {
                    label: "Foot opening width", size: 20.5
                }
            ]
        }, {
            label: '4XL',
            size: 60,
            category:"Long",
            subSize: [
                {
                    label: "Waist width", size: 55
                },
                {
                    label: "Inside leg", size: 91
                },
                {
                    label: "Upper leg width", size: 33.5
                },
                {
                    label: "Foot opening width", size: 21
                }
            ]
        }
    ],


    long: [
        {
            label: 'S',
            size: 90,
            category:"Small",
            subSize: [
                {
                    label: "Waist width", size: 40
                },
                {
                    label: "Inside leg", size: 88
                },
                {
                    label: "Upper leg width", size: 27
                },
                {
                    label: "Foot opening width", size: 17.5
                }
            ]
        }, {
            label: 'M',
            size: 94,
            category:"Medium",
            subSize: [
                {
                    label: "Waist width", size: 42
                },
                {
                    label: "Inside leg", size: 89
                },
                {
                    label: "Upper leg width", size: 28
                },
                {
                    label: "Foot opening width", size: 18
                }
            ]
        }, {
            label: 'M/L',
            size: 98,
            category:"Medium",
            subSize: [
                {
                    label: "Waist width", size: 44
                },
                {
                    label: "Inside leg", size: 90
                },
                {
                    label: "Upper leg width", size: 29
                },
                {
                    label: "Foot opening width", size: 18.5
                }
            ]
        }, {
            label: 'L',
            size: 102,
            category:"Long",
            subSize: [
                {
                    label: "Waist width", size: 46
                },
                {
                    label: "Inside leg", size: 91
                },
                {
                    label: "Upper leg width", size: 29.5
                },
                {
                    label: "Foot opening width", size: 19
                }
            ]
        },{
            label: 'XL',
            size: 106,
            category:"Long",
            subSize: [
                {
                    label: "Waist width", size: 48
                },
                {
                    label: "Inside leg", size: 92
                },
                {
                    label: "Upper leg width", size: 30.5
                },
                {
                    label: "Foot opening width", size: 19.5
                }
            ]
        }, {
            label: 'XXL',
            size: 110,
            category:"Long",
            subSize: [
                {
                    label: "Waist width", size: 50
                },
                {
                    label: "Inside leg", size: 93
                },
                {
                    label: "Upper leg width", size: 31.5
                },
                {
                    label: "Foot opening width", size: 20
                }
            ]
        }, {
            label: '3XL',
            size: 114,
            category:"Long",
            subSize: [
                {
                    label: "Waist width", size: 52
                },
                {
                    label: "Inside leg", size: 94
                },
                {
                    label: "Upper leg width", size: 32.5
                },
                {
                    label: "Foot opening width", size: 20.5
                }
            ]
        }, {
            label: '4XL',
            size: 118,
            category:"Long",
            subSize: [
                {
                    label: "Waist width", size: 54
                },
                {
                    label: "Inside leg", size: 95
                },
                {
                    label: "Upper leg width", size: 33.5
                },
                {
                    label: "Foot opening width", size: 21
                }
            ]
        }
    ]
}