import Actions from '../../action'
import PantStyle from '../../../config/pant_style.json'

const initialState = {
    isLoading: false,
    error: false,
    selectedCombination: {
        cuff: PantStyle['cuff'].child[0],
        pocket: PantStyle['pocket'].child[0],
        fastening: PantStyle['fastening'].child[0],
        fit: PantStyle['fit'].child[0],
        pleats: PantStyle['pleats'].child[0],
        side_pocket: PantStyle['side_pocket'].child[0],
        rise: PantStyle['rise'].child[0],
        pant_bottom: PantStyle['pant_bottom'].child[0],
        suspender_button: PantStyle['suspender_button'].child[0]
    },
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.UPDATE_PANT_STYLE: {
            const { selectedCombination } = state;
            return {
                ...state,
                selectedCombination: {
                    ...selectedCombination,
                    [action.key]: action.data,
                }
            };
        }
        case Actions.RESET_SELECTED_PANT_STYLE: {
            return {
                ...state,
                ...initialState
            };
        }
        case Actions.EDIT_SELECTED_PANT_COMBINATION: {
            return {
                ...state,
                selectedCombination: action.data,
            };
        } case Actions.ADMIN_UPDATE_PANT_STYLE: {
            return {
                ...state,
                selectedCombination: action.data,
            };
        } case Actions.RESET_CANVAS: {
            return {
                ...state,
                ...initialState
            }
        } case Actions.RESET_STYLE: {
            return {
                ...state,
                ...initialState
            }
        }
        default:
            return state;
    }

};