import { combineReducers } from 'redux';
import blazer from './blazer'
import pant from './pant'
import shirt from './shirt'
import suit from './suit'
import vest from './vest'
export default combineReducers({
    blazer,
    pant,
    shirt,
    suit,
    vest
});