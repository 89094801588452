import React, { useState } from "react";
import { CONTENTS } from "../globals/app-constraints";

const Faqs = ({ }) => {
    const [showAccordion, setShowAccordion] = useState({ accordion1: true, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: false, accordion7: false, accordion8: false, accordion9: false, accordion10: false })
    const handleAccordion = (accordion) => {
        switch (accordion) {
            case 1:
                setShowAccordion({ accordion1: !showAccordion.accordion1, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: false, accordion7: false, accordion8: false, accordion9: false, accordion10: false })
                break;
            case 2:
                setShowAccordion({ accordion1: false, accordion2: !showAccordion.accordion2, accordion3: false, accordion4: false, accordion5: false, accordion6: false, accordion7: false, accordion8: false, accordion9: false, accordion10: false })
                break;
            case 3:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: !showAccordion.accordion3, accordion4: false, accordion5: false, accordion6: false, accordion7: false, accordion8: false, accordion9: false, accordion10: false })
                break;
            case 4:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: !showAccordion.accordion4, accordion5: false, accordion6: false, accordion7: false, accordion8: false, accordion9: false, accordion10: false })
                break;
            case 5:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: !showAccordion.accordion5, accordion6: false, accordion7: false, accordion8: false, accordion9: false, accordion10: false })
                break;
            case 6:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: !showAccordion.accordion6, accordion7: false, accordion8: false, accordion9: false, accordion10: false })
                break;
            case 7:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: false, accordion7: !showAccordion.accordion7, accordion8: false, accordion9: false, accordion10: false })
                break;
            case 8:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: false, accordion7: false, accordion8: !showAccordion.accordion8, accordion9: false, accordion10: false })
                break;
            case 9:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: false, accordion7: false, accordion8: false, accordion9: !showAccordion.accordion9, accordion10: false })
                break;
            case 10:
                setShowAccordion({ accordion1: false, accordion2: false, accordion3: false, accordion4: false, accordion5: false, accordion6: false, accordion7: false, accordion8: false, accordion9: false, accordion10: !showAccordion.accordion10 })
                break;
        }
    }
    return (
        <React.Fragment>
            <div className="pt-4">
                <p className="H-l fs-16 H-m pb-3">{CONTENTS.faq.heading1}</p>
                <div className="faq-accordion">
                    <button className="py-3 d-flex justify-content-between gap-2 align-items-center w-100 bg-transparent border-0" type="button" onClick={() => handleAccordion(1)}>
                        <span className="cursor-pointer H-l fs-14">{CONTENTS.faq.question1}</span>
                        <div className="cursor-pointer">
                            {showAccordion.accordion1 ? <i class="bi bi-dash fs-20"></i> : <i class="bi bi-plus fs-20"></i>}
                        </div>
                    </button>
                    <div className={`${!showAccordion.accordion1 && "d-none"} `}>
                        <div>
                            <p className="fs-14 H-l pb-4">{CONTENTS.faq.answer1}</p>
                        </div>
                    </div>
                </div>
                <div className="faq-accordion">
                    <button className="py-3 d-flex justify-content-between gap-2 align-items-center w-100 bg-transparent border-0" type="button" onClick={() => handleAccordion(2)}>
                        <span className="cursor-pointer H-l fs-14">{CONTENTS.faq.question2}</span>
                        <div className="cursor-pointer">
                            {showAccordion.accordion2 ? <i class="bi bi-dash fs-20"></i> : <i class="bi bi-plus fs-20"></i>}
                        </div>
                    </button>
                    <div className={`${!showAccordion.accordion2 && "d-none"} `}>
                        <div>
                            <p className="fs-14 H-l pb-4">{CONTENTS.faq.answer2}</p>
                        </div>
                    </div>
                </div>
                <div className="faq-accordion">
                    <button className="py-3 d-flex justify-content-between gap-2 align-items-center w-100 bg-transparent border-0" type="button" onClick={() => handleAccordion(3)}>
                        <span className="cursor-pointer H-l fs-14 text-start">{CONTENTS.faq.question3}</span>
                        <div className="cursor-pointer">
                            {showAccordion.accordion3 ? <i class="bi bi-dash fs-20"></i> : <i class="bi bi-plus fs-20"></i>}
                        </div>
                    </button>
                    <div className={`${!showAccordion.accordion3 && "d-none"} `}>
                        <div>
                            <p className="fs-14 H-l pb-4">{CONTENTS.faq.answer3}</p>
                        </div>
                    </div>
                </div>
                <div className="faq-accordion">
                    <button className="py-3 d-flex justify-content-between gap-2 align-items-center w-100 bg-transparent border-0" type="button" onClick={() => handleAccordion(4)}>
                        <span className="cursor-pointer H-l fs-14">{CONTENTS.faq.question4}</span>
                        <div className="cursor-pointer">
                            {showAccordion.accordion4 ? <i class="bi bi-dash fs-20"></i> : <i class="bi bi-plus fs-20"></i>}
                        </div>
                    </button>
                    <div className={`${!showAccordion.accordion4 && "d-none"} `}>
                        <div>
                            <p className="fs-14 H-l pb-4">{CONTENTS.faq.answer4}</p>
                        </div>
                    </div>
                </div>
                <div className="faq-accordion">
                    <button className="py-3 d-flex justify-content-between gap-2 align-items-center w-100 bg-transparent border-0" type="button" onClick={() => handleAccordion(5)}>
                        <span className="cursor-pointer H-l fs-14">{CONTENTS.faq.question5}</span>
                        <div className="cursor-pointer">
                            {showAccordion.accordion5 ? <i class="bi bi-dash fs-20"></i> : <i class="bi bi-plus fs-20"></i>}
                        </div>
                    </button>
                    <div className={`${!showAccordion.accordion5 && "d-none"} `}>
                        <div>
                            <p className="fs-14 H-l pb-4">{CONTENTS.faq.answer5}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="faq-div">
                <p className="H-l fs-16 H-m pb-3">{CONTENTS.faq.heading2}</p>
                <div className="faq-accordion">
                    <button className="py-3 d-flex justify-content-between gap-2 align-items-center w-100 bg-transparent border-0" type="button" onClick={() => handleAccordion(6)}>
                        <span className="cursor-pointer H-l fs-14">{CONTENTS.faq.question6}</span>
                        <div className="cursor-pointer">
                            {showAccordion.accordion6 ? <i class="bi bi-dash fs-20"></i> : <i class="bi bi-plus fs-20"></i>}
                        </div>
                    </button>
                    <div className={`${!showAccordion.accordion6 && "d-none"} `}>
                        <div>
                            <p className="fs-14 H-l pb-4">{CONTENTS.faq.answer6}</p>
                        </div>
                    </div>
                </div>
                <div className="faq-accordion">
                    <button className="py-3 d-flex justify-content-between gap-2 align-items-center w-100 bg-transparent border-0" type="button" onClick={() => handleAccordion(7)}>
                        <span className="cursor-pointer H-l fs-14">{CONTENTS.faq.question7}</span>
                        <div className="cursor-pointer">
                            {showAccordion.accordion7 ? <i class="bi bi-dash fs-20"></i> : <i class="bi bi-plus fs-20"></i>}
                        </div>
                    </button>
                    <div className={`${!showAccordion.accordion7 && "d-none"} `}>
                        <div>
                            <p className="fs-14 H-l pb-4">{CONTENTS.faq.answer7}</p>
                        </div>
                    </div>
                </div>
                <div className="faq-accordion">
                    <button className="py-3 d-flex justify-content-between gap-2 align-items-center w-100 bg-transparent border-0" type="button" onClick={() => handleAccordion(8)}>
                        <span className="cursor-pointer H-l fs-14">{CONTENTS.faq.question8}</span>
                        <div className="cursor-pointer">
                            {showAccordion.accordion8 ? <i class="bi bi-dash fs-20"></i> : <i class="bi bi-plus fs-20"></i>}
                        </div>
                    </button>
                    <div className={`${!showAccordion.accordion8 && "d-none"} `}>
                        <div>
                            <p className="fs-14 H-l pb-4">{CONTENTS.faq.answer8}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="faq-div">
                <p className="H-l fs-16 H-m pb-3">{CONTENTS.faq.heading3}</p>
                <div className="faq-accordion">
                    <button className="py-3 d-flex justify-content-between gap-2 align-items-center w-100 bg-transparent border-0" type="button" onClick={() => handleAccordion(9)}>
                        <span className="cursor-pointer H-l fs-14">{CONTENTS.faq.question9}</span>
                        <div className="cursor-pointer">
                            {showAccordion.accordion9 ? <i class="bi bi-dash fs-20"></i> : <i class="bi bi-plus fs-20"></i>}
                        </div>
                    </button>
                    <div className={`${!showAccordion.accordion9 && "d-none"} `}>
                        <div>
                            <p className="fs-14 H-l pb-4">{CONTENTS.faq.answer9}</p>
                        </div>
                    </div>
                </div>
                <div className="faq-accordion">
                    <button className="py-3 d-flex justify-content-between gap-2 align-items-center w-100 bg-transparent border-0" type="button" onClick={() => handleAccordion(10)}>
                        <span className="cursor-pointer H-l fs-14">{CONTENTS.faq.question10}</span>
                        <div className="cursor-pointer">
                            {showAccordion.accordion10 ? <i class="bi bi-dash fs-20"></i> : <i class="bi bi-plus fs-20"></i>}
                        </div>
                    </button>
                    <div className={`${!showAccordion.accordion10 && "d-none"} `}>
                        <div>
                            <p className="fs-14 H-l pb-4">{CONTENTS.faq.answer10}</p>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default Faqs