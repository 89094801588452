import React from 'react';
import { PATH } from '../../globals/app-constraints'



export const SHIRT_IMAGES = () => {
    
    return (
        <div>
            <div className="">
                <p className='fs-22 text-start H-cb'>Measurements</p>
                <p className='text_grey text-start H-l fs-14'>Shirt size in inches</p>
                <div className="">
                    <div className="d-flex pb-3 pt-4 flex-column gap-16">
                        <div className="d-flex gap-24">
                            <div className="add_measurement_popup_circle">
                                <img src={PATH?.img?.MeasurementCollar} alt="" />
                            </div>
                            <div className="d-flex align-items-center">
                                <p className="fs-14 H-l">collar</p>
                            </div>
                            <div className="d-flex align-items-center">
                                <p className="fs-14 H-l">45 in</p>
                            </div>
                        </div>
                        <div className="d-flex gap-24">
                            <div className="add_measurement_popup_circle">
                                <img src={PATH?.img?.MeasurementBack} alt="" />
                            </div>
                            <div className="d-flex align-items-center">
                                <p className="fs-14 H-l">back</p>
                            </div>
                            <div className="d-flex align-items-center">
                                <p className="fs-14 H-l">15 in</p>
                            </div>
                        </div>
                        <div className="d-flex gap-24">
                            <div className="add_measurement_popup_circle">
                                <img src={PATH?.img?.MeasurementWaist} alt="" />
                            </div>
                            <div className="d-flex align-items-center">
                                <p className="fs-14 H-l">waist</p>
                            </div>
                            <div className="d-flex align-items-center">
                                <p className="fs-14 H-l">15 in</p>
                            </div>
                        </div>
                        <div className="d-flex gap-24">
                            <div className="add_measurement_popup_circle">
                                <img src={PATH?.img?.MeasurementSleeve} alt="" />
                            </div>
                            <div className="d-flex align-items-center">
                                <p className="fs-14 H-l">sleeve</p>
                            </div>
                            <div className="d-flex align-items-center">
                                <p className="fs-14 H-l">15 in</p>
                            </div>
                        </div>
                        <div className="d-flex gap-24">
                            <div className="add_measurement_popup_circle">
                                <img src={PATH?.img?.MeasurementChest} alt="" />
                            </div>
                            <div className="d-flex align-items-center">
                                <p className="fs-14 H-l">chest</p>
                            </div>
                            <div className="d-flex align-items-center">
                                <p className="fs-14 H-l">15 in</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <thead>
              
            <tr>
                < td>
                    <img className="ml-3" src={require('../../asset/images/tape/icons/6.png')} alt="" /><br />
                    <span className="light-color">Collar</span>
                </ td>
                < td >
                    <img className="ml-3" src={require('../../asset/images/tape/icons/1.png')} alt="" /><br />
                    <span className="light-color">Chest</span>
                </ td>
                < td>
                    <img className="ml-3" src={require('../../asset/images/tape/icons/8.png')} alt="" /><br />
                    <span className="light-color">Back</span>
                </ td>
                < td >
                    <img className="ml-3" src={require('../../asset/images/tape/icons/2.png')} alt="" /><br />
                    <span className="light-color">Waist</span>
                </ td>
                < td>
                    <img className="ml-3" src={require('../../asset/images/tape/icons/5.png')} alt="" /><br />
                    <span className="light-color">Sleeve Length</span>
                </ td>
                < td>
                    <img className="ml-3" src={require('../../asset/images/tape/icons/3.png')} alt="" /><br />
                    <span className="light-color">Shirt Length</span>
                </ td>
            </tr>
        </ thead> */}
        </div>
    )
}


export const SUIT_IMAGES = () => {
    return (
        <thead>
            <tr>
                < td >
                    <img className="ml-3" src={require('../../asset/images/tape/icons/1.png')} alt="" /><br />
                    <span className="light-color">Chest</span>
                </ td>
                < td>
                    <img className="ml-3" src={require('../../asset/images/tape/icons/9.png')} alt="" /><br />
                    <span className="light-color">Stomach</span>
                </ td>
                < td >
                    <img className="ml-3" src={require('../../asset/images/tape/icons/4.png')} alt="" /><br />
                    <span className="light-color">Shoulder</span>
                </ td>
                < td>
                    <img className="ml-3" src={require('../../asset/images/tape/icons/5.png')} alt="" /><br />
                    <span className="light-color">Sleeve</span>
                </ td>
                < td>
                    <img className="ml-3" src={require('../../asset/images/tape/icons/10.png')} alt="" /><br />
                    <span className="light-color">Blazer Length</span>
                </ td>
            </tr>
        </ thead>
    )
}


export const PANT_IMAGES = () => {
    return (
        <thead>
            <tr>
                < td>
                    <img className="ml-3" src={require('../../asset/images/tape/icons/9.png')} alt="" /><br />
                    <span className="light-color">Waist</span>
                </ td>
                < td>
                    <img className="ml-3" src={require('../../asset/images/tape/icons/10.png')} alt="" /><br />
                    <span className="light-color">Hip</span>
                </ td>
                < td>
                    <img className="ml-3" src={require('../../asset/images/tape/icons/11.png')} alt="" /><br />
                    <span className="light-color">Crotch</span>
                </ td>
                < td>
                    <img className="ml-3" src={require('../../asset/images/tape/icons/14.png')} alt="" /><br />
                    <span className="light-color">Thigh</span>
                </td>
                < td>
                    <img className="ml-3" src={require('../../asset/images/tape/icons/12.png')} alt="" /><br />
                    <span className="light-color">Pant Length</span>
                </ td>
            </tr>
        </ thead>
    )
}


export const editMeasurementsInput = (measurementId, stringifyJson, decode) => {
    let input = {
        id: decode,
        metafields: [{
            id: measurementId,
            value: stringifyJson,
            type: 'json_string'
        }]
    }
    return input
}

export const customerMeasurementInput = (decode, key, namespace, stringifyJson) => {
    let input = {
        id: decode,
        metafields: [{
            key: key,
            namespace: namespace,
            value: stringifyJson,
            type: 'json_string'
        }]
    }

    return input
}

export const checkoutMeasurementParams = (selectedKey, measurementId, namespace) => {
    let params = {
        key: selectedKey,
        measurementId,
        namespace: namespace
    }
    return params
}