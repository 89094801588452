const NAME = 'SELECTED_STYLE'
export const UPDATE_VEST_STYLE = `${NAME}/UPDATE_VEST_STYLE`;
// export const RESET_SELECTED_SUIT_STYLE = `${NAME}/RESET_SELECTED_SUIT_STYLE`;
export const UPDATE_SUIT_TYPE = `${NAME}/UPDATE_SUIT_TYPE`;
export const getSelectedVestStyle = store => store[NAME].vest

export const updateSelectedVestStyle = (key, data) => {
    return {
        type: UPDATE_VEST_STYLE,
        key,
        data
    }
}



// export const resetSelectedSuitStyle = (key) => {
//     return {
//         type: RESET_SELECTED_SUIT_STYLE,
//         key
//     }
// }


export const updateSuitType = (data) => {
    return {
        type: UPDATE_SUIT_TYPE,
        data
    }
}