import React from 'react'
import { withRouter } from 'react-router-dom'
import { CUSTOMER_UPDATE } from '../../Queries'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { store } from '../../createStore'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isFieldEmpty, isValidEmail, isValidName } from '../../util/validation'
import ButtonDesign from '../button'
import { CONTENTS, PATH } from '../../globals/app-constraints'

class ProfileUpdate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hamburgerState: true,
            show: 'show',
            dialog: 'dialog',
            fields: {},
            errors: {},
            isEditing: false,
            passwordVisible: false,
            passwordField: false
        }
    }

    updateProfile() {
        const { fields } = this.state;
        const validationFields = ["firstName", "email"];

        if (this.state.passwordField) {
            validationFields.push("password");
        }

        if (this.handleSubmitValidation(validationFields)) {
            let { token = {} } = this.props.token;
            let { accessToken } = token;
            const callback = (response) => {
                let { customer, customerUserErrors } = response?.customerUpdate || {};
                let errorMessage = customerUserErrors?.map(o => o.message);
                if (!customer) {
                    toast.error(`${errorMessage}`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.success('Profile Successfully Updated ', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    this.setState({ isEditing: false, passwordField: false });
                    this.props._getCustomerDetails()
                }
            };

            let input = {
                customerAccessToken: accessToken,
                customer: {
                    firstName: fields?.firstName,
                    email: fields?.email,
                    password: fields?.password
                }
            };
            console.log("input", input);
            this.props.customerProfileUpdate(CUSTOMER_UPDATE, input, callback);
        }
    }


    handleChange(e) {
        let { name, value } = e.target;
        let { fields } = this.state;
        fields[name] = value;
        this.setState({ fields }, () => {
            this.handleValidation(name);
        });
    }

    handleValidation(field) {
        let { fields, errors } = this.state;
        switch (field) {
            case 'firstName':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the first name';
                    return false;
                } else if (fields[field].length < 5) {
                    errors[field] = 'First name must be at least 5 characters.';
                    return false;
                } else if (fields[field].length > 20) {
                    errors[field] = 'First name can only be up to 20 characters';
                    return false;
                } else if (!isValidName(fields[field])) {
                    errors[field] = 'Only alphabetic characters are allowed';
                    return false;
                } else {
                    errors[field] = '';
                }
                break;
            case 'email':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the email';
                    return false;
                } else if (!isValidEmail(fields[field])) {
                    errors[field] = 'Please enter a valid email';
                    return false;
                } else {
                    errors[field] = '';
                }
                break;
            case 'password':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the password';
                    return false;
                } else if (fields[field].length < 6) {
                    errors[field] = 'Password must be at least 6 characters';
                    return false;
                } else {
                    errors[field] = '';
                }
                break;
            default:
                return null;
        }
        console.log(errors);
        errors[field] = "";
        this.setState({ errors });
        return true;
    }

    handleSubmitValidation(fields) {
        let isFormValid = true;
        fields.forEach((field) => {
            if (!this.handleValidation(field)) {
                isFormValid = false;
            }
        });
        return isFormValid;
    }

    handleEdit = () => {
        this.setState({ isEditing: true });
    }

    handleCloseEdit = () => {
        this.setState({ isEditing: false, passwordField: false });
    }

    togglePasswordVisibility = () => {
        this.setState((prevState) => ({
            passwordVisible: !prevState.passwordVisible
        }));
    }
    componentDidMount() {
        this.initialStateSet()
    }
    initialStateSet() {
        const { payload } = this.props;
        console.log("payload", payload);
        let { fields } = this.state || {};
        fields['firstName'] = payload?.firstName || '';
        fields['email'] = payload?.email || '';
        this.setState({ fields });
    }
    openPasswordModel() {
        this.setState({ passwordField: true })
    }
    render() {
        const { fields, errors, isEditing, passwordVisible, passwordField } = this.state || {};
        return (
            <React.Fragment>
                <div className='pt-lg-4 pt-2 pb-lg-4 pb-5 m-0 row row-gap-4'>
                    <div className="d-flex align-items-center justify-content-end gap-24 px-0">
                        {!isEditing && (
                            <ButtonDesign
                                btnWidth={'d-flex'}
                                className={'H-l'}
                                buttonImgPath={PATH?.img?.EditIcon}
                                ButtonValue={CONTENTS?.profile?.informationContent?.edit}
                                onClick={() => this.handleEdit()}
                            />
                        )}
                        {isEditing && (
                            <>
                                <ButtonDesign
                                    btnWidth={'d-flex'}
                                    className={'H-l d-flex'}
                                    buttonImgPath={PATH?.img?.EditIcon}
                                    ButtonValue={CONTENTS?.profile?.informationContent?.changePassword}
                                    onClick={() => this.openPasswordModel()}
                                />
                                <ButtonDesign
                                    btnWidth={'d-flex'}
                                    className={'H-l d-flex'}
                                    buttonImgPath={PATH?.img?.SaveIcon}
                                    ButtonValue={CONTENTS?.profile?.informationContent?.save}
                                    onClick={() => this.updateProfile()}
                                />
                                <ButtonDesign
                                    btnWidth={'d-flex'}
                                    className={'H-l d-flex'}
                                    buttonImgPath={PATH?.img?.ExitPopupIcon}
                                    ButtonValue={CONTENTS?.profile?.informationContent?.close}
                                    onClick={this.handleCloseEdit}
                                />
                            </>
                        )}
                    </div>
                    <div className="col-lg-4 col-md-6 px-lg-4">
                        <div className='cart_form'>
                            <label className='form-label H-l fs-14'>{CONTENTS.profile.informationContent.firstName}</label>
                            <input
                                type='text'
                                className='form-control py-2'
                                name="firstName"
                                value={fields['firstName'] || ''}
                                placeholder='Please enter your first name'
                                onChange={(e) => this.handleChange(e)}
                                disabled={!isEditing}
                            />
                        </div>
                        {errors && <small style={{ color: "red" }}>{errors['firstName']}</small>}
                    </div>
                    <div className="col-lg-4 col-md-6 px-lg-4">
                        <div className='cart_form'>
                            <label className='form-label H-l fs-14'>{CONTENTS.profile.informationContent.emailID}</label>
                            <input
                                type='text'
                                className='form-control py-2'
                                placeholder='Please enter your email'
                                name="email"
                                value={fields['email'] || ''}
                                onChange={(e) => this.handleChange(e)}
                                disabled={true}
                            />
                        </div>
                        {errors && <small style={{ color: "red" }}>{errors['email']}</small>}
                        {/* {isEditing &&
                            <div className="col-lg-6 mt-2">
                                <p className='text_grey cursor-pointer m-0 H-l p-0 fs-13'
                                    onClick={() => this.openPasswordModel()}>reset password</p>
                            </div>
                        } */}
                    </div>
                    {passwordField && (
                        <div className="col-lg-4 col-md-6 px-lg-4">
                            <div className='cart_form'>
                                <label className='form-label H-l fs-14'>{CONTENTS.profile.informationContent.password}</label>
                                <div className='password d-flex'>
                                    <input
                                        type={passwordVisible ? 'text' : 'password'}
                                        className='form-control py-2'
                                        name="password"
                                        placeholder='Please enter your password'
                                        value={fields['password'] || ''}
                                        onChange={(e) => this.handleChange(e)}
                                        disabled={!isEditing}
                                    />
                                    <img
                                        src={PATH?.img?.EyeIcon}
                                        className='ps-3 cursor-pointer'
                                        alt='eye-icon'
                                        onClick={() => this.togglePasswordVisibility()}
                                    />
                                </div>
                                {errors && <small style={{ color: "red" }}>{errors['password']}</small>}
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>

        )
    }
}

const mapStateToProp = (state) => {
    return {
        token: Actions.getTokenSelector(store?.getState()) || {},
    }
}

export default withRouter(connect(mapStateToProp, {
    customerProfileUpdate: Actions.customerProfileUpdate
})(ProfileUpdate));