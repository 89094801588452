import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Actions from '../../redux/action'

class CanvasButtons extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    _renderAmounts() {
        const { isShirt, isPant, isSuit, isBlazer, isVest,
            selectedShirtFabric: { selectedShirtFabric },
            selectedPantFabric: { selectedPantFabric },
            selectedSuitFabric: { selectedSuitFabric },
            selectedBlazerFabric: { selectedBlazerFabric },
            selectedVestFabric: { selectedVestFabric }
        } = this.props

        if (isShirt) {
            let { amount, currencyCode } = selectedShirtFabric || {}
            return amount ? `${currencyCode} ${amount}` : null
        } else if (isPant) {
            let { amount, currencyCode } = selectedPantFabric || {}
            return amount ? `${currencyCode} ${amount}` : null
        } else if (isSuit) {
            let { amount, currencyCode } = selectedSuitFabric || {}
            return amount ? `${currencyCode} ${amount}` : null
        } else if (isBlazer) {
            let { amount, currencyCode } = selectedBlazerFabric || {}
            return amount ? `${currencyCode} ${amount}` : null
        } else if (isVest) {
            let { amount, currencyCode } = selectedVestFabric || {}
            return amount ? `${currencyCode} ${amount}` : null
        } else {
            let { amount, currencyCode } = selectedShirtFabric || {}
            return amount ? `${currencyCode} ${amount}` : null
        }
    }

    render() {
        const { name, nextStep, handleOnClickOptions, optionName, isPant } = this.props
        return (
            <ul id="fab_fxd_n" className="left-bar">
                {/* <div className="row mx-0">
                    <p className="col-lg-6 nw_lt">
                        Delivery in 3 to 4 Weeks
                    </p>
                    <p className="col-lg-6 nw_rt">
                        {this._renderAmounts()}
                    </p>
                </div> */}
                {/* <li className="nw_snd_btn">
                    <button type="button">
                        <select className="nw_drp" value={optionName} onChange={(e) => handleOnClickOptions(e)}>
                            <option value='shirt'>Fabric</option>
                            <option value='style'>Style</option>
                            {!isPant && <option value='accent'>Accents</option>}
                        </select>
                    </button></li>
                <li className="nw_snd_btn1"><button type="button" onClick={() => nextStep()}>{name}</button></li> */}
            </ul>
        )
    }
}

const mapStateToProp = (state) => {
    return {
        selectedShirtFabric: Actions.getShirtFabric(state),
        selectedPantFabric: Actions.getSelectedPantFabric(state),
        selectedSuitFabric: Actions.getSelectedSuitFabric(state),
        selectedBlazerFabric: Actions.getSelectedBlazerFabric(state),
        selectedVestFabric: Actions.getVestFabric(state)
    }
}

export default withRouter(connect(mapStateToProp, {
})(CanvasButtons));