export const vestMock = {
    overSize: true,
    type: 'vest',
    short: [
        {
            label: 'XS',
            size: 23,
            category:"Small",
            subSize: [
                {
                    label: "Chest width", size: 51
                },
                {
                    label: "Waist width", size: 45
                },
                {
                    label: "Shoulder width", size: 44
                },
                {
                    label: "Sleeve length", size: 57
                },
                {
                    label: "Jacket length", size: 69
                }
            ]
        }, {
            label: 'M',
            size: 24,
            category:"Medium",
            subSize: [
                {
                    label: "Chest width", size: 53
                },
                {
                    label: "Waist width", size: 47
                },
                {
                    label: "Shoulder width", size: 45
                },
                {
                    label: "Sleeve length", size: 58
                },
                {
                    label: "Jacket length", size: 70
                }
            ]
        }, {
            label: 'M/L',
            size: 25,
            category:"Medium",
            subSize: [
                {
                    label: "Chest width", size: 55
                },
                {
                    label: "Waist width", size: 49
                },
                {
                    label: "Shoulder width", size: 46
                },
                {
                    label: "Sleeve length", size: 59
                },
                {
                    label: "Jacket length", size: 71
                }
            ]
        }, {
            label: 'L',
            size: 26,
            category:"Long",
            subSize: [
                {
                    label: "Chest width", size: 57
                },
                {
                    label: "Waist width", size: 51
                },
                {
                    label: "Shoulder width", size: 47
                },
                {
                    label: "Sleeve length", size: 60
                },
                {
                    label: "Jacket length", size: 72
                }
            ]
        }, {
            label: 'XL',
            size: 27,
            category:"Long",
            subSize: [
                {
                    label: "Chest width", size: 59
                },
                {
                    label: "Waist width", size: 53
                },
                {
                    label: "Shoulder width", size: 48
                },
                {
                    label: "Sleeve length", size: 61
                },
                {
                    label: "Jacket length", size: 73
                }
            ]
        }, {
            label: 'XXL',
            size: 28,
            category:"Long",
            subSize: [
                {
                    label: "Chest width", size: 61
                },
                {
                    label: "Waist width", size: 55
                },
                {
                    label: "Shoulder width", size: 49
                },
                {
                    label: "Sleeve length", size: 61.5
                },
                {
                    label: "Jacket length", size: 74
                }
            ]
        }, {
            label: '3XL',
            size: 29,
            category:"Long",
            subSize: [
                {
                    label: "Chest width", size: 63
                },
                {
                    label: "Waist width", size: 57
                },
                {
                    label: "Shoulder width", size: 50
                },
                {
                    label: "Sleeve length", size: 62
                },
                {
                    label: "Jacket length", size: 75
                }
            ]
        }, {
            label: '4XL',
            size: 30,
            category:"Long",
            subSize: [
                {
                    label: "Chest width", size: 65
                },
                {
                    label: "Waist width", size: 59
                },
                {
                    label: "Shoulder width", size: 51
                },
                {
                    label: "Sleeve length", size: 62.5
                },
                {
                    label: "Jacket length", size: 76
                }
            ]
        }
    ]
}