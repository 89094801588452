import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { isValidEmail } from '../../util/validation'
import { FORGET_PASSWORD } from '../../Queries'
import { toast } from 'react-toastify'
import Loader from '../../component/Loader'
import ButtonDesign from '../../component/button'

class ForgetPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            isLoading: false,
            error: ''
        }
    }

    handleChange(event) {
        const { name, value } = event.target
        this.setState({ [name]: value })
    }

    handleSubmit(event) {
        this.setState({ isLoading: true })
        event.preventDefault()
        let { error } = this.state
        const { username } = this.state
        error = undefined
        if (!isValidEmail(username)) {
            error = 'Invalid Email '
            this.setState({ error, isLoading: false })
            return
        }
        let email = {
            email: username
        }
        this.setState({ error: '' })
        this.props.forgetPassword(FORGET_PASSWORD, email, this.callback)
    }

    callback = (response) => {
        this.setState({ isLoading: false })
        let { customerRecover } = response?.data || {}
        if (customerRecover?.customerUserErrors?.length > 0) {
            if (customerRecover?.customerUserErrors[0]?.message) {
                this.setState({ error: customerRecover?.customerUserErrors[0]?.message })
                return
            }
        } else if (response?.errors?.length > 0) {
            if (response?.errors[0]?.message) {
                this.setState({ error: response?.errors[0]?.message })
                return
            }
        } else {
            toast.success('Please check your mail', {
                position: toast.POSITION.TOP_RIGHT,
            })
            this.closeModal()
        }
    }

    closeModal() {
        this.setState({ email: '', error: '' })
        this.props.closeForgetModal()
    }

    handleNextKeyUp(e) {
        if (e.key === 'Enter') {
            this.handleSubmit(e)
        }
    }
    toggleModalClose = () => {
        document.body.style.overflow = 'auto';
    };
    render() {
        let { username, error, isLoading } = this.state
        return (
            <React.Fragment>
                {isLoading && <Loader />}

                <div className={`modal-backdrop fade show`}></div>
                <div className={`forget_password_modal modal fade show`}
                    id="loginpop"
                    tabIndex="-1" aria-labelledby="exampleModalLabel"
                    aria-modal="true" style={{ display: 'flex' }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content rounded-0 forgot_password_design">
                            <div className='measurement_btn_close d-flex justify-content-end' onClick={() => this.toggleModalClose()}>
                                <span className='cursor-pointer'
                                    data-dismiss="modal" onClick={() => this.closeModal()}>
                                    <i className="bi bi-x fs-20 text_black"></i>
                                </span>
                            </div>
                            <div className="modal-body d-flex flex-column gap-30 p-0">
                                <h1 className='fs-24 H-m text-center'>Forgot Password</h1>
                                {
                                    (error) && (<div className="alert alert-danger" role="alert">{error} </div>)
                                }
                                <div className='d-flex flex-column gap-2 email_password_align'>
                                    <div className='cart_form'>
                                        <div className='input-group'>
                                            <label className='form-label login_input_text_color'>Email</label>
                                            <input type="text"
                                                className="form-control login_form_control"
                                                placeholder="Please enter your email"
                                                name="username"
                                                value={username || ''}
                                                autoComplete="off"
                                                onKeyUp={(event) => this.handleNextKeyUp(event)}
                                                onChange={(e) => this.handleChange(e)} />
                                        </div>
                                    </div>
                                </div>
                                <ButtonDesign
                                    btnWidth={'w-100 px-0 justify-content-center button-primary'}
                                    className={'btn-getmore fs-16 H-cb py-12 w-100 m-0'}
                                    ButtonValue={'submit'}
                                    
                                    onClick={(event) => this.handleSubmit(event)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProp = (state) => {
    return {}
}

export default withRouter(connect(mapStateToProp, {
    forgetPassword: Actions.forgetPassword
})(ForgetPassword))
