import React from 'react'
import { withRouter } from 'react-router-dom'
import { CONTENTS, PATH, SERVERIMG } from '../../globals/app-constraints'
import ButtonDesign from '../../component/button';
// import { VideoPlayer } from '../../component/videoPlayer'
import LazyLoad from 'react-lazyload';

class Story extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        return (
            // <VideoPlayer />
            <React.Fragment>
                <div className="p-0">
                    {/* <iframe src="https://www.youtube.com/embed/Amn3kUkv-E8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                    {/* <div className="mt-2" style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                        <iframe src="https://player.vimeo.com/video/629776617?h=e908b8a1d0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{ position: "absolute", top: '0', left: '0', width: '100%', height: '100%' }} title="ในร้าน (1) (1)">
                        </iframe>
                    </div> */}
                    {/* <div className="main_banner w-100 vh-100 row m-0 position-relative">
                        <div className='overlay-bg'></div>
                        <div className='col-lg-6 p-0 h-100 d-flex align-items-center justify-content-center position-relative'>
                            <LazyLoad className="w-100 h-100 object-fit-cover">
                                <img src={SERVERIMG.image.footer.ourStory.bannerLeftImg} className='w-100 h-100 object-fit-cover' alt="blazer-right-img" />
                            </LazyLoad>
                            <div className="corporate-align container position-absolute z-2">
                                <p className='fs-13 H-l text-white'>{CONTENTS.ourStory.bannertext}</p>
                                <h6 className='exchange_banner_head fs-24 H-cb text-uppercase text-white pt-4'>{CONTENTS.ourStory.bannerHead}</h6>
                                <p className='exchange_text fs-14 H-l text-white text-start pt-3 pb-5'>
                                    {CONTENTS.ourStory.bannerContent}
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-6 p-0 h-100 d-none d-lg-flex align-items-center justify-content-center'>
                            <LazyLoad className="w-100 h-100 object-fit-cover">
                                <img src={SERVERIMG.image.footer.ourStory.bannerRightImg} className='w-100 h-100 object-fit-cover' alt="blazer-right-img" />
                            </LazyLoad>
                        </div>
                    </div> */}
                    <div className='blazer_section mx-0 position-relative pb-lg-5 pt-lg-3'>
                        <div className='container pb-lg-5 pb-4'>
                            <div className='exchange_contents pt-lg-5 pt-4'>
                                <p className='fs-14 H-l mt-2'>
                                    {CONTENTS.ourStory.content1}
                                </p>
                                <p className='fs-14 H-m mt-4'>
                                    {CONTENTS.ourStory.head1}
                                </p>
                                <p className='fs-14 H-l mt-2'>
                                    {CONTENTS.ourStory.content2}
                                </p>
                                <p className='fs-14 H-m mt-4'>
                                    {CONTENTS.ourStory.head2}
                                </p>
                                <p className='fs-14 H-l mt-2'>
                                    {CONTENTS.ourStory.content3Part1}
                                </p>
                                <p className='fs-14 H-l mt-2'>{CONTENTS.ourStory.content3Part2}</p>
                                <ButtonDesign
                                    btnWidth={'gap-12 p-0 d-flex'}
                                    className={'fs-16 H-cb py-12 text-decoration-underline'}
                                    ButtonValue={CONTENTS.ourStory.joinnow}
                                    
                                    Noarrow
                                    onClick={() => this.navCustomClothingPage('blazer')}
                                    arrowImg={PATH?.img?.GetMoreArrow}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className="container">
                            <div className='px-lg-0 px-2'>
                                <div className='our_story_align'>
                                    <p className='fs-16 H-l'>
                                        AKAL stands the test of time by being one of the first few <b>tailor
                                            shops located in Thailand to make a long-lasting impression worldwide.</b>
                                    </p>
                                    <p className='fs-16 H-l mt-2'>
                                        Our Amari Watergate branch is revered throughout Southeast Asia for its high-quality
                                        workmanship and fair prices. So much so that competitors felt inclined to set up shop
                                        right next to our offices in an attempt to funnel away our clients. But fortunately, the
                                        loyalty of our clients isn't so fickle.
                                    </p>
                                    <p className='fs-16 H-l mt-2'>
                                        We pride ourselves on providing high-quality, customizable fabrics at fair prices. Transparency
                                        is essential in building a long-lasting relationship - and that is how we choose to operate with our
                                        clients, tailors, and other stakeholders.
                                    </p>
                                    <p className='fs-16 H-l mt-2'>
                                        <b>We never compromise on quality,</b> and we never charge a premium. High-quality menswear can and should
                                        be affordable for the everyday man.
                                    </p>
                                </div>
                            </div>
                        </div> */}
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(Story)