const NAME = 'SELECTED_STYLE'

export const UPDATE_SHIRT_STYLE = `${NAME}/UPDATE_SHIRT_STYLE`;

export const EDIT_SHIRT_STYLE = `${NAME}/EDIT_SHIRT_STYLE`


export const getShirtStyle = store => store[NAME].shirt

export const updateShirtStyle = (key, data) => {
    return {
        type: UPDATE_SHIRT_STYLE,
        key,
        data
    }
}

export const editShirtStyle = (data) => {
    return {
        type: EDIT_SHIRT_STYLE,
        data
    }
}
