import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Actions from '../../redux/action';
import { store } from '../../createStore';
import { CUSTOMER_ADDRESS_CREATE, CUSTOMER_ADDRESS_UPDATE, CUSTOMER_LIST } from '../../Queries/index';
import { toast } from 'react-toastify';
import { Constants } from '../../util/Constant';
import Loader from '../Loader';
import ButtonDesign from '../../component/button';
import { CONTENTS, PATH } from '../../globals/app-constraints';

class AddAddress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            countryCode: Constants.COUNTRIES,
            isLoading: false,
            errors: {},
            fields: {}
        };
    }

    componentDidMount() {
        if (this.props.isEdit && this.props.fields) {
            // Prepopulate fields for edit mode
            this.setState({
                fields: this.props.fields
            });
        }
    }

    validateFields() {
        let { fields } = this.state;
        let errors = {};
        let isValid = true;

        if (!fields?.country) {
            errors.country = 'please select a country.';
            isValid = false;
        }
        if (!fields?.phone) {
            errors.phone = 'please enter your phone number.';
            isValid = false;
        }
        if (!fields?.address1) {
            errors.address1 = 'please enter your address.';
            isValid = false;
        }
        if (!fields?.city) {
            errors.city = 'please enter your city.';
            isValid = false;
        }
        if (!fields?.province) {
            errors.province = 'please enter your state.';
            isValid = false;
        }
        if (!fields?.zip) {
            errors.zip = 'please enter your zip code.';
            isValid = false;
        }

        this.setState({ errors });
        return isValid;
    }

    handleProceed = (e) => {
        e.preventDefault();
        const { fields } = this.state;
        let { token = {} } = this.props.token
        const { accessToken } = token || {};

        if (this.validateFields()) {
            this.setState({ isLoading: true });

            const callback = (response) => {
                const { errors, customerAddressUpdate, customerAddressCreate } = response || {};
                let userErrors;

                if (fields?.id) {
                    userErrors = customerAddressUpdate?.customerUserErrors || {};
                } else {
                    userErrors = customerAddressCreate?.customerUserErrors || {};
                }

                if (errors?.length > 0) {
                    toast.error(errors[0]?.message, { position: toast.TOP_RIGHT });
                } else if (userErrors?.length > 0) {
                    let error = userErrors[0].message;
                    toast.error(error, { position: toast.TOP_RIGHT });
                } else {
                    let query = CUSTOMER_LIST(accessToken);
                    this.props.getCustomerList(query);
                    this.props.closeAddModal('success');
                    toast.success("Updated Successfully")
                }

                this.setState({ isLoading: false });
            };

            let input = {
                address: {
                    phone: fields?.phone,
                    address1: fields?.address1,
                    zip: fields?.zip,
                    city: fields?.city,
                    province: fields?.province,
                    country: fields?.country
                },
                customerAccessToken: accessToken
            };
            if (fields?.id) {
                input.id = fields?.id;
                this.props.addresssUpdate(CUSTOMER_ADDRESS_UPDATE, input, callback);
            } else {
                this.props.addressCreate(CUSTOMER_ADDRESS_CREATE, input, callback);
            }
        }
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => {
            const newFields = {
                ...prevState.fields,
                [name]: value
            };
            const newErrors = { ...prevState.errors };

            // Clear error for the specific field if it has a value
            if (value) {
                delete newErrors[name];
            }

            return {
                fields: newFields,
                errors: newErrors
            };
        });
    };

    toggleOffcanvasClose = () => {
        document.body.style.overflow = 'auto';
    };

    render() {
        const { countryCode, isLoading, fields, errors } = this.state;
        const { closeAddModal } = this.props;

        return (
            <React.Fragment>
                {isLoading && <Loader />}
                <div className='offcanvas offcanvas-end measurement_offcanvas show' id="checkoutaddress">
                    <div className='overflow-auto h-100'>
                        <div onClick={() => this.toggleOffcanvasClose()}>
                            <span className='measurement_btn_close px-4 pt-3 pb-2 d-block cursor-pointer' data-bs-dismiss="offcanvas" onClick={() => closeAddModal('cancel')}>
                                <img src={PATH?.img?.ExitPopupIcon} alt="" className='address_exit_icon' />
                            </span>
                        </div>
                        <p className='fs-16 H-m text-center pb-2'>{fields?.id ? 'Update' : 'Create'} {CONTENTS.profile.addAddressPopup.address}</p>
                        <div className='offcanvas-body pt-4 pb-5 px-4'>
                            <div className='d-flex flex-column gap-24'>
                                <div className='cart_form'>
                                    <label className='form-label fs-14 H-l login_input_text_color'>{CONTENTS.profile.addAddressPopup.country}</label>
                                    <select className='form-select fs-14 H-l' name='country' value={fields?.country || ''} onChange={this.handleChange}>
                                        <option value="" className='fs-14 H-l'>{CONTENTS.profile.addAddressPopup.chooseCountry}</option>
                                        {Constants.COUNTRIES.map((o) => (
                                            <option key={o.addressCode} value={o.addressCode}>{o.country}</option>
                                        ))}
                                    </select>
                                    {errors.country && <small style={{ color: "red" }}>{errors.country}</small>}
                                </div>
                                {fields?.country && (
                                    <div className='cart_form input-group-append'>
                                        <label className='form-label login_input_text_color'>{CONTENTS.profile.addAddressPopup.phone}</label>
                                        <div className='d-flex'>
                                            {countryCode.map((cObj) => {
                                                let { country, countryCode: code } = cObj;
                                                return (
                                                    country === fields?.country && <span className="input-group-text fs-14 H-l" key={code}>{code}</span>
                                                );
                                            })}
                                            <input
                                                className='form-control py-2 H-l fs-14'
                                                name="phone"
                                                placeholder='please enter your phone'
                                                value={fields?.phone || ''}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        {errors.phone && <small style={{ color: "red" }}>{errors.phone}</small>}
                                    </div>
                                )}

                                <div className='cart_form'>
                                    <label className='form-label fs-14 H-l login_input_text_color'>{CONTENTS.profile.addAddressPopup.address}</label>
                                    <input
                                        className='form-control fs-14 H-l'
                                        name="address1"
                                        value={fields?.address1 || ''}
                                        onChange={this.handleChange}
                                    />
                                    {errors.address1 && <small style={{ color: "red" }}>{errors.address1}</small>}
                                </div>

                                <div className='cart_form'>
                                    <label className='form-label fs-14 H-l login_input_text_color'>{CONTENTS.profile.addAddressPopup.city}</label>
                                    <input
                                        className='form-control fs-14 H-l'
                                        name="city"
                                        value={fields?.city || ''}
                                        onChange={this.handleChange}
                                    />
                                    {errors.city && <small style={{ color: "red" }}>{errors.city}</small>}
                                </div>

                                <div className='cart_form'>
                                    <label className='form-label fs-14 H-l login_input_text_color'>{CONTENTS.profile.addAddressPopup.state}</label>
                                    <input
                                        className='form-control fs-14 H-l'
                                        name="province"
                                        value={fields?.province || ''}
                                        onChange={this.handleChange}
                                    />
                                    {errors.province && <small style={{ color: "red" }}>{errors.province}</small>}
                                </div>

                                <div className='cart_form'>
                                    <label className='form-label fs-14 H-l login_input_text_color'>{CONTENTS.profile.addAddressPopup.zipcode}</label>
                                    <input
                                        className='form-control fs-14 H-l'
                                        name="zip"
                                        value={fields?.zip || ''}
                                        onChange={this.handleChange}
                                    />
                                    {errors.zip && <small style={{ color: "red" }}>{errors.zip}</small>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex gap-2 px-4 py-3 measure_offcanvas_bottom_btns address_popup_btn'>
                        <div data-bs-dismiss="offcanvas">
                            <ButtonDesign
                                btnWidth={'address_popup_close_btn'}
                                className={'w-100 fs-16 H-cb py-12'}
                                ButtonValue={CONTENTS.profile.addAddressPopup.close}
                                onClick={closeAddModal}
                            />
                        </div>
                        <ButtonDesign
                            btnWidth={'address_popup_proceed_btn button-primary'}
                            className={'w-100 fs-16 H-cb py-12'}
                            ButtonValue={fields?.id ? 'Update' : 'Create'}
                            onClick={this.handleProceed}
                        />
                    </div>
                </div>
                <div className="offcanvas-backdrop fade show"></div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: Actions.getTokenSelector(store.getState()) || {},
    };
};

export default withRouter(connect(mapStateToProps, {
    addressCreate: Actions.addressCreate,
    getCustomerList: Actions.getCustomerList,
    addresssUpdate: Actions.addresssUpdate,
})(AddAddress));
