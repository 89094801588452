export const SIGNUP_QUERY = `mutation customerCreate($input: CustomerCreateInput!) {
  customerCreate(input: $input) {
    customer {
      id
      email 
      firstName
      phone
      acceptsMarketing 
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}`