import Actions from '../action'

const initialState = {
    isLoading: false,
    error: false,
    payload: [],
    data: [],
    recentCheckout: {},
    checkoutId: undefined,
    checkoutDetails: {}
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.CHECKOUT_CREATE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CHECKOUT_CREATE_SUCCESS: {
            let { checkout } = action.data?.checkoutCreate
            return {
                ...state,
                isLoading: false,
                payload: action.data,
                checkoutId: checkout && checkout.id,
                error: false
            };
        }
        case Actions.CHECKOUT_CREATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.CHECKOUT_LINE_ITEMS_ADD: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CHECKOUT_LINE_ITEMS_ADD_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: false
            };
        }
        case Actions.CHECKOUT_LINE_ITEMS_ADD_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.CHECKOUT_ATTRIBUTES_UPDATE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CHECKOUT_ATTRIBUTES_UPDATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: false
            };
        }
        case Actions.CHECKOUT_ATTRIBUTES_UPDATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.CHECKOUT_LINE_ITEMS_UPDATE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CHECKOUT_LINE_ITEMS_UPDATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: false
            };
        }
        case Actions.CHECKOUT_LINE_ITEMS_UPDATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.CHECKOUT_LINE_ITEMS_REMOVE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CHECKOUT_LINE_ITEMS_REMOVE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: false,
                payload: [],
                recentCheckout: {}
            };
        }
        case Actions.CHECKOUT_LINE_ITEMS_REMOVE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.CHECKOUT_CUSTOMER_ASSOCIATE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CHECKOUT_CUSTOMER_ASSOCIATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: false
            };
        }
        case Actions.CHECKOUT_CUSTOMER_ASSOCIATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.CHECKOUT_GIFT_CARD_ADD: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CHECKOUT_GIFT_CARD_ADD_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: false
            };
        }
        case Actions.CHECKOUT_GIFT_CARD_ADD_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.CHECKOUT_DISCOUNT_ADD: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CHECKOUT_DISCOUNT_ADD_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: false
            };
        }
        case Actions.CHECKOUT_DISCOUNT_ADD_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.SHIPPING_LINE_UPDATE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.SHIPPING_LINE_UPDATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: false
            };
        }
        case Actions.SHIPPING_LINE_UPDATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        case Actions.SHIPPING_ADDRESS_ADD: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.SHIPPING_ADDRESS_ADD_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                data: action.data,
                error: false
            };
        }
        case Actions.SHIPPING_ADDRESS_ADD_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }


        case Actions.GET_RECENT_CHECKOUT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_RECENT_CHECKOUT_SUCCESS: {
            let { customer } = action.data && action.data?.data
            return {
                ...state,
                isLoading: false,
                recentCheckout: customer && customer,
                error: false
            };
        }
        case Actions.GET_RECENT_CHECKOUT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }


        case Actions.GET_CHECKOUT_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_CHECKOUT_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                checkoutDetails: action.data,
                error: false
            };
        }
        case Actions.GET_CHECKOUT_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }

        case Actions.LOGOUT: {
            return {
                ...initialState
            }
        }

        case Actions.RESET_CANVAS: {
            return {
                ...initialState
            }
        }

        default:
            return state;
    }

};