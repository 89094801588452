import Actions from '../action'

const initialState = {
    measureMentType: '',
    key: '',
    namespace: '',
    measurementId: ''
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.UPDATE_MEASUREMENT_OPTIONS: {
            return {
                ...state,
                measureMentType: action.measurementType,
                key: action.data.key,
                namespace: action.data.namespace,
                measurementId: action.data.measurementId
            };
        }
        default:
            return state;
    }

};