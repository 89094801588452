import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Actions from '../../redux/action';
import { SIGNUP_QUERY } from '../../Queries/signup';
import { isFieldEmpty, isValidEmail, isValidName, isValidNumber } from '../../util/validation';
import { toast } from 'react-toastify';
import { Constants } from '../../util/Constant';
import { CONTENTS, PATH, SERVERIMG } from '../../globals/app-constraints';
import ButtonDesign from '../../component/button';
import LazyLoad from 'react-lazyload';

const blockInvalidChar = (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

class Signup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            errorMessage: '',
            isLoginModal: false,
            isChecked: true,
            isAcceptMarketing: false,
            countryCode: Constants.COUNTRIES,
            showPassword: false,
            showConfirmPassword: false
        };
    }

    handleChange(e) {
        let { fields } = this.state;
        let { name, value } = e.target;
        if (name === "phone" && value == -1) {
            value = 0;
        }
        fields[name] = value;
        this.setState({ fields }, () => {
            this.handleValidation(name);
        });
    }

    handleValidation(field) {
        let { fields, errors } = this.state;
        switch (field) {
            case 'firstName':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the first name';
                } else if (fields[field].length < 5) {
                    errors[field] = 'First name must be at least 5 characters.';
                } else if (fields[field].length > 20) {
                    errors[field] = 'First name can only be up to 20 characters';
                } else if (!isValidName(fields[field])) {
                    errors[field] = 'Only alphabetic characters are allowed';
                } else {
                    errors[field] = '';
                }
                break;
            case 'lastName':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the last name';
                } else if (fields[field].length > 20) {
                    errors[field] = 'Last name can only be up to 20 characters';
                } else if (!isValidName(fields[field])) {
                    errors[field] = 'Only alphabetic characters are allowed';
                } else {
                    errors[field] = '';
                }
                break;
            case 'password':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the password';
                } else if (fields[field].length < 6) {
                    errors[field] = 'Password must be at least 6 characters';
                } else {
                    errors[field] = '';
                }
                break;
            case 'cpassword':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the confirm password';
                } else if (fields[field] !== fields['password']) {
                    errors[field] = 'Passwords do not match';
                } else {
                    errors[field] = '';
                }
                break;
            case 'email':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the email';
                } else if (!isValidEmail(fields[field])) {
                    errors[field] = 'Please enter a valid email';
                } else {
                    errors[field] = '';
                }
                break;
            case 'phone':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please enter the mobile number';
                } else if (!isValidNumber(fields[field])) {
                    errors[field] = 'Please enter a valid mobile number';
                } else {
                    errors[field] = '';
                }
                break;
            case 'country':
                if (isFieldEmpty(fields[field])) {
                    errors[field] = 'Please choose the country';
                } else {
                    errors[field] = '';
                }
                break;
            default:
                break;
        }
        this.setState({ errors });
        return errors[field] === '';
    }
    handleSubmitValidation(fields) {
        let isFormValid = true;
        fields.forEach((field) => {
            console.log(field, this.handleValidation(field), "Check cubmit::::::::::::::::")
            if (!this.handleValidation(field)) {
                isFormValid = false;
            }
        });
        return isFormValid;
    }

    handleSubmit(e) {
        e.preventDefault();
        const { fields, isAcceptMarketing, isChecked } = this.state;
        const { firstName, email, phone, password, lastName, country, cpassword } = fields;
        if (!isChecked) {
            this.setState({ errorMessage: 'You must accept the Terms & Conditions' });
            return;
        }
        if (this.handleSubmitValidation(["email", "password", "firstName", "cpassword"])) {
            const callback = (response) => {
                if (response?.customerCreate === null) {
                    toast.error('Sorry, something went wrong. Please try again later.', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    let { customer, customerUserErrors } = response?.customerCreate || {};
                    let errorMessage = customerUserErrors?.map(o => o.message);
                    if (!customer) {
                        toast.error(`${errorMessage}`, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    } else {
                        toast.success('Registered Successfully', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                        localStorage.removeItem('email');
                        localStorage.removeItem('password');
                        this.onSignupSuccess();
                    }
                }
            };
            let input = {
                email: email,
                password: password,
                firstName: firstName,
                acceptsMarketing: isAcceptMarketing
            };
            console.log("input", input);
            this.props.signUp(SIGNUP_QUERY, input, callback);
        } else {
            this.setState({ errorMessage: 'Please fill out all required fields correctly' });
        }
    }

    onSignupSuccess() {
        this.setState({ isLoginModal: true, fields: {}, errors: {}, errorMessage: '' });
        localStorage.removeItem('email');
        localStorage.removeItem('password');
    }

    onShowConfirmPassword = () => {
        this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
    }

    onShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    }
    render() {
        let { fields, errors, errorMessage, isChecked, isAcceptMarketing, countryCode, showPassword, showConfirmPassword } = this.state;
        return (
            <React.Fragment>
                <div className="h-100">
                    <div className='d-flex mobile_login h-100'>
                        <div className='login_img_section'>
                            <LazyLoad className="w-100 h-100">
                                <img src={PATH.img.SignImg} alt="" className='login_img' />
                            </LazyLoad>
                        </div>
                        <div className='d-flex flex-column login_content_section gap-42 h-100 overflow-auto'>
                            <img src={PATH?.img?.Logo_black} className='login_logo cursor-pointer' alt="logo" onClick={() => this.props.history.push('/')} />
                            <div className='d-flex flex-column gap-12'>
                                <p className='H-m fs-24 text-center'>{CONTENTS.signup.signUp}</p>
                                <p className='fs-14 H-l text-center'>{CONTENTS.signup.content}</p>
                            </div>
                            <div className='d-flex flex-column gap-37'>
                                <div className="">
                                    <label htmlFor="exampleFormControlInput1" className="fs-14 H-l form-label login_input_text_color">{CONTENTS.signup.fullName}</label>
                                    <input type="text"
                                        className="form-control login_form_control"
                                        onChange={(e) => this.handleChange(e)}
                                        name='firstName'
                                        value={fields['firstName'] || ''}
                                    />
                                    {errors['firstName'] && <small style={{ color: "red" }}>{errors['firstName']}</small>}
                                </div>
                                <div className="">
                                    <label className="fs-14 H-l form-label login_input_text_color">{CONTENTS.signup.email}</label>
                                    <input type="email"
                                        className="form-control login_form_control"
                                        onChange={(e) => this.handleChange(e)}
                                        name='email'
                                        value={fields['email'] || ''}
                                    />
                                    {errors['email'] && <small style={{ color: "red" }}>{errors['email']}</small>}
                                </div>
                                <div className="">
                                    <label className="fs-14 H-l form-label login_input_text_color">{CONTENTS.signup.password}</label>
                                    <div className='input-group login_form_control'>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            onChange={(e) => this.handleChange(e)}
                                            name='password'
                                            value={fields['password'] || ''}
                                            className="form-control login_form_control border-0"
                                        />
                                        <span
                                            onClick={() => this.onShowPassword()}
                                            className="input-group-text forgot_password_input_text cursor-pointer"
                                            id="basic-addon1">
                                            <img src={showPassword ? PATH.img.EyeIcon : PATH.img.EyeCloseIcon} className='cursor-pointer' alt='eye-icon' />
                                        </span>
                                    </div>
                                    {errors['password'] && <small style={{ color: "red" }}>{errors['password']}</small>}
                                </div>
                                <div className="">
                                    <label className="fs-14 H-l form-label login_input_text_color">{CONTENTS.signup.confirmPassword}</label>
                                    <div className='input-group login_form_control'>
                                        <input
                                            className="form-control border-0"
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            onChange={(e) => this.handleChange(e)}
                                            name='cpassword'
                                            value={fields['cpassword'] || ''}
                                        />
                                        <span
                                            onClick={() => this.onShowConfirmPassword()}
                                            className="input-group-text forgot_password_input_text cursor-pointer"
                                            id="basic-addon1">
                                            <img src={showConfirmPassword ? PATH.img.EyeIcon : PATH.img.EyeCloseIcon} className='cursor-pointer' alt='eye-icon' />
                                        </span>
                                    </div>
                                    {errors['cpassword'] && <small style={{ color: "red" }}>{errors['cpassword']}</small>}
                                </div>
                            </div>
                            <div className='d-flex flex-column gap-24'>
                                <div className='d-flex align-items-center m-0 w-100'>
                                    <div>
                                        <input
                                            type='checkbox'
                                            checked={isChecked}
                                            className='form-check-input form_checked col-1'
                                            onChange={() => this.setState({ isChecked: !isChecked })}
                                        />
                                    </div>
                                    <div>
                                        <label className='form-check-label text_grey ms-2 H-l'>
                                            {CONTENTS.signup.acceptThe} <strong className='text_black cursor-pointer' onClick={() => this.props.history.push('/terms')}>{CONTENTS.signup.termsConditions}</strong>
                                        </label>
                                    </div>
                                </div>
                                {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                                <div className='d-flex flex-column'>
                                    <ButtonDesign
                                        btnWidth={'w-100 px-0 btn-getmore button-primary'}
                                        className={'fs-16 H-cb py-12 w-100'}
                                        ButtonValue={CONTENTS.signup.signUp}
                                        
                                        // disabled={!isChecked}
                                        onClick={(e) => this.handleSubmit(e)}
                                        Noarrow
                                    />
                                </div>
                                <p className='H-l fs-14 text-center login_input_text_color'>{CONTENTS.signup.alreadyAccount} <span className='H-l fs-15 text-decoration-underline text-color-black cursor-pointer' onClick={() => this.props.history.push('/login')}>{CONTENTS.signup.login}</span> </p>
                            </div>
                        </div>
                    </div>



                    {/*                 
                    <div className="signup_form">
                        <h4 className='f-sb text-center fs-20 mb-2'>Sign Up</h4>
                        <p className='text_grey text-center pt-1'>Become a member today</p>
                        <div className='d-flex flex-column email_password_align signup_forms_align'>
                            <div className='cart_form'>
                                <label className='form-label mb-2'>First Name</label>
                                <input
                                    type='text'
                                    className='form-control py-2'
                                    placeholder='Please enter your first name'
                                    onChange={(e) => this.handleChange(e)}
                                    name='firstName'
                                    value={fields['firstName'] || ''}
                                    onInput={(e) => {
                                        e.target.value = e.target.value?.toString()?.trim()
                                    }}
                                />
                                {errors && <small style={{ color: "red" }}>{errors['firstName']}</small>}
                            </div>
                            <div className='cart_form'>
                                <label className='form-label mb-2'>Last Name</label>
                                <input type='text'
                                    className='form-control py-2'
                                    placeholder='Please enter your last name'
                                    onChange={(e) => this.handleChange(e)}
                                    name='lastName' value={fields['lastName'] || ''}
                                    onInput={(e) => {
                                        e.target.value = e.target.value?.toString()?.trim()
                                    }}
                                />
                                {errors && <small style={{ color: "red" }}>{errors['lastName']}</small>}
                            </div>
                            <div className='cart_form'>
                                <label className='form-label mb-2'>Email</label>
                                <input type='email'
                                    className='form-control py-2'
                                    placeholder='Please enter your email'
                                    onChange={(e) => this.handleChange(e)}
                                    name='email'
                                    value={fields['email'] || ''}
                                />
                                {errors && <small style={{ color: "red" }}>{errors['email']}</small>}
                            </div>
                            <div className='cart_form'>
                                <label className='form-label mb-2'>Country</label>
                                <select className='form-select py-2'
                                    name="country"
                                    value={fields["country"] || ''}
                                    onChange={(e) => this.handleChange(e)}
                                >
                                    <option>Please select your country</option>
                                    {
                                        Constants.COUNTRIES.map((o) => {
                                            return (
                                                <option value={o.countryCode}>{o.country}</option>
                                            )
                                        })
                                    }
                                </select>
                                {errors && <small style={{ color: "red" }}>{errors['country']}</small>}
                            </div>
                            {countryCode.map((i, j) => {
                                let { countryCode: code } = i
                                return (
                                    fields['country'] === code ? (
                                        <React.Fragment>
                                            <div className='cart_form'>
                                                <label className='form-label mb-2' key={j}>Phone</label>
                                                <div className="input-group-append">
                                                    <span className="input-group-text">{code}</span>
                                                    <input
                                                        type="number"
                                                        className="form-control py-2"
                                                        placeholder=""
                                                        name="phone"
                                                        value={fields['phone'] || ''}
                                                        onChange={(e) => this.handleChange(e)}
                                                        onKeyDown={blockInvalidChar}
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ) : null
                                )
                            })}
                            {(errors && fields["country"]) && <small style={{ color: "red" }}>{errors['phone']}</small>}
                            <div className='cart_form'>
                                <label className='form-label mb-2'>Password</label>
                                <div className='input-group'>
                                    <input
                                        className='form-control forgot_password_input py-2'
                                        placeholder='Please enter your password'
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={(e) => this.handleChange(e)}
                                        name='password'
                                        value={fields['password'] || ''}
                                    />
                                    <span
                                        onClick={() => this.onShowPassword()}
                                        className="input-group-text forgot_password_input_text cursor-pointer" id="basic-addon1"><i className="bi bi-eye-fill"></i></span>
                                </div>
                                {errors && <small style={{ color: "red" }}>{errors['password']}</small>}
                            </div>
                            <div className='cart_form'>
                                <label className='form-label mb-2'>Confirm Password</label>
                                <div className='input-group'>
                                    <input
                                        className='form-control forgot_password_input py-2'
                                        placeholder='Please enter your confirm password'
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        onChange={(e) => this.handleChange(e)}
                                        name='cpassword'
                                        value={fields['cpassword'] || ''}
                                    />
                                    <span
                                        onClick={() => this.onShowConfirmPassword()}
                                        className="input-group-text forgot_password_input_text cursor-pointer" id="basic-addon1"><i className="bi bi-eye-fill"></i></span>
                                </div>
                                {errors && <small style={{ color: "red" }}>{errors['cpassword']}</small>}
                            </div>
                        </div>
                        <div className='signup_policy_align'>
                            <div className='form-check d-flex align-items-end'>
                                <div className='d-flex align-items-center m-0 w-100'>
                                    <div className=''>
                                        <input
                                            type='checkbox'
                                            checked={isChecked}
                                            className='form-check-input form_checked col-1' />
                                    </div>
                                    <div className=''>
                                        <label className='form-check-label text_grey ms-2 '
                                            onClick={() => this.props.history.push('/terms')}>
                                            Accept the <strong className='text_black'>Terms & Condition</strong>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='form-check d-flex align-items-end'>
                                <div className='d-flex m-0 w-100'>
                                    <div className=''>
                                        <input type='checkbox' className='form-check-input form_checked'
                                            value={isAcceptMarketing}
                                            onChange={() => this.setState({ isAcceptMarketing: !this.state.isAcceptMarketing })}
                                        />
                                    </div>
                                    <div className=''>
                                        <label className='form-check-label text_grey ms-2'>
                                            Sign up to our newsletter for early access to discounts and promotions
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            className='btn common_btn primary_btn w-100 signup_btn_align'
                            disabled={!isChecked}
                            onClick={(e) => this.handleSubmit(e)}
                            type="button" >Sign Up</button>
                        <div className='d-flex flex-column signup_contents'>
                            <p className='m-0 text_grey'><i className="bi bi-check-lg fs-16 me-2 text_black"></i>Track and manage all orders</p>
                            <p className='m-0 text_grey'><i className="bi bi-check-lg fs-16 me-2 text_black"></i>Experience fast Checkout</p>
                            <p className='m-0 text_grey'><i className="bi bi-check-lg fs-16 me-2 text_black"></i>Early access to promotions</p>
                        </div>
                    </div> */}

                    {/* canvas screen start */}

                    {/* canvas screen end */}

                    {/* admin canvas detail page start */}
                    {/* <div className='admin_canvas_detail_page'>
                        <div className='row m-0'>
                            <div className='col-lg-6 admin_canvas_section'>
                            </div>
                            <div className='col-lg-6'>
                                <h6 className='fs-18 f-sb text-center pb-1'>Measurements</h6>
                                <p className='text-center'>Shirt size in inches</p>
                                <div className='pt-3'>
                                    <div className='row row-gap-3'>
                                        <div className='col-lg-6 measure_icon_align'>
                                            <div className='measure_inputs admin_icon_box d-flex justify-content-between align-items-center'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='admin_canvas_measure_icons'>
                                                        <img src={measure_icon1} alt='back-icon' />
                                                    </div>
                                                    <p className='text_grey m-0 admin_canvas_icon_name'>Collar</p>
                                                </div>
                                                <p className='f-m m-0'>45 in</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 measure_icon_align'>
                                            <div className='measure_inputs admin_icon_box d-flex justify-content-between align-items-center'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='admin_canvas_measure_icons'>
                                                        <img src={measure_icon2} alt='back-icon' />
                                                    </div>
                                                    <p className='text_grey m-0 admin_canvas_icon_name'>Chest</p>
                                                </div>
                                                <p className='f-m m-0'>20 in</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 measure_icon_align'>
                                            <div className='measure_inputs admin_icon_box d-flex justify-content-between align-items-center'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='admin_canvas_measure_icons'>
                                                        <img src={measure_icon3} alt='back-icon' />
                                                    </div>
                                                    <p className='text_grey m-0 admin_canvas_icon_name'>Back</p>
                                                </div>
                                                <p className='f-m m-0'>23 in</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 measure_icon_align'>
                                            <div className='measure_inputs admin_icon_box d-flex justify-content-between align-items-center'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='admin_canvas_measure_icons'>
                                                        <img src={measure_icon4} alt='back-icon' />
                                                    </div>
                                                    <p className='text_grey m-0 admin_canvas_icon_name'>Waist</p>
                                                </div>
                                                <p className='f-m m-0'>34 in</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 measure_icon_align'>
                                            <div className='measure_inputs admin_icon_box d-flex justify-content-between align-items-center'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='admin_canvas_measure_icons'>
                                                        <img src={measure_icon5} alt='back-icon' />
                                                    </div>
                                                    <p className='text_grey m-0 admin_canvas_icon_name'>Sleeve</p>
                                                </div>
                                                <p className='f-m m-0'>20 in</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 measure_icon_align'>
                                            <div className='measure_inputs admin_icon_box d-flex justify-content-between align-items-center'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='admin_canvas_measure_icons'>
                                                        <img src={measure_icon6} alt='back-icon' />
                                                    </div>
                                                    <p className='text_grey m-0 admin_canvas_icon_name'>Shirt Length</p>
                                                </div>
                                                <p className='f-m m-0'>20 in</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='admin_canvas_style_section'>
                            <h6 className='fs-18 f-sb text-center py-4'>Styles</h6>
                            <div className='row m-0 row-gap-4'>
                                <div className='col-lg-4 style_details'>
                                    <div className='admin_canvas_style_section_contents row m-0'>
                                        <div className='col-2'>
                                            <div className='style_icon_section'>
                                                <img src={fabric} alt='fabric-icon' />
                                            </div>
                                        </div>
                                        <div className='col-10'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <p className='f-sb mb-1'>Fabric</p>
                                                </div>
                                                <div className='col-6'>
                                                    <p className='fs-13 f-sb mb-1 text-end'><span className='text_muted'>SKU: </span> <span>PKO3NB7M</span></p>
                                                </div>
                                            </div>
                                            <p className='m-0'>Elijah Dobby Cotton-Blend in red</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 style_details'>
                                    <div className='admin_canvas_style_section_contents row m-0'>
                                        <div className='col-2'>
                                            <div className='style_icon_section'>
                                                <img src={fabric} alt='fabric-icon' />
                                            </div>
                                        </div>
                                        <div className='col-10'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <p className='f-sb mb-1'>Fabric</p>
                                                </div>
                                                <div className='col-6'>
                                                    <p className='fs-13 f-sb mb-1 text-end'></p>
                                                </div>
                                            </div>
                                            <p className='m-0'>Elijah Dobby Cotton-Blend in red</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 style_details'>
                                    <div className='admin_canvas_style_section_contents row m-0'>
                                        <div className='col-2'>
                                            <div className='style_icon_section'>
                                                <img src={fabric} alt='fabric-icon' />
                                            </div>
                                        </div>
                                        <div className='col-10'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <p className='f-sb mb-1'>Fabric</p>
                                                </div>
                                                <div className='col-6'>
                                                    <p className='fs-13 f-sb mb-1 text-end'></p>
                                                </div>
                                            </div>
                                            <p className='m-0'>Elijah Dobby Cotton-Blend in red</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 style_details'>
                                    <div className='admin_canvas_style_section_contents row m-0'>
                                        <div className='col-2'>
                                            <div className='style_icon_section'>
                                                <img src={fabric} alt='fabric-icon' />
                                            </div>
                                        </div>
                                        <div className='col-10'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <p className='f-sb mb-1'>Fabric</p>
                                                </div>
                                                <div className='col-6'>
                                                    <p className='fs-13 f-sb mb-1 text-end'></p>
                                                </div>
                                            </div>
                                            <p className='m-0'>Elijah Dobby Cotton-Blend in red</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 style_details'>
                                    <div className='admin_canvas_style_section_contents row m-0'>
                                        <div className='col-2'>
                                            <div className='style_icon_section'>
                                                <img src={fabric} alt='fabric-icon' />
                                            </div>
                                        </div>
                                        <div className='col-10'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <p className='f-sb mb-1'>Fabric</p>
                                                </div>
                                                <div className='col-6'>
                                                    <p className='fs-13 f-sb mb-1 text-end'></p>
                                                </div>
                                            </div>
                                            <p className='m-0'>Elijah Dobby Cotton-Blend in red</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 style_details'>
                                    <div className='admin_canvas_style_section_contents row m-0'>
                                        <div className='col-2'>
                                            <div className='style_icon_section'>
                                                <img src={fabric} alt='fabric-icon' />
                                            </div>
                                        </div>
                                        <div className='col-10'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <p className='f-sb mb-1'>Fabric</p>
                                                </div>
                                                <div className='col-6'>
                                                    <p className='fs-13 f-sb mb-1 text-end'></p>
                                                </div>
                                            </div>
                                            <p className='m-0'>Elijah Dobby Cotton-Blend in red</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* admin canvas detail page end */}

                    {/* <div className="col-lg-12">
                            <span
                                className={`fa fa-fw ${showPassword ? 'fa-eye-slash' : 'fa-eye'} viewIcon field-icon sign-cs toggle-password cursor-pointer`}
                                onClick={() => this.onShowPassword()}>
                            </span>
                        </div> */}


                    {/* <span
                                className={`fa fa-fw ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'} viewIcon field-icon sign-cs toggle-password cursor-pointer`}
                                onClick={() => this.onShowConfirmPassword()}
                            ></span> */}
                </div >
            </React.Fragment >
        )
    }
}
const mapStateToProp = (state) => { return {} }
export default withRouter(connect(mapStateToProp, { signUp: Actions.signUp })(Signup));
