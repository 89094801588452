export const GET_COLLECTION_FILTER_LIST = (productType, perPage, sort, reverse) => {
    return `{
        shop {
          name
        }
        collectionByHandle(handle:"${productType.trim('')}") {
          description
          id
          products(first: ${perPage},sortKey: ${sort}, reverse: ${reverse}) {
            edges {
              node {
                id
                description
                availableForSale
                productType
                title
                tags
                images(first: ${perPage}) {
                  edges {
                    node {
                      id
                      src
                    }
                  }
                }
                variants(first: ${perPage}) {
                  edges {
                    node {
                      id
                      image {
                        id
                        src
                      }
                      sku
                      priceV2 {
                        amount
                        currencyCode
                      }
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
      
        `
}