import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { ORDER_STATUS } from '../../Queries/orderStatus'
import moment from 'moment'
// import ReactToPrint from "react-to-print";

class OrderStatus extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showDetailCollapse: false,
            showGeneralCollapse: true,
            showOrderCollapse: false
        }
        this.componentRef = undefined
    }
    componentDidMount() {
        const { location: { search } } = this.props
        const modifiedUrl = search.replace(/\?/, '');
        this.props.getOrderList(ORDER_STATUS(modifiedUrl))
    }
    _renderOrderDate(date) {
        return moment(date).format('DD-MM-YYYY')
    }

    _renderOrderStatus(finance, fulfil) {
        if (finance === 'PAID' && fulfil === 'UNFULFILLED') {
            return 'Ready for delivery'
        } else if (finance === 'PAID' && fulfil === 'FULFILLED') {
            return 'Track and Trace'
        } else {
            return 'Progressing'
        }
    }

    render() {
        let { showDetailCollapse } = this.state
        const { orderList } = this.props.getOrder || {}
        const { email, shippingAddress = {}, orderNumber, processedAt, totalPrice, lineItems,
            financialStatus, successfulFulfillments, fulfillmentStatus } = orderList?.data?.node || {}
        const { firstName, address1, province, city, country, zip, phone } = shippingAddress || {}
        return (
            <React.Fragment>
                {/* <div className="row">
                    <div className="col-lg-6 col-6">
                        <h3 className="order-h3">ORDER STATUS</h3>
                        <ReactToPrint
                            trigger={() => <Link className="print-order" to="#">Print Order</Link>
                            }
                            content={() => this.componentRef}
                        />

                    </div>
                </div> */}
                {/* <h4 className="text-center py-md-3 py-4">{this._renderOrderStatus(financialStatus, fulfillmentStatus)}</h4> */}


                <p className='cursor-pointer d-inline-block'><i className="bi bi-arrow-left me-1"></i><span onClick={() => this.props.history.goBack()} className="text_black">Back</span></p>
                <div className='d-flex justify-content-between align-items-center px-2'>
                    <p className='f-m text_grey'>Order: <span className="fs-16 text_black f-sb">#{orderNumber}</span></p>
                    <p className='f-m text_grey'>Order Date: <span className="fs-16 text_black f-sb">{this._renderOrderDate(processedAt)}</span></p>
                </div>

                <div className="px-2">
                    <div className='order_status_content order_tracking_content px-lg-3 px-2 py-lg-4 py-2'>
                        <div className='px-lg-0 px-2 py-4'>
                            <div className='row'>
                                <div className='row col-lg-6'>
                                    <div className='col-lg-5 col-6'>
                                        <h6 className='f-m'>Name</h6>
                                        <p className='f-l'>{firstName}</p>
                                    </div>
                                    <div className='col-lg-7 col-6'>
                                        <h6 className='f-m'>Email</h6>
                                        <p className='f-l text-break'>{email}</p>
                                    </div>
                                </div>
                                <div className='row col-lg-6 pt-lg-0 pt-3'>
                                    <div className='col-lg-5 col-6'>
                                        <h6 className='f-m'>Phone Number</h6>
                                        <p className='f-l'>{phone}</p>
                                    </div>

                                    <div className='col-lg-7 col-6'>
                                        <h6 className='f-m'>Address</h6>
                                        <p className='f-l'>{address1} {city} {province},
                                            <span className='d-block'>{country}-{zip}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul className='px-0 py-3' id="progressbar">
                            <li
                                className="step1">
                                <div className='process_state'></div>
                                <p>Processing</p>
                            </li>
                            <li
                                className={`${((financialStatus === 'PAID' && fulfillmentStatus === 'UNFULFILLED') || ((financialStatus === 'PAID' && fulfillmentStatus === 'FULFILLED'))) ? 'active step2' : 'step2'}`}>
                                <div className='process_state'></div>
                                <p>Ready for Delivery</p>
                            </li>
                            {
                                successfulFulfillments?.length > 0 ? (
                                    <li
                                        className={`${(financialStatus === 'PAID' && fulfillmentStatus === 'FULFILLED') ? 'active step3' : 'step3'}`}>
                                        {successfulFulfillments?.map((o) => {
                                            return o?.trackingInfo.map((k, index) =>
                                                <span key={index}><a href={k?.url} target="blank">Track and Trace</a></span>)
                                        })
                                        }
                                    </li>
                                ) : (<li className="step3">
                                    <div className='process_state notfilled process_not'></div>
                                    <p>Track and Trace</p>
                                </li>)
                            }

                        </ul>
                        <div className="d-flex flex-column row-gap-3">
                            {
                                lineItems?.edges?.length > 0 && lineItems?.edges?.map((o, i) => {
                                    let { variant, quantity, title } = o?.node || {}
                                    return (
                                        <div className='ordered_products px-lg-0 px-2 py-lg-3' key={i}>
                                            <div className='row m-0'>
                                                <React.Fragment>
                                                    <div className='col-lg-6'>
                                                        <div className='row'>
                                                            <div className='ps-2 col-12'>
                                                                <p className='f-m mb-2'>{title}</p>
                                                                {/* <p className='fs-12 text_grey m-0'>Status: {financialStatus}</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-3 pt-lg-0 pt-2'>
                                                        <p className='f-l text-lg-end'>Qty: <span className='fs-15 f-m'>{quantity}</span></p>
                                                    </div>
                                                    <div className='col-lg-3 pt-lg-0 pt-2'>
                                                        <p className='f-sb text-lg-end'>{quantity * variant?.price?.amount} {totalPrice?.currencyCode}</p>
                                                    </div>
                                                </React.Fragment>


                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='px-lg-0 px-2 py-4'>
                        <div className='d-flex flex-column row-gap-2'>
                            <div className='d-flex justify-content-end align-items-center'>
                                <p className='f-sb fs-18 w-20 text-start'>Total</p>
                                <p className='f-sb fs-18 w-20 text-end'>{totalPrice?.amount}{totalPrice?.currencyCode}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="accordion" id="accordionExample">
                    {/* <div className="card">
                        <div className="card-header" id="headingOne">
                            <h2 className="mb-0">
                                <button className="btn btn-link btn-block text-left"
                                    type="button" data-toggle="collapse" data-target="/printcollapseOne"
                                    aria-expanded="true" aria-controls="collapseOne"
                                    onClick={() => this.setState({ showGeneralCollapse: !this.state.showGeneralCollapse })}>
                                    General Information
                                </button>
                            </h2>
                        </div>
                        
                        <div id="collapseOne" className={`collapse ${showGeneralCollapse ? 'show' : ''}`} aria-labelledby="headingOne" data-parent="/printaccordionExample">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-8">
                                       
                                    </div>

                                    <div className="col-lg-4 order-r-stats">
                                        <h3>Order Date: {this._renderOrderDate(processedAt)}</h3>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Order Summary</th>
                                                </tr>
                                                <tr>
                                                    <td>Subtotal: </td>
                                                    <td>{subtotalPrice} {currencyCode} </td>
                                                </tr>
                                                <tr>
                                                    <td>Shipping: </td>
                                                    <td>{totalShippingPrice} {currencyCode}</td>
                                                </tr>
                                                <tr>
                                                    <td>Estimated Tax: </td>
                                                    <td>{totalTax} {currencyCode}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Total</b></td>
                                                    <td>{totalPrice} {currencyCode}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div> */}
                    {successfulFulfillments && successfulFulfillments.map((o, i) => {
                        return (
                            <div className="card">
                                <div className="card-header" id="headingTwo">
                                    <h2 className="mb-0">
                                        <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="/printcollapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={() => this.setState({ showDetailCollapse: !this.state.showDetailCollapse })}>
                                            Detailed View
                                        </button>
                                    </h2>
                                </div>
                                <div id="collapseTwo" className={`collapse ${showDetailCollapse ? 'show' : ''}`} aria-labelledby="headingTwo" data-parent="/printaccordionExample">
                                    <div className="card-body">
                                        <ul key={i}>
                                            <li><img src={require('../../asset/images/truck.png')} className="img-fluid" alt="icons" /></li>
                                            <li><b>Company</b><br />{o?.trackingCompany}</li>
                                            <li><b>Tracking Number</b><br />{o?.trackingInfo.map((k) => k.number)}</li>
                                            <li><a href={o?.trackingInfo?.map((k) => k.url)} target="blank">{o?.trackingInfo?.map((k) => k?.url)}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>



            </React.Fragment>
        )
    }
}
const mapStateToProp = (state) => {
    return {
        getOrder: Actions.getOrder(state),
    }
}

export default withRouter(connect(mapStateToProp, {
    getOrderList: Actions.getOrderList
})(OrderStatus));