import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Actions from '../../redux/action'

class CustomAccents extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    _renderHighMonogram() {
        const { shirtAccents } = this.props.selectedAccents
        const { isFolded } = this.props
        return (
            <svg className={isFolded ? 'folded_svg' : 'front_svg'} viewBox="0 0 400 465" width="326" height={isFolded ? '520' : '477'}>
                <text x={isFolded ? '510' : "430"}
                    y={isFolded ? '440' : '320'}
                    transform="rotate(1,170,155)"
                    fontFamily={shirtAccents?.font}
                    fontSize={isFolded ? '12px' : '8px'} fill={shirtAccents?.fill}>{shirtAccents?.initials}</text>
            </svg>
        )
    }
    _renderCuffMonogram() {
        const { shirtAccents } = this.props.selectedAccents || {}
        return (
            <svg className={'cuff_svg'} viewBox="0 0 400 465" width="326" height={'477'}>
                <text x={"350"} y={'350'} transform="rotate(1,170,155)"
                    fontFamily={shirtAccents?.font}
                    fontSize={'8px'} fill={shirtAccents?.fill}>
                    {shirtAccents?.initials}</text>
            </svg>
        )
    }
    render() {
        const { monogramPosition } = this.props
        return (
            <React.Fragment>
                {monogramPosition === 'cuff' ? (this._renderCuffMonogram()) : (this._renderHighMonogram())}
            </React.Fragment>
        )
    }
}
const mapStateToProp = (state) => {
    return {
        selectedAccents: Actions.getShirtAccent(state),
    }
}

export default withRouter(connect(mapStateToProp, {
})(CustomAccents));