export const GET_ADMIN_ORDER_LIST = (orderId) => {
    return `{
        shop {
          name
        }
        order(id: "${orderId}") {
          id
          lineItems(first: 100) {
            edges {
              node {
                id
                customAttributes {
                  key
                  value
                }
              }
            }
          }
          customAttributes {
            key
            value
          }
        }
      }
      `
}