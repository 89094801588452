import React from 'react'
import Header from '../Header';
import Footer from '../Footer'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../Loader';

export default (Component, isShirt, isPant, isSuit, isBlazer,
    removeFooter, isCheckout, headerOne, isShowLogin, removeHeader, isCanvas, isVest,
    isAdminCanvas, isMeasurement) => {
    class HocComponent extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
            };
        }
        render() {
            const { isLoading } = this.props
            const isHomePage = this.props.location.pathname === '/';
            return (
                <div className={`container-fluid px-0 header_height ${isHomePage ? '' : 'd-flex flex-column'}`}>
                    {
                        !removeHeader && (
                            <Header
                                headerOne={headerOne}
                                isShowLogin={isShowLogin}
                                isShirt={isShirt}
                                isPant={isPant}
                                isSuit={isSuit}
                                isBlazer={isBlazer}
                                isCanvas={isCanvas}
                                isVest={isVest}
                                isAdminCanvas={isAdminCanvas}
                                isMeasurement={isMeasurement}
                            />
                        )
                    }

                    {isLoading && <Loader />}
                    <Component
                        isShirt={isShirt}
                        isPant={isPant}
                        isSuit={isSuit}
                        isBlazer={isBlazer}
                        isVest={isVest}
                        isCheckout={isCheckout}
                    />
                    {!removeFooter && <Footer />}
                </div>
            )
        }
    }
    const mapStateToProp = (state) => {
        let { FABRIC = {}, LOGIN = {}, SIGNUP = {}, CUSTOMER = {},
            PLP = {}, CHECKOUT = {}, ADMIN_ORDER_ID = {}, SELECTED_FABRIC = {},
            MEASUREMENT = {}, ORDER = {}, CUSTOMER_ORDERS = {}, SELECTED_FABRICS = {},
            SELECTED_STYLE = {}, SELECTED_ACCENTS = {} } = state;


        let { blazer: blazerFabric } = SELECTED_FABRICS || {}
        let { blazer: blazerStyle } = SELECTED_STYLE || {}
        let { blazer: blazerAccents } = SELECTED_ACCENTS || {}


        let { suit: suitFabric } = SELECTED_FABRICS || {}
        let { suit: suitStyle } = SELECTED_STYLE || {}
        let { suit: suitAccents } = SELECTED_ACCENTS || {}

        let { pant: pantFabric } = SELECTED_FABRICS || {}
        let { pant: pantStyle } = SELECTED_STYLE || {}

        let { vest: vestFabric } = SELECTED_FABRICS || {}
        let { vest: vestStyle } = SELECTED_STYLE || {}
        let { vest: vestAccents } = SELECTED_ACCENTS || {}


        const { isLoading: fabricLoading } = FABRIC
        const { isLoading: loginLoading } = LOGIN
        const { isLoading: signUpLoading } = SIGNUP
        const { isLoading: customerLoading } = CUSTOMER
        const { isLoading: checkoutLoading } = CHECKOUT
        const { isLoading: selectedFabric } = SELECTED_FABRIC
        const { isLoading: measurementLoading } = MEASUREMENT
        const { isLoading: orderLoading } = ORDER
        const { isLoading: adminOrderLoading } = ADMIN_ORDER_ID
        const { isUrlLoading } = ADMIN_ORDER_ID
        const { isLoading: customerOrderLoading } = CUSTOMER_ORDERS
        const { isLoading: plpLoading } = PLP
        const { isLoading: isBlazerFabricLoading } = blazerFabric
        const { isLoading: isBlazerStyleLoading } = blazerStyle
        const { isLoading: isBlazerAccentsLoading } = blazerAccents

        const { isLoading: isPantFabricLoading } = pantFabric
        const { isLoading: isPantStyleLoading } = pantStyle

        const { isLoading: isSuitFabricLoading } = suitFabric
        const { isLoading: isSuitStyleLoading } = suitStyle
        const { isLoading: isSuitAccentsLoading } = suitAccents

        const { isLoading: isVestFabricLoading } = vestFabric
        const { isLoading: isVestStyleLoading } = vestStyle
        const { isLoading: isVestAccentsLoading } = vestAccents

        return {
            isLoading: fabricLoading || measurementLoading
                || adminOrderLoading || selectedFabric ||
                loginLoading || signUpLoading || customerLoading
                || checkoutLoading || orderLoading || customerOrderLoading || plpLoading ||
                isBlazerFabricLoading || isBlazerStyleLoading || isBlazerAccentsLoading
                || isPantFabricLoading || isPantStyleLoading ||
                isBlazerAccentsLoading || isBlazerFabricLoading || isBlazerStyleLoading ||
                isVestAccentsLoading || isVestFabricLoading || isVestStyleLoading ||
                isSuitAccentsLoading || isSuitStyleLoading || isSuitFabricLoading || isUrlLoading
        }
    }

    return withRouter(connect(mapStateToProp)(HocComponent));
}