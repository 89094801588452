import React from 'react'
import { withRouter } from 'react-router-dom'

class Monogram extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { handleIntialChange, removeMonogram, checkFontStyle,
            chooseMonogramColor, isSuit, selectedAccents } = this.props
        const { fill, font, initials } = selectedAccents || {}
        return (
            <React.Fragment>
                {/* <div className="form-group text-left w-90 mx-auto mt-sm-4 mt-0">
                    <label>{isSuit ? `Suit Initials` : `Shirt Initials`}</label>
                    <input
                        type="text"
                        className="form-control w-90"
                        placeholder="Type Your Initials"
                        value={initials}
                        maxLength={isSuit ? 20 : 5}
                        onChange={(e) => handleIntialChange(e)}
                    />
                    <b className="clse" onClick={() => removeMonogram()}>X</b>
                </div> */}
                <div className='canvas_style_section'>
                    <p className='H-l fs-13 pb-2 text-lowercase'>{isSuit ? `suit initials` : `shirt initials`}</p>
                    <input
                        type='text'
                        className='form-control canvas-input'
                        value={initials}
                        maxLength={isSuit ? 20 : 5}
                        placeholder={isSuit ? `suit initials` : `shirt initials`}
                        onChange={(e) => handleIntialChange(e)} />
                </div>
                <div className=''>
                    <p className='H-l fs-13 pb-2 text-lowercase'>Font</p>
                    <div className='d-flex gap-2'>
                        <div className={`style_type ${font === 'Calibri' ? 'active_type' : ''}`}
                            onClick={(e) => checkFontStyle('Calibri')}>
                            <p className='px-2 font1 text-lowercase'>Abc</p>
                        </div>
                        <div className={`style_type ${font === 'Yu Gothic' ? 'active_type' : ''}`}
                            onClick={(e) => checkFontStyle('Yu Gothic')}>
                            <p className='px-2 font2 text-lowercase'>Abc</p>
                        </div>
                        <div className={`style_type ${font === 'Brush Script MT,cursive' ? 'active_type' : ''}`}
                            onClick={(e) => checkFontStyle('Brush Script MT,cursive')}>
                            <p className='px-2 font3 text-lowercase'>Abc</p>
                        </div>
                        <div className={`style_type ${font === 'Baskerville' ? 'active_type' : ''}`}
                            onClick={(e) => checkFontStyle('Baskerville')}>
                            <p className='px-2 font4 text-lowercase'>Abc</p>
                        </div>
                    </div>
                </div>


                <div className="mt-2">
                    <p className="H-l fs-13 pb-2 text-lowercase">Monogram Thread Color</p>
                    <div className='d-flex flex-wrap gap-3'>
                        <div className={`cursor-pointer position-relative ${fill === '#000000' ? 'active_color' : ''}`}>
                            <div
                                className="m-1" onClick={() => chooseMonogramColor('#000000')}
                                style={{ width: "40px", height: "40px", background: "#000000", border: '1px solid #ccc', borderRadius: '4px' }}></div>
                        </div>
                        <div className={`cursor-pointer position-relative ${fill === '#FFFFFF' ? 'active_color' : ''}`}>
                            <div
                                className="m-1"
                                onClick={() => chooseMonogramColor('#FFFFFF')}
                                style={{ width: "40px", height: "40px", background: "#FFFFFF", border: '1px solid #ccc' }}
                            ></div>
                        </div>
                        <div className={`cursor-pointer position-relative ${fill === '#FFD700' ? 'active_color' : ''}`}>
                            <div
                                className="m-1"
                                onClick={() => chooseMonogramColor('#FFD700')}
                                style={{ width: "40px", height: "40px", background: "#FFD700", border: '1px solid #ccc' }}>
                            </div>
                        </div>
                        <div className={`cursor-pointer position-relative ${fill === '#7030A0' ? 'active_color' : ''}`}>
                            <div
                                className="m-1"
                                onClick={() => chooseMonogramColor('#7030A0')}
                                style={{ width: "40px", height: "40px", background: "#7030A0 ", border: '1px solid #ccc' }}>
                            </div>
                        </div>
                        <div className={`cursor-pointer position-relative ${fill === '#002060' ? 'active_color' : ''}`}>
                            <div
                                className="m-1"
                                onClick={() => chooseMonogramColor('#002060')}
                                style={{ width: "40px", height: "40px", background: "#002060", border: '1px solid #ccc' }}>
                            </div>
                        </div>
                        <div className={`cursor-pointer position-relative ${fill === '#00B050' ? 'active_color' : ''}`}>
                            <div
                                className="m-1"
                                onClick={() => chooseMonogramColor('#00B050',)}
                                style={{ width: "40px", height: "40px", background: "#00B050 ", border: '1px solid #ccc', borderradius: '10px' }}>
                            </div>
                        </div>
                        <div className={`cursor-pointer position-relative ${fill === '#FF0000' ? 'active_color' : ''}`}>
                            <div
                                className="m-1"
                                onClick={() => chooseMonogramColor('#FF0000')}
                                style={{ width: "40px", height: "40px", background: "#FF0000 ", border: '1px solid #ccc' }}>
                            </div>
                        </div>
                        <div className={`cursor-pointer position-relative ${fill === '#C00000' ? 'active_color' : ''}`}>
                            <div
                                className="m-1"
                                onClick={() => chooseMonogramColor('#C00000')}
                                style={{ width: "40px", height: "40px", background: "#C00000 ", border: '1px solid #ccc' }}>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withRouter(Monogram)