import { detectBrowser } from "../util/detect-browser"

export const DEV_URL = 'http://mikegulati.proglint.com/'

export const LOCAL_URL = 'http://localhost:3000/'

export const TRANSPARENT = `transparent/transparent${(detectBrowser())}`

export const PANT_TRANSPARENT = 'transparent/transparent.png'

export const SUIT_TRANSPARENT = 'transparent/transparent.png'

export const Collection = {
    shirt: 'Shirts',
    pant: 'Pant',
    suit: 'Suits',
    lining: 'Linings',
    plp: 'Plpshirt',
    featureProducts: 'FeatureProducts',
    home: 'home',
    blazer: 'blazer',
    vest: 'vests',
    plpPant: 'plpPant',
    plpBlazer: 'plpBlazer',
    plpSuit: 'plpSuit',
    plpVest: 'plpVest'
}

export const StaticHeader = [
    "100% Made to Measure",
    "Build Your Own Reality",
    "Guaranteed Custom Fit",
    "Delivery In 21 Days Or Less"
]

export const SHOPIFY_ADMIN_ORDER_URL = 'gid://shopify/Order/'


export const CALENDY = 'https://calendly.com/'


export const CHATBOT_URL = '//js-na1.hs-scripts.com/8155811.js'

export const TOTAL_ORDERS = 250

export const RECENT_ORDERS = 10
