import { put, takeLatest, all } from 'redux-saga/effects';
import Actions from '../action';
import { getProductsDetails, getQuery } from '../../service';
export function* plpList({ query }) {
    try {
        const response = yield getQuery(query);
        yield put(Actions.getPLPListSuccess(response));
    } catch (error) {
        yield put(Actions.getPLPListFailure(error));
    }
}

export function* getHomeProducts({ query }) {
    try {
        const response = yield getQuery(query);
        yield put(Actions.getHomeProductSuccess(response));
    } catch (error) {
        yield put(Actions.getHomeProductFailure(error));
    }
}


export function* getProductDetails({ data }) {
    try {
        const response = yield getProductsDetails(data);
        yield put(Actions.pdpDetailsSuccess(response));
    } catch (error) {
        yield put(Actions.pdpDetailsFailure(error));
    }
}


export default function* () {
    yield all([
        takeLatest(Actions.PLP_LIST, plpList),
        takeLatest(Actions.GET_HOME_PRODUCTS, getHomeProducts),
        takeLatest(Actions.PDP_DETAILS, getProductDetails)
    ]);
}