import Actions from '../../action'
const initialState = {
    isLoading: false,
    error: false,
    shirtAccents: {
        fill: "#000000",
        font: "Calibri",
        initials: "",
        selectedButton: 'white',
        hole: 'white',
        thread: 'white',
        variantId: undefined,
        monogramPosition: 'high',
        selectedCollarFabric: 'default',
        selectedCuffFabric: 'default',
        elbowPatches: 'default',
        selectedAccentStyle: false,
        cuffFabricId: null,
        collarFabricId: null,
        elbowPatchesId: null,
        isShowCasual: false,
        cuffFabricName: null,
        collarFabricName: null,
        elbowFabricName: null
    }
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.UPDATE_SHIRT_ACCENTS: {
            let { shirtAccents } = state
            return {
                ...state,
                shirtAccents: {
                    ...shirtAccents,
                    [action.key]: action.value
                },
            };
        }
        case Actions.REMOVE_SHIRT_MONOGRAM: {
            let { shirtAccents } = state
            return {
                ...state,
                shirtAccents: {
                    ...shirtAccents,
                    initials: ''
                },
            };
        }
        case Actions.EDIT_SHIRT_ACCENTS: {
            return {
                ...state,
                shirtAccents: action.data
            };
        }
        case Actions.ADMIN_UPDATE_SHIRT_ACCENTS: {
            return {
                ...state,
                shirtAccents: action.data
            };
        } case Actions.UPDATE_SHIRT_TYPE: {
            let { shirtAccents } = state
            return {
                ...state,
                shirtAccents: {
                    ...shirtAccents,
                    isShowCasual: action.data
                },
            };
        } case Actions.RESET_CANVAS: {
            return {
                ...state,
                ...initialState
            }
        } case Actions.RESET_STYLE: {
            return {
                ...state,
                ...initialState
            }
        }
        default:
            return state;
    }

};