import React from 'react'
import { withRouter } from 'react-router-dom'
import Shipping from '../../component/Shipping'
import ContentHeader from '../../component/ContentHeader'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { store } from '../../createStore'
import { LAST_INCOMPLETE_CHECK_OUT_ID } from '../../Queries'
import 'react-toastify/dist/ReactToastify.css';
import { titleCase } from '../../util/random_number'
class Payment extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            fields: {}
        }
    }
    handleChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value })
    }
    componentDidMount() {
        let { token = {} } = this.props.token
        let { accessToken } = token || {}
        if (accessToken) {
            this.props.getRecentCheckOutDetails(LAST_INCOMPLETE_CHECK_OUT_ID(accessToken))
        } else {
            this.props.history.push('/')
        }
    }
    _renderMeasurements(getMeasurements) {
        let { value } = getMeasurements || {}
        let measurements = JSON.parse(value)
        return measurements && measurements.value.map((o, i) => {
            return (
                Object.keys(o).map((key, index) => {
                    return (
                        <tr key={index}>
                            <td> {titleCase(key)} </td>
                            <td className='text-left'>{`${o[key]}`}</td>
                        </tr>
                    )
                })


            )
        })
    }
    _renderRootMeasurements() {
        const { customerList = {} } = this.props.customerList || {}
        const { data = {} } = customerList || {}
        let { lastIncompleteCheckout } = (data && data.customer) || {}
        let { lineItems } = lastIncompleteCheckout || {}
        let measurementDetails = ''
        let getMeasurements = lineItems && lineItems.edges.map((o) => o?.node?.customAttributes)
        if (getMeasurements?.length > 0) {
            measurementDetails = getMeasurements && getMeasurements[0].find((j) => j.key === "Measurement")
        }
        if (measurementDetails) {
            return this._renderMeasurements(measurementDetails)
        }
    }
    render() {
        const { customerList = {} } = this.props.customerList
        const { data = {} } = customerList
        let { lastIncompleteCheckout, email } = (data && data.customer) || {}
        let { lineItems, currencyCode } = lastIncompleteCheckout || {}
        return (
            <div className="col-lg-12 shopping_crt">
                <ContentHeader Payment={true} />
                <div className="col-12 s_heading">
                    <h2 className="text-center mb-5"><b>ORDER SUMMARY</b></h2>
                    <p className="text-center"><a href="/cart">{' << '}Back to the shopping cart</a></p>
                </div>

                <div className="container payment"  >
                    <div className="row">
                        <div className="col-lg-6 firt">
                            <form>
                                <h4><b>Customer details*</b></h4>
                                <label>E-mail</label>
                                <input type="text" disabled className="form-control" placeholder="" value={email} onChange={(e) => this.handleChange(e)} />
                                <h4><b>Items</b></h4>
                                <table className="mb-lg-5 mb-3">
                                    {
                                        lineItems && lineItems.edges.map((o, i) => {
                                            let { title, quantity, variant = {} } = o.node
                                            return (
                                                <tbody>
                                                    <tr key={i}>
                                                        <td> {quantity} x {title} </td>
                                                        <td>{(quantity * variant.price)} &nbsp;
                                                            {currencyCode} </td>
                                                    </tr>
                                                </tbody>
                                            )
                                        })
                                    }
                                </table>
                            </form>
                        </div>
                        <Shipping
                            checkOutList={lastIncompleteCheckout} />
                    </div>
                </div>
            </div>

        )
    }
}
const mapStateToProp = (state) => {
    return {
        token: Actions.getTokenSelector(store.getState()) || {},
        recentCheckout: Actions.checkout(state),
        customerList: Actions.getCustomer(state),
    }
}

export default withRouter(connect(mapStateToProp, {
    getRecentCheckOutDetails: Actions.getRecentCheckOutDetails,
})(Payment));
