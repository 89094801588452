import { defaultBlazerFabrics } from '../../../util/defaultFabricObject';
import Actions from '../../action'
const initialState = {
    isLoading: false,
    error: false,
    selectedBlazerFabric: defaultBlazerFabrics()
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.UPDATE_BLAZER_FABRIC: {
            let { selectedBlazerFabric } = state
            return {
                ...state,
                selectedBlazerFabric: {
                    ...selectedBlazerFabric,
                    ...action.value
                }
            };
        }
        case Actions.UPDATE_BLAZER_AMOUNT: {
            return {
                ...state,
                selectedAmount: action.data.amount,
                selectedCurrencyCode: action.data.currencyCode
            };
        } case Actions.SET_DEFAULT_BLAZER_FABRIC: {
            return {
                ...state,
                selectedBlazerFabric: defaultBlazerFabrics(action.data),
            };
        } case Actions.RESET_CANVAS: {
            return {
                ...state,
                ...initialState
            }
        }
        default:
            return state;
    }

};