import React from 'react'
import { withRouter } from 'react-router-dom'
import { PANT_IMAGES, SHIRT_IMAGES, SUIT_IMAGES } from '../util/Canvas_Measurement'
import { Constants } from '../util/Constant'
class CartModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    _renderShirtProducts(attributes) {
        let { value } = (attributes && attributes[0]) || {}
        let { value: selectedFabric } = (attributes && attributes[1]) || {}
        let { value: selectedAccent } = (attributes && attributes[2]) || {}
        let { value: measurement } = (attributes && attributes[3]) || {}
        let selectedMeasurement = JSON.parse(measurement)
        let { value: measure } = selectedMeasurement || {}
        let shirtAccents = JSON.parse(selectedAccent)
        let selectedFabrics = JSON.parse(selectedFabric)
        let selectedCombination = JSON.parse(value)
        const { sleeve, back, bottom, collar, cuff, fit, placket, pocket } = selectedCombination || {}
        const { fill, font, hole, initials, selectedButton, thread, monogramPosition } = shirtAccents || {}
        let { name, images } = selectedFabrics || {}
        let selectedImage = images && images.edges?.map((o) => o?.node?.src)
        return (
            <div className="row">
                <div className="col-lg-6 cart_pop_up">
                    <h4>Details</h4>
                    <div>
                        <p>Sleeve : {sleeve && sleeve.title}</p>
                        <p>Collar : {collar && collar.title}</p>
                        <p>Cuff : {cuff && cuff.title}</p>
                        <p>Fit : {fit && fit.title}</p>
                        <p>Placket : {placket && placket.title}</p>
                        <p>Back : {back && back.title}</p>
                        <p>Bottom : {bottom && bottom.title}</p>
                        <p>Pocket : {pocket && pocket.title}</p>
                    </div>
                    <h5>Options</h5>
                    <div>
                        {
                            initials && (
                                <>
                                    <p>Intials: {initials}</p>
                                    <p>Monogram color : {fill && fill}</p>
                                    <p>Font : {font && font}</p>
                                    <p>Position: {monogramPosition && monogramPosition}</p>
                                </>
                            )
                        }
                        <p>Hole : {hole && hole}</p>
                        <p>Selected Button : {selectedButton && selectedButton}</p>
                        <p>Thread : {thread && thread}</p>
                    </div>
                </div>
                <div className="col-lg-6 fb_popup_img">
                    <h4>Fabric</h4>
                    <p><b>{name}</b></p>
                    <img src={selectedImage} alt="#" />
                </div>
                <div className="col-lg-12">
                    <h4>Measurements</h4>
                    <div className="table-responsive">
                        <table className="table text-center shirt-mm">
                            {SHIRT_IMAGES()}
                            <tbody>
                                <tr>
                                    <td>{measure?.collar}</td>
                                    <td>{measure?.chest}</td>
                                    <td>{measure?.back}</td>
                                    <td>{measure?.waist}</td>
                                    <td>{measure?.sleeve}</td>
                                    <td>{measure?.shirtLength}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div >
        )
    }

    _renderSuitStandardMeasurements(measurementValue) {
        const { pantFields, suitFields } = measurementValue || {}
        return (
            <>
                <table className="table text-center shirt-mm">
                    {SUIT_IMAGES()}

                    <tbody>
                        <tr>
                            <td>{suitFields?.chest}</td>
                            <td>{suitFields?.stomach}</td>
                            <td>{suitFields?.shoulder}</td>
                            <td>{suitFields?.sleeve}</td>
                            <td>{suitFields?.shirtLength}</td>
                        </tr>
                    </tbody>

                </table>
                <table className="table text-center shirt-mm">
                    {PANT_IMAGES()}
                    <tbody>
                        <tr>
                            <td>{pantFields?.waist}</td>
                            <td>{pantFields?.hip}</td>
                            <td>{pantFields?.crotch}</td>
                            <td>{pantFields?.thigh}</td>
                            <td>{pantFields?.pantLength}</td>
                        </tr>
                    </tbody>

                </table>
            </>
        )
    }

    _renderSuitCustomMeasurement(measurementValue) {
        return (
            <>
                <table className="table text-center shirt-mm">
                    {SUIT_IMAGES()}
                    <tbody>
                        <tr>
                            <td>{measurementValue?.chest}</td>
                            <td>{measurementValue?.stomach}</td>
                            <td>{measurementValue?.shoulder}</td>
                            <td>{measurementValue?.sleeve}</td>
                            <td>{measurementValue?.shirtLength}</td>
                        </tr>
                    </tbody>

                </table>
                <table className="table text-center shirt-mm">
                    {PANT_IMAGES()}
                    <tbody>
                        <tr>
                            <td>{measurementValue?.waist}</td>
                            <td>{measurementValue?.hip}</td>
                            <td>{measurementValue?.crotch}</td>
                            <td>{measurementValue?.thigh}</td>
                            <td>{measurementValue?.pantLength}</td>
                        </tr>
                    </tbody>

                </table>
            </>
        )
    }

    _renderSuitProducts(attributes) {
        let { value } = (attributes && attributes[0]) || {}
        let { value: selectedSuitFabric } = (attributes && attributes[1]) || {}
        let { value: selectedSuitAccents } = (attributes && attributes[2]) || {}
        let { value: measurement } = (attributes && attributes[3]) || {}
        let selectedMeasurement = JSON.parse(measurement)
        let selectedSuitFabricDetails = JSON.parse(selectedSuitFabric)
        let selectedAccents = JSON.parse(selectedSuitAccents)
        let selectedCombination = JSON.parse(value)
        const { productSizeType, key, value: measurementValue, type } = selectedMeasurement || {}
        const { style, fit, pocket, sleeve_buttons, back_style, breast_pocket, lapels, vests, lapels_width } = selectedCombination || {}
        const { thread, hole, button, fill, initials, font } = selectedAccents || {}
        let { name, images } = selectedSuitFabricDetails || {}
        let selectedImage = images && images.edges?.map((o) => o?.node?.src)
        return (
            <div className="row">
                <div className="col-lg-6 cart_pop_up">
                    <h4>Details</h4>
                    <div>
                        <p>Suit Styles : {style && style.title}</p>
                        <p>Fit : {fit && fit.title}</p>
                        <p>Sleeve Button : {sleeve_buttons && sleeve_buttons.title}</p>
                        <p>Back Style : {back_style && back_style.title}</p>
                        <p>Pocket : {pocket && pocket.title}</p>
                        <p>Breast Pocket : {breast_pocket && breast_pocket.title}</p>
                        <p>Lapels : {lapels && lapels.title}</p>
                        <p>Lapels Width : {lapels_width && pocket.title}</p>
                        <p>Suit Type : {vests && vests.title}</p>
                    </div>
                    <h5>Options</h5>
                    <div>
                        <p>Button : {button}</p>
                        <p>Hole : {hole}</p>
                        <p>Thread : {thread}</p>
                        {
                            initials && (
                                <>
                                    <p>Monogram color : {fill} </p>
                                    <p>Intials: {initials}</p>
                                    <p>Font: {font}</p>
                                </>
                            )
                        }
                    </div>
                </div>
                <div className="col-lg-6 fb_popup_img">
                    <h4>Fabric</h4>
                    <p><b>{name}</b></p>
                    <img src={selectedImage} alt="#" />
                </div>
                <div className="col-lg-12">
                    <h4>Measurements</h4>
                    {
                        key && (
                            <>
                                <span>Size :{key}</span> <span>{productSizeType}</span>
                            </>
                        )
                    }
                    <div className="table-responsive">
                        {
                            type === Constants.MEASUREMENT_TYPES.STANDARD_MEASUREMENT ? this._renderSuitStandardMeasurements(measurementValue) : this._renderSuitCustomMeasurement(measurementValue)
                        }

                    </div>
                </div>
            </div >
        )

    }

    _renderVestProducts(attributes) {

        let selectedCombination = JSON.parse(attributes && attributes[0].value)

        let vestsFabrics = JSON.parse(attributes && attributes[2].value)

        let selectedSuitAccents = JSON.parse(attributes && attributes[3].value)

        let selectedVestAccents = JSON.parse(attributes && attributes[4].value)

        let selectedVestCombination = JSON.parse(attributes && attributes[5].value)

        let selectedMeasurement = JSON.parse(attributes && attributes[6].value)

        const { productSizeType, key, value: measurementValue, type } = selectedMeasurement || {}
        const { style, fit, pocket, sleeve_buttons, back_style,
            breast_pocket, lapels, lapels_width } = selectedCombination || {}
        const { thread, hole, button, fill, initials, font } = selectedSuitAccents || {}
        let { name, images } = vestsFabrics || {}
        let selectedImage = images && images.edges?.map((o) => o?.node?.src)
        return (
            <div className="row">
                <div className="col-lg-6 cart_pop_up">
                    <h4>Suit Details</h4>
                    <div>
                        <p>Suit Styles : {style && style.title}</p>
                        <p>Fit : {fit && fit.title}</p>
                        <p>Sleeve Button : {sleeve_buttons && sleeve_buttons.title}</p>
                        <p>Back Style : {back_style && back_style.title}</p>
                        <p>Pocket : {pocket && pocket.title}</p>
                        <p>Breast Pocket : {breast_pocket && breast_pocket.title}</p>
                        <p>Lapels : {lapels && lapels.title}</p>
                        <p>Lapels Width : {lapels_width && pocket.title}</p>
                    </div>
                    <h5>Options</h5>
                    <div>
                        <p>Button : {button}</p>
                        <p>Hole : {hole}</p>
                        <p>Thread : {thread}</p>
                        {initials && (
                            <>
                                <p>Monogram color : {fill} </p>
                                <p>Intials: {initials}</p>
                                <p>Font: {font}</p>
                            </>
                        )}
                    </div>
                </div>

                <div className="col-lg-6 cart_pop_up">
                    <h4>Vest Details</h4>
                    <div>
                        <p>Suit Styles : {selectedVestCombination?.style?.title}</p>
                        <p>Pocket : {selectedVestCombination?.pocket?.title}</p>
                        <p>Breast Pocket : {selectedVestCombination?.breast_pocket?.title}</p>
                        <p>Lapels : {selectedVestCombination?.lapels?.title}</p>
                        <p>Lapels Width : {selectedVestCombination?.pocket?.title}</p>
                    </div>
                    <h5>Options</h5>
                    <div>
                        <p>Button : {selectedVestAccents?.button}</p>
                    </div>
                </div>

                <div className="col-lg-6 fb_popup_img">
                    <h4>Fabric</h4>
                    <p><b>{name}</b></p>
                    <img src={selectedImage} alt="#" />
                </div>
                <div className="col-lg-12">
                    <h4>Measurements</h4>
                    {
                        key && (
                            <>
                                <span>Size :{key}</span> <span>{productSizeType}</span>
                            </>
                        )
                    }
                    <div className="table-responsive">
                        {
                            type === Constants.MEASUREMENT_TYPES.STANDARD_MEASUREMENT ? this._renderSuitStandardMeasurements(measurementValue) : this._renderSuitCustomMeasurement(measurementValue)
                        }

                    </div>
                </div>
            </div >
        )
    }

    _renderBlazerProducts(attributes) {
        let { value } = (attributes && attributes[0]) || {}
        let { value: selectedBlazerFabric } = (attributes && attributes[1]) || {}
        let { value: selectedSuitAccents } = (attributes && attributes[2]) || {}
        let { value: measurement } = (attributes && attributes[3]) || {}

        let selectedMeasurement = JSON.parse(measurement)
        let selectedBlazerFabricDetails = JSON.parse(selectedBlazerFabric)
        let selectedAccents = JSON.parse(selectedSuitAccents)
        let selectedCombination = JSON.parse(value)
        const { productSizeType, key, value: measurementValue } = selectedMeasurement || {}
        const { style, fit, pocket, sleeve_buttons, back_style,
            breast_pocket, lapels, vests, lapels_width } = selectedCombination || {}
        const { thread, hole, button, fill, initials, font } = selectedAccents || {}
        let { name, images } = selectedBlazerFabricDetails || {}
        let selectedImage = images && images.edges?.map((o) => o?.node?.src)
        return (
            <div className="row">
                <div className="col-lg-6 cart_pop_up">
                    <h4>Details</h4>
                    <div>
                        <p>Suit Styles : {style && style.title}</p>
                        <p>Fit : {fit && fit.title}</p>
                        <p>Sleeve Button : {sleeve_buttons && sleeve_buttons.title}</p>
                        <p>Back Style : {back_style && back_style.title}</p>
                        <p>Pocket : {pocket && pocket.title}</p>
                        <p>Breast Pocket : {breast_pocket && breast_pocket.title}</p>
                        <p>Lapels : {lapels && lapels.title}</p>
                        <p>Lapels Width : {lapels_width && pocket.title}</p>
                        <p>Suit Type : {vests && vests.title}</p>
                    </div>
                    <h5>Options</h5>
                    <div>
                        <p>Button : {button}</p>
                        <p>Hole : {hole}</p>
                        <p>Thread : {thread}</p>
                        {
                            initials && (
                                <>
                                    <p>Monogram color : {fill} </p>
                                    <p>Intials: {initials}</p>
                                    <p>Font: {font}</p>
                                </>
                            )
                        }
                    </div>
                </div>
                <div className="col-lg-6 fb_popup_img">
                    <h4>Fabric</h4>
                    <p><b>{name}</b></p>
                    <img src={selectedImage} alt="#" />
                </div>
                <div className="col-lg-12">
                    <h4>Measurements</h4>
                    <span>Size :{key}</span> <span>{productSizeType}</span>
                    <div className="table-responsive">
                        <table className="table text-center shirt-mm">
                            {SUIT_IMAGES()}
                            <tbody>
                                <tr>
                                    <td>{measurementValue?.chest}</td>
                                    <td>{measurementValue?.stomach}</td>
                                    <td>{measurementValue?.shoulder}</td>
                                    <td>{measurementValue?.sleeve}</td>
                                    <td>{measurementValue?.shirtLength}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div >
        )
    }

    _renderPantProducts(attributes) {
        let { value } = (attributes && attributes[0]) || {}
        let { value: selectedFabric } = (attributes && attributes[1]) || {}
        let { value: measurement } = (attributes && attributes[2]) || {}

        let selectedCombination = JSON.parse(value)
        let selectedFabricsDetails = JSON.parse(selectedFabric)
        let selectedMeasurements = JSON.parse(measurement)
        let { cuff, fastening, fit, pant_bottom, pleats, pocket, rise, side_pocket, suspender_button } = selectedCombination || {}
        let { name, images } = selectedFabricsDetails || {}
        let selectedImage = images && images.edges?.map((o) => o?.node?.src)
        let { key, productSizeType, value: pantMeasurement } = selectedMeasurements || {}
        return (
            <div className="row">
                <div className="col-lg-6 cart_pop_up">
                    <h4>Details</h4>
                    <div>
                        <p>Cuff : {cuff && cuff.title}</p>
                        <p>Fastening : {fastening && fastening.title}</p>
                        <p>Fit : {fit && fit.title}</p>
                        <p>Pleats : {pleats && pleats.title}</p>
                        <p>Side Pockets : {side_pocket && side_pocket.title}</p>
                        <p>Pocket : {pocket && pocket.title}</p>
                        <p>Rise : {rise && rise.title}</p>
                        <p>Pant Bottom : {pant_bottom && pant_bottom.title}</p>
                        <p>Suspender Button : {suspender_button && suspender_button.title}</p>
                    </div>
                </div>
                <div className="col-lg-6 fb_popup_img">
                    <h4>Fabric</h4>
                    <p><b>{name}</b></p>
                    <img src={selectedImage} alt="#" />
                </div>
                <div className="col-lg-12">
                    <h4>Measurements</h4>
                    <span>Size :{key}</span> <span>{productSizeType}</span>
                    <div className="table-responsive">
                        <table className="table text-center shirt-mm">
                            {PANT_IMAGES()}
                            <tbody>
                                <tr>
                                    <td>{pantMeasurement?.waist}</td>
                                    <td>{pantMeasurement?.hip}</td>
                                    <td>{pantMeasurement?.crotch}</td>
                                    <td>{pantMeasurement?.thigh}</td>
                                    <td>{pantMeasurement?.pantLength}</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div >

        )
    }
    _renderCartProducts(attributes) {
        let { key } = (attributes && attributes[0]) || {}
        if (key === 'SelectedShirt') {
            return this._renderShirtProducts(attributes)
        } else if (key === 'SelectedPant') {
            return this._renderPantProducts(attributes)
        } else if (key === 'SelectedSuit') {
            return this._renderSuitProducts(attributes)
        } else if (key === 'SelectedBlazer') {
            return this._renderBlazerProducts(attributes)
        } else if (key === 'selected3PieceSuit') {
            return this._renderVestProducts(attributes)
        }
    }
    render() {
        const { showModal, closeModal, attributes } = this.props
        return (
            <>
                {
                    showModal && (
                        <div className={`modal-backdrop fade show`}></div>
                    )
                }
                <div className={`modal fade show`}
                    tabIndex="-1" id="cart_deatils" aria-labelledby="measure"
                    aria-hidden="true" style={{
                        display: showModal ? 'block' : 'none',
                        padding_right: showModal ? ' 17px' : ''
                    }}
                    aria-modal="true"
                    role={'dialog'} >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => closeModal()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {this._renderCartProducts(attributes)}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(CartModal)