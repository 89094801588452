import React from 'react'
import { withRouter } from 'react-router-dom'
import { images } from '../../util/image_render'

class AccentsElbow extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        const { elbowPatches, addElbowPatches } = this.props
        return (
            <React.Fragment>
                <div className="mt-2">
                    <p className="H-l fs-13 pb-2 text-lowercase">Add Elbow Patches</p>
                    <div className='row row-gap-3 m-0'>
                        <div className='col-lg-3 col-6 px-1'>
                            <div className={`style_type ${elbowPatches === 'default' ? 'active_type' : ''}`}
                                onClick={() => addElbowPatches('default')}>
                                <div className='style_img' >
                                    <img
                                        src={images('./images/shirt/sidebar_icons/without_elb.svg')}
                                        className="img-fluid"
                                        alt="icons"
                                    />
                                </div>
                                <p className="pt-1 H-l fs-13 text-lowercase">Without</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-6 px-1'>
                            <div className={`style_type ${elbowPatches === 'color' ? 'active_type' : ''}`}
                                onClick={() => addElbowPatches('color')}>
                                <div className='style_img' >
                                    <img
                                        src={images('./images/shirt/sidebar_icons/custom_color_elb.svg')}
                                        className="img-fluid"
                                        alt="icons"
                                    />
                                </div>
                                <p className="pt-1 H-l fs-13 text-lowercase">Custom Color</p>
                            </div>
                        </div>
                    </div>
                    {/* <li className={elbowPatches === 'default' ? 'active' : ''}>
                        <img
                            src={images('./images/shirt/sidebar_icons/without_elb.svg')}
                            className="img-fluid"
                            alt="icons"
                            onClick={() => addElbowPatches('default')} />
                        <p>WITHOUT</p>
                    </li> */}
                    {/* <li className={elbowPatches === 'color' ? 'active' : ''}>
                        <img
                            src={images('./images/shirt/sidebar_icons/custom_color_elb.svg')}
                            className="img-fluid"
                            alt="icons"
                            onClick={() => addElbowPatches('color')} />
                        <p>CUSTOMCOLOR</p>
                    </li> */}
                </div>
            </React.Fragment>
        )
    }
}
export default withRouter(AccentsElbow)