import Actions from '../../action'
import ShirtStyle from '../../../config/shirt_style.json'

const initialState = {
    isLoading: false,
    error: false,
    selectedCombination: {
        sleeve: ShirtStyle['sleeve'].child[0],
        cuff: ShirtStyle['cuff'].child[0],
        collar: ShirtStyle['collar'].child[0],
        pocket: ShirtStyle['pocket'].child[0],
        placket: ShirtStyle['placket'].child[0],
        back: ShirtStyle['back'].child[0],
        fit: ShirtStyle['fit'].child[0],
        bottom: ShirtStyle['bottom'].child[0]
    },
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.UPDATE_SHIRT_STYLE: {
            const { selectedCombination } = state;
            return {
                ...state,
                selectedCombination: {
                    ...selectedCombination,
                    [action.key]: action.data,
                }
            };
        }
        case Actions.EDIT_SHIRT_STYLE: {
            return {
                ...state,
                selectedCombination: action.data,
            };
        }
        case Actions.ADMIN_UPDATE_SHIRT_STYLE: {
            return {
                ...state,
                selectedCombination: action.data,
            };
        } case Actions.RESET_CANVAS: {
            return {
                ...state,
                ...initialState
            }
        } case Actions.RESET_STYLE: {
            return {
                ...state,
                ...initialState
            }
        }
        default:
            return state;
    }

};