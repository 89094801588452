import React from 'react';
import loader from "../../asset/new_images/loader_new.gif"

export default (props) => {
    return (
        <div className="loader">
            <div className={`${props.isCanvas ? 'canvas-loaderAlign' : 'loaderAlign'}`}>
                <img width="90px" src={loader} alt="" /><br />
            </div>
        </div>
    )
}