import React from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import tape from '../../asset/images/tape.png'
import { titleCase } from '../../util/random_number'
import { Constants } from '../../util/Constant'

class CopyMeasurement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    _renderOrderPlacedDate(date) {
        let updatedDate = moment(date)
        return moment(updatedDate, "YYYYMMDD").fromNow();
    }

    _renderMeasurementProducts() {
        const { isShirt, isSuit, isBlazer, isPant, isVest } = this.props
        if (isShirt) {
            return titleCase(Constants.PRODUCTS.SHIRT)
        } else if (isPant) {
            return titleCase(Constants.PRODUCTS.PANT)
        } else if (isSuit) {
            return titleCase(Constants.PRODUCTS.SUIT)
        } else if (isVest) {
            return titleCase(Constants.PRODUCTS.SUIT)
        } else if (isBlazer) {
            return titleCase(Constants.PRODUCTS.BLAZER)
        } else {
            return titleCase(Constants.PRODUCTS.SHIRT)
        }
    }

    revealMeasurement(arr) {
        const { deleteMeasurement, editMeasurement, openMeasurementSuccessPage } = this.props
        let validMeasurements = arr?.edges?.filter((o) => o?.node?.key.includes(this._renderMeasurementProducts()))
        return (
            <>
                {
                    validMeasurements && validMeasurements.map((o, i) => {
                        const { value, id, key, namespace, updatedAt } = o.node
                        const parseData = JSON.parse(value)
                        return (
                            <div key={i} className="media nos cursor-pointer">
                                <img src={tape} className="img-fluid mr-3" alt="icon" />
                                <div className="media-body">
                                    <div className="row">
                                        <div className="col-lg-8 col-8">
                                            <h5 onClick={() => openMeasurementSuccessPage(parseData)}>{key}</h5>
                                            <p className="mb-0">Last updated {this._renderOrderPlacedDate(updatedAt)}</p>
                                            <span onClick={() => editMeasurement(key, namespace, id)} className="measurement-link edit">Edit</span>
                                            <span onClick={() => deleteMeasurement(id)} className="measurement-link-delete">Delete</span>
                                        </div>
                                        <div className="col-lg-4 col-4">
                                            <button type="button"
                                                onClick={() => openMeasurementSuccessPage(parseData)}
                                                className="btn button-primary ml-2">Select
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    validMeasurements && validMeasurements.length === 0 && (
                        <p className="text-center">No {this._renderMeasurementProducts()} measurement profiles</p>
                    )
                }
            </>
        )
    }

    render() {
        const { openModal, closeModal, measurementList } = this.props
        const { metafields = {} } = measurementList || {}
        return (
            <React.Fragment>
                {
                    openModal && (
                        <div className={`modal-backdrop fade show`}></div>
                    )
                }
                <div className={`modal fade ${openModal ? 'show' : ''} measurement-modal`} id="measure" tabIndex="-1" style={{ display: openModal ? `block` : 'none', paddingRight: openModal ? `15px` : '' }} role="dialog" aria-labelledby="measure" aria-modal="true">
                    <div className="modal-dialog modal-lg mess-modal" role="document">
                        <div className="modal-content nos_pop">
                            <div className="modal-header">
                                <h5 className="modal-title text-center" id="measureLabel">Choose your measurements</h5>

                                <button type="button" className="close" onClick={() => closeModal()} data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="mt-5">
                                    {this.revealMeasurement(metafields)}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}
export default withRouter(CopyMeasurement)