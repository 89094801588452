import { put, takeLatest, all } from 'redux-saga/effects';
import Actions from '../action';
import { commonPostQuery, postQuery } from '../../service';
export function* forgetPassword({ query, email, callback }) {
    try {
        const response = yield commonPostQuery(query, email);
        yield put(Actions.forgetPasswordSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.forgetPasswordFailure(error));
        if (callback) {
            callback({ error })
        }
    }
}

export function* resetPasswordByUrl({ query, input, callback }) {
    try {
        const response = yield postQuery(query, input);
        yield put(Actions.resetPasswordByUrlSuccess(response));
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(Actions.resetPasswordByUrlFailure(error));
        if (callback) {
            callback({ error })
        }
    }
}

export default function* () {
    yield all([
        takeLatest(Actions.FORGET_PASSWORD, forgetPassword),
        takeLatest(Actions.RESET_PASSWORD_BY_URL, resetPasswordByUrl),
    ]);
}
