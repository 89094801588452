import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import { S3BASE_URL_BLAZER } from '../../config/api_endpoint'
import Loader from '../Loader'
import { SUIT_TRANSPARENT } from '../../config/constant'
import { convertAdminProductId } from '../../util/event'
import { detectBrowser, detectBrowserBaseUrl } from '../../util/detect-browser'
import { FabricsDetails } from '../SelectedProducts/fabrics_details'
import BlazerZoom from '../Zoom/blazer'
import { CONTENTS, PATH } from '../../globals/app-constraints'
import CustomHeader from '../Header/customHeader'
import { BLAZER_CHECKOUT } from '../ProductCheckout'
import { toast } from 'react-toastify'
import { CUSTOMER_ASSOCIATE, LINE_ITEMS_ADD, LINE_ITEMS_UPDATE } from '../../Queries'
import { store } from '../../createStore'

class BlazerSection extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedCombination: undefined,
            isShowFolded: true,
            isLoading: true,
            isLoadingStarted: false,
            folded: 15,
            isFabricModal: false,
            isZoomModal: false
        }
    }
    onloadImageSuccess() {
        this.onloadImages()
    }
    openZoomModal() {
        this.setState({ isZoomModal: true })
    }
    closeZoomModal() {
        this.setState({ isZoomModal: false })
    }
    onloadImageError() {
        this.onloadImages()
    }

    closeModal() {
        this.setState({ isFabricModal: false })
    }
    openModal() {
        this.setState({ isFabricModal: true })
    }

    onloadImages() {
        const { folded } = this.state;
        if (folded === 1) {
            this.setState({ isLoading: false, folded: 15 })
            return;
        } else {
            this.setState({ folded: folded - 1 })
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { selectedBlazerStyle: { selectedCombination }, activeChildMenu, blazerAccents } = props;
        let { selectedBlazerFabric } = props.blazerFabric || {}
        let { button, hole, thread, blazerInnerFabric,
            selectedBlazerLining, pocketSquareFabric, selectedPocketSquare, selectedButtonsThreads } = blazerAccents?.selectedAccents

        let blazerAccentsPath = S3BASE_URL_BLAZER + 'accents'
        let baseBlazerInnerPath = blazerAccentsPath + '/6756353671332/custom/'
        let basepath = S3BASE_URL_BLAZER + '7080752611492/' + detectBrowserBaseUrl() + '/'
        let blazerPocketPath = blazerAccentsPath + '/6756353671332/pocket/'
        if (selectedBlazerFabric?.productId !== 'undefined') {
            basepath = S3BASE_URL_BLAZER + convertAdminProductId(selectedBlazerFabric?.productId) + detectBrowserBaseUrl() + '/'
        }

        if (blazerInnerFabric) {
            let parts = blazerInnerFabric?.split('/');
            let shopifyId = parts[parts.length - 1];
            if (selectedBlazerLining === 'custom') {
                baseBlazerInnerPath = blazerAccentsPath + '/' + shopifyId + '/custom/'
            } else if (selectedBlazerLining === 'unlined') {
                baseBlazerInnerPath = blazerAccentsPath + '/' + shopifyId + '/unlined/'
            } else {
                baseBlazerInnerPath = blazerAccentsPath + '/' + shopifyId + '/custom/'
            }
        }
        if (pocketSquareFabric) {
            let parts = pocketSquareFabric?.split('/');
            let shopifyId = parts[parts.length - 1];
            blazerPocketPath = blazerAccentsPath + '/' + shopifyId + '/pocket/'
        }
        let selectedCombinationObj = {
            foldedSuitFit: basepath + 'style/normal/slim/notch/folded/single_one_breasted.png',
            foldedBreastPocket: basepath + 'transparent/transparent.png',
            foldedSleeve: basepath + 'sleeve/front/sleeve.png',
            foldedNormalFit: basepath + 'fit/folded/normal_fit_ventless.png',
            foldedInnerBottom: basepath + 'bottom/folded/inner_bottom.png',
            foldedInnerSuit: basepath + 'bottom/folded/inner_suit.png',
            foldedCollar: basepath + 'collar/folded/other_collar.png',
            suitInner: baseBlazerInnerPath + 'suit_interior.png',
            suitBottomInner: baseBlazerInnerPath + 'suit_interior_bottom.png',
            suitPocketsSquare: selectedPocketSquare === 'without' ? blazerAccentsPath + '/' + SUIT_TRANSPARENT : blazerPocketPath + 'pocket_square.png',
            foldedSuitButtons: blazerAccentsPath + '/buttons/folded/j8/single_one_button.png',
            foldedButtonThread: blazerAccentsPath + '/threads/folded/single_1_button_thread/button_center/white.png',
            foldedButtonHole: blazerAccentsPath + '/threads/folded/single_1_button_thread/button_hole/white.png',
            foldedLapelsHole: blazerAccentsPath + 'threads/folded/notch_collar_hole/white.png',
        }

        let foldedButtonThread = ''
        let foldedButtonHole = ''
        let foldedLapelsHole = ''

        switch (selectedButtonsThreads) {
            case 'all':
                foldedButtonThread = blazerAccentsPath + selectedCombination['style'].folded_button_thread_url + `${thread}.png`
                foldedButtonHole = blazerAccentsPath + selectedCombination['style'].folded_button_hole_url + `${hole}.png`
                foldedLapelsHole = selectedCombination['lapels'].key === 'shawl' ? blazerAccentsPath + '/' + SUIT_TRANSPARENT : blazerAccentsPath + selectedCombination['lapels'].folded_button_hole_image_url + `${hole}.png`
                break;
            case 'cuffs':
                foldedButtonThread = blazerAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonHole = blazerAccentsPath + '/' + SUIT_TRANSPARENT
                foldedLapelsHole = blazerAccentsPath + '/' + SUIT_TRANSPARENT
                break;
            case 'lapel':
                foldedButtonThread = blazerAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonHole = blazerAccentsPath + '/' + SUIT_TRANSPARENT
                foldedLapelsHole = selectedCombination['lapels'].key === 'shawl' ? blazerAccentsPath + '/' + SUIT_TRANSPARENT : blazerAccentsPath + selectedCombination['lapels'].folded_button_hole_image_url + `${hole}.png`
                break;
            case 'default':
                foldedButtonThread = blazerAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonHole = blazerAccentsPath + '/' + SUIT_TRANSPARENT
                foldedLapelsHole = blazerAccentsPath + '/' + SUIT_TRANSPARENT
                break;
            default:
                foldedButtonThread = blazerAccentsPath + '/' + SUIT_TRANSPARENT
                foldedButtonHole = blazerAccentsPath + '/' + SUIT_TRANSPARENT
                foldedLapelsHole = blazerAccentsPath + '/' + SUIT_TRANSPARENT
                break;
        }

        Object.keys(selectedCombination).forEach((key) => {
            switch (key) {
                case 'pocket':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedPocket: selectedCombination[key].key === 'no_pocket' ? basepath + SUIT_TRANSPARENT : basepath + selectedCombination[key].folded_image_url,
                    }
                    break;
                case 'breast_pocket':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedBreastPocket: selectedCombination[key].key === 'no' ? basepath + SUIT_TRANSPARENT : basepath + selectedCombination[key].folded_image_url,
                    }
                    break;
                case 'fit':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedNormalFit: selectedCombination[key].key === 'normal_fit' ? basepath + 'fit/folded/normal_fit_ventless.png' : basepath + SUIT_TRANSPARENT
                    }
                    break;
                case 'sleeve_buttons':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedSleeve: basepath + selectedCombination[key].folded_image_url,
                    }
                    break;
                case 'style':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedSuitFit: selectedCombination[key].key === 'mandarin' ? basepath + selectedCombination['fit'].folded_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination['lapels_width'].key + '/' + selectedCombination['lapels'].key + '/folded/' + selectedCombination[key].folded_image_url,
                        foldedCollar: selectedCombination[key].key === 'mandarin' ? basepath + 'collar/folded/mandarin_collar.png' : basepath + 'collar/folded/other_collar.png',
                        suitInner: selectedCombination[key].key === 'mandarin' ? baseBlazerInnerPath + 'mandarin_interior.png' : baseBlazerInnerPath + 'suit_interior.png',
                        foldedSuitButtons: blazerAccentsPath + '/buttons/folded/' + button + '/' + selectedCombination[key].button_image_url,
                        foldedButtonThread: foldedButtonThread,
                        foldedButtonHole: foldedButtonHole,
                    }
                    break;
                case 'lapels':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedSuitFit: selectedCombination['style'].key === 'mandarin' ? basepath + selectedCombination['fit'].folded_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination['lapels_width'].key + '/' + selectedCombination[key].key + '/folded/' + selectedCombination['style'].folded_image_url,
                        foldedCollar: selectedCombination['style'].key === 'mandarin' ? basepath + 'collar/folded/mandarin_collar.png' : basepath + 'collar/folded/other_collar.png',
                        suitInner: selectedCombination['style'].key === 'mandarin' ? baseBlazerInnerPath + 'mandarin_interior.png' : baseBlazerInnerPath + 'suit_interior.png',
                        foldedSuitButtons: blazerAccentsPath + '/buttons/folded/' + button + '/' + selectedCombination['style'].button_image_url,
                        foldedLapelsHole: foldedLapelsHole,
                    }
                    break;
                case 'lapels_width':
                    selectedCombinationObj = {
                        ...selectedCombinationObj,
                        foldedSuitFit: selectedCombination['style'].key === 'mandarin' ? basepath + selectedCombination['fit'].folded_image_url : basepath + 'style/' + selectedCombination['fit'].key + '/' + selectedCombination[key].key + '/' + selectedCombination['lapels'].key + '/folded/' + selectedCombination['style'].folded_image_url,
                        foldedCollar: selectedCombination['style'].key === 'mandarin' ? basepath + 'collar/folded/mandarin_collar.png' : basepath + 'collar/folded/other_collar.png',
                        foldedSuitButtons: blazerAccentsPath + '/buttons/folded/' + button + '/' + selectedCombination['style'].button_image_url,
                    }
                    break;
                default:
                    break;
            }
        })
        return {
            selectedCombination: selectedCombinationObj,
            activeChildMenu
        }
    }
    resetCanvas() {
        this.props.resetStyle()
    }
    componentDidUpdate(prevProps, prevState) {
        if ((this.props.selectedPosition !== prevProps.selectedPosition) || (this.props.selectedBlazerStyle !== prevProps.selectedBlazerStyle) || (this.props.selectedSuitFabric !== prevProps.selectedSuitFabric)) {
            this.setState({ isLoadingStarted: true, isLoading: true }, () => {
                this.setState({ isLoadingStarted: false })
            })
        }
    }

    navCartPage() {
        let { token: { accessToken } } = this.props.token || {}
        let { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        let { lineItems } = lastIncompleteCheckout || {}
        if (!accessToken) {
            this.props.history.push('/login')
        } else if (accessToken && !lineItems || lineItems?.edges?.length === 0) {
            this.props.history.push('/canvas/shirt?fabric')
        } else {
            this.props.history.push('/cart')
        }

    }
    customerAssociate(checkoutId) {
        let { token: { accessToken } } = this.props.token
        let input = {
            checkoutId: checkoutId,
            customerAccessToken: accessToken
        }
        const callback = (response) => {
            let { customer } = response?.checkoutCustomerAssociateV2 || {}
            if (!customer) {
                toast.error(`Something went wrong ,try again later`, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            } else {
                this.props.history.push('/cart')
            }
        }
        this.props.checkoutCustomerAssociate(CUSTOMER_ASSOCIATE, input, callback)
    }
    checkoutBlazer() {
        const { edit } = this.props.match.params
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        // const { selectedMeasurement } = this.props.getMeasurement
        let { selectedBlazerFabric } = this.props.blazerFabric
        let { selectedAccents } = this.props.blazerAccents
        let { selectedCombination } = this.props.selectedBlazerStyle
        let { token: { accessToken } } = this.props.token || {}
        const checkoutId = lastIncompleteCheckout ? lastIncompleteCheckout?.id : this.props.getNewCheckOutId
        // let Measurements = {
        //     value: selectedMeasurement,
        //     type: Constants.MEASUREMENT_TYPES.CUSTOM_MEASUREMENT,
        //     productType: Constants.PRODUCTS.SUIT
        // }
        // let customAttributes = BLAZER_CHECKOUT(selectedCombination, selectedBlazerFabric, selectedAccents, Measurements)
        let customAttributes = BLAZER_CHECKOUT(selectedCombination, selectedBlazerFabric, selectedAccents,)

        let lineItems;
        if (edit) {
            lineItems = [{
                quantity: 1,
                variantId: selectedBlazerFabric?.id,
                id: selectedBlazerFabric?.updated_line_item_id,
                customAttributes: customAttributes
            }]
        } else {
            lineItems = [{
                quantity: 1,
                variantId: selectedBlazerFabric?.id,
                customAttributes: customAttributes
            }]
        }
        let input = {
            lineItems,
            checkoutId: checkoutId,
        }


        const callback = (response) => {
            let { checkoutLineItemsAdd } = response || {}
            if (accessToken && checkoutLineItemsAdd) {
                if (!lastIncompleteCheckout) {
                    this.customerAssociate(checkoutId)
                } else {
                    this.props.history.push('/cart')
                }
            } else {
                this.props.history.push('/cart')
            }
        }
        if (edit) {
            this.props.checkoutLineItemsUpdate(LINE_ITEMS_UPDATE, input, callback)
        } else {
            this.props.checkoutLineItemsAdd(LINE_ITEMS_ADD, input, callback)
        }
    }
    onNextStep() {
        let { token = {} } = this.props.token
        let { accessToken } = token || {}
        const { history: { location: { search } }, isPant,
            isShirt, isSuit, isVest, isBlazer
        } = this.props || {}
        console.log("d", this.props);
        switch (search) {
            case '?fabric':
                this.props.history.push(`${this.props.location.pathname}?style`)
                break;
            case '?style':
                if (isPant)
                    this.props.history.push(`${this.props.location.pathname}?measure`)
                else
                    this.props.history.push(`${this.props.location.pathname}?accent`)
                break;
            case '?accent':
                this.props.history.push(`${this.props.location.pathname}?measure`)
                break;
            case '?measure':
                this.props.history.push(`${this.props.location.pathname}?summary`)
                break;
            case '?summary':
                if (!accessToken) {
                    this.props.history.push("/login")
                } else {
                    if (isBlazer) {
                        this.checkoutBlazer()
                    }
                }
        }
    }
    render() {
        const isHomePage = this.props.location.pathname === '/';
        const { isScrolled } = this.state;
        const { selectedCombination, isLoading, isFabricModal,
            isZoomModal, isLoadingStarted } = this.state;
        const { foldedSuitFit, foldedPocket, foldedBreastPocket, foldedNormalFit,
            foldedSleeve, foldedSuitButtons, foldedCollar, foldedInnerBottom, foldedInnerSuit,
            suitPocketsSquare, suitInner, suitBottomInner, foldedButtonHole,
            foldedButtonThread, foldedLapelsHole } = selectedCombination || {}
        const { selectedBlazerFabric } = this.props.blazerFabric
        let { location } = this.props
        let { search } = location

        return (
            <React.Fragment>
                <div className="order-1 order-sm-1 order-lg-2 verify-casual h-100">
                    {isLoading && <Loader isCanvas={true} />}
                    <div id="carouselExampleIndicators" className="carousel slide text-center text-center" data-interval="false" data-ride="carousel">
                        {selectedCombination && (
                            <div className='carousel-inner' data-interval="false">
                                <p className='canvas-finish H-cb fs-16' onClick={() => this.onNextStep()}>{search === '?summary' ? "Finish" : "Next"}</p>
                                <CustomHeader />
                                <div className={`carousel-item active`}>
                                    <div className="pi-shirt-containers">
                                        {!isLoadingStarted &&
                                            <div className={`pi_container pi-suit w-75 m-auto blazer_cuff ${isLoading && 'v-hide'}`}>
                                                <img className="custom_shirt folded_fit"
                                                    src={foldedNormalFit.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_collar"
                                                    src={foldedCollar.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_inner_suit"
                                                    src={foldedInnerSuit.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_collar"
                                                    src={suitInner}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_collar"
                                                    src={foldedInnerBottom.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_collar"
                                                    src={suitBottomInner}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_fit"
                                                    src={foldedSuitFit.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_lapels"
                                                    src={foldedLapelsHole}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                                <img className="custom_shirt folded_fit"
                                                    src={suitPocketsSquare}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_pleats"
                                                    src={foldedSleeve.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt'
                                                />
                                                <img className="custom_shirt folded_pocket"
                                                    src={foldedPocket.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_side_pocket"
                                                    src={foldedBreastPocket.replace('.png', detectBrowser())}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_hole"
                                                    src={foldedButtonHole}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_button"
                                                    src={foldedSuitButtons}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />

                                                <img className="custom_shirt folded_thread"
                                                    src={foldedButtonThread}
                                                    onLoad={() => this.onloadImageSuccess()}
                                                    onError={() => this.onloadImageError()}
                                                    alt='shirt' />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='canvas_details_icons d-flex flex-column align-items-center gap-12'>
                                    <div onClick={() => this.openZoomModal()}>
                                        <div className='icons_name'>
                                            <img src={PATH?.img?.ZoomIn} alt='zoom-in' />
                                        </div>
                                        <p className='H-l fs-11 text_grey canvas-data-hide'>{CONTENTS.canvas.zoom}</p>
                                    </div>
                                    <div className='d-flex flex-column align-items-center' onClick={() => this.openModal()}>
                                        <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#FabricDetail">
                                            <div className='icons_name'>
                                                <img src={PATH?.img?.FabricCanvasIcon} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" alt='fabric-icon' />
                                            </div>
                                        </button>
                                        {/* <button className="btn" type="button"
                                            data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                            <div className='icons_name'>
                                                <img src={PATH?.img?.FabricCanvasIcon} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" alt='fabric-icon' />
                                            </div>
                                        </button> */}
                                        <p className='H-l fs-11 text_grey canvas-data-hide'>{CONTENTS.canvas.fabricDetails}</p>
                                    </div>
                                    <div className='d-flex flex-column align-items-center' onClick={() => this.resetCanvas()}>
                                        <div className='icons_name'>
                                            <img src={PATH?.img?.ResetIcon} alt='zoom-in' />
                                        </div>
                                        <p className='H-l fs-11 text_grey canvas-data-hide'>{CONTENTS.canvas.reset}</p>
                                        {/* <i className="bi bi-arrow-clockwise canvas_icon_color fs-16"></i> */}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <FabricsDetails
                        openFabricDesign={isFabricModal}
                        closeModal={() => this.closeModal()}
                        selectedFabric={selectedBlazerFabric}
                    />
                    <BlazerZoom
                        selectedCombination={selectedCombination}
                        openModal={isZoomModal}
                        closeModal={() => this.closeZoomModal()}
                    />
                </div>
            </React.Fragment >
        )
    }
}

const mapStateToProp = (state) => {
    return {
        selectedBlazerStyle: Actions.getSelectedBlazerStyle(state),
        blazerFabric: Actions.getSelectedBlazerFabric(state),
        blazerAccents: Actions.getSelectedBlazerAccent(state),
        token: Actions.getTokenSelector(store.getState()) || {},
        getCheckoutDetails: Actions.getCheckoutId(store.getState()),
        getMeasurement: Actions.getSelectedMeasurement(store.getState()) || {},
        getNewCheckOutId: Actions.getNewCheckOutId(state),

    }
}
export default withRouter(connect(mapStateToProp, {
    resetStyle: Actions.resetStyle,
    checkoutLineItemsAdd: Actions.checkoutLineItemsAdd,
    checkoutLineItemsUpdate: Actions.checkoutLineItemsUpdate,
    checkoutCustomerAssociate: Actions.checkoutCustomerAssociate,
})(BlazerSection));
