const { detect } = require('detect-browser');
const browser = detect();

export const detectBrowser = () => {
    if (browser && browser.name === 'safari') {
        return '.png'
    } else {
        return '.png'
    }
}

export const detectBrowserBaseUrl = () => {
    if (browser && browser.name === 'safari') {
        return 'png'
    } else {
        return 'png'
    }
}