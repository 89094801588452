import React from 'react'
import { withRouter } from 'react-router-dom'

class ButtonsHoles extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    _renderParentButtonHoles() {
        let { isSuit, isShirt } = this.props
        if (isSuit) {
            return this._renderSuitButtonHoles()
        } else if (isShirt) {
            return this._renderShirtButtonHoles()
        }
    }
    _renderSuitButtonHoles() {
        const { chooseButtonHoleColor, selectedAccents } = this.props
        return (
            <div className="mt-2">
                <p className="H-l fs-13 pb-2 text-lowercase">Button Holes </p>
                <div className='d-flex flex-wrap gap-3'>

                    <div className={`cursor-pointer position-relative ${selectedAccents && selectedAccents.hole === 'white' ? 'active_color' : ''}`}>
                        <div
                            className="m-1"
                            onClick={() => chooseButtonHoleColor('white', 'mono1')}
                            style={{ width: "40px", height: "40px", background: "#FFFFFF", border: '1px solid #ccc' }}
                        ></div>
                    </div>
                    <div className={`cursor-pointer position-relative ${selectedAccents && selectedAccents.hole === 'yellow' ? 'active_color' : ''}`}>
                        <div
                            className="m-1"
                            onClick={() => chooseButtonHoleColor('yellow', 'mono2')}
                            style={{ width: "40px", height: "40px", background: "#FFD700", border: '1px solid #ccc' }}>
                        </div>
                    </div>
                    <div className={`cursor-pointer position-relative ${selectedAccents && selectedAccents.hole === 'violet' ? 'active_color' : ''}`}>
                        <div
                            className="m-1"
                            onClick={() => chooseButtonHoleColor('violet', 'mono3')}
                            style={{ width: "40px", height: "40px", background: "#7030A0 ", border: '1px solid #ccc' }}>
                        </div>
                    </div>
                    <div className={`cursor-pointer position-relative ${selectedAccents && selectedAccents.hole === 'blue' ? 'active_color' : ''}`}>
                        <div
                            className="m-1"
                            onClick={() => chooseButtonHoleColor('blue', 'mono4')}
                            style={{ width: "40px", height: "40px", background: "#002060", border: '1px solid #ccc' }}>
                        </div>
                    </div>
                    <div className={`cursor-pointer position-relative ${selectedAccents && selectedAccents.hole === 'green' ? 'active_color' : ''}`}>
                        <div
                            className="m-1"
                            onClick={() => chooseButtonHoleColor('green', 'mono5')}
                            style={{ width: "40px", height: "40px", background: "#00B050 ", border: '1px solid #ccc', borderradius: '10px' }}>
                        </div>
                    </div>
                    <div className={`cursor-pointer position-relative ${selectedAccents && selectedAccents.hole === 'red' ? 'active_color' : ''}`}>
                        <div
                            className="m-1"
                            onClick={() => chooseButtonHoleColor('red', 'mono6')}
                            style={{ width: "40px", height: "40px", background: "#FF0000 ", border: '1px solid #ccc' }}>
                        </div>
                    </div>
                    <div className={`cursor-pointer position-relative ${selectedAccents && selectedAccents.hole === 'brown' ? 'active_color' : ''}`}>
                        <div
                            className="m-1"
                            onClick={() => chooseButtonHoleColor('brown', 'mono7')}
                            style={{ width: "40px", height: "40px", background: "#C00000 ", border: '1px solid #ccc' }}>
                        </div>
                    </div>
                    <div className={`cursor-pointer position-relative ${selectedAccents && selectedAccents.hole === 'black' ? 'active_color' : ''}`}>
                        <div
                            className="m-1" onClick={() => chooseButtonHoleColor('black', 'mono8')}
                            style={{ width: "40px", height: "40px", background: "#000000", border: '1px solid #ccc', borderRadius: '4px' }}></div>
                    </div>
                </div>
            </div>
            // <ul className="row mx-auto text-left p-0 w-90 my-4 mono pl-0">
            //     <h6 className="col-12 text-left px-0">BUTTON HOLES</h6>
            //     <li>
            //         <div
            //             className={`cursor-pointer 
            //   ${selectedAccents && selectedAccents.hole === 'black' ? 'active-mono' : ''}`}
            //             onClick={() => chooseButtonHoleColor('black', 'mono1')}
            //             style={{ width: "40px", height: "40px", background: "#000000", border: '1px solid #ccc' }}></div>
            //     </li>
            //     <li>
            //         <div
            //             className={`cursor-pointer ${selectedAccents && selectedAccents.hole === 'white' ? 'active-mono' : ''}`}
            //             onClick={() => chooseButtonHoleColor('white', 'mono2')}
            //             style={{ width: "40px", height: "40px", background: "#FFFFFF", border: '1px solid #ccc' }}
            //         ></div>
            //     </li>
            //     <li>
            //         <div
            //             className={`cursor-pointer ${selectedAccents && selectedAccents.hole === 'yellow' ? 'active-mono' : ''}`}
            //             onClick={() => chooseButtonHoleColor('yellow', 'mono3')}
            //             style={{ width: "40px", height: "40px", background: "#FFD700", border: '1px solid #ccc' }}>
            //         </div>
            //     </li>
            //     <li>
            //         <div
            //             className={`cursor-pointer ${selectedAccents && selectedAccents.hole === 'violet' ? 'active-mono' : ''}`}
            //             onClick={() => chooseButtonHoleColor('violet', 'mono4')}
            //             style={{ width: "40px", height: "40px", background: "#7030A0", border: '1px solid #ccc' }}>
            //         </div>
            //     </li>
            //     <li>
            //         <div
            //             className={`cursor-pointer ${selectedAccents && selectedAccents.hole === 'blue' ? 'active-mono' : ''}`}
            //             onClick={() => chooseButtonHoleColor('blue', 'mono5')}
            //             style={{ width: "40px", height: "40px", background: "#002060", border: '1px solid #ccc' }}>
            //         </div>
            //     </li>
            //     <li>
            //         <div
            //             className={`cursor-pointer ${selectedAccents && selectedAccents.hole === 'green' ? 'active-mono' : ''}`}
            //             onClick={() => chooseButtonHoleColor('green', 'mono6')}
            //             style={{ width: "40px", height: "40px", background: "#00B050", border: '1px solid #ccc' }}>
            //         </div>
            //     </li>
            //     <li>
            //         <div
            //             className={`cursor-pointer ${selectedAccents && selectedAccents.hole === 'red' ? 'active-mono' : ''}`}
            //             onClick={() => chooseButtonHoleColor('red', 'mono7')}
            //             style={{ width: "40px", height: "40px", background: "#FF0000", border: '1px solid #ccc' }}>
            //         </div>
            //     </li>
            //     <li>
            //         <div
            //             className={`cursor-pointer ${selectedAccents && selectedAccents.hole === 'brown' ? 'active-mono' : ''}`}
            //             onClick={() => chooseButtonHoleColor('brown', 'mono8')}
            //             style={{ width: "40px", height: "40px", background: "#C00000", border: '1px solid #ccc' }}>
            //         </div>
            //     </li>
            // </ul>
        )
    }
    _renderShirtButtonHoles() {
        const { chooseButtonHoleColor, selectedAccents } = this.props
        return (
            <div className="mt-2">
                <p className="H-l fs-13 pb-2 text-lowercase">Button Holes </p>
                <div className='d-flex flex-wrap gap-3'>

                    <div className={`cursor-pointer position-relative ${selectedAccents && selectedAccents.hole === 'white' ? 'active_color' : ''}`}>
                        <div
                            className="m-1"
                            onClick={() => chooseButtonHoleColor('white', 'mono1')}
                            style={{ width: "40px", height: "40px", background: "#FFFFFF", border: '1px solid #ccc' }}
                        ></div>
                    </div>
                    <div className={`cursor-pointer position-relative ${selectedAccents && selectedAccents.hole === 'yellow' ? 'active_color' : ''}`}>
                        <div
                            className="m-1"
                            onClick={() => chooseButtonHoleColor('yellow', 'mono2')}
                            style={{ width: "40px", height: "40px", background: "#FFD700", border: '1px solid #ccc' }}>
                        </div>
                    </div>
                    <div className={`cursor-pointer position-relative ${selectedAccents && selectedAccents.hole === 'violet' ? 'active_color' : ''}`}>
                        <div
                            className="m-1"
                            onClick={() => chooseButtonHoleColor('violet', 'mono3')}
                            style={{ width: "40px", height: "40px", background: "#7030A0 ", border: '1px solid #ccc' }}>
                        </div>
                    </div>
                    <div className={`cursor-pointer position-relative ${selectedAccents && selectedAccents.hole === 'blue' ? 'active_color' : ''}`}>
                        <div
                            className="m-1"
                            onClick={() => chooseButtonHoleColor('blue', 'mono4')}
                            style={{ width: "40px", height: "40px", background: "#002060", border: '1px solid #ccc' }}>
                        </div>
                    </div>
                    <div className={`cursor-pointer position-relative ${selectedAccents && selectedAccents.hole === 'green' ? 'active_color' : ''}`}>
                        <div
                            className="m-1"
                            onClick={() => chooseButtonHoleColor('green', 'mono5')}
                            style={{ width: "40px", height: "40px", background: "#00B050 ", border: '1px solid #ccc', borderradius: '10px' }}>
                        </div>
                    </div>
                    <div className={`cursor-pointer position-relative ${selectedAccents && selectedAccents.hole === 'red' ? 'active_color' : ''}`}>
                        <div
                            className="m-1"
                            onClick={() => chooseButtonHoleColor('red', 'mono6')}
                            style={{ width: "40px", height: "40px", background: "#FF0000 ", border: '1px solid #ccc' }}>
                        </div>
                    </div>
                    <div className={`cursor-pointer position-relative ${selectedAccents && selectedAccents.hole === 'brown' ? 'active_color' : ''}`}>
                        <div
                            className="m-1"
                            onClick={() => chooseButtonHoleColor('brown', 'mono7')}
                            style={{ width: "40px", height: "40px", background: "#C00000 ", border: '1px solid #ccc' }}>
                        </div>
                    </div>
                    <div className={`cursor-pointer position-relative ${selectedAccents && selectedAccents.hole === 'black' ? 'active_color' : ''}`}>
                        <div
                            className="m-1" onClick={() => chooseButtonHoleColor('black', 'mono8')}
                            style={{ width: "40px", height: "40px", background: "#000000", border: '1px solid #ccc', borderRadius: '4px' }}></div>
                    </div>
                </div>
            </div>
            // <ul className="row mx-auto text-left p-0 w-90 my-4 mono pl-0">
            //     <h6 className="col-12 text-left px-0">BUTTON HOLES</h6>
            //     <li>
            //         <div
            //             className={`cursor-pointer 
            //   ${selectedAccents && selectedAccents.hole === 'black' ? 'active-mono' : ''}`}
            //             onClick={() => chooseButtonHoleColor('black', 'mono1')}
            //             style={{ width: "40px", height: "40px", background: "#000000", border: '1px solid #ccc' }}></div>
            //     </li>
            //     <li>
            //         <div
            //             className={`cursor-pointer ${selectedAccents && selectedAccents.hole === 'white' ? 'active-mono' : ''}`}
            //             onClick={() => chooseButtonHoleColor('white', 'mono2')}
            //             style={{ width: "40px", height: "40px", background: "#FFFFFF", border: '1px solid #ccc' }}
            //         ></div>
            //     </li>
            //     <li>
            //         <div
            //             className={`cursor-pointer ${selectedAccents && selectedAccents.hole === 'yellow' ? 'active-mono' : ''}`}
            //             onClick={() => chooseButtonHoleColor('yellow', 'mono3')}
            //             style={{ width: "40px", height: "40px", background: "#FFD700", border: '1px solid #ccc' }}>
            //         </div>
            //     </li>
            //     <li>
            //         <div
            //             className={`cursor-pointer ${selectedAccents && selectedAccents.hole === 'violet' ? 'active-mono' : ''}`}
            //             onClick={() => chooseButtonHoleColor('violet', 'mono4')}
            //             style={{ width: "40px", height: "40px", background: "#7030A0", border: '1px solid #ccc' }}>
            //         </div>
            //     </li>
            //     <li>
            //         <div
            //             className={`cursor-pointer ${selectedAccents && selectedAccents.hole === 'blue' ? 'active-mono' : ''}`}
            //             onClick={() => chooseButtonHoleColor('blue', 'mono5')}
            //             style={{ width: "40px", height: "40px", background: "#002060", border: '1px solid #ccc' }}>
            //         </div>
            //     </li>
            //     <li>
            //         <div
            //             className={`cursor-pointer ${selectedAccents && selectedAccents.hole === 'green' ? 'active-mono' : ''}`}
            //             onClick={() => chooseButtonHoleColor('green', 'mono6')}
            //             style={{ width: "40px", height: "40px", background: "#00B050", border: '1px solid #ccc' }}>
            //         </div>
            //     </li>
            //     <li>
            //         <div
            //             className={`cursor-pointer ${selectedAccents && selectedAccents.hole === 'red' ? 'active-mono' : ''}`}
            //             onClick={() => chooseButtonHoleColor('red', 'mono7')}
            //             style={{ width: "40px", height: "40px", background: "#FF0000", border: '1px solid #ccc' }}>
            //         </div>
            //     </li>
            //     <li>
            //         <div
            //             className={`cursor-pointer ${selectedAccents && selectedAccents.hole === 'brown' ? 'active-mono' : ''}`}
            //             onClick={() => chooseButtonHoleColor('brown', 'mono8')}
            //             style={{ width: "40px", height: "40px", background: "#C00000", border: '1px solid #ccc' }}>
            //         </div>
            //     </li>
            // </ul>
        )
    }
    render() {
        return (
            <React.Fragment>
                {this._renderParentButtonHoles()}

            </React.Fragment>
        )
    }
}
export default withRouter(ButtonsHoles)