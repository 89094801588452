import React from 'react'
import { withRouter } from 'react-router-dom'
import { pantMock } from '../../../util/measurement_mocks/pant_measurement_data'
import { connect } from 'react-redux'
import { CONTENTS } from '../../../globals/app-constraints'

class PantMeasurement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        const { selectedData, selectedPantMeasurementSize } = this.props
        return (
            <React.Fragment>
                <div className="measure_content_scroll">
                    <p className='fs-18 H-m text-center m-0 pb-2'>{CONTENTS.canvas.measureContent.measurePopup.selectYourSize}</p>
                    <p className='fs-13 text-center H-l'>{CONTENTS.canvas.measureContent.measurePopup.pickTheSize}</p>
                    {
                        pantMock?.overSize &&
                        <div className='buttons_align margin_align'>
                            <button className='btn common_btn measurement_btn border-0'><span>{CONTENTS.canvas.measureContent.measurePopup.short}</span><span className='text_grey'>&#60; 168 cm</span></button>
                            <button className='btn common_btn measurement_btn border-0'><span>{CONTENTS.canvas.measureContent.measurePopup.regular}</span><span className='text_grey'>168 - 186 cm</span></button>
                            <button className='btn common_btn measurement_btn border-0'><span>{CONTENTS.canvas.measureContent.measurePopup.long}</span><span className='text_grey'>&#62; 168 cm</span></button>
                        </div>
                    }
                    <div className='d-flex flex-column row-gap-3 px_measure_values'>
                        {/* {
                            pantMock?.short?.map((pantSizes, index) => {
                                return ( */}
                        <div className='row m-0'>
                            {/* {
                                pantMock?.short?.map((pantSizes, index) => {
                                    return <div className='col-2 align-self-center p-0'>
                                        <p className='fs-15 text_grey m-0'>{pantSizes?.label}</p>
                                    </div>
                                })
                            } */}
                            <div className='col-12'>
                                <div className='row justify-content-between align-self-center p-0'>
                                    <div className='col-4'>
                                        {
                                            pantMock?.short?.map((pantSizes, index) => {
                                                return <div className='row' key={index}>
                                                    <div style={{paddingRight:"5px"}}>
                                                        <p className={`measure_border ${(selectedData?.selectedIndex === index && selectedData?.category === "Small") && 'active_measure'}`}
                                                            onClick={() => selectedPantMeasurementSize(pantSizes, index, 'Small')}>
                                                            {pantSizes?.size} S
                                                        </p>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className='col-4'>
                                        {pantMock?.regular?.map((pantSizes, index) => {
                                            return <div className='row'>
                                                <div style={{paddingRight:"5px"}}>
                                                    <p className={`measure_border ${(selectedData?.selectedIndex === index && selectedData?.category === "Medium") && 'active_measure'}`}
                                                        onClick={() => selectedPantMeasurementSize(pantSizes, index, 'Medium')}>
                                                        {pantSizes?.size} S
                                                    </p>
                                                </div>
                                            </div>
                                        })
                                        }
                                    </div>
                                    <div className='col-4'>
                                        {pantMock?.long?.map((pantSizes, index) => {
                                            return <div className='row'>
                                                <div style={{paddingRight:"5px"}}>
                                                    <p className={`measure_border ${(selectedData?.selectedIndex === index && selectedData?.category === "Long") && 'active_measure'}`}
                                                        onClick={() => selectedPantMeasurementSize(pantSizes, index, 'Long')}>
                                                        {pantSizes?.size} S
                                                    </p>
                                                </div>
                                            </div>
                                        })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* )
                            })
                        } */}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProp = (state) => {
    return {}
}

export default withRouter(connect(mapStateToProp, {

})(PantMeasurement));