import { put, takeLatest, all } from 'redux-saga/effects';
import Actions from '../action';
import { postQuery } from '../../service';
export function* getTags({ query, input }) {
    try {
        const response = yield postQuery(query, input);
        yield put(Actions.getAllTagsSuccess(response));
    } catch (error) {
        yield put(Actions.getAllTagsFailure(error));
    }
}

export default function* () {
    yield all([
        takeLatest(Actions.GET_ALL_TAGS, getTags),
    ]);
}
