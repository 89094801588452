import React from 'react'
import { withRouter } from 'react-router-dom'

class PantZoom extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {

        const { selectedCombination, isShowFolded, isShowFront, isShowBack, openModal, closeModal } = this.props;
        const { backModal, backShirt, backShoe, backPantFit, backCuff, backPocket, frontModal, frontShirt, frontShoe, frontPantFit, frontCuff,
            frontFastening, frontPleats, frontPocket, foldedPantFit, foldedCuff, foldedPleats, foldedPocket, foldedSidePocket } = selectedCombination || {}
        return (
            <React.Fragment>
                {
                    openModal && (
                        <div className={`modal-backdrop fade show`}></div>
                    )
                }
                <div className={`modal fade ${openModal ? 'show' : ''}`} tabIndex="-1" 
                style={{ display: openModal ? `block` : 'none', paddingRight: openModal ? `0px` : '' }} role="dialog" aria-modal="true">
                    <div className="modal-dialog modal-lg zoom_full_width" role="document">
                        <div className="modal-content canva_zoom_hgt rounded-0">
                        <div className="modal-header border-0 px-4 py-4">
                                <button type="button" className="btn-close"
                                    onClick={() => closeModal()} data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"></span>
                                </button>
                            </div>
                            <div className="modal-body zoom-canvas">
                                <div className="">
                                    <div className='carousel-inner' data-interval="false">
                                        <div className={`carousel-item ${isShowFront ? 'active' : ''}`}>
                                            <div className="pi-shirt-containers">
                                                <div className={`pi_container pi-pant w-75 m-auto pi_pant_front`}>
                                                    <img className="custom_shirt shirt_man"
                                                        src={frontModal}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_sleeve"
                                                        src={frontShirt}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_pant"
                                                        src={frontShoe}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_fit"
                                                        src={frontPantFit}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_cuff_fabric"
                                                        src={frontCuff}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_fastening"
                                                        src={frontFastening}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_collar_fabric"
                                                        src={frontPleats}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_button"
                                                        src={frontPocket}
                                                        alt='shirt' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`carousel-item ${isShowBack ? 'active' : ''}`}>
                                            <div className="pi-shirt-containers">
                                                <div className={`pi_container pi-pant w-75 m-auto pi_pant_back`}>
                                                    <img className="custom_shirt shirt_back"
                                                        src={backModal}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_shirt_sleeve"
                                                        src={backShirt}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_pant"
                                                        src={backShoe}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_shirt_fit"
                                                        src={backPantFit}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_collar_fabric"
                                                        src={backCuff}
                                                        alt='shirt' />
                                                    <img className="custom_shirt shirt_back_shirt_pocket"
                                                        src={backPocket}
                                                        alt='shirt' />
                                                </div>

                                            </div>
                                        </div>
                                        <div className={`carousel-item ${isShowFolded ? 'active' : ''}`}>
                                            <div className="pi-shirt-containers">
                                                <div className={`pi_container pi-pant folded-pant w-75 m-auto pi_pant_cuff`}>
                                                    <img className="custom_shirt folded_fit"
                                                        src={foldedPantFit}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_cuff"
                                                        src={foldedCuff}
                                                        alt='shirt' />
                                                    <img className="custom_shirt folded_pleats"
                                                        src={foldedPleats}
                                                        alt='shirt'
                                                    />
                                                    <img className="custom_shirt folded_pocket"
                                                        src={foldedPocket}
                                                        alt='shirt' />

                                                    <img className="custom_shirt folded_side_pocket"
                                                        src={foldedSidePocket}
                                                        alt='shirt' />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(PantZoom)