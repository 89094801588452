import Actions from '../action'

const initialState = {
    isLoading: false,
    error: false,
    forgetDetails: undefined
}
export default (state = initialState, action) => {
    switch (action.type) {
        case Actions.FORGET_PASSWORD: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.FORGET_PASSWORD_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                forgetDetails: action.data,
                error: false
            };
        }
        case Actions.FORGET_PASSWORD_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        }
        default:
            return state;
    }

};