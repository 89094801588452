const NAME = 'SELECTED_ACCENTS'
export const UPDATE_SUIT_ACCENTS = `${NAME}/UPDATE_SUIT_ACCENTS`;
export const EDIT_SUIT_ACCENTS = `${NAME}/EDIT_SUIT_ACCENTS`;
// export const RESET_SELECTED_SUIT_ACCENTS = `${NAME}/RESET_SELECTED_SUIT_ACCENTS`;

export const getSelectedSuitAccent = store => store[NAME].suit
export const updateSelectedSuitAccent = (key, data) => {
    return {
        type: UPDATE_SUIT_ACCENTS,
        key,
        data
    }
}

export const editSelectedSuitAccent = (data) => {
    return {
        type: EDIT_SUIT_ACCENTS,
        data
    }
}

// export const resetSelectedSuitAccent = (key) => {
//     return {
//         type: RESET_SELECTED_SUIT_ACCENTS,
//         key
//     }
// }