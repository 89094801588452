import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Accents from './Accents'
import Fabric from './Fabric'
import Style from './Style'
import Actions from '../../redux/action'
import Measurement from '../measurements/LandingScreen'
import { Constants, revealSameProductIDS, revealVestId } from '../../util/Constant'
import CanvasSection from '../../component/Canvas';
import PantSection from '../../component/Canvas/pant'
import SuitSection from '../../component/Canvas/suit'
import BlazerSection from '../../component/Canvas/blazer'
import VestsSection from '../../component/Canvas/vest'
import ShirtStyle from '../../config/shirt_style.json'
import PantStyle from '../../config/pant_style.json'
import SuitStyle from '../../config/suit_style.json'
import BlazerStyle from '../../config/blazer_style.json'
import VestStyle from '../../config/vests.json'
import { CHECKOUT_CREATE, CUSTOMER_ASSOCIATE, DELETE_MEASUREMENT, LINE_ITEMS_ADD, LINE_ITEMS_UPDATE } from '../../Queries'
import { store } from '../../createStore'
import { BLAZER_CHECKOUT, PANT_CHECKOUT, SHIRT_CHECKOUT, SUIT_CHECKOUT, VEST_CHECKOUT } from '../../component/ProductCheckout'
import { toast } from 'react-toastify'
import { shirtMock } from '../../util/measurement_mocks/shirt_measurement_data'
import Shirt_Measurement from './measurements/Shirt_Measurement'
import Pant_Measurements from './measurements/Pant_Measurements'
import { pantMock } from '../../util/measurement_mocks/pant_measurement_data'
import CustomizeSize from './cutom_size_mesurement/CustomizeSize'
import { suitMock } from '../../util/measurement_mocks/suit_measurement_data'
import Suit_Measurements from './measurements/Suit_Measurements'
import Vest_Measurements from './measurements/Vest_Measurements'
import { CONTENTS, PATH } from '../../globals/app-constraints'
import ButtonDesign from '../../component/button'
// import CopyMeasurement from '../measurements/CopyMeasurement'
import { titleCase } from '../../util/random_number'
// import routes from '../../config/routes'
// import tape from '../../asset/images/tape.png'
import moment from 'moment'
// import SizeComplete from '../measurements/SizeComplete'

class CanvasScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedPosition: '',
            isShowCasual: false,
            isShowJacket: false,
            isSuitVest: false,
            childMenu: ShirtStyle['sleeve'].child,
            activeChildMenu: 'sleeve',
            isShowCasual: false,
            openFabricDesign: false,
            activePantChildMenu: 'fit',
            childPantMenu: PantStyle['fit'].child,
            activeSuitStyleMenu: 'style',
            childSuitMenu: SuitStyle['style'].child,
            activeVestStyleMenu: 'style',
            childVestMenu: VestStyle['style'].child,
            activeBlazerMenu: 'style',
            childBlazerMenu: BlazerStyle['style'].child,
            standardSizePage: 1,
            previousOrderSizePage: 1,
            selectedData: { type: "", size: "", selectedIndex: "" },
            selectedUnits: 'cm',
            searchVisible: false,
            searchValue: null,
            selectedStandardSizeModel: false,
            selectedPreviousOrderModel: false,
            currentIndex: 0
        }
        this.menuContainerRef = React.createRef();
    }

    componentDidMount() {
        // Generate checkout id
        this.createIntialCheckOutId()

    }

    createIntialCheckOutId() {
        let { token: { accessToken } } = this.props.token || {}
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        if (accessToken) {
            if (!lastIncompleteCheckout && !this.props.getNewCheckOutId) {
                this.props.checkoutCreate(CHECKOUT_CREATE)
            }
        } else {
            if (!this.props.getNewCheckOutId) {
                this.props.checkoutCreate(CHECKOUT_CREATE)
            }
        }
    }


    onCanvasPositionChanged(selectedPosition) {
        this.setState({ selectedPosition: selectedPosition })
    }
    showCasual(isShowFront, isShowSide) {
        const { shirtAccents } = this.props.selectedAccents
        let { isShowCasual } = shirtAccents || {}
        if (isShowFront) {
            this.onCanvasPositionChanged('front')
        } else if (isShowSide) {
            this.onCanvasPositionChanged('side')
        } else {
            this.onCanvasPositionChanged('front')
        }
        this.props.updateShirtAccent('isShowCasual', !isShowCasual)
    }
    showJacket() {
        const { isVests } = this.props.selectedVestStyle
        if (isVests) {
            this.props.updateSuitType(false)
            const { selectedSuitFabric } = this.props.selectedSuitFabric || {}
            const { images, amount, name, tags, description } = selectedSuitFabric || {}
            let parts = selectedSuitFabric?.productId?.split('/');
            let shopifyId = parts[parts.length - 1];
            const { vestId } = revealSameProductIDS(`${shopifyId}/`)
            if (vestId) {
                const convertStoreFrontId = Constants.SHOPIFY_TYPE_URL.productID + vestId
                let params = { productId: convertStoreFrontId, images, amount, name, tags, description }
                this.props.updateVestFabric(params)
            }
        } else {
            const { selectedVestFabric } = this.props.selectedVestFabric || {}
            const { images, amount, name, tags, description } = selectedVestFabric || {}
            let parts = selectedVestFabric?.productId?.split('/');
            let number = parts[parts.length - 1];
            const { suitId } = revealVestId(number)
            this.props.updateSuitType(true)
            if (suitId) {
                let params = { productId: suitId, images, amount, name, tags, description }
                this.props.updateSelectedSuitFabric(params)
            }
        }


    }
    _renderCanvasScreens() {
        let { location, isShirt, isPant, isBlazer, isSuit, isVest } = this.props
        let { search } = location
        const { selectedPosition, isShowJacket, searchValue } = this.state
        const { shirtAccents } = this.props.selectedAccents
        switch (search) {
            case '?fabric':
                return <Fabric
                    isShirt={isShirt}
                    isPant={isPant}
                    isBlazer={isBlazer}
                    isSuit={isSuit}
                    isVest={isVest}
                    onCanvasPositionChanged={(position) => this.onCanvasPositionChanged(position)}
                    selectedPosition={selectedPosition}
                    showCasual={(isShowFront, isShowSide) => this.showCasual(isShowFront, isShowSide)}
                    isShowCasual={shirtAccents?.isShowCasual}
                    showJacket={() => this.showJacket()}
                    isShowJacket={isShowJacket}
                    searchValue={searchValue}
                />
            case '?style':
                return <Style
                    isShirt={isShirt}
                    isPant={isPant}
                    isBlazer={isBlazer}
                    isSuit={isSuit}
                    isVest={isVest}
                    onCanvasPositionChanged={(position) => this.onCanvasPositionChanged(position)}
                    selectedPosition={selectedPosition}
                    showCasual={(isShowFront, isShowSide) => this.showCasual(isShowFront, isShowSide)}
                    isShowCasual={shirtAccents?.isShowCasual}
                    showJacket={() => this.showJacket()}
                    isShowJacket={isShowJacket}
                    childMenu={this.state.childMenu}
                    activeChildMenu={this.state.activeChildMenu}
                    childPantMenu={this.state.childPantMenu}
                    activePantChildMenu={this.state.activePantChildMenu}
                    childBlazerMenu={this.state.childBlazerMenu}
                    activeBlazerMenu={this.state.activeBlazerMenu}
                    childSuitMenu={this.state.childSuitMenu}
                    activeSuitStyleMenu={this.state.activeSuitStyleMenu}
                    childVestMenu={this.state.childVestMenu}
                    activeVestStyleMenu={this.state.activeVestStyleMenu}
                />
            case '?accent':
                if (!isPant) {
                    return <Accents
                        isShirt={isShirt}
                        isPant={isPant}
                        isBlazer={isBlazer}
                        isSuit={isSuit}
                        isVest={isVest}
                        onCanvasPositionChanged={(position) => this.onCanvasPositionChanged(position)}
                        selectedPosition={selectedPosition}
                        showCasual={(isShowFront, isShowSide) => this.showCasual(isShowFront, isShowSide)}
                        isShowCasual={shirtAccents?.isShowCasual}
                        showJacket={() => this.showJacket()}
                        isShowJacket={isShowJacket}
                    />
                }
                break;
            case '?measurement':
                return <Measurement
                    isShirt={isShirt}
                    isPant={isPant}
                    isBlazer={isBlazer}
                    isSuit={isSuit}
                    isVest={isVest}
                    isCheckout={true}
                />
            default:
                break;
        }
    }




    _renderShirtCanvasSection() {
        const { selectedAccents } = this.props
        const { shirtAccents } = selectedAccents || {}
        return (
            <CanvasSection
                activeChildMenu={'sleeve'}
                navStyle={() => this.navStyle()}
                navFabric={() => this.navFabric()}
                navAccent={() => this.navAccent()}
                onCanvasPositionChanged={(position) => this.onCanvasPositionChanged(position)}
                selectedPosition={this.state.selectedPosition}
                isShowCasual={this.state.isShowCasual}
                nextStep={() => this.navAccent()}
                showCasual={(isShowFront, isShowSide) => this.showCasual(isShowFront, isShowSide)}
                monogramPosition={shirtAccents?.monogramPosition}
                isShowFabric={true}
                isShirt={this.props.isShirt}
            />
        )
    }

    _renderSuitCanvasSection() {
        return (
            <SuitSection
                activeChildMenu={'style'}
                onCanvasPositionChanged={(position) => this.onCanvasPositionChanged(position)}
                selectedPosition={this.state.selectedPosition}
                showJacket={() => this.showJacket()}
                isShowJacket={this.state.isShowJacket}
                isSuit={this.props.isSuit}
                isVest={this.props.isVest}
            />
        )
    }

    _renderPantCanvasSection() {
        return (
            <PantSection
                activeChildMenu={'fit'}
                onCanvasPositionChanged={(position) => this.onCanvasPositionChanged(position)}
                selectedPosition={this.state.selectedPosition}
                isPant={this.props.isPant}
            />
        )
    }

    _renderBlazerCanvasSection() {
        return (
            <BlazerSection
                activeChildMenu={'style'}
                isBlazer={this.props.isBlazer}
            />
        )
    }

    _renderVestsCanvasSection() {
        return (
            <VestsSection
                activeChildMenu={'style'}
                onCanvasPositionChanged={(position) => this.onCanvasPositionChanged(position)}
                selectedPosition={this.state.selectedPosition}
                showJacket={() => this.showJacket()}
                isShowJacket={this.props.isShowJacket}
                isVest={this.props.isVest}
            />
        )
    }
    _renderCanvasSections() {
        const { isShirt, isBlazer, isPant, isSuit, isVest } = this.props
        const { isSuitVest } = this.state
        if (isShirt) {
            return this._renderShirtCanvasSection()
        } else if (isBlazer) {
            return this._renderBlazerCanvasSection()
        } else if (isPant) {
            return this._renderPantCanvasSection()
        } else if (isSuit) {
            return this._renderSuitCanvasSection()
        } else if (isVest && isSuitVest) {
            return this._renderSuitCanvasSection()
        } else if (isVest && !isSuitVest) {
            return this._renderVestsCanvasSection()
        } else {
            return this._renderShirtCanvasSection()
        }
    }

    _renderStyles() {
        const { isShirt, isPant, isBlazer, isSuit, isVest } = this.props
        const { isSuitVest } = this.state
        if (isShirt) {
            return this.renderShirtSideMenu()
        } else if (isPant) {
            return this._renderPantStyleMenu()
        } else if (isBlazer) {
            return this._renderBlazerStyleMenu()
        } else if (isSuit) {
            return this._renderSuitStyleMenu()
        } else if (isVest && isSuitVest) {
            return this._renderSuitStyleMenu()
        } else if (isVest && !isSuitVest) {
            return this._renderVestsStyleMenu()
        }
    }

    _renderPantStyleMenu() {
        const sideMenu = Object.keys(PantStyle).map((key, index) => (PantStyle[key]))
        const { activePantChildMenu } = this.state;
        return (<React.Fragment>
            {
                sideMenu.map((menu, i) => {
                    return (
                        <a className={`cursor-pointer ${activePantChildMenu === menu.key ? 'active_text' : ''}`}
                            onClick={() => this.changePantChildMenus(menu)}
                            key={i}>
                            {menu.key}
                        </a>
                    )
                })
            }
        </React.Fragment>)
    }


    _renderVestsStyleMenu() {
        const sideMenu = Object.keys(VestStyle).map((key) => (VestStyle[key]))
        const { activeVestStyleMenu } = this.state;
        return (<React.Fragment>
            {
                sideMenu.map((menu, i) => {
                    return (
                        <a className={`cursor-pointer ${activeVestStyleMenu === menu.key ? 'active_text' : ''}`}
                            onClick={() => this.changeVestsChildMenus(menu)}
                            key={i}>
                            {menu.key}
                        </a>
                    )
                })
            }
        </React.Fragment>)
    }


    _renderBlazerStyleMenu() {
        const sideMenu = Object.keys(BlazerStyle).map((key, index) => (BlazerStyle[key]))
        const { activeBlazerMenu } = this.state;
        const { selectedBlazerStyle } = this.props;
        const selectedStyle = selectedBlazerStyle.selectedCombination['style'] || ''

        return (<React.Fragment>
            {
                sideMenu.map((menu, i) => {
                    return (
                        ((menu.key !== 'lapels') || (menu.key === 'lapels' && selectedStyle.key !== 'mandarin')) && ((menu.key !== 'lapels_width') || (menu.key === 'lapels_width' && selectedStyle.key !== 'mandarin')) ?
                            <a className={`cursor-pointer H-l fs-16 ${activeBlazerMenu === menu.key ? 'active_text' : ''}`}
                                onClick={() => this.changeBlazerChildMenus(menu)}
                                key={i}>
                                {menu.key}
                            </a>
                            : ''
                    )
                })
            }
        </React.Fragment>)
    }

    _renderSuitStyleMenu() {
        const sideMenu = Object.keys(SuitStyle).map((key, index) => (SuitStyle[key]))
        const { activeSuitStyleMenu } = this.state;
        const { selectedSuitStyle } = this.props;
        const selectedStyle = selectedSuitStyle.selectedCombination['style'] || ''

        return (<React.Fragment>
            {
                sideMenu.map((menu, i) => {
                    return (
                        ((menu.key !== 'lapels') || (menu.key === 'lapels' && selectedStyle.key !== 'mandarin')) &&
                            ((menu.key !== 'lapels_width') || (menu.key === 'lapels_width' && selectedStyle.key !== 'mandarin')) ?
                            <a className={`cursor-pointer ${activeSuitStyleMenu === menu.key ? 'active_text' : ''}`}
                                onClick={() => this.changeSuitChildMenus(menu)}
                                key={i}>
                                {menu.key}
                            </a>
                            : ''
                    )
                })
            }
        </React.Fragment>)
    }

    changeShirtChildMenus(menu) {
        this.setState({
            activeChildMenu: menu.key,
            childMenu: menu.child
        })
        switch (menu.key) {
            case 'sleeve':
            case 'collar':
            case 'placket':
            case 'pocket':
                this.onCanvasPositionChanged('folded')
                break;
            case 'cuff':
                this.onCanvasPositionChanged('folded')
                break;
            case 'back':
                this.onCanvasPositionChanged('back')
                break;
            default:
                this.onCanvasPositionChanged('front')
                break;
        }
    }


    changePantChildMenus(menu) {
        this.setState({
            activePantChildMenu: menu.key,
            childPantMenu: menu.child
        })
        switch (menu.key) {
            case 'fit':
            case 'pleats':
            case 'placket':
                this.onCanvasPositionChanged('front')
                break;
            case 'cuff':
                this.onCanvasPositionChanged('back')
                break;
            case 'pocket':
                this.onCanvasPositionChanged('back')
                break;
            default:
                this.onCanvasPositionChanged('front')
                break;
        }

    }

    changeBlazerChildMenus(menu) {
        this.setState({
            activeBlazerMenu: menu.key,
            childBlazerMenu: menu.child
        })
    }

    changeSuitChildMenus(menu) {
        this.setState({
            activeSuitStyleMenu: menu.key,
            childSuitMenu: menu.child
        })
        switch (menu.key) {
            case 'fit':
            case 'pocket':
                this.onCanvasPositionChanged('folded')
                break;
            case 'breast_pocket':
            case 'style':
                this.onCanvasPositionChanged('front')
                break;
            case 'back_style':
            case 'sleeve_buttons':
                this.onCanvasPositionChanged('back')
                break;
            default:
                this.onCanvasPositionChanged('folded')
                break;
        }

    }

    changeVestsChildMenus(menu) {
        this.setState({
            activeVestStyleMenu: menu.key,
            childVestMenu: menu.child
        })
        switch (menu.key) {
            case 'pocket':
                this.onCanvasPositionChanged('folded')
                break;
            case 'breast_pocket':
            case 'style':
                this.onCanvasPositionChanged('front')
                break;
            case 'back_style':
            default:
                this.onCanvasPositionChanged('folded')
                break;
        }

    }


    renderShirtSideMenu() {
        const sideMenu = Object.keys(ShirtStyle).map((key, index) => (ShirtStyle[key]))
        const { activeChildMenu } = this.state;
        const { selectedStyle } = this.props;
        const selectedSleeve = selectedStyle.selectedCombination['sleeve'] || ''
        return (<React.Fragment>
            {
                sideMenu.map((menu, i) => {
                    return (
                        (menu.key !== 'cuff') || (menu.key === 'cuff' && selectedSleeve.key === 'long') ?
                            <a className={`cursor-pointer ${activeChildMenu === menu.key ? 'active_text' : ''}`}
                                onClick={() => this.changeShirtChildMenus(menu)} key={i}>
                                {menu.key}</a> : ''
                    )
                })
            }
        </React.Fragment>)
    }

    navSamePostion(label) {
        this.props.history.push(`${this.props.location.pathname}?${label}`)
    }

    _renderSelectedFabric() {
        const { selectedShirtFabric } = this.props.selectedFabric || {}
        const { selectedBlazerFabric } = this.props.selectedBlazerFabric || {}
        const { selectedSuitFabric } = this.props.selectedSuitFabric || {}
        const { selectedVestFabric } = this.props.selectedVestFabric || {}
        const { selectedPantFabric } = this.props.selectedPantFabric || {}
        let name;
        let currencyCode;
        let amount
        const { isShirt, isPant, isBlazer, isSuit, isVest } = this.props
        if (isShirt) {
            name = selectedShirtFabric?.name
            currencyCode = selectedShirtFabric?.currencyCode
            amount = selectedShirtFabric?.amount
        } else if (isPant) {
            name = selectedPantFabric?.name
            currencyCode = selectedPantFabric?.currencyCode
            amount = selectedPantFabric?.amount
        } else if (isSuit) {
            name = selectedSuitFabric?.name
            currencyCode = selectedSuitFabric?.currencyCode
            amount = selectedSuitFabric?.amount
        } else if (isVest) {
            name = selectedVestFabric?.name
            currencyCode = selectedVestFabric?.currencyCode
            amount = selectedVestFabric?.amount
        } else if (isBlazer) {
            name = selectedBlazerFabric?.name
            currencyCode = selectedBlazerFabric?.currencyCode
            amount = selectedBlazerFabric?.amount
        } else {
            name = selectedShirtFabric?.name
            currencyCode = selectedShirtFabric?.currencyCode
            amount = selectedShirtFabric?.amount
        }
        return { name, currencyCode, amount }
    }

    _render_summary_style() {
        const { selectedStyle, selectedPantStyle, selectedSuitStyle, selectedVestStyle, selectedBlazerStyle,
            isShirt, isBlazer, isVest, isSuit, isPant } = this.props || {}
        let selectedCombination;
        if (isShirt) {
            selectedCombination = selectedStyle?.selectedCombination || {}
        } else if (isPant) {
            selectedCombination = selectedPantStyle?.selectedCombination || {}
        } else if (isSuit) {
            selectedCombination = selectedSuitStyle?.selectedCombination || {}
        } else if (isBlazer) {
            selectedCombination = selectedVestStyle?.selectedCombination || {}
        } else if (isVest) {
            selectedCombination = selectedBlazerStyle?.selectedCombination || {}
        } else {
            selectedCombination = selectedStyle?.selectedCombination || {}
        }

        return (
            <React.Fragment>
                {Object.keys(selectedCombination)?.map((key, index) => {
                    return (
                        <div className='row mx-0 border-divider-summary' key={key}>
                            <div className='col-6 px-0'>
                                <p className='H-l fs-13'>{key?.toLowerCase()}</p>
                            </div>
                            <div className='col-6 px-0'>
                                <p className='H-l fs-13 text-lowercase'>{selectedCombination[key]?.title}</p>
                            </div>
                        </div>
                    )
                })}
            </React.Fragment>
        )
    }

    _renderMeasurement() {
        let { selectedMeasurement } = this.props.getMeasurement
        const { edit } = this.props.match.params
        if (typeof selectedMeasurement === 'string') {
            let data = JSON.parse(selectedMeasurement)
            selectedMeasurement = data?.value
        }
        const { shirtSizes, pantSizes, suitSizes } = selectedMeasurement || {}
        if (selectedMeasurement?.type === "shirt") {
            return (
                <React.Fragment>
                    {shirtSizes?.subSize?.map((obj, index) => {
                        return (
                            <div className='row mx-0 border-divider-summary' key={index}>
                                <div className='col-6 px-0'>
                                    <p className='H-l fs-13'>{obj?.label}</p>
                                </div>
                                <div className='col-6 px-0'>
                                    <p className='H-l fs-13'>{obj?.size}</p>
                                </div>
                            </div>
                        )
                    })}
                </React.Fragment>
            )
        } else if (selectedMeasurement?.type === "pant") {
            return (
                <React.Fragment>
                    {pantSizes?.subSize?.map((obj, index) => {
                        return (
                            <div className='row mx-0 border-divider-summary' key={index}>
                                <div className='col-6 px-0'>
                                    <p className='H-l fs-13'>{obj?.label}</p>
                                </div>
                                <div className='col-6 px-0'>
                                    <p className='H-l fs-13'>{obj?.size}</p>
                                </div>
                            </div>
                        )
                    })}
                </React.Fragment>
            )
        } else if (selectedMeasurement?.type === "suit") {
            return (
                <React.Fragment>
                    {suitSizes?.subSize?.map((obj, index) => {
                        return (
                            <div className='row mx-0 border-divider-summary' key={index}>
                                <div className='col-6 px-0'>
                                    <p className='H-l fs-13'>{obj?.label}</p>
                                </div>
                                <div className='col-6 px-0'>
                                    <p className='H-l fs-13'>{obj?.size}</p>
                                </div>
                            </div>
                        )
                    })}
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    {Object.entries(selectedMeasurement).map(([label, size], index) => {
                        return (
                            <div className='row mx-0 border-divider-summary' key={index}>
                                <div className='col-6 px-0'>
                                    <p className='H-l fs-13'>{label}</p>
                                </div>
                                <div className='col-6 px-0'>
                                    <p className='H-l fs-13'>{size}</p>
                                </div>
                            </div>
                        );
                    })}
                </React.Fragment>
            )
        }
    }

    _render_summary_shirt_accents({ shirtAccents }) {
        const { fill, font, hole, initials, selectedButton, thread, monogramPosition } = shirtAccents || {}
        return (
            <React.Fragment>
                {initials && (
                    <React.Fragment>
                        <div className='row mx-0 border-divider-summary'>
                            <div className='col-6 px-0'>
                                <p className='H-l fs-13'>initials</p>
                            </div>
                            <div className='col-6 px-0'>
                                <p className='H-l fs-13'>{initials}</p>
                            </div>
                        </div>
                        <div className='row mx-0 border-divider-summary'>
                            <div className='col-6 px-0'>
                                <p className='H-l fs-13'>monogram color</p>
                            </div>
                            <div className='col-6 px-0'>
                                <p className='H-l fs-13'>{fill}</p>
                            </div>
                        </div>
                        <div className='row mx-0 border-divider-summary'>
                            <div className='col-6 px-0'>
                                <p className='H-l fs-13'>font</p>
                            </div>
                            <div className='col-6 px-0'>
                                <p className='H-l fs-13'>{font}</p>
                            </div>
                        </div>
                        <div className='row mx-0 border-divider-summary'>
                            <div className='col-6 px-0'>
                                <p className='H-l fs-13'>position</p>
                            </div>
                            <div className='col-6 px-0'>
                                <p className='H-l fs-13'>{monogramPosition}</p>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                <div className='row mx-0 border-divider-summary'>
                    <div className='col-6 px-0'>
                        <p className='H-l fs-13'>hole</p>
                    </div>
                    <div className='col-6 px-0'>
                        <p className='H-l fs-13'>{hole}</p>
                    </div>
                </div>
                <div className='row mx-0 border-divider-summary'>
                    <div className='col-6 px-0'>
                        <p className='H-l fs-13'>selected button</p>
                    </div>
                    <div className='col-6 px-0'>
                        <p className='H-l fs-13'>{selectedButton}</p>
                    </div>
                </div>
                <div className='row mx-0 border-divider-summary'>
                    <div className='col-6 px-0'>
                        <p className='H-l fs-13'>thread</p>
                    </div>
                    <div className='col-6 px-0'>
                        <p className='H-l fs-13'>{thread}</p>
                    </div>
                </div>
            </React.Fragment>
        )

    }

    _render_common_summary_accents({ selectedAccents }) {
        const { thread, hole, button, fill, initials, font } = selectedAccents || {}
        return (
            <React.Fragment>
                {initials && (
                    <React.Fragment>
                        <div className='row mx-0 border-divider-summary'>
                            <div className='col-6 px-0'>
                                <p className='H-l fs-13'>initials</p>
                            </div>
                            <div className='col-6 px-0'>
                                <p className='H-l fs-13'>{initials}</p>
                            </div>
                        </div>
                        <div className='row mx-0 border-divider-summary'>
                            <div className='col-6 px-0'>
                                <p className='H-l fs-13'>monogram color</p>
                            </div>
                            <div className='col-6 px-0'>
                                <p className='H-l fs-13'>{fill}</p>
                            </div>
                        </div>
                        <div className='row mx-0 border-divider-summary'>

                            <div className='col-6 px-0'>
                                <p className='H-l fs-13'>font</p>
                            </div>
                            <div className='col-6 px-0'>
                                <p className='H-l fs-13'>{font}</p>
                            </div>
                        </div>
                        {/* <div className='col-6 px-0'>
                            <p className='text_grey'>Position</p>
                        </div> */}
                    </React.Fragment>
                )}
                <div className='row mx-0 border-divider-summary'>

                    <div className='col-6 px-0'>
                        <p className='H-l fs-13'>hole</p>
                    </div>
                    <div className='col-6 px-0'>
                        <p className='H-l fs-13'>{hole}</p>
                    </div>
                </div>
                <div className='row mx-0 border-divider-summary'>

                    <div className='col-6 px-0'>
                        <p className='H-l fs-13'>selected button</p>
                    </div>
                    <div className='col-6 px-0'>
                        <p className='H-l fs-13'>{button}</p>
                    </div>
                </div>
                <div className='row mx-0 border-divider-summary'>
                    <div className='col-6 px-0'>
                        <p className='H-l fs-13'>thread</p>
                    </div>
                    <div className='col-6 px-0'>
                        <p className='H-l fs-13'>{thread}</p>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    _render_summary_accents() {
        const { isShirt, isBlazer, isVest, isSuit,
            selectedAccents, selectedSuitAccents,
            selectedVestAccents, blazerAccents } = this.props || {}
        if (isShirt) {
            return this._render_summary_shirt_accents(selectedAccents)
        } else if (isSuit) {
            return this._render_common_summary_accents(selectedSuitAccents)
        } else if (isBlazer) {
            return this._render_common_summary_accents(blazerAccents)
        } else if (isVest) {
            return this._render_common_summary_accents(selectedVestAccents)
        }

    }

    onNextStep() {
        let { token = {} } = this.props.token
        let { accessToken } = token || {}
        const { history: { location: { search } }, isPant,
            isShirt, isSuit, isVest, isBlazer
        } = this.props || {}
        switch (search) {
            case '?fabric':
                this.props.history.push(`${this.props.location.pathname}?style`)
                break;
            case '?style':
                if (isPant)
                    this.props.history.push(`${this.props.location.pathname}?measure`)
                else
                    this.props.history.push(`${this.props.location.pathname}?accent`)
                break;
            case '?accent':
                this.props.history.push(`${this.props.location.pathname}?measure`)
                break;
            case '?measure':
                this.props.history.push(`${this.props.location.pathname}?summary`)
                break;
            case '?summary':

                if (!accessToken) {
                    this.props.history.push("/login")
                } else {
                    if (isShirt) {
                        this.checkoutShirt()
                    } else if (isPant) {
                        this.checkoutPant()
                    } else if (isSuit) {
                        this.checkoutSuit()
                    } else if (isBlazer) {
                        this.checkoutBlazer()
                    } else if (isVest) {
                        this.checkoutVest()
                    }
                }
        }

    }


    customerAssociate(checkoutId) {
        let { token: { accessToken } } = this.props.token
        let input = {
            checkoutId: checkoutId,
            customerAccessToken: accessToken
        }
        const callback = (response) => {
            let { customer } = response?.checkoutCustomerAssociateV2 || {}
            if (!customer) {
                toast.error(`Something went wrong ,try again later`, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            } else {
                this.props.history.push('/cart')
            }
        }
        this.props.checkoutCustomerAssociate(CUSTOMER_ASSOCIATE, input, callback)
    }


    checkoutShirt() {
        const { selectedMeasurement } = this.props.getMeasurement
        const { edit } = this.props.match.params
        let { selectedShirtFabric } = this.props.selectedFabric
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        const { selectedCombination } = this.props.selectedStyle
        const { shirtAccents } = this.props.selectedAccents
        let { token: { accessToken } } = this.props.token || {}
        const checkoutId = lastIncompleteCheckout ? lastIncompleteCheckout?.id : this.props.getNewCheckOutId
        let Measurements = {
            value: selectedMeasurement,
            type: Constants.MEASUREMENT_TYPES.CUSTOMER_MEASUREMENT_SIZE,
            productType: Constants.PRODUCTS.SHIRT
        }
        let customAttributes = SHIRT_CHECKOUT(shirtAccents, selectedCombination, selectedShirtFabric, Measurements)

        let lineItems;
        if (edit) {
            lineItems = [{
                quantity: 1,
                variantId: selectedShirtFabric?.id,
                id: selectedShirtFabric?.updated_line_item_id,
                customAttributes: customAttributes
            }]
        } else {
            lineItems = [{
                quantity: 1,
                variantId: selectedShirtFabric?.id,
                customAttributes: customAttributes
            }]
        }
        let input = {
            lineItems,
            checkoutId: checkoutId,
        }
        const callback = (response) => {
            let { checkoutLineItemsAdd } = response || {}
            if (accessToken && checkoutLineItemsAdd) {
                if (!lastIncompleteCheckout) {
                    this.customerAssociate(checkoutId)
                } else {
                    this.props.history.push('/cart')
                }
            } else {
                this.props.history.push('/cart')
            }
        }
        if (edit) {
            this.props.checkoutLineItemsUpdate(LINE_ITEMS_UPDATE, input, callback)
        } else {
            this.props.checkoutLineItemsAdd(LINE_ITEMS_ADD, input, callback)

        }
    }

    checkoutPant() {
        const { edit } = this.props.match.params
        const { selectedMeasurement } = this.props.getMeasurement
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        let { selectedPantFabric } = this.props.selectedPantFabric
        const { selectedCombination } = this.props.selectedPantStyle
        let { token: { accessToken } } = this.props.token || {}
        const checkoutId = lastIncompleteCheckout ? lastIncompleteCheckout?.id : this.props.getNewCheckOutId

        let Measurements = {
            value: selectedMeasurement,
            type: Constants.MEASUREMENT_TYPES.CUSTOMER_MEASUREMENT_SIZE,
            productType: Constants.PRODUCTS.PANT
        }
        let customAttributes = PANT_CHECKOUT(selectedCombination, selectedPantFabric, Measurements)
        let lineItems;
        if (edit) {
            lineItems = [{
                quantity: 1,
                variantId: selectedPantFabric?.id,
                id: selectedPantFabric?.updated_line_item_id,
                customAttributes: customAttributes
            }]
        } else {
            lineItems = [{
                quantity: 1,
                variantId: selectedPantFabric?.id,
                customAttributes: customAttributes
            }]
        }
        let input = {
            lineItems,
            checkoutId: checkoutId,
        }
        const callback = (response) => {
            let { checkoutLineItemsAdd } = response || {}
            if (accessToken && checkoutLineItemsAdd) {
                if (!lastIncompleteCheckout) {
                    this.customerAssociate(checkoutId)
                } else {
                    this.props.history.push('/cart')
                }
            } else {
                this.props.history.push('/cart')
            }
        }
        if (edit) {
            this.props.checkoutLineItemsUpdate(LINE_ITEMS_UPDATE, input, callback)
        } else {
            this.props.checkoutLineItemsAdd(LINE_ITEMS_ADD, input, callback)

        }

    }

    checkoutSuit() {
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}

        const { isBlazer } = this.props
        const { edit } = this.props.match.params
        // const { selectedMeasurement } = this.props.getMeasurement
        let { selectedSuitFabric } = this.props.selectedSuitFabric || {}
        let { selectedAccents } = this.props.selectedSuitAccents
        let { selectedCombination } = this.props.selectedSuitStyle
        let { token: { accessToken } } = this.props.token || {}
        const checkoutId = lastIncompleteCheckout ? lastIncompleteCheckout?.id : this.props.getNewCheckOutId

        // let Measurements = {
        //     value: selectedMeasurement,
        //     type: Constants.MEASUREMENT_TYPES.CUSTOM_MEASUREMENT,
        //     productType: Constants.PRODUCTS.SUIT
        // }
        // let customAttributes = SUIT_CHECKOUT(isBlazer, selectedCombination,
        //     selectedSuitFabric, selectedAccents, Measurements)
        let customAttributes = SUIT_CHECKOUT(isBlazer, selectedCombination,
            selectedSuitFabric, selectedAccents)

        let lineItems;
        if (edit) {
            lineItems = [{
                quantity: 1,
                variantId: selectedSuitFabric?.id,
                id: selectedSuitFabric?.updated_line_item_id,
                customAttributes: customAttributes
            }]
        } else {
            lineItems = [{
                quantity: 1,
                variantId: selectedSuitFabric?.id,
                customAttributes: customAttributes
            }]
        }
        let input = {
            lineItems,
            checkoutId: checkoutId,
        }

        const callback = (response) => {
            let { checkoutLineItemsAdd } = response || {}
            if (accessToken && checkoutLineItemsAdd) {
                if (!lastIncompleteCheckout) {
                    this.customerAssociate(checkoutId)
                } else {
                    this.props.history.push('/cart')
                }
            } else {
                this.props.history.push('/cart')
            }
        }
        if (edit) {
            this.props.checkoutLineItemsUpdate(LINE_ITEMS_UPDATE, input, callback)
        } else {
            this.props.checkoutLineItemsAdd(LINE_ITEMS_ADD, input, callback)

        }

    }

    checkoutBlazer() {
        const { edit } = this.props.match.params
        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        // const { selectedMeasurement } = this.props.getMeasurement
        let { selectedBlazerFabric } = this.props.selectedBlazerFabric
        let { selectedAccents } = this.props.blazerAccents
        let { selectedCombination } = this.props.selectedBlazerStyle
        let { token: { accessToken } } = this.props.token || {}
        const checkoutId = lastIncompleteCheckout ? lastIncompleteCheckout?.id : this.props.getNewCheckOutId
        // let Measurements = {
        //     value: selectedMeasurement,
        //     type: Constants.MEASUREMENT_TYPES.CUSTOM_MEASUREMENT,
        //     productType: Constants.PRODUCTS.SUIT
        // }
        // let customAttributes = BLAZER_CHECKOUT(selectedCombination, selectedBlazerFabric, selectedAccents, Measurements)
        let customAttributes = BLAZER_CHECKOUT(selectedCombination, selectedBlazerFabric, selectedAccents,)

        let lineItems;
        if (edit) {
            lineItems = [{
                quantity: 1,
                variantId: selectedBlazerFabric?.id,
                id: selectedBlazerFabric?.updated_line_item_id,
                customAttributes: customAttributes
            }]
        } else {
            lineItems = [{
                quantity: 1,
                variantId: selectedBlazerFabric?.id,
                customAttributes: customAttributes
            }]
        }
        let input = {
            lineItems,
            checkoutId: checkoutId,
        }


        const callback = (response) => {
            let { checkoutLineItemsAdd } = response || {}
            if (accessToken && checkoutLineItemsAdd) {
                if (!lastIncompleteCheckout) {
                    this.customerAssociate(checkoutId)
                } else {
                    this.props.history.push('/cart')
                }
            } else {
                this.props.history.push('/cart')
            }
        }
        if (edit) {
            this.props.checkoutLineItemsUpdate(LINE_ITEMS_UPDATE, input, callback)
        } else {
            this.props.checkoutLineItemsAdd(LINE_ITEMS_ADD, input, callback)

        }

    }

    checkoutVest() {
        const { edit } = this.props.match.params
        let { selectedSuitFabric } = this.props.selectedSuitFabric
        let { selectedAccents } = this.props.selectedSuitAccents
        let { selectedCombination } = this.props.selectedSuitStyle
        // const { selectedMeasurement } = this.props.getMeasurement
        let { selectedVestStyle, selectedVestAccents, selectedVestFabric: { selectedVestFabric } } = this.props

        const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
        let { token: { accessToken } } = this.props.token || {}
        const checkoutId = lastIncompleteCheckout ? lastIncompleteCheckout?.id : this.props.getNewCheckOutId

        // let Measurements = {
        //     value: selectedMeasurement,
        //     type: Constants.MEASUREMENT_TYPES.CUSTOMER_MEASUREMENT_SIZE,
        //     productType: Constants.PRODUCTS.SUIT
        // }

        // let customAttributes = VEST_CHECKOUT(selectedSuitFabric, selectedCombination,
        //     selectedAccents, selectedVestAccents, selectedVestStyle, Measurements, selectedVestFabric)
        let customAttributes = VEST_CHECKOUT(selectedSuitFabric, selectedCombination,
            selectedAccents, selectedVestAccents, selectedVestStyle, '', selectedVestFabric)
        let lineItems;
        if (edit) {
            lineItems = [{
                quantity: 1,
                variantId: selectedVestFabric?.id,
                id: selectedVestFabric?.updated_line_item_id,
                customAttributes: customAttributes
            }]
        } else {
            lineItems = [{
                quantity: 1,
                variantId: selectedVestFabric?.id,
                customAttributes: customAttributes
            }]
        }
        let input = {
            lineItems,
            checkoutId: checkoutId,
        }
        const callback = (response) => {
            let { checkoutLineItemsAdd } = response || {}
            if (accessToken && checkoutLineItemsAdd) {
                if (!lastIncompleteCheckout) {
                    this.customerAssociate(checkoutId)
                } else {
                    this.props.history.push('/cart')
                }
            } else {
                this.props.history.push('/cart')
            }
        }
        if (edit) {
            this.props.checkoutLineItemsUpdate(LINE_ITEMS_UPDATE, input, callback)
        } else {
            this.props.checkoutLineItemsAdd(LINE_ITEMS_ADD, input, callback)

        }

    }
    onPrevStep() {
        const { location: { search } } = this.props || {}
        switch (search) {
            case '?fabric':
                this.props.history.goBack()
                break;
            case '?style':
                this.props.history.push(`${this.props.location.pathname}?fabric`)
                break;
            case '?accent':
                this.props.history.push(`${this.props.location.pathname}?style`)
                break;
            case '?measure':
                this.props.history.push(`${this.props.location.pathname}?accent`)
                break;
            case '?summary':
                this.props.history.push(`${this.props.location.pathname}?measure`)
                break;
        }

    }

    handleSave() {
        if (this.state.standardSizePage === 1) {
            this.setState({ standardSizePage: 2 })
        } else {
            this.props.updateSelectedMeasurement(this.state.selectedData)
            this.onNextStep()
        }
    }

    showMeasurementModal() {
        let { token = {} } = this.props.token
        let { accessToken } = token || {}
        if (accessToken) {
            this.setState({ selectedPreviousOrderModel: !this.state.selectedPreviousOrderModel, previousOrderSizePage: 1, standardSizePage: 1 })
        } else {
            this.setState({ selectedPreviousOrderModel: false, previousOrderSizePage: 1, standardSizePage: 1 })
        }
    }

    selectedMeasurementSize(shirtSizes, index, category) {
        if (shirtSizes?.category !== category) {
            return
        }
        this.setState({
            selectedData: {
                type: shirtMock?.type,
                size: shirtSizes?.size,
                selectedIndex: index,
                category: shirtSizes.category,
                shirtSizes: shirtSizes
            }
        })
    }

    selectedPantMeasurementSize(pantSizes, index, category) {
        this.setState({
            selectedData: {
                type: pantMock?.type,
                size: pantSizes?.size,
                selectedIndex: index,
                category: category,
                pantSizes: pantSizes
            }
        })
    }

    selectedSuitMeasurementSize(suitSizes, index, category) {
        if (suitSizes?.category !== category) {
            return
        }
        this.setState({
            selectedData: {
                type: suitMock?.type,
                size: suitSizes?.size,
                selectedIndex: index,
                category: suitSizes.category,
                suitSizes: suitSizes
            }
        })
    }

    selectedVestMeasurementSize(suitSizes, index, category) {
        if (suitSizes?.category !== category) {
            return
        }
        this.setState({
            selectedData: {
                type: suitMock?.type,
                size: suitSizes?.size,
                selectedIndex: index,
                category: suitSizes.category,
                suitSizes: suitSizes
            }
        })
    }

    reduceCount(subIndex, sizeType) {
        let { selectedData } = this.state || {}
        selectedData[sizeType].subSize[subIndex].size = selectedData[sizeType].subSize[subIndex].size - 1
        this.setState({
            ...this.state,
            selectedData
        })
    }
    increaseCount(subIndex, sizeType) {
        let { selectedData } = this.state
        selectedData[sizeType].subSize[subIndex].size = selectedData[sizeType].subSize[subIndex].size + 1
        this.setState({
            selectedData
        })
    }

    render_custom_measurements() {
        const { isShirt, isBlazer, isSuit, isPant, isVest } = this.props
        const { selectedData } = this.state
        if (isShirt) {
            return <Shirt_Measurement
                selectedMeasurementSize={(size, index, category) => this.selectedMeasurementSize(size, index, category)}
                selectedData={selectedData}
            />
        } else if (isPant) {
            return <Pant_Measurements
                selectedPantMeasurementSize={(size, index, category) => this.selectedPantMeasurementSize(size, index, category)}
                selectedData={selectedData}
            />
        } else if (isSuit) {
            return <Suit_Measurements
                selectedSuitMeasurementSize={(size, index, category) => this.selectedSuitMeasurementSize(size, index, category)}
                selectedData={selectedData}
            />
        } else if (isVest) {
            return <Vest_Measurements
                selectedVestMeasurementSize={(size, index, category) => this.selectedVestMeasurementSize(size, index, category)}
                selectedData={selectedData}
            />
        }
    }


    renderCustomSize() {
        const { selectedData, selectedUnits } = this.state;
        const componentMap = {
            isShirt: {
                props: {
                    reduceCount: (subIndex) => this.reduceCount(subIndex, 'shirtSizes'),
                    increaseCount: (subIndex) => this.increaseCount(subIndex, 'shirtSizes'),
                    selectedData: selectedData?.shirtSizes?.subSize,
                    selectedUnits: selectedUnits
                },
            },
            isPant: {
                props: {
                    reduceCount: (subIndex) => this.reduceCount(subIndex, 'pantSizes'),
                    increaseCount: (subIndex) => this.increaseCount(subIndex, 'pantSizes'),
                    selectedData: selectedData?.pantSizes?.subSize,
                    selectedUnits: selectedUnits
                },
            },
            isSuit: {
                props: {
                    reduceCount: (subIndex) => this.reduceCount(subIndex, 'suitSizes'),
                    increaseCount: (subIndex) => this.increaseCount(subIndex, 'suitSizes'),
                    selectedData: selectedData?.suitSizes?.subSize,
                    selectedUnits: selectedUnits
                },
            },
        };

        for (const prop in componentMap) {
            if (this.props[prop]) {
                const { props } = componentMap[prop];
                return <CustomizeSize {...props} />;
            }
        }
        return null;
    }

    shirtConvertMeters(e) {
        const { checked } = e.target
        if (checked) {
            this.setState({ selectedUnits: "cm" })
        } else {
            this.setState({ selectedUnits: "in" })
        }
    }
    toggleSearch = () => {
        this.setState({ searchVisible: !this.state.searchVisible, searchValue: "" });
    }
    deleteMeasurement(id) {
        let { customerId } = (this.props.customerId) || {}
        const callback = (response) => {
            const { userErrors = {} } = (response && response.privateMetafieldDelete) || {}
            if (userErrors !== null) {
                toast.success(`Measurement Successfully Deleted`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                this._getMeasurementDetails(customerId)
                return;
            } else {
                toast.error('Something Went wrong Please Try again later ', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
        let input = {
            id: id
        }
        this.props.measurementDelete(DELETE_MEASUREMENT, input, callback)
    }
    navEditMeasurement(key, namespace, measurementId) {
        if (key.includes(titleCase(Constants.PRODUCTS.SHIRT))) {
            this.props.history.push(
                `/measurement/tape/shirt/success/${key}/${namespace}/${measurementId}/:edit`
            );
        } else if (key.includes(titleCase(Constants.PRODUCTS.PANT))) {
            this.props.history.push(
                `/measurement/tape/pant/success/${key}/${namespace}/${measurementId}/:edit`
            );
        } else if (key.includes(titleCase(Constants.PRODUCTS.SUIT))) {
            this.props.history.push(
                `/measurement/tape/suit/success/${key}/${namespace}/${measurementId}/:edit`
            );
        } else if (key.includes(titleCase(Constants.PRODUCTS.BLAZER))) {
            this.props.history.push(
                `/measurement/tape/blazer/success/${key}/${namespace}/${measurementId}/:edit`
            );
        } else if (key.includes(titleCase(Constants.PRODUCTS.VESTS))) {
            this.props.history.push(
                `/measurement/tape/suit/success/${key}/${namespace}/${measurementId}/:edit`
            );
        } else {
            this.props.history.push(
                `/measurement/tape/shirt/success/${key}/${namespace}/${measurementId}/:edit`
            );
        }
    }
    editMeasurement(key, namespace, id) {
        let measurementId = btoa(id)
        let params = {
            key,
            measurementId,
            namespace
        }
        this.props.updateMeasurementOptions('edit1', params)
        this.navEditMeasurement(key, namespace, measurementId)
    }
    openMeasurementSuccessPage(parseData) {
        if (parseData?.value) {
            this.props.updateSelectedMeasurement(parseData?.value)
        } else {
            this.props.updateSelectedMeasurement(parseData)
        }
        this.setState({ previousOrderSizePage: 2 })
    }
    handleStandardSizeModel() {
        this.setState({ selectedStandardSizeModel: !this.state.selectedStandardSizeModel, standardSizePage: 1 })
    }
    handlePreviousOrderModel() {
        this.setState({ selectedPreviousOrderModel: !this.state.selectedPreviousOrderModel })
    }
    _renderMeasurementProducts() {
        const { isShirt, isSuit, isBlazer, isPant, isVest } = this.props
        if (isShirt) {
            return titleCase(Constants.PRODUCTS.SHIRT)
        } else if (isPant) {
            return titleCase(Constants.PRODUCTS.PANT)
        } else if (isSuit) {
            return titleCase(Constants.PRODUCTS.SUIT)
        } else if (isVest) {
            return titleCase(Constants.PRODUCTS.SUIT)
        } else if (isBlazer) {
            return titleCase(Constants.PRODUCTS.BLAZER)
        } else {
            return titleCase(Constants.PRODUCTS.SHIRT)
        }
    }
    _renderOrderPlacedDate(date) {
        let updatedDate = moment(date)
        return moment(updatedDate, "YYYYMMDD").fromNow();
    }
    searchFabrics(event) {
        let { value } = event.target;
        this.setState({ searchValue: value })
    }
    scrollLeft = () => {
        const { currentIndex } = this.state;
        if (currentIndex > 0) {
            const newIndex = currentIndex - 2;
            this.setState({ currentIndex: newIndex }, () => {
                this.scrollToIndex(newIndex);
            });
        }
    };

    scrollRight = () => {
        const { currentIndex } = this.state;
        const renderedMenu = this._getRenderedMenu();
        if (currentIndex < renderedMenu.length - 1) {
            const newIndex = currentIndex + 2;
            this.setState({ currentIndex: newIndex }, () => {
                this.scrollToIndex(newIndex);
            });
        }
    };

    scrollToIndex = (index) => {
        const container = this.menuContainerRef.current;
        const menuItems = container.querySelectorAll('a');
        if (menuItems.length > 0) {
            const menuItem = menuItems[index];
            if (menuItem) {
                menuItem.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'center'
                });
            }
        }
    };

    _getRenderedMenu() {
        // Get the rendered menu items dynamically from _renderStyles
        const renderedMenu = this._renderStyles().props.children;
        return React.Children.toArray(renderedMenu);
    }

    toggleOffcanvas = () => {
        document.body.style.overflow = 'hidden';
    };
    toggleOffcanvasClose = () => {
        document.body.style.overflow = 'auto';
    };
    render() {
        const { search } = this.props.location
        const { isShirt, isSuit, isPant, isBlazer, isVest } = this.props
        const { measurementDetails } = this.props.measurementDetails || {}
        const { standardSizePage, previousOrderSizePage, selectedData, selectedPreviousOrderModel, selectedUnits, searchVisible, selectedStandardSizeModel, searchValue } = this.state
        const { name, amount, currencyCode } = this._renderSelectedFabric()
        let { token: { accessToken } } = this.props.token || {}
        const { metafields = {} } = measurementDetails?.customer || {}
        let validMeasurements = metafields?.edges?.filter((o) => o?.node?.key.includes(this._renderMeasurementProducts()))
        const { selectedMeasurement } = this.props.getMeasurement || {};
        return (
            <React.Fragment>
                <div className='canvas_screen'>
                    <div className='row carousel_canvas'>
                        <div className='p-0 mobile_canvas_part' >
                            <div className='canavas_slide_change'>
                                <div className='canvas_align'>
                                    {this._renderCanvasSections()}
                                </div>
                            </div>
                        </div>
                        <div className='p-0 mobile_canvas_content h-100 position-relative'>
                            <div className='d-lg-flex w-100 h-100 m-0'>
                                <div className='canvas_contents border_canvas'>
                                    {
                                        search === '?fabric' && <div className='d-flex gap-2 canvas_top_section'>
                                            {/* <button className="btn dropdown_toggle_remove sort_btn dropdown-toggle"
                                                type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span className='H-cb fs-14 px-2'>SORT BY <img src={PATH?.img?.SortByIcon} alt="sortbyicon" className='sort_by_icon' />
                                                </span>
                                            </button> */}
                                            <div className='d-flex gap-12 w-100'>
                                                {/* <ButtonDesign
                                                    btnWidth={'gap-16 d-lg-flex d-none sort_btn'}
                                                    className={'fs-14 H-l py-6'}
                                                    ButtonValue={'filter'}
                                                    
                                                    arrowImg={PATH?.img?.SortByIcon}
                                                /> */}
                                                {/* <img src={PATH?.img?.SortByIcon} className='filter-mobile-icon' alt='filter-icon' /> */}
                                                {!searchVisible &&
                                                    <>
                                                        <p className='filter-mobile-icon flex-grow-1 ps-2'>{name}</p>
                                                        <i className="bi bi-search fs-13 text_muted me-2 filter-mobile-icon" onClick={this.toggleSearch}></i>
                                                    </>
                                                }
                                                <div className={`${searchVisible ? "input-group w-100 canvas_top_btns" : "input-group w-100 canvas_top_btns search_mobile_hide"}`}>
                                                    <span className="input-group-text bg-transparent border-0 p-0" id="basic-addon1">
                                                        <i className="bi bi-search fs-13 text_muted me-2" ></i></span>
                                                    <input type="search" className="form-control border-0 p-0 canvas_search" value={searchValue}
                                                        placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => this.searchFabrics(e)} />
                                                    <span className='close-search' onClick={this.toggleSearch}>Close</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {search === '?style' ?
                                        <div className="menu-wrapper d-flex align-items-center">
                                            {/* <button className='btn canvas_prev_next canvas_style_prev'
                                                type="button" onClick={this.scrollLeft}><img src={PATH?.img?.CanvasArrow} className='arrowCanvas prev' alt='arrow' /></button> */}
                                            {/* <button className="arrow left">
                                                &#9664;
                                            </button> */}
                                            <div className='canvas_style_headings' ref={this.menuContainerRef}>
                                                {this._renderStyles()}
                                            </div>
                                            {/* <button className='btn canvas_prev_next canvas_style_prev'
                                                type="button" onClick={this.scrollRight}>
                                                <img src={PATH?.img?.CanvasArrow} className='arrowCanvas' alt='arrow' />
                                            </button> */}
                                            {/* <button className="arrow right" >
                                                &#9654;
                                            </button> */}
                                        </div> : null}
                                    {search === '?measure' ?
                                        <div className='pt-lg-5 pt-4 measure_contents'>
                                            <p className='text-center fs-16 H-m m-0 pb-3'>{CONTENTS.canvas.measureContent.addYourMeasurement}</p>
                                            <div className='d-flex flex-column justify-content-center row-gap-3 px-lg-5 px-2 pt-lg-4 pt-2'>
                                                {/* {
                                                    Object.keys(finalCustomSize).length !== 0 &&
                                                    <div className='style_type py-3 px-4 active-final-mesure' type="button">
                                                        <p className='customFinalSize'>Size: {finalCustomSize?.size}</p>
                                                        <p className='pt-2 d-flex align-item-left'>Created Online</p>
                                                    </div>
                                                } */}
                                                <div className='d-flex justify-content-center gap-8'>
                                                    <div className='d-flex flex-column gap-16 text-center align-items-center px-2 pt-0'>
                                                        <div onClick={() => this.toggleOffcanvas()}>
                                                            <div className='d-flex flex-column gap-16 align-items-center' type="button" data-bs-toggle="offcanvas" onClick={() => this.handleStandardSizeModel()}>
                                                                <div className="icon-bg canvas rounded-circle d-flex align-items-center justify-content-center">
                                                                    <img src={PATH?.img?.StandardSizeIcon} className='icon_alignment canvas' alt="fit-icon" />
                                                                </div>
                                                                <p className='H-l fs-13'>{CONTENTS.canvas.measureContent.selectStandardSize}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className='d-flex flex-column gap-16 text-center align-items-center px-2 pt-0'>
                                                        <div className='d-flex flex-column gap-16 align-items-center' type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvassize">
                                                            <div className="icon-bg canvas rounded-circle d-flex align-items-center justify-content-center">
                                                                <img src={PATH?.img?.CustomProfileIcon} className='icon_alignment canvas' alt="fit-icon" />
                                                            </div>
                                                            <p className='H-l fs-14'>build a custom profile</p>
                                                        </div>
                                                    </div> */}
                                                    <div className='d-flex flex-column gap-16 text-center align-items-center px-2 pt-0' onClick={() => this.showMeasurementModal()}>
                                                        <div className='d-flex flex-column gap-16 align-items-center' type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvassize" onClick={() => this.toggleOffcanvas()}>
                                                            <div className="icon-bg canvas rounded-circle d-flex align-items-center justify-content-center">
                                                                <img src={PATH?.img?.PreviousOrderIcon} className='icon_alignment canvas' alt="fit-icon" />
                                                            </div>
                                                            <p className='H-l fs-13'>{CONTENTS.canvas.measureContent.copyPreviousOrder}</p>
                                                        </div>
                                                    </div>
                                                    {/* <CopyMeasurement
                                                        openModal={selectedPreviousOrderModel}
                                                        closeModal={() => this.showMeasurementModal()}
                                                        measurementList={measurementDetails?.customer}
                                                        deleteMeasurement={(id) => this.deleteMeasurement(id)}
                                                        editMeasurement={(key, namespace, id) => this.editMeasurement(key, namespace, id)}
                                                        isShirt={isShirt}
                                                        isBlazer={isBlazer}
                                                        isSuit={isSuit}
                                                        isPant={isPant}
                                                        isVest={isVest}
                                                        openMeasurementSuccessPage={(parseData) => this.openMeasurementSuccessPage(parseData)}
                                                    /> */}
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                    {
                                        search === '?summary' ?
                                            <div className='pt-lg-4 pt-3 measure_contents'>
                                                <p className='text-center fs-16 H-m m-0'>{CONTENTS.canvas.summaryContent.yourSummary}</p>
                                                <div className='d-flex flex-column justify-content-center row-gap-2 px-lg-4 px-3 pt-lg-4 pt-3'>
                                                    <div className='measure_inputs pb-2 pt-2 px-4'>
                                                        <p className='H-m fs-13'>{CONTENTS.canvas.summaryContent.fabric}</p>
                                                        <div className='row py-2 mx-0'>
                                                            <div className='col-6 px-0'>
                                                                <p className='H-l fs-13 text-lowercase'>{name}</p>
                                                            </div>
                                                            <div className='col-6 px-0'>
                                                                <p className='H-l fs-13'>{amount}{currencyCode}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='measure_inputs summary_style pb-2 pt-2 px-4'>
                                                        <p className='H-m fs-13'>{CONTENTS.canvas.summaryContent.style}</p>
                                                        {this._render_summary_style()}
                                                    </div>
                                               
                                                    <div className='measure_inputs summary_style pb-2 pt-2 px-4'>
                                                        <p className='H-m fs-13'>{CONTENTS.canvas.summaryContent.measurement}</p>
                                                        {this._renderMeasurement()}
                                                    </div>
                                                </div>
                                            </div> : null
                                    }

                                    {
                                        search !== '?summary' ?
                                            <div className='canvas_content_scroll pt-lg-4 pt-3 '>
                                                {this._renderCanvasScreens()}
                                            </div> : null
                                    }

                                </div>
                                <div className='canvas_headings border_canvas'>
                                    <div className='mt-md-4 mt-0 canvas_headings_align'>
                                        <div className={`heading_align head1 canvas-menu-divider ${search === '?fabric' ? 'active' : ''}`}>
                                            <div className='head_detail' onClick={() => this.navSamePostion('fabric')}>
                                                <img src={`${search === '?fabric' ? PATH?.img?.FabricCanvasActiveIcon : PATH?.img?.FaricCanvasNormalICon}`} className='fabric_icon' alt="fabric-icon" />
                                                <p className={`H-m fs-13 text-lowercase ${search === '?fabric' ? 'canvas-active-text' : 'canvas-text-color'}`}>{CONTENTS.canvas.fabric}</p>
                                            </div>
                                        </div>
                                        <div className={`heading_align head2 canvas-menu-divider ${search === '?style' ? 'active' : ''}`}>
                                            <div className='head_detail' onClick={() => this.navSamePostion('style')}>
                                                <img src={`${search === '?style' ? PATH?.img?.StyleCanvasActiveIcon : PATH?.img?.StyleCanvasIcon}`} className='fabric_icon' alt="style-icon" />
                                                <p className={`H-m fs-13 text-lowercase ${search === '?style' ? 'canvas-active-text' : 'canvas-text-color'}`}>{CONTENTS.canvas.style}</p>
                                            </div>
                                        </div>
                                     

                                        <div className={`heading_align head4 canvas-menu-divider ${search === '?measure' ? 'active' : ''}`}>
                                            <div className='head_detail' onClick={() => this.navSamePostion('measure')}>
                                                <img src={`${search === '?measure' ? PATH?.img?.MeasureCanvasActiveIcon : PATH?.img?.MeasureCanvasIcon}`} className='fabric_icon' alt="measure-icon" />
                                                <p className={`H-m fs-13 text-lowercase ${search === '?measure' ? 'canvas-active-text' : 'canvas-text-color'}`}>{CONTENTS.canvas.measure}</p>
                                            </div>
                                        </div>

                                        <div className={`heading_align head5 canvas-menu-divider ${search === '?summary' ? 'active' : ''}`}>
                                            <div className='head_detail' onClick={() => this.navSamePostion('summary')}>
                                                <img src={`${search === '?summary' ? PATH?.img?.SummaryCanvasActiveIcon : PATH?.img?.SummaryCanvasIcon}`} className='fabric_icon' alt="summary-icon" />
                                                <p className={`H-m fs-13 text-lowercase ${search === '?summary' ? 'canvas-active-text' : 'canvas-text-color'}`}>{CONTENTS.canvas.summary}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='canvas_bottom_btns canvas_bottom_btn_align'>
                                <div className='d-flex flex-column gap-6'>
                                    <p className="canvas_bottom_text text-nowrap fs-14 H-l text-lowercase">{name}</p>
                                    <p className='fs-14 H-m m-0'>
                                        {Number(amount)?.toFixed(2)} {currencyCode}</p>
                                </div>
                                <div className='d-flex gap-2'>
                                    {search !== '?summary' &&
                                        <>
                                            <button className='btn canvas_prev_next'
                                                type="button" onClick={() => this.onPrevStep()}><img src={PATH?.img?.CanvasArrow} className='arrowCanvas prev' alt='arrow' /></button>
                                            <button className='btn canvas_prev_next'
                                                type="button" onClick={() => this.onNextStep()}>
                                                <img src={PATH?.img?.CanvasArrow} className='arrowCanvas' alt='arrow' />
                                            </button>
                                        </>
                                    }
                                    {search === '?summary' && <ButtonDesign
                                        btnWidth={'p-0 d-flex'}
                                        className={'canvas_summary_addtocart fs-16 H-cb button-primary py-12'}
                                        ButtonValue={'Add to cart'}
                                        
                                        onClick={() => this.onNextStep()}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {selectedStandardSizeModel &&
                    <React.Fragment>
                        <div className="offcanvas-backdrop fade show"></div>
                        <div className='offcanvas offcanvas-end measurement_offcanvas show'>
                            <div className='h-100 d-flex flex-column'>
                                <div className='d-flex justify-content-between'>
                                    <div onClick={() => this.toggleOffcanvasClose()}>
                                        <span className='measurement_btn_close mx-4 mt-3 mb-lg-0 mb-2 d-inline-block' onClick={() => this.handleStandardSizeModel()}>
                                            <i className="bi bi-x fs-19 text_black"></i>
                                        </span>
                                    </div>
                                    {standardSizePage === 2 && <span className='mt-3 me-2 cursor-pointer H-l pe-4 fs-14' onClick={() => this.setState({ standardSizePage: 1 })}>Back</span>}
                                </div>
                                <div className='flex-grow-1'>
                                    {
                                        standardSizePage === 1 ? this.render_custom_measurements()
                                            :
                                            <div className="measure_content_scroll">
                                                <p className='fs-18 H-m text-center m-0 pb-2'>Size measurements</p>
                                                <p className='fs-13 H-l text-center px-2'>If a standard size fits you perfectly, you can skip this step.</p>
                                                <div className='size_chart_margin me-0 mt-1 table_class px-5'>
                                                    <div className="d-flex justify-content-center pb-3 pb-md-4 pt-4">
                                                        <label className="mx-2 H-l fs-14">in</label>
                                                        <label className="switch_m">
                                                            <input
                                                                type="checkbox"
                                                                defaultChecked={selectedUnits === 'cm' ? true : false}
                                                                onClick={(e) => this.shirtConvertMeters(e)}
                                                            />
                                                            <span className="slider_m round_m"></span>
                                                        </label>
                                                        <label className="mx-2 H-l fs-14">cm</label>
                                                    </div>
                                                    {
                                                        this.renderCustomSize()
                                                    }
                                                </div>
                                                {/* <div className='pt-4 pb-5 px-4'>
                                                <div className='mx-3 py-4 text-center'>
                                                    <p className='fs-20 f-sb mb-1'>We’re here to help</p>
                                                    <p className='fs-13 mb-1'>Still unsure about your size?</p>
                                                </div>
                                                <div className='d-flex flex-column measure_contact_align px-lg-4 px-3 mx-2'>
                                                    <div className='row align-items-baseline measure_contact py-3 px-3'>
                                                        <div className='col-11 pe-2'>
                                                            <p className='fs-16 f-m mb-1'>Whatsapp</p>
                                                            <p className='text_grey fs-12 mb-1'>Reach us on +66 98 765 2345 for free</p>
                                                        </div>
                                                    </div>
                                                    <div className='row align-items-baseline measure_contact py-3 px-3'>
                                                        <div className='col-1 p-0'><i className="bi bi-envelope fs-20"></i></div>
                                                        <div className='col-11 pe-2'>
                                                            <p className='fs-16 f-m mb-1'>Email</p>
                                                            <p className='text_grey fs-12 mb-1'>Send us a mail on <span className='text-decoration-underline'>service@paulsfashion.com</span> We will reply within 8 hours</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            </div>
                                    }
                                </div>
                                {/* <div className='d-flex gap-2 px-4 py-3 measure_offcanvas_bottom_btns'>
                                    <button className='btn common_btn secondary_btn w-50'
                                        data-bs-dismiss="offcanvas"
                                        type="button">Close</button>
                                    <button className='btn common_btn primary_btn w-50'
                                        data-bs-dismiss={`${standardSizePage !== 1 && "offcanvas"}`}
                                        disabled={!selectedData?.size}
                                        type="button" onClick={() => this.handleSave()}>
                                        {standardSizePage === 2 ? 'Save' : 'Next'}
                                    </button>
                                </div> */}
                                <div className='d-flex gap-15 px-4 py-3 address_popup_btn justify-content-start'>
                                    <ButtonDesign
                                        dismiss="offcanvas"
                                        btnWidth={'profile_popup_close_btn'}
                                        className={'w-100 fs-16 H-cb py-12'}
                                        
                                        ButtonValue={'close'}
                                    />
                                    <ButtonDesign
                                        dismiss={`${standardSizePage !== 1 && "offcanvas"}`}
                                        disabled={!selectedData?.size}
                                        btnWidth={'canvas_popup_proceed_btn border-0 button-primary'}
                                        className={'w-100 fs-16 H-cb py-12'}
                                        
                                        onClick={() => this.handleSave()}
                                        ButtonValue={standardSizePage === 2 ? 'Save' : 'Next'}
                                    />
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
                {selectedPreviousOrderModel &&
                    <React.Fragment>
                        <div className="offcanvas-backdrop fade show"></div>
                        <div className='offcanvas offcanvas-end measurement_offcanvas show'>
                            <div className='h-100 d-flex flex-column'>
                                <div className='d-flex justify-content-between'>
                                    <div onClick={() => this.toggleOffcanvasClose()}>
                                        <span className='measurement_btn_close mx-4 mt-3 mb-lg-0 mb-2 d-inline-block' onClick={() => this.showMeasurementModal()}>
                                            <i className="bi bi-x fs-19 text_black"></i>
                                        </span>
                                    </div>
                                    {previousOrderSizePage === 2 && <span className='mt-3 me-2 cursor-pointer H-l pe-4 fs-14' onClick={() => this.setState({ previousOrderSizePage: 1 })}>Back</span>}

                                </div>
                                {previousOrderSizePage === 1 &&
                                    <>
                                        <div className='overflow-auto h-100 d-flex flex-column'>
                                            <h5 className="modal-title text-center fs-16 H-m" id="measureLabel">{CONTENTS.canvas.measureContent.measurePopup.chooseYourMeasurements}</h5>
                                            <p className='fs-14 H-l text-center pb-2'>{CONTENTS.canvas.measureContent.measurePopup.content}</p>
                                            {
                                                validMeasurements && validMeasurements.map((o, i) => {
                                                    const { value, id, key, namespace, updatedAt } = o.node
                                                    const parseData = JSON.parse(value)
                                                    return (
                                                        <>

                                                            <div key={i} className="media nos cursor-pointer measurement_popup d-flex">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="flexRadioDefault"
                                                                    // id={`flexRadioDefault${index}`}
                                                                    // checked={this.state.selectedShippingAddress?.id === o?.id}
                                                                    // onChange={() => this.handleRadioChange(o)}
                                                                    />
                                                                </div>
                                                                <div className='d-flex flex-column gap-0 ps-2 flex-grow-1'>
                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                        <h6 className='fs-14 H-l' onClick={() => this.openMeasurementSuccessPage(parseData)}>{key}</h6>
                                                                        <div className='text-end d-flex gap-10 ps-5'>
                                                                            <img src={PATH?.img?.EditIcon} className='cart-icon cursor-pointer' alt='edit-icon' onClick={() => this.editMeasurement(key, namespace, id)} />
                                                                            <img src={PATH?.img?.DeleteIcon} className='cart-icon cursor-pointer' alt='delete-icon' onClick={() => this.deleteMeasurement(id)} />
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-0 H-l fs-14">Last updated {this._renderOrderPlacedDate(updatedAt)}</p>

                                                                </div>

                                                                {/* <img src={tape} className="img-fluid mr-3 h-35 mb-2" alt="icon" />
                                                            <div className="media-body">
                                                                <div className="row">
                                                                    <div className="col-lg-8 col-8">
                                                                        <h6 className='H-m' onClick={() => this.openMeasurementSuccessPage(parseData)}>{key}</h6>
                                                                        <p className="mb-0 H-l fs-14">Last updated {this._renderOrderPlacedDate(updatedAt)}</p>
                                                                        <span onClick={() => this.editMeasurement(key, namespace, id)} className="measurement-link edit H-l">Edit</span>
                                                                        <span onClick={() => this.deleteMeasurement(id)} className="measurement-link-delete H-l">Delete</span>
                                                                    </div>
                                                                    <div className="col-lg-4 col-4">
                                                                        <button type="button"
                                                                            onClick={() => this.openMeasurementSuccessPage(parseData)}
                                                                            className="btn button-primary ml-2 H-cb">Select
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                            </div>
                                                            {/* <div className='d-flex gap-15 px-4 py-3 address_popup_btn justify-content-start'>
                                                        <ButtonDesign
                                                            dismiss="offcanvas"
                                                            btnWidth={'profile_popup_close_btn'}
                                                            className={'w-100 fs-14 H-cb py-12'}
                                                            
                                                            ButtonValue={'close'}
                                                        />
                                                        <ButtonDesign
                                                            dismiss={`${previousOrderSizePage !== 1 && "offcanvas"}`}
                                                            disabled={!selectedData?.size}
                                                            btnWidth={'canvas_popup_proceed_btn border-0'}
                                                            className={'w-100 fs-14 H-cb button-primary py-12'}
                                                            
                                                            ButtonValue={previousOrderSizePage === 2 ? 'Save' : 'Next'}
                                                        />
                                                    </div> */}
                                                        </>

                                                    )
                                                })
                                            }
                                            {
                                                validMeasurements && validMeasurements.length === 0 && (
                                                    <p className="text-center">No {this._renderMeasurementProducts()} measurement profiles</p>
                                                )
                                            }
                                        </div>
                                        <div className='d-flex gap-15 px-4 py-3 justify-content-start'>
                                            <ButtonDesign
                                                btnWidth={'profile_popup_close_btn'}
                                                className={'w-100 fs-16 H-cb py-12'}
                                                
                                                ButtonValue={'close'}

                                            />
                                            <ButtonDesign
                                                btnWidth={'profile_popup_proceed_btn button-primary'}
                                                className={'w-100 fs-16 H-cb py-12'}
                                                
                                                ButtonValue={'proceed'}

                                            />
                                        </div>
                                    </>
                                }

                                {previousOrderSizePage === 2 &&
                                    <div className='overflow-auto h-100 d-flex flex-column'>
                                        {/* Instruction */}
                                        <div className="measure_content_scroll flex-grow-1">
                                            <p className='fs-22 H-cb text-center mb-1'>Size measurements</p>
                                            <p className='text_grey text-center H-l fs-13 mb-4 letter-spacing'>Your measurement is now complete</p>
                                            <div className="profile_measurement_popup">
                                                {
                                                    Object.keys(selectedMeasurement).map((key) => (
                                                        <div className="row mx-auto pb-3 pt-3" key={key}>
                                                            <div className="col-4">
                                                                <div className="add_measurement_popup_circle">
                                                                    <img src={PATH?.img[`Measurement${titleCase(key)}`]} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-3 d-flex align-items-center">
                                                                <p className="fs-14 H-l">{key}</p>
                                                            </div>
                                                            <div className="col-5 d-flex align-items-center">
                                                                <p className="fs-14 H-l">{selectedMeasurement[key]} in</p>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className='d-flex gap-15 px-4 py-3 address_popup_btn justify-content-start'>
                                            <ButtonDesign
                                                btnWidth={'profile_popup_close_btn'}
                                                className={'w-100 fs-16 H-cb py-12'}
                                                
                                                ButtonValue={'close'}
                                                onClick={() => this.showMeasurementModal()} // assuming 'close' means reset
                                            />
                                            <ButtonDesign
                                                btnWidth={'profile_popup_proceed_btn button-primary'}
                                                className={'w-100 fs-16 H-cb py-12'}
                                                
                                                ButtonValue={'Save'}
                                                onClick={() => this.onNextStep()}
                                            />
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProp = (state) => {
    return {
        token: Actions.getTokenSelector(store.getState()) || {},
        selectedAccents: Actions.getShirtAccent(state),
        getMeasurement: Actions.getSelectedMeasurement(store.getState()) || {},
        getNewCheckOutId: Actions.getNewCheckOutId(state),
        fabricList: Actions.getFabrics(state),
        getTags: Actions.getTags(state),

        selectedAccents: Actions.getShirtAccent(state),
        selectedSuitAccents: Actions.getSelectedSuitAccent(state),
        selectedVestAccents: Actions.getSelectedVestAccent(state),
        blazerAccents: Actions.getSelectedBlazerAccent(state),

        selectedVestFabric: Actions.getVestFabric(state),
        selectedFabric: Actions.getShirtFabric(state),
        getCheckoutDetails: Actions.getCheckoutId(store.getState()),
        selectedPantFabric: Actions.getSelectedPantFabric(state),
        selectedBlazerFabric: Actions.getSelectedBlazerFabric(state),
        selectedSuitFabric: Actions.getSelectedSuitFabric(state),

        selectedStyle: Actions.getShirtStyle(state),
        selectedPantStyle: Actions.getSelectedPantStyle(state),
        selectedSuitStyle: Actions.getSelectedSuitStyle(state),
        selectedVestStyle: Actions.getSelectedVestStyle(state),
        selectedBlazerStyle: Actions.getSelectedBlazerStyle(state),

        measurementDetails: Actions.getMeasurement(store.getState()) || {},
        customerId: Actions.getCustomerIdSelector(state),

    }
}

export default withRouter(connect(mapStateToProp, {
    updateSelectedAccent: Actions.updateShirtAccent,
    checkoutLineItemsAdd: Actions.checkoutLineItemsAdd,
    checkoutLineItemsUpdate: Actions.checkoutLineItemsUpdate,
    updateShirtAccent: Actions.updateShirtAccent,
    updateSuitType: Actions.updateSuitType,
    updateVestFabric: Actions.updateVestFabric,
    updateSelectedSuitFabric: Actions.updateSelectedSuitFabric,
    checkoutCreate: Actions.checkoutCreate,
    checkoutCustomerAssociate: Actions.checkoutCustomerAssociate,
    updateSelectedMeasurement: Actions.updateSelectedMeasurement,
    updatePantMeasurement: Actions.updatePantMeasurement,
    updateSuitMeasurement: Actions.updateSuitMeasurement,
    updateBlazerMeasurement: Actions.updateBlazerMeasurement,
    updateVestMeasurement: Actions.updateVestMeasurement,
    measurementDelete: Actions.measurementDelete,
    updateMeasurementOptions: Actions.updateMeasurementOptions,

})(CanvasScreen));