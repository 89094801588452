import React from 'react'
import { withRouter } from 'react-router-dom'
import CanvasSection from '../../component/Canvas'
import { connect } from 'react-redux'
import Actions from '../../redux/action'
import AccentsFabric from '../../component/AccentsFabric'
import CanvasButtons from '../../component/Canvas/canvas_buttons'
import { FabricsDetails } from '../../component/SelectedProducts/fabrics_details';
import Monogram from '../../component/Monogram'
import MonogramPosition from '../../component/Monogram/position'
import ButtonThreads from '../../component/Buttons/threads'
import ButtonHoles from '../../component/Buttons/holes'
import ShirtAccentsCollar from '../../component/ShirtAccents/collar'
import ShirtAccentsCuff from '../../component/ShirtAccents/cuff'
import ShirtAccentsButton from '../../component/ShirtAccents/buttons'
import ShirtAccentsElbow from '../../component/ShirtAccents/elbow'
import SuitSection from '../../component/Canvas/suit'
import SuitLining from '../../component/SuitAccents/lining'
import PocketSquare from '../../component/SuitAccents/pockets'
import { GET_COLLECTION_LIST } from '../../Queries/collection'
import { Collection } from '../../config/constant'
import SuitButtons from '../../component/SuitAccents/buttons'
import { images } from '../../util/image_render'
import VestSection from '../../component/Canvas/vest'
import BlazerSection from '../../component/Canvas/blazer'
import VestLining from '../../component/VestsAccents'
import SuitMonogramInitials from '../../component/SuitMonogramInitials'
import { store } from '../../createStore'
import { BLAZER_CHECKOUT, SHIRT_CHECKOUT, SUIT_CHECKOUT, VEST_CHECKOUT } from '../../component/ProductCheckout'
import { LINE_ITEMS_UPDATE } from '../../Queries'
import { ErrorToaster } from '../../util/toast'

class CanvasAccents extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: {
        initials: "",
        selectedButton: "white"
      },
      openMobile: true,
      isAccent: true,
      openStyle: true,
      showCollarCategory: false,
      categoryCollarType: 'default',
      showCuffCategory: false,
      showElbowCategory: false,
      openFabricDesign: false,
      showAccentsLining: false,
      showPocketSquare: false,
      perPage: 100,
      optionName: 'accent',
      isSuitVest: false,
      isClickCheckout: false,
      isSuitMonogram: false,
      openPreviewModal: false,
      sortBy: 'BEST_SELLING',
    }
  }

  componentDidMount() {
    const { selectedAccents } = this.props.selectedAccents
    let { fields, perPage } = this.state
    if (selectedAccents && selectedAccents.initials) {
      fields["initials"] = selectedAccents && selectedAccents.initials
      this.setState({ fields })
    }
    const { isShirt, isBlazer, isSuit, isVest } = this.props
    const { sortBy } = this.state
    let query = ''
    if (isShirt) {
      query = GET_COLLECTION_LIST(Collection.shirt, perPage, sortBy, true)
    } else if (isSuit || isVest) {
      query = GET_COLLECTION_LIST(Collection.lining, perPage, sortBy, true)
    } else if (isBlazer) {
      query = GET_COLLECTION_LIST(Collection.lining, perPage, sortBy, true)
    } else {
      query = GET_COLLECTION_LIST(Collection.shirt, perPage, sortBy, true)
    }
    this.props.getFabricList(query)
  }

  static getDerivedStateFromProps(props, state) {
    const { isVest } = props;
    const { isVests } = props.selectedVestStyle
    let isSuitVest = ''
    if (isVest && isVests) {
      isSuitVest = true
    } else {
      isSuitVest = false
    }
    return {
      isSuitVest,
    }
  }



  closeSideNavBar() {
    this.setState({ openMobile: false, openStyle: false })
  }
  navAccent() {
    this.setState({ openMobile: true, openStyle: true })
  }
  navFabric() {
    const { isShirt, isBlazer, isSuit, isVest } = this.props
    let { edit } = this.props.match.params
    if (edit) {
      if (isShirt) {
        this.props.history.push(`/canvas/shirt/${edit}?fabric`)
      } else if (isBlazer) {
        this.props.history.push(`/canvas/blazer/${edit}?fabric`)
      } else if (isSuit) {
        this.props.history.push(`/canvas/suit/${edit}?fabric`)
      } else if (isVest) {
        this.props.history.push(`/canvas/vests/${edit}?fabric`)
      }
    } else {
      if (isShirt) {
        this.props.history.push('/canvas/shirt?fabric')
      } else if (isBlazer) {
        this.props.history.push('/canvas/blazer?fabric')
      } else if (isSuit) {
        this.props.history.push('/canvas/suit?fabric')
      } else if (isVest) {
        this.props.history.push('/canvas/vests?fabric')
      }
    }
  }
  navMeasurement() {
    const { isShirt, isPant, isBlazer, isSuit, isVest } = this.props
    let { edit } = this.props.match.params
    const { lastIncompleteCheckout } = this.props.getCheckoutDetails || {}
    if (edit) {
      const { isBlazer, isShirt, isSuit } = this.props
      if (this.props.getNewCheckOutId || lastIncompleteCheckout) {
        if (isSuit) {
          this.suitCheckout(lastIncompleteCheckout?.id ? lastIncompleteCheckout?.id : this.props.getNewCheckOutId)
        } else if (isShirt) {
          this.shirtCheckout(lastIncompleteCheckout?.id ? lastIncompleteCheckout?.id : this.props.getNewCheckOutId)
        } else if (isBlazer) {
          this.blazerCheckout(lastIncompleteCheckout?.id ? lastIncompleteCheckout?.id : this.props.getNewCheckOutId)
        } else if (isVest) {
          this.vestCheckout(lastIncompleteCheckout?.id ? lastIncompleteCheckout?.id : this.props.getNewCheckOutId)
        } else {
          this.shirtCheckout(lastIncompleteCheckout?.id ? lastIncompleteCheckout?.id : this.props.getNewCheckOutId)
        }
      }
    } else {
      if (isShirt) {
        this.props.history.push('/canvas/shirt?measurement')
      } else if (isPant) {
        this.props.history.push('/canvas/pant?measurement')
      } else if (isBlazer) {
        this.props.history.push('/canvas/blazer?measurement')
      } else if (isSuit) {
        this.props.history.push('/canvas/suit?measurement')
      } else if (isVest) {
        this.props.history.push('/canvas/vests?measurement')
      }
    }
    this.setState({ openPreviewModal: false })
  }


  shirtCheckout(checkoutId) {
    const { selectedMeasurement } = this.props.getMeasurement
    let { selectedShirtFabric } = this.props.selectedFabric
    const { selectedCombination } = this.props.selectedStyle
    const { shirtAccents } = this.props.selectedAccents
    let convertMeasurement = JSON.parse(selectedMeasurement)
    let Measurements = {
      value: convertMeasurement?.value,
      type: convertMeasurement?.type,
      productType: convertMeasurement?.productType,
      key: convertMeasurement?.key
    }
    let customAttributes = SHIRT_CHECKOUT(shirtAccents, selectedCombination,
      selectedShirtFabric, Measurements)

    const callback = (response) => {
      let { checkoutLineItemsAdd = {} } = response && response
      if (checkoutLineItemsAdd === null) {
        ErrorToaster()
      } else {
        this.props.history.push('/cart')
      }
    }
    let lineItems = [{
      quantity: 1,
      variantId: selectedShirtFabric?.id,
      id: selectedShirtFabric?.lineItemId,
      customAttributes: customAttributes
    }]
    let input = {
      checkoutId: checkoutId,
      lineItems
    }
    this.props.checkoutLineItemsUpdate(LINE_ITEMS_UPDATE, input, callback)
  }

  blazerCheckout(checkoutId) {
    const { selectedMeasurement } = this.props.getMeasurement
    let { selectedBlazerFabric } = this.props.blazerFabric
    let { selectedAccents } = this.props.blazerAccents
    let { selectedCombination } = this.props.selectedBlazerStyle
    let convertMeasurement = JSON.parse(selectedMeasurement)

    let Measurements = {
      value: convertMeasurement?.value,
      type: convertMeasurement?.type,
      productType: convertMeasurement?.productType,
      key: convertMeasurement?.key
    }
    let customAttributes = BLAZER_CHECKOUT(selectedCombination, selectedBlazerFabric,
      selectedAccents, Measurements)

    const callback = (response) => {
      let { checkoutLineItemsAdd = {} } = response && response
      if (checkoutLineItemsAdd === null) {
        ErrorToaster()
      } else {
        this.props.history.push('/cart')
      }
    }
    let lineItems = [{
      quantity: 1,
      variantId: selectedBlazerFabric?.id,
      id: selectedBlazerFabric?.lineItemId,
      customAttributes: customAttributes
    }]
    let input = {
      checkoutId: checkoutId,
      lineItems
    }
    this.props.checkoutLineItemsUpdate(LINE_ITEMS_UPDATE, input, callback)
  }


  suitCheckout(checkoutId) {
    const { isBlazer } = this.props
    const { selectedMeasurement } = this.props.getMeasurement
    let { selectedSuitFabric } = this.props.selectedSuitFabric
    let { selectedAccents } = this.props.selectedSuitAccents
    let { selectedCombination } = this.props.selectedSuitStyle

    let { id, lineItemId } = selectedSuitFabric || {}

    let convertMeasurement = JSON.parse(selectedMeasurement)

    let Measurements = {
      value: convertMeasurement?.value,
      type: convertMeasurement?.type,
      productType: convertMeasurement?.productType,
      key: convertMeasurement?.key
    }
    let customAttributes = SUIT_CHECKOUT(isBlazer, selectedCombination, selectedSuitFabric,
      selectedAccents, Measurements)

    const callback = (response) => {
      let { checkoutLineItemsAdd = {} } = response && response
      if (checkoutLineItemsAdd === null) {
        ErrorToaster()
      } else {
        this.props.history.push('/cart')
      }
    }
    let lineItems = [{
      quantity: 1,
      variantId: id,
      id: lineItemId,
      customAttributes: customAttributes
    }]
    let input = {
      checkoutId: checkoutId,
      lineItems
    }
    this.props.checkoutLineItemsUpdate(LINE_ITEMS_UPDATE, input, callback)
  }


  vestCheckout(checkoutId) {
    let { selectedSuitFabric } = this.props.selectedSuitFabric
    const { selectedMeasurement } = this.props.getMeasurement

    let { selectedAccents } = this.props.selectedSuitAccents
    let { selectedCombination } = this.props.selectedSuitStyle
    let { selectedVestStyle, selectedVestAccents, selectedVestFabric: { selectedVestFabric } } = this.props
    let { id, lineItemId } = selectedVestFabric || {}
    let convertMeasurement = JSON.parse(selectedMeasurement)

    let Measurements = {
      value: convertMeasurement?.value,
      type: convertMeasurement?.type,
      productType: convertMeasurement?.productType,
      key: convertMeasurement?.key
    }

    let customAttributes = VEST_CHECKOUT(selectedSuitFabric, selectedCombination,
      selectedAccents, selectedVestAccents, selectedVestStyle, Measurements, selectedVestFabric)

    const callback = (response) => {
      let { checkoutLineItemsAdd = {} } = response && response
      if (checkoutLineItemsAdd === null) {
        ErrorToaster()
      } else {
        this.props.history.push('/cart')
      }
    }
    let lineItems = [{
      quantity: 1,
      variantId: id,
      id: lineItemId,
      customAttributes: customAttributes
    }]
    let input = {
      checkoutId: checkoutId,
      lineItems
    }
    this.props.checkoutLineItemsUpdate(LINE_ITEMS_UPDATE, input, callback)
  }




  navStyle() {
    const { isShirt, isPant, isBlazer, isSuit, isVest } = this.props
    let { edit } = this.props.match.params
    if (edit) {
      if (isShirt) {
        this.props.history.push(`/canvas/shirt/${edit}?style`)
      } else if (isPant) {
        this.props.history.push(`/canvas/pant/${edit}?style`)
      } else if (isBlazer) {
        this.props.history.push(`/canvas/blazer/${edit}?style`)
      } else if (isSuit) {
        this.props.history.push(`/canvas/suit/${edit}?style`)
      } else if (isVest) {
        this.props.history.push(`/canvas/vests/${edit}?style`)
      }
    } else {
      if (isShirt) {
        this.props.history.push('/canvas/shirt?style')
      } else if (isPant) {
        this.props.history.push('/canvas/pant?style')
      } else if (isBlazer) {
        this.props.history.push('/canvas/blazer?style')
      } else if (isSuit) {
        this.props.history.push('/canvas/suit?style')
      } else if (isVest) {
        this.props.history.push('/canvas/vests?style')
      }
    }
  }
  handleIntialChange(e) {
    const { isBlazer, isSuit } = this.props
    let { value } = e.target
    let { fields } = this.state
    fields['initials'] = value
    this.setState({ fields, isSuitMonogram: true })
    if (isSuit) {
      this.props.updateSelectedSuitAccent('initials', fields?.initials)
    } else if (isBlazer) {
      this.props.updateSelectedBlazerAccent('initials', fields?.initials)
    } else {
      this.props.updateSelectedAccent('initials', fields?.initials)

    }
  }
  checkFontStyle(value) {
    const { isBlazer, isSuit } = this.props
    // let { value, checked } = e.target
    if (isSuit) {
      this.props.updateSelectedSuitAccent('font', value)
    } else if (isBlazer) {
      this.props.updateSelectedBlazerAccent('font', value)
    } else {
      this.props.updateSelectedAccent('font', value)
    }

  }
  chooseMonogramColor(color) {
    const { isBlazer, isSuit } = this.props
    if (isSuit) {
      this.props.updateSelectedSuitAccent('fill', color)
    } else if (isBlazer) {
      this.props.updateSelectedBlazerAccent('fill', color)
    } else {
      this.props.updateSelectedAccent('fill', color)
    }
  }
  removeMonogram() {
    let { fields } = this.state
    const { isBlazer, isSuit } = this.props
    fields['initials'] = ''
    if (isSuit) {
      this.props.updateSelectedSuitAccent('initials', '')
    } else if (isBlazer) {
      this.props.removeBlazerMonogram()
    } else {
      this.props.removeShirtMonogram()
    }
    this.setState({ fields })
  }

  customizeButton(button) {
    let { fields } = this.state
    fields['selectedButton'] = button
    this.setState({ fields })
    this.props.updateSelectedAccent('selectedButton', button)
  }
  chooseButtonHoleColor(color) {
    const { isShirt, isVest, isSuit } = this.props
    if (isShirt) {
      this.props.updateSelectedAccent('hole', color)
    } else if (isSuit) {
      this.props.updateSelectedSuitAccent('hole', color)
    } else if (isVest) {
      this.props.updateSelectedVestAccents('hole', color)
    } else {
      this.props.updateSelectedAccent('hole', color)
    }
  }

  chooseButtonThreadColor(color, fabrics) {
    const { isShirt, isSuit, isVest } = this.props
    if (isShirt) {
      this.props.updateSelectedAccent('thread', color)
    } else if (isSuit) {
      this.props.updateSelectedSuitAccent('thread', color)
    } else if (isVest) {
      this.props.updateSelectedVestAccents('thread', color)
    } else {
      this.props.updateSelectedAccent('thread', color)
    }
  }
  selectAccentsButton(type) {
    const { isSuit, isVest } = this.props
    const { isSuitVest } = this.state
    if (isSuit) {
      this.props.updateSelectedSuitAccent('button', type)
    } else if (isVest && isSuitVest) {
      this.props.updateSelectedSuitAccent('button', type)
    } else if (isVest && !isSuitVest) {
      this.props.updateSelectedVestAccents('button', type)
    } else {
      this.props.updateSelectedSuitAccent('button', type)
    }
  }
  closeCollarCategory(id, name) {
    this.props.updateSelectedAccent('collarFabricId', id)
    this.props.updateSelectedAccent('collarFabricName', name)
  }
  openCollarCategories(type) {
    if (type === 'default') {
      this.setState({ showCollarCategory: false })
    } else {
      this.setState({ showCollarCategory: !this.state.showCollarCategory })
    }
    this.props.updateSelectedAccent('selectedCollarFabric', type)
  }
  closeSideCuffCategory(id, name) {
    this.props.updateSelectedAccent('cuffFabricId', id)
    this.props.updateSelectedAccent('cuffFabricName', name)
  }
  openCuffCategories(type) {
    if (type === 'default') {
      this.setState({ showCuffCategory: false })
    } else {
      this.setState({ showCuffCategory: !this.state.showCuffCategory })
    }
    this.props.updateSelectedAccent('selectedCuffFabric', type)
  }
  closeSideElbowCategory(id, name) {
    this.props.updateSelectedAccent('elbowPatchesId', id)
    this.props.updateSelectedAccent('elbowFabricName', name)
  }

  addElbowPatches(type) {
    switch (type) {
      case 'default':
        this.setState({ showElbowCategory: false })
        break;
      case 'color':
        this.setState({ showElbowCategory: !this.state.showElbowCategory })
        break;
      default:
        return null
    }
    this.props.updateSelectedAccent('elbowPatches', type)
    this.props.onCanvasPositionChanged('back')
  }
  openDesign() {
    this.setState({ openFabricDesign: true })
  }
  closeModal() {
    this.setState({ openFabricDesign: false })
  }

  moveMonogramPosition(type) {
    if (type === 'high') {
      this.props.onCanvasPositionChanged('folded')
    } else {
      this.props.onCanvasPositionChanged('front')
    }
    this.props.updateSelectedAccent('monogramPosition', type)
  }


  _renderParentAccents() {
    const { isShirt, isBlazer, isSuit, isVest } = this.props
    const { isSuitVest } = this.state
    if (isShirt) {
      return this._renderShirtAccents()
    } else if (isSuit) {
      return this._renderSuitAccents()
    } else if (isBlazer) {
      return this._renderBlazerAccents()
    } else if (isVest && isSuitVest) {
      return this._renderSuitAccents()
    } else if (isVest && !isSuitVest) {
      return this._renderVestAccents()
    } else {
      return this._renderShirtAccents()
    }
  }
  openSuitInnerFabrics(type) {
    console.log("type", type);

    if (type) {
      let key = 'selectedSuitLining'
      this.props.updateSelectedSuitAccent(key, type)
    }
    if (type === 'default') {
      this.setState({ showAccentsLining: false })
    } else {
      this.setState({ showAccentsLining: !this.state.showAccentsLining })
    }
  }
  openVestInnerFabrics(type) {
    if (type) {
      let key = 'selectedVestLining'
      this.props.updateSelectedVestAccents(key, type)
    }
    if (type === 'default') {
      this.setState({ showAccentsLining: false })
    } else {
      this.setState({ showAccentsLining: !this.state.showAccentsLining })
    }
  }
  openPocketSquare(type) {
    if (type) {
      let key = 'selectedPocketSquare'
      this.props.updateSelectedSuitAccent(key, type)
    }
    if (type === 'without') {
      this.setState({ showPocketSquare: false })
    } else {
      this.setState({ showPocketSquare: !this.state.showPocketSquare })
    }
  }
  closeSuitInnerFabrics(id, name) {
    this.props.updateSelectedSuitAccent('suitInnerFabric', id)
    this.props.updateSelectedSuitAccent('liningFabricName', name)
  }


  closeVestInnerFabrics(id, name) {
    this.props.updateSelectedVestAccents('selectedVestInnerFabric', id)
    this.props.updateSelectedSuitAccent('liningFabricName', name)
  }

  closePocketSquare(id, name) {
    this.props.updateSelectedSuitAccent('pocketSquareFabric', id)
    this.props.updateSelectedSuitAccent('pocketFabricName', name)
  }
  handleButtons(type) {
    const { isBlazer } = this.props
    let key = 'selectedButtonsThreads'
    if (isBlazer) {
      this.props.updateSelectedBlazerAccent(key, type)
    } else {
      this.props.updateSelectedSuitAccent(key, type)
    }
  }
  handleOnClickOptions(e) {
    let { value } = e.target
    switch (value) {
      case 'fabric':
        this.navFabric()
        break
      case 'style':
        this.navStyle()
        break
      case 'accent':
        this.navAccent()
        break
      case 'measurement':
        this.navMeasurement()
        break;
      default:
        this.navFabric()
        break;
    }
  }
  closeMonogramModal() {
    this.setState({ isSuitMonogram: false })
  }

  _renderSuitAccents(type) {
    let { showAccentsLining, showPocketSquare,
      optionName, fields, isSuitMonogram } = this.state
    const { isShirt, isBlazer, isPant, isSuit, isVest,
      selectedSuitAccents } = this.props
    const { selectedAccents } = selectedSuitAccents || {}
    const { selectedSuitLining, suitInnerFabric, selectedPocketSquare,
      pocketSquareFabric, selectedButtonsThreads } = selectedAccents || {}

    const { fabricList = {} } = this.props.fabricList
    const { collectionByHandle } = fabricList?.data || {}
    const { products } = collectionByHandle || {}
    return (
      <React.Fragment>
        <SuitLining
          openSuitInnerFabrics={(type) => this.openSuitInnerFabrics(type)}
          selectedSuitLining={selectedSuitLining}
        />
        <Monogram
          fields={fields}
          selectedAccents={selectedAccents}
          removeMonogram={() => this.removeMonogram()}
          handleIntialChange={(e) => this.handleIntialChange(e)}
          checkFontStyle={(font, e) => this.checkFontStyle(font, e)}
          chooseMonogramColor={(font, style) => this.chooseMonogramColor(font, style)}
          isSuit={true}
        />
        <SuitButtons
          selectedAccentsButton={selectedAccents}
          selectAccentsButton={(type) => this.selectAccentsButton(type)}
        />
        <PocketSquare
          selectedPocketSquare={selectedPocketSquare}
          openPocketSquare={(type) => this.openPocketSquare(type)}
        />
        <div className="">
          <p className="H-l pb-2 fs-13 text-lowercase">Suit Buttons and Colors</p>
          <div className='row row-gap-3 m-0'>
            <div className='col-lg-3 col-6 px-1'>
              <div className={`style_type ${selectedButtonsThreads === 'default' ? 'active_type' : ''}`}
                onClick={() => this.handleButtons('default')}>
                <div className='style_img' >
                  <img src={images('./images/suit/SVG/Default.svg')} alt="suit-accents" />
                </div>
                <p className="pt-1 fs-13 text-lowercase">Default</p>
              </div>
            </div>
            <div className='col-lg-3 col-6 px-1'>
              <div className={`style_type ${selectedButtonsThreads === 'all' ? 'active_type' : ''}`}
                onClick={() => this.handleButtons('all')}>
                <div className='style_img' >
                  <img src={images('./images/suit/SVG/CustomColor.svg')} alt="suit-accents" />
                </div>
                <p className="pt-1 fs-13 text-lowercase">All</p>
              </div>
            </div>
            <div className='col-lg-3 col-6 px-1'>
              <div className={`style_type ${selectedButtonsThreads === 'cuffs' ? 'active_type' : ''}`}
                onClick={() => this.handleButtons('cuffs')}>
                <div className='style_img' >
                  <img src={images('./images/suit/SVG/Unlined.svg')} alt="suit-accents" />
                </div>
                <p className="pt-1 fs-13 text-lowercase">Only Cuffs</p>
              </div>
            </div>
          </div>
        </div>
        {/* <ul className="row mx-auto p-0 w-90 my-sm-4 my-1 custom-collar cuff_desg">
          <h6 className="col-12 text-left px-0">SUIT BUTTONS AND COLORS</h6>
          <li className={selectedButtonsThreads === 'default' ? 'active' : ''}>
            <img
              src={images('./images/suit/SVG/No.svg')}
              className="img-fluid"
              alt="icons"
              onClick={() => this.handleButtons('default')} />
            <p>BY DEFAULT</p>
          </li>
          <li className={selectedButtonsThreads === 'all' ? 'active' : ''}>
            <img
              src={images('./images/suit/SVG/DOUBLE-BREASTED 2 BUTTONS.svg')}
              className="img-fluid"
              alt="icons"
              onClick={() => this.handleButtons('all')} />
            <p>ALL</p>
          </li>
          <li className={selectedButtonsThreads === 'cuffs' ? 'active' : ''}>
            <img
              src={images('./images/suit/SVG/2.svg')}
              className="img-fluid"
              alt="icons"
              onClick={() => this.handleButtons('cuffs')} />
            <p>ONLY CUFFS</p>
          </li>
          {
            lapels && lapels.key !== 'shawl' && (
              <li className={selectedButtonsThreads === 'lapel' ? 'active' : ''}>
                <img
                  src={images('./images/suit/SVG/STANDARD.svg')}
                  className="img-fluid"
                  alt="icons"
                  onClick={() => this.handleButtons('lapel')} />
                <p>LAPEL ONLY</p>
              </li>
            )
          }
        </ul> */}
        {
          selectedButtonsThreads !== 'lapel' && (
            <ButtonThreads
              chooseButtonThreadColor={(color, thread) => this.chooseButtonThreadColor(color, thread)}
              selectedAccents={selectedAccents}
              isSuit={isSuit || isBlazer}
            />
          )
        }
        <ButtonHoles
          chooseButtonHoleColor={(color, hole) => this.chooseButtonHoleColor(color, hole)}
          selectedAccents={selectedAccents}
          isSuit={isSuit || isBlazer}
        />


        <CanvasButtons
          name="Next"
          nextStep={() => this.navMeasurement()}
          handleOnClickOptions={(e) => this.handleOnClickOptions(e)}
          optionName={optionName}
          isShirt={isShirt}
          isPant={isPant}
          isSuit={isSuit}
          isBlazer={isBlazer}
          isVest={isVest}
        />

        <AccentsFabric
          showCategory={showAccentsLining}
          selectedFabric={suitInnerFabric}
          products={products}
          openCategories={() => this.openSuitInnerFabrics()}
          closeSideBar={(id, name) => this.closeSuitInnerFabrics(id, name)}
          isSuit={true}
        />
        <AccentsFabric
          showCategory={showPocketSquare}
          selectedFabric={pocketSquareFabric}
          products={products}
          openCategories={() => this.openPocketSquare()}
          closeSideBar={(id) => this.closePocketSquare(id)}
          isSuit={true}
        />
        {
          isSuitMonogram && (
            <SuitMonogramInitials
              selectedAccents={selectedAccents}
              closeMonogramModal={() => this.closeMonogramModal()}
            />
          )
        }

      </React.Fragment >
    )
  }


  manageBlazerAccents(key, value, secondKey, secondValue) {
    this.props.updateSelectedBlazerAccent(key, value)
    if (secondKey) {
      this.props.updateSelectedBlazerAccent(secondKey, secondValue)
    }
  }

  openBlazerLining(key, value) {
    if (value) {
      this.props.updateSelectedBlazerAccent(key, value)
    }
    if (value === 'default') {
      this.setState({ showAccentsLining: false })
    } else {
      this.setState({ showAccentsLining: !this.state.showAccentsLining })
    }
  }

  openBlazerPocketFabric(key, value) {
    if (value) {
      this.props.updateSelectedBlazerAccent(key, value)
    }
    if (value === 'without') {
      this.setState({ showPocketSquare: false })
    } else {
      this.setState({ showPocketSquare: !this.state.showPocketSquare })
    }
  }

  _renderBlazerAccents() {
    let { showAccentsLining, showPocketSquare, optionName, fields, isSuitMonogram } = this.state

    const { isShirt, isBlazer, isPant, isSuit, isVest, blazerAccents,
      selectedBlazerStyle } = this.props

    const { selectedBlazerLining, blazerInnerFabric, selectedPocketSquare,
      pocketSquareFabric, selectedButtonsThreads } = blazerAccents?.selectedAccents || {}
    const { fabricList = {} } = this.props.fabricList
    const { collectionByHandle } = fabricList?.data || {}
    const { products } = collectionByHandle || {}
    const { lapels } = selectedBlazerStyle?.selectedCombination || {}
    return (
      <React.Fragment>

        <SuitLining
          openSuitInnerFabrics={(type) => this.openBlazerLining('selectedBlazerLining', type)}
          selectedSuitLining={selectedBlazerLining}
        />
        <Monogram
          fields={fields}
          selectedAccents={blazerAccents?.selectedAccents}
          removeMonogram={() => this.removeMonogram()}
          handleIntialChange={(e) => this.handleIntialChange(e)}
          checkFontStyle={(font, e) => this.checkFontStyle(font, e)}
          chooseMonogramColor={(font, style) => this.chooseMonogramColor(font, style)}
          isSuit={true}
        />
        <SuitButtons
          selectedAccentsButton={blazerAccents?.selectedAccents}
          selectAccentsButton={(type) => this.manageBlazerAccents('button', type)}
        />
        <PocketSquare
          selectedPocketSquare={selectedPocketSquare}
          openPocketSquare={(type) => this.openBlazerPocketFabric('selectedPocketSquare', type)}
        />
        <div className="">
          <p className="H-l fs-13 text-lowercase pb-2 ">Suit Buttons and Colors</p>
          <div className='row row-gap-3 m-0'>
            <div className='col-lg-3 col-6 px-1'>
              <div className={`style_type ${selectedButtonsThreads === 'default' ? 'active_type' : ''}`}
                onClick={() => this.manageBlazerAccents('selectedButtonsThreads', 'default')}>
                <div className='style_img' >
                  <img src={images('./images/suit/SVG/No.svg')} alt="suit-accents" />
                </div>
                <p className="pt-1 H-l fs-13 text-lowercase">Default</p>
              </div>
            </div>
            <div className='col-lg-3 col-6 px-1'>
              <div className={`style_type ${selectedButtonsThreads === 'all' ? 'active_type' : ''}`}
                onClick={() => this.manageBlazerAccents('selectedButtonsThreads', 'all')}>
                <div className='style_img' >
                  <img src={images('./images/suit/SVG/DOUBLE-BREASTED 2 BUTTONS.svg')} alt="suit-accents" />
                </div>
                <p className="pt-1 H-l fs-13 text-lowercase">All</p>
              </div>
            </div>
            <div className='col-lg-3 col-6 px-1'>
              <div className={`style_type ${selectedButtonsThreads === 'cuffs' ? 'active_type' : ''}`}
                onClick={() => this.manageBlazerAccents('selectedButtonsThreads', 'cuffs')}>
                <div className='style_img' >
                  <img src={images('./images/suit/SVG/2.svg')} alt="suit-accents" />
                </div>
                <p className="pt-1 H-l fs-13 text-lowercase">Only Cuffs</p>
              </div>
            </div>
            {
              lapels && lapels.key !== 'shawl' && (
                <div className='col-lg-3 col-6 px-1'>
                  <div className={`style_type ${selectedButtonsThreads === 'lapel' ? 'active_type' : ''}`}
                    onClick={() => this.manageBlazerAccents('selectedButtonsThreads', 'lapel')}>
                    <div className='style_img' >
                      <img src={images('./images/suit/SVG/STANDARD.svg')} alt="suit-accents" />
                    </div>
                    <p className="pt-1 H-l fs-13 text-lowercase">Lapel Cuffs</p>
                  </div>
                </div>
                // <li className={selectedButtonsThreads === 'lapel' ? 'active' : ''}>
                //   <img
                //     src={images('./images/suit/SVG/STANDARD.svg')}
                //     className="img-fluid"
                //     alt="icons"
                //     onClick={() => this.manageBlazerAccents('selectedButtonsThreads', 'lapel')} />
                //   <p>LAPEL ONLY</p>
                // </li>
              )
            }
          </div>
        </div>
        {/* <ul className="row mx-auto p-0 w-90 my-sm-4 my-1 custom-collar cuff_desg">
          <h6 className="col-12 text-left px-0">SUIT BUTTONS AND COLORS</h6>
          <li className={selectedButtonsThreads === 'default' ? 'active' : ''}>
            <img
              src={images('./images/suit/SVG/No.svg')}
              className="img-fluid"
              alt="icons"
              onClick={() => this.manageBlazerAccents('selectedButtonsThreads', 'default')} />
            <p>BY DEFAULT</p>
          </li>
          <li className={selectedButtonsThreads === 'all' ? 'active' : ''}>
            <img
              src={images('./images/suit/SVG/DOUBLE-BREASTED 2 BUTTONS.svg')}
              className="img-fluid"
              alt="icons"
              onClick={() => this.manageBlazerAccents('selectedButtonsThreads', 'all')} />
            <p>ALL</p>
          </li>
          <li className={selectedButtonsThreads === 'cuffs' ? 'active' : ''}>
            <img
              src={images('./images/suit/SVG/2.svg')}
              className="img-fluid"
              alt="icons"
              onClick={() => this.manageBlazerAccents('selectedButtonsThreads', 'cuffs')} />
            <p>ONLY CUFFS</p>
          </li>
          {
            lapels && lapels.key !== 'shawl' && (
              <li className={selectedButtonsThreads === 'lapel' ? 'active' : ''}>
                <img
                  src={images('./images/suit/SVG/STANDARD.svg')}
                  className="img-fluid"
                  alt="icons"
                  onClick={() => this.manageBlazerAccents('selectedButtonsThreads', 'lapel')} />
                <p>LAPEL ONLY</p>
              </li>
            )
          }
        </ul> */}
        {
          selectedButtonsThreads !== 'lapel' && (
            <ButtonThreads
              chooseButtonThreadColor={(color) => this.manageBlazerAccents('thread', color)}
              selectedAccents={blazerAccents?.selectedAccents}
              isSuit={isSuit || isBlazer}
            />
          )
        }
        <ButtonHoles
          chooseButtonHoleColor={(color) => this.manageBlazerAccents('hole', color)}
          selectedAccents={blazerAccents?.selectedAccents}
          isSuit={isSuit || isBlazer}
        />


        <CanvasButtons
          name="Next"
          nextStep={() => this.navMeasurement()}
          handleOnClickOptions={(e) => this.handleOnClickOptions(e)}
          optionName={optionName}
          isShirt={isShirt}
          isPant={isPant}
          isSuit={isSuit}
          isBlazer={isBlazer}
          isVest={isVest}
        />

        <AccentsFabric
          showCategory={showAccentsLining}
          selectedFabric={blazerInnerFabric}
          products={products}
          openCategories={(id) => this.openBlazerLining('blazerInnerFabric', id)}
          closeSideBar={(id, name) => this.manageBlazerAccents('blazerInnerFabric', id, 'liningFabricName', name)}
          isSuit={true}
        />
        <AccentsFabric
          showCategory={showPocketSquare}
          selectedFabric={pocketSquareFabric}
          products={products}
          openCategories={(id) => this.openBlazerPocketFabric('pocketSquareFabric', id)}
          closeSideBar={(id, name) => this.manageBlazerAccents('pocketSquareFabric', id, 'pocketFabricName', name)}
          isSuit={true}
        />
        {
          isSuitMonogram && (
            <SuitMonogramInitials
              selectedAccents={blazerAccents?.selectedAccents}
              closeMonogramModal={() => this.closeMonogramModal()}
            />
          )
        }
      </React.Fragment>
    )
  }


  _renderVestAccents() {
    const { isSuitVest, optionName, showAccentsLining } = this.state
    const { isShirt, isPant, isBlazer, selectedVestAccents, isVest, isSuit } = this.props
    const { fabricList = {} } = this.props.fabricList
    const { collectionByHandle } = (fabricList && fabricList.data) || {}
    const { products } = collectionByHandle || {}
    return (
      <React.Fragment>
        <VestLining
          openVestInnerFabrics={(type) => this.openVestInnerFabrics(type)}
          selectedVestsLining={selectedVestAccents && selectedVestAccents.selectedAccents}
        />
        <SuitButtons
          selectedAccentsButton={selectedVestAccents && selectedVestAccents.selectedAccents}
          selectAccentsButton={(type) => this.selectAccentsButton(type)}
        />
        <ButtonThreads
          chooseButtonThreadColor={(color, thread) => this.chooseButtonThreadColor(color, thread)}
          selectedAccents={selectedVestAccents && selectedVestAccents.selectedAccents}
          isSuit={isSuit || isBlazer || isSuitVest}
        />
        <ButtonHoles
          chooseButtonHoleColor={(color, hole) => this.chooseButtonHoleColor(color, hole)}
          selectedAccents={selectedVestAccents && selectedVestAccents.selectedAccents}
          isSuit={isSuit || isBlazer || isSuitVest}
        />

        <CanvasButtons
          name="Next"
          nextStep={() => this.navMeasurement()}
          handleOnClickOptions={(e) => this.handleOnClickOptions(e)}
          optionName={optionName}
          isShirt={isShirt}
          isPant={isPant}
          isSuit={isSuit}
          isBlazer={isBlazer}
          isVest={isVest}
        />

        <AccentsFabric
          showCategory={showAccentsLining}
          selectedFabric={selectedVestAccents?.selectedVestInnerFabric}
          products={products}
          openCategories={() => this.openVestInnerFabrics()}
          closeSideBar={(id, name) => this.closeVestInnerFabrics(id, name)}
          isSuit={true}
        />

      </React.Fragment>
    )
  }


  _renderShirtAccents() {
    let { fields, showCollarCategory, showCuffCategory, showElbowCategory, optionName } = this.state
    const { isShirt, isBlazer, isPant, isSuit, isVest,
      selectedAccents: { shirtAccents } } = this.props
    const { fabricList = {} } = this.props.fabricList
    const { collectionByHandle } = (fabricList && fabricList.data) || {}
    const { products } = collectionByHandle || {}
    const { selectedCombination: { sleeve } } = this.props.selectedStyle
    let { selectedCollarFabric, selectedCuffFabric, elbowPatches, monogramPosition } = shirtAccents || {}
    return (
      <React.Fragment>
        <Monogram
          fields={fields}
          selectedAccents={shirtAccents}
          removeMonogram={() => this.removeMonogram()}
          handleIntialChange={(e) => this.handleIntialChange(e)}
          checkFontStyle={(e) => this.checkFontStyle(e)}
          chooseMonogramColor={(font) => this.chooseMonogramColor(font)}
        />

        <MonogramPosition
          monogramPosition={monogramPosition}
          sleeve={sleeve}
          moveMonogramPosition={(position) => this.moveMonogramPosition(position)}
        />

        <ShirtAccentsCollar
          selectedCollarFabric={selectedCollarFabric}
          openCollarCategories={(type) => this.openCollarCategories(type)}
        />

        <ShirtAccentsCuff
          selectedCuffFabric={selectedCuffFabric}
          openCuffCategories={(type) => this.openCuffCategories(type)}
        />

        <ShirtAccentsButton
          fields={fields}
          customizeButton={(button) => this.customizeButton(button)}
        />

        <ButtonHoles
          chooseButtonHoleColor={(color) => this.chooseButtonHoleColor(color)}
          selectedAccents={shirtAccents}
          isShirt={isShirt}
        />
        <ButtonThreads
          chooseButtonThreadColor={(color) => this.chooseButtonThreadColor(color)}
          selectedAccents={shirtAccents}
          isShirt={isShirt}
        />
        {
          sleeve?.key === 'long' && <ShirtAccentsElbow
            elbowPatches={elbowPatches}
            addElbowPatches={(type) => this.addElbowPatches(type)}
          />
        }


        <CanvasButtons
          name="Next"
          nextStep={() => this.navMeasurement()}
          handleOnClickOptions={(e) => this.handleOnClickOptions(e)}
          optionName={optionName}
          isShirt={isShirt}
          isPant={isPant}
          isSuit={isSuit}
          isBlazer={isBlazer}
          isVest={isVest}
        />


        {
          showCollarCategory && (
            <AccentsFabric
              showCategory={showCollarCategory}
              selectedFabric={selectedCollarFabric}
              products={products}
              openCategories={(fabric) => this.openCollarCategories(fabric)}
              closeSideBar={(id, title) => this.closeCollarCategory(id, title)}
            />
          )
        }
        {
          showCuffCategory && (
            <AccentsFabric
              showCategory={showCuffCategory}
              selectedFabric={selectedCuffFabric}
              products={products}
              openCategories={(fabric) => this.openCuffCategories(fabric)}
              closeSideBar={(id, title) => this.closeSideCuffCategory(id, title)}
            />
          )
        }
        {
          showElbowCategory && (
            <AccentsFabric
              showCategory={showElbowCategory}
              selectedFabric={elbowPatches}
              products={products}
              openCategories={(fabric) => this.addElbowPatches(fabric)}
              closeSideBar={(id, title) => this.closeSideElbowCategory(id, title)}
            />
          )
        }

      </React.Fragment >
    )
  }


  render() {
    return (
      <div className='d-flex flex-column row-gap-4 pb-lg-4 pb-3 canvas_style_contents'>
        {
          this._renderParentAccents()
        }
      </div>
    )

  }
}
const mapStateToProp = (state) => {
  return {
    fabricList: Actions.getFabrics(state),
    token: Actions.getTokenSelector(store.getState()) || {},
    getMeasurement: Actions.getSelectedMeasurement(store.getState()) || {},
    getCheckoutDetails: Actions.getCheckoutId(store.getState()),
    selectedStyle: Actions.getShirtStyle(state),
    selectedAccents: Actions.getShirtAccent(state),
    selectedSuitFabric: Actions.getSelectedSuitFabric(state),
    selectedSuitAccents: Actions.getSelectedSuitAccent(state),
    selectedSuitStyle: Actions.getSelectedSuitStyle(state),
    selectedFabric: Actions.getShirtFabric(state),
    getNewCheckOutId: Actions.getNewCheckOutId(state),
    blazerFabric: Actions.getSelectedBlazerFabric(state),
    selectedBlazerStyle: Actions.getSelectedBlazerStyle(state),
    blazerAccents: Actions.getSelectedBlazerAccent(state),
    selectedVestAccents: Actions.getSelectedVestAccent(state),
    selectedVestStyle: Actions.getSelectedVestStyle(state),
    selectedVestFabric: Actions.getVestFabric(state)
  }
}

export default withRouter(connect(mapStateToProp, {
  updateSelectedAccent: Actions.updateShirtAccent,
  removeShirtMonogram: Actions.removeShirtMonogram,
  getFabricList: Actions.getFabricList,
  updateSelectedSuitAccent: Actions.updateSelectedSuitAccent,
  getRecentCheckOutDetails: Actions.getRecentCheckOutDetails,
  checkoutAttributesUpdate: Actions.checkoutAttributesUpdate,
  updateMeasurementOptions: Actions.updateMeasurementOptions,
  checkoutLineItemsAdd: Actions.checkoutLineItemsAdd,
  checkoutLineItemsUpdate: Actions.checkoutLineItemsUpdate,
  checkoutCreate: Actions.checkoutCreate,
  updateSelectedBlazerAccent: Actions.updateSelectedBlazerAccent,
  removeBlazerMonogram: Actions.removeBlazerMonogram,
  updateSelectedVestAccents: Actions.updateSelectedVestAccent
})(CanvasAccents));
